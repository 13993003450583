(function () {
    'use strict';
    angular
        .module('currencyExchangeModule')
        .constant('XE_CURRENCIES_FORMAT', {
                "AUD": {
                    "format": "A$#"
                },
                "BGN": {
                    "format": "# leva"
                },
                "BRL": {
                    "format": "R$#"
                },
                "CAD": {
                    "format": "C$#"
                },
                "CHF": {
                    "format": "CHF #"
                },
                "CNY": {
                    "format": "元#"
                },
                "CZK": {
                    "format": "# Kč",
                    "fractional": false
                },
                "DKK": {
                    "format": "# kr."
                },
                "EUR": {
                    "format": "€#"
                },
                "GBP": {
                    "format": "£#"
                },
                "HKD": {
                    "format": "HK$#"
                },
                "HRK": {
                    "format": "# kn"
                },
                "HUF": {
                    "format": "# Ft",
                    "fractional": false
                },
                "IDR": {
                    "format": "Rp #",
                    "fractional": false
                },
                "ILS": {
                    "format": "₪#"
                },
                "INR": {
                    "format": "₹#"
                },
                "ISK": {
                    "format": "#kr",
                    "fractional": false
                },
                "JPY": {
                    "format": "¥ #",
                    "fractional": false
                },
                "KRW": {
                    "format": "₩#",
                    "fractional": false
                },
                "MXN": {
                    "format": "$#"
                },
                "MYR": {
                    "format": "RM#"
                },
                "NOK": {
                    "format": "# kr",
                    "fractional": false
                },
                "NZD": {
                    "format": "$#"
                },
                "PHP": {
                    "format": "₱#"
                },
                "PLN": {
                    "format": "#zł"
                },
                "RON": {
                    "format": "# lei"
                },
                "RUB": {
                    "format": "#₽"
                },
                "SEK": {
                    "format": "# kr",
                    "fractional": false
                },
                "SGD": {
                    "format": "S$#"
                },
                "THB": {
                    "format": "฿#"
                },
                "TRY": {
                    "format": "₺#"
                },
                "USD": {
                    "format": "$#"
                },
                "ZAR": {
                    "format": "R #"
                }
            }
        );


})();
