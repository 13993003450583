(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('LegalFooterController', LegalFooterController);

    LegalFooterController.$inject = ['$state','$rootScope', 'ModalService', '$transitions', '$window'];

    function LegalFooterController ($state, $rootScope, ModalService, $transitions, $window) {
        var vm = this;
        vm.today = new Date();

        vm.isGDPR = ($window && $window.IN1 && $window.IN1.isGDPR) ? $window.IN1.isGDPR : false;

        var modal;

        vm.openCookiePreferencesTab = function(){

            modal = ModalService.open({
                templateUrl: 'app/components/cookies-consent/cookies-preferences-modal.html',
                controller: 'CookiesBannerController',
                controllerAs: 'vm',
                backdrop: true,
                animation: false,
                size: 'lg',
                resolve: {

                },
            });

            $state.cookiePreferencesModal = modal;

        }

    }



})();
