(function() {
    'use strict';


    //var langs = {};


    angular
        .module('vobeApp')

        /*
         Languages codes are ISO_639-1 codes, see http://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
         They are written in English to avoid character encoding issues (not a perfect solution)
         */
        .constant('LANGUAGES', [
            'en',
            'en_GB',
            'en_IE',
            //'en-gb',//these will not work as mappings will never resolve to this
            //'en-ie',//these will not work as mappings will never resolve to this
            'ru',
            'es',
            'fr',
            'nl',
            'it',
            'pl',
            'zh',
            'he',
            'iw',
            'ar',
            'ja',
            'ko',
            'de',
            'pt',
            'hr',
            // jhipster-needle-i18n-language-constant - JHipster will add/remove languages in this array
        ])
        .constant('LANGUAGES_MAPPINGS', {
            'en': 'en',
            //this will never work as $translate will use for-each (=keys are enumerated) to loop and order is not guaranteed (as oppose to Object.keys(obj) which is guaranteed) https://exploringjs.com/es6/ch_oop-besides-classes.html#_traversal-order-of-properties
            //en_GB': 'en-gb',
            //en_IE': 'en-ie',
            'ru': 'ru',
            'es': 'es',
            'fr': 'fr',
            'nl': 'nl',
            'it': 'it',
            'pl': 'pl',
            'zh': 'zh',
            'he': 'he',
            'iw': 'iw',
            'ar': 'ar',
            'ja': 'ja',
            'ko': 'ko',
            'de': 'de',
            'pt': 'pt',
            'hr': 'hr',

            'en_*': 'en',
            'ru_*': 'ru',
            'es_*': 'es',
            'fr_*': 'fr',
            'nl_*': 'nl',
            'it_*': 'it',
            'pl_*': 'pl',
            'zh_*': 'zh',
            'he_*': 'he',
            'iw_*': 'iw',
            'ar_*': 'ar',
            'ja_*': 'ja',
            'ko_*': 'ko',
            'de_*': 'de',
            'pt_*': 'pt',
            'hr_*': 'hr',
            '*': 'en'
        });
})();
