(function() {
    'use strict';

    angular.module('currencyExchangeModule', []).provider('CurrencyExchange', CurrencyExchangeProvider);

    function isInt(value){
        return !isNaN(value) && parseInt(value) === value;
    };

    function Currency(code, symbol, rate, precision, decimal, thousands, post) {
        this.code = code;
        this.symbol = symbol;
        this.precision = isInt(precision) ? precision : 2;
        this.decimal = decimal || '.';
        this.thousands = thousands || ',';
        this.post = post || false;
        this.rate = rate || 1;
        this.isInt = isInt;
    }

    function CurrencyExchangeProvider(
        XE_CURRENCIES
    ) {
        'ngInject';

        var isReady = { currencies: false };
        var currencyCodes = {all: []};
        var currencies = {all: {}, current: null, base: null};
        var currencyWaiting = '';


        var addBaseCurrency = function(code){
            currencies.base = currencies.all[code];
            currencies.current = currencies.all[code];
            isReady.currencies = true;
        }

        var changeCurrentCurrency = function(code){
            currencies.current = currencies.all[code];
        }

        var resetCurrentCurrency = function(){
            currencies.current = null;
        }

        var addAllCurrencies = function(currencyArr){
            angular.forEach(currencyArr,function(currency,i){
                var precision = XE_CURRENCIES[currency.code] ? XE_CURRENCIES[currency.code][0] : 2;
                var symbol = XE_CURRENCIES[currency.code] ? XE_CURRENCIES[currency.code][1] : currency.symbol;
                addCurrency(currency.code, symbol, currency.eurExchange, precision)
            });
            console.log('CurrencyExchangeProvider addAllCurrencies', currencies.all)
        }

        var setActiveCurrencies = function(activeCurrencies){
            currencyCodes.all = activeCurrencies;
        }

        var addCurrency = function(code, symbol, rate, precision, decimal, thousands, post){
            currencies.all[code] = new Currency(code, symbol, rate, precision, decimal, thousands, post);
        }


        var publicService = function(CurrenciesService,$rootScope) {
            'ngInject';

            return {
                initCurrencies: function() {
                    console.log('CurrencyExchangeProvider initCurrencies');
                    var currenciesPromise = CurrenciesService.get().$promise;
                    var currenciesPromiseChain1 = currenciesPromise.then(function(data) {
                        addAllCurrencies(data);
                    })
                    return currenciesPromiseChain1;
                },
                changeCurrency: function(code) {
                    changeCurrentCurrency(code);
                    $rootScope.$broadcast('CurrencyExchangeProvider.changeCurrency', currencies.current);
                    console.log('CurrencyExchangeProvider change current currency: ', currencies.current);
                },
                addBaseCurrency: function(code) {
                    addBaseCurrency(code);
                    console.log('CurrencyExchangeProvider base currency: ', currencies.base);
                },
                setActiveCurrencies: function(activeCurrencies) {
                    setActiveCurrencies(activeCurrencies);
                    console.log('CurrencyExchangeProvider active currencies', currencyCodes.all)
                },
                resetCurrency : function(){
                    resetCurrentCurrency();
                },
                currencyCodes: currencyCodes,
                currencies: currencies
            };
        }


        return {
            $get : publicService
        }



    }
})();
