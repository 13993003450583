(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('VobeConfig', VobeConfig);

    VobeConfig.$inject = ['$resource'];

    function VobeConfig($resource) {

        return {
            config: $resource('api/config?propertyIds=:propertyIds', {}, {
                'query': {method: 'GET', isArray: false},
                'get': {
                    method: 'GET',
                    ignoreLoadingBar: true,
                    transformResponse: function (data) {
                        if(!data || (typeof data === 'string' && data.length==0)){
                            return data;
                        }
                        data = angular.fromJson(data);
                        return data;
                    }
                }
            }),
            emailConfig: $resource('api/config/email?propertyIds=:propertyIds', {}, {
                'query': {method: 'GET', isArray: false},
                'get': {
                    method: 'GET',
                    ignoreLoadingBar: true,
                    transformResponse: function (data) {
                        if(!data || (typeof data === 'string' && data.length==0)){
                            return data;
                        }
                        data = angular.fromJson(data);
                        return data;
                    }
                }
            })
        }

    }
})();
