(function() {
    'use strict';


    angular
        .module('vobeApp')
        .filter('numberInteger', numberInteger);

    numberInteger.$inject = ['$filter'];

    function numberInteger ($filter) {
    	return function (number) {
    		if(isNaN(number)){
    			return number;
    		}
    		//console.log('numberInteger from: ',number);
    		var price = parseInt(number);
    		return $filter("currency")(price, "", 0).trim();
    	};
    }
})();
