(function() {
    'use strict';

    angular.module('vobeApp').controller('CurrencyDropdownController', CurrencyDropdownController);

    function CurrencyDropdownController(
        $scope,
        $rootScope,
        $state,
        $stateParams,
        $translate,
        $q,
        CurrencyExchange,
        $localStorage

    ) {
        'ngInject';
        var vm = this;
        var currencyApi = CurrencyExchange;

        vm.currencies = currencyApi.currencies.all;//it's map with code as key-value as currencyCode-currencyObject
        vm.availableCurrencies = currencyApi.currencyCodes.all;

        //vm.baseCurrency = currencyApi.currencies.base;//base currency might be undefined if this is group engine
        vm.currentCurrency = currencyApi.currencies.current;//current currency might be NULL if this is group engine

        vm.changeCurrency = changeCurrency;

        if(vm.onAvailableCurrencies){
            vm.onAvailableCurrencies(vm.availableCurrencies);
        }

        function changeCurrency(currency) {

            if(currency) {
                currencyApi.changeCurrency(currency.code);
            } else {
                currencyApi.resetCurrency();
            }

            vm.currentCurrency = currencyApi.currencies.current;

            if(vm.onUpdate){
                vm.onUpdate(currency);
            }
        }
    }
})();
