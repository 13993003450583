(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('CldrDataService', CldrDataService);

    CldrDataService.$inject = ['$resource'];

    function CldrDataService ($resource) {
        var service = $resource('/i18n/cldr-data/main/:language/:name.json', {language:'@language',name:'@name'}, {
            'getMain': {
                method: 'GET',
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getSupplemental': {
                url:'/i18n/cldr-data/supplemental/:name.json',
                method: 'GET',
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;

    }
})();
