(function() {
    'use strict';

    angular
        .module('vobeApp')
        .factory('faviconHandler', faviconHandler);

    faviconHandler.$inject = ['$rootScope', '$window', '$state'];

    function faviconHandler($rootScope, $window, $state) {

        var _doc = $window.document;

        return {
            initialize: initialize,
            updateFavicon: updateFavicon
        };

        function initialize() {

        }

        function updateFavicon() {
            var _orig = getIcons();
            if ($state.params.propertyId && ($state.params.propertyId === 'tgbvus' || $state.params.channel === '20')) {
                var _orig = getIcons();
                _orig.forEach(function(icon) {
                    icon.setAttribute('href', '/favicon-guestbook.ico' );
                });
            } /*else {
                _orig.forEach(function(icon) {
                    icon.setAttribute('href', '/favicon.png' );
                });
            }*/
        }

        function getIcons() {
            var elms = [];
            //get link element
            var getLinks = function () {
                var icons = [];
                var links = _doc.getElementsByTagName('head')[0].getElementsByTagName('link');
                for (var i = 0; i < links.length; i++) {
                    if ((/(^|\s)icon(\s|$)/i).test(links[i].getAttribute('rel'))) {
                        icons.push(links[i]);
                    }
                }
                return icons;
            };

            //if link element
            elms = getLinks();
            if (elms.length === 0) {
                elms = [_doc.createElement('link')];
                elms[0].setAttribute('rel', 'icon');
                _doc.getElementsByTagName('head')[0].appendChild(elms[0]);
            }
            elms.forEach(function(item) {
                item.setAttribute('type', 'image/png');
            });
            return elms;
        };
    }
})();
