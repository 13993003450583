(function() {
    'use strict';

    angular
        .module('vobeApp')
        .factory('translationStorageProvider', translationStorageProvider);

    function translationStorageProvider($cookies, $log, LANGUAGES, LANGUAGES_MAPPINGS) {
        'ngInject';

        return {
            get: get,
            put: put
        };

        function get(name) {
            var preferred = $cookies.getObject(name);
            //preferred = getAlias(preferred);
        	//console.log("getting cookie: "+name+", value: "+$cookies.getObject(name));
            if (/*preferred && */LANGUAGES.indexOf(preferred) === -1) {
                $log.info('Resetting invalid cookie language "' + $cookies.getObject(name) + '" to prefered language "en"');
                //$cookies.putObject(name, 'en');
            }
            return preferred;
        }

        function put(name, value) {
        	//console.log("putting cookie lang: "+value);
        	//console.trace();
            $cookies.putObject(name, value);
        }

        function getAlias(preferred){
            if(!preferred){
                return preferred;
            }
            if (LANGUAGES_MAPPINGS) {
                var alias;
                for (var langKeyAlias in LANGUAGES_MAPPINGS) {
                    if (LANGUAGES_MAPPINGS.hasOwnProperty(langKeyAlias)) {
                        var hasWildcardKey = false;
                        var hasExactKey = Object.prototype.hasOwnProperty.call(LANGUAGES_MAPPINGS, langKeyAlias) &&
                            langKeyAlias.toLowerCase() === preferred.toLowerCase();

                        if (langKeyAlias.slice(-1) === '*') {
                            hasWildcardKey = langKeyAlias.slice(0, -1) === preferred.slice(0, langKeyAlias.length - 1);
                        }
                        if (hasExactKey || hasWildcardKey) {
                            alias = LANGUAGES_MAPPINGS[langKeyAlias];
                            if (LANGUAGES.indexOf(alias.toLowerCase()) > -1) {
                                return alias;
                            }
                        }
                    }
                }
            }
        }
    }
})();
