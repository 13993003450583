(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PropertyVouchersNavbarController', PropertyVouchersNavbarController);

    function PropertyVouchersNavbarController ($rootScope,$scope,$state,$location, Auth, Principal, ProfileService, LoginService, $translate, CurrencyExchange) {
        'ngInject';
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isWorldNavbarCollapsed = true;
        vm.isSortNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.currentLanguageCode = $translate.use();
        vm.currentCurrency = CurrencyExchange.currencies.current;//current currency might be NULL if this is group engine


        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.toggleWorldNavbar = toggleWorldNavbar;
        vm.collapseWorldNavbar = collapseWorldNavbar;
        vm.toggleSortNavbar = toggleSortNavbar;
        vm.collapseSortNavbar = collapseSortNavbar;


        vm.languages;
        vm.onAvailableLanguages = function(languages){
            vm.languages=languages;
        }
        vm.availableCurrencies;
        vm.onAvailableCurrencies = function(availableCurrencies){
            vm.availableCurrencies=availableCurrencies;
        }

        vm.onCurrencyChanged = function(currency){
            if(vm.onCurrencyUpdated/*from component*/){
                vm.onCurrencyUpdated(currency);
            }
            vm.currentCurrency = CurrencyExchange.currencies.current;
        }

        vm.$state = $state;
        vm.stateParams = $location.search();

        //vm.shoppingCartConfigs = shoppingCartConfigs;
        //vm.currentProperty = currentProperty;


        function toggleWorldNavbar() {
            vm.isWorldNavbarCollapsed = !vm.isWorldNavbarCollapsed;
        }
        function collapseWorldNavbar() {
            vm.isWorldNavbarCollapsed = true;
        }

        function toggleSortNavbar() {
            vm.isSortNavbarCollapsed = !vm.isSortNavbarCollapsed;
        }
        function collapseSortNavbar() {
            vm.isSortNavbarCollapsed = true;
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }
        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }


        vm.openShoppingCartNavbar = openShoppingCartNavbar;
        function openShoppingCartNavbar() {
        	$rootScope.$emit('openShoppingCartDropdown');
        }


        vm.$onInit = function(){

            vm.useCategorySequence = false;
            var propertyId = vm.currentProperty.hotelId;
            if (vm.shoppingCartConfigs[propertyId] && vm.shoppingCartConfigs[propertyId].configMap && vm.shoppingCartConfigs[propertyId].configMap.useCategorySequence && vm.shoppingCartConfigs[propertyId].configMap.useCategorySequence=='true') {
                vm.useCategorySequence = true;
            }
            //vm.useCategorySequence = true;//for testing purposes

            vm.determineVoucherPoolOrderBy = function(){
                var mainOrderBy = 'sequence';
                if(vm.settings && vm.settings.voucherPoolOrderBy && vm.settings.voucherPoolOrderBy.name){
                    var mainOrderBy = vm.settings.voucherPoolOrderBy.name.split("_")[0];
                }
                var voucherCategoryShortCodes = vm.voucherClassActive ? vm.voucherClassActive.split(",") : [];
                if(mainOrderBy == 'sequence' && vm.useCategorySequence && voucherCategoryShortCodes.length==1 && voucherCategoryShortCodes[0]!='all'){
                    vm.voucherPoolOrderBy = 'sequence_'+voucherCategoryShortCodes[0];
                } else {
                    vm.voucherPoolOrderBy = mainOrderBy;
                }
                //UPDATE PARENT
                vm.settings.voucherPoolOrderBy = vm.settings.voucherPoolOrderBy != vm.voucherPoolOrderBy ? vm.voucherPoolOrderBy : vm.settings.voucherPoolOrderBy;
            }
            //INITIAL SORT
            vm.determineVoucherPoolOrderBy();

            //$scope.$watch('vm.settings.voucherPoolOrderBy.name', function () {
            //    console.log('vm.settings.voucherPoolOrderBy.name: ',vm.settings.voucherPoolOrderBy.name);
            //});

        }


    }
})();
