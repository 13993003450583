(function() {
    'use strict';

    angular
        .module('vobeApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ngAnimate',
            'ngSanitize',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            'duScroll',
            'slickCarousel',
            'payment',
            'ui.select',
            'angular-loading-bar',
            'angular-google-analytics',
            'rzSlider',
            "oc.lazyLoad",
            'currencyExchangeModule',
            'ngAria'
            //'wu.masonry'
            //'ngHandsontable',//I want this to be loaded ASYNC -> not in the webpack vendor.js or main.js, so using "oc.lazyLoad"
            // jhipster-needle-angularjs-add-module JHipster will add new module here

        ])
        .config(config)
        .config(analytics)
        .config(tagmanager)
        .config(stripeElements)
        .config(configLazyLoad)
        .run(run);

    stripeElements.$inject = ['StripeElementsProvider'];
    function stripeElements(StripeElementsProvider) {
        console.log('stripe elements provider init');
    }

    angular.element(function() {
        angular.bootstrap(document, ['vobeApp']);
    });

    function run(stateHandler, translationHandler, $rootScope, $timeout, $uibModalStack, $http, ENV, $transitions, $window, CookiesConsentStorageService, IN1_CONSENT) {
        'ngInject';
        stateHandler.initialize();
        translationHandler.initialize();

        //very important, this will enable analytics to pick up proper page tile which is ASYNC
        $transitions.onSuccess({}, function(transition) {
            $timeout(function() {
                $rootScope.$broadcast('$stateChangeSuccessAnalytics');
                if (typeof posthog !== 'undefined') {
                    posthog.capture('$pageview');
                }
            }, 10);

        });
    }

    function config($locationProvider, $qProvider, $sceDelegateProvider) {
        'ngInject';
        $locationProvider.hashPrefix('');
        $locationProvider.html5Mode({ enabled: true, requireBase: true });
        $qProvider.errorOnUnhandledRejections(false); //this stops errors being throws for unhandled rejections.
        $sceDelegateProvider.resourceUrlWhitelist([
            // Allow same origin resource loads.
            'self',
            // Allow loading from our assets domain. **.
            'https://go.mips.mu/**'
        ]);
    }

    tagmanager.$inject = ['TagManagerProvider'];

    function tagmanager(TagManagerProvider) {

        TagManagerProvider.setPageEvent('$stateChangeSuccessAnalytics');
        TagManagerProvider.ignoreFirstPageLoad(false);
        TagManagerProvider.delayScriptTag(true);
        TagManagerProvider.trackUrlParams(true);
    }

    function configLazyLoad($ocLazyLoadProvider) {
        'ngInject';
        $ocLazyLoadProvider.config({
            debug: true,
            serie: true,
            modules: [{
                name: 'ngHandsontable',
                files: [
                    'content/lazyload/handsontable/handsontable.full.min.js',
                    'content/lazyload/handsontable/handsontable.full.min.css',
                    'content/lazyload/handsontable/ngHandsontable.js'
                ],
                serie: true
            }]
        });

        $ocLazyLoadProvider.config({
            modules: [{
                name: 'zapper',
                files: [
                    'content/lazyload/zapper/zapper.ecommerce-2.0.2.min.js'
                ]
            }]
        });
    }

    analytics.$inject = ['AnalyticsProvider','$windowProvider'];

    function analytics(AnalyticsProvider, $windowProvider) {

        var $window = $windowProvider.$get();


        //no GTM and GA in China!
        var lang = $window.navigator.language || $window.navigator.userLanguage;
        if(lang && (lang.toLowerCase()=='zh-cn' || lang.toLowerCase()=='zh') &&  new Date().getTimezoneOffset()/60 == -8){
            //console.log("detected chinese user");
            //AnalyticsProvider.startOffline(true);
        }

        //AnalyticsProvider.logAllCalls(true);
        //AnalyticsProvider.enterDebugMode(true);
        AnalyticsProvider.delayScriptTag(true);

        //default values for above trackers, if they don't set those properties
        AnalyticsProvider.setAccount([]);
        AnalyticsProvider.useAnalytics(true); //Use analytics.js (universal)  instead of  ga.js (classic)
        AnalyticsProvider.useECommerce(true, true); //uses enhanced es.js (not ecommerce.js)
        AnalyticsProvider.trackUrlParams(true);
        AnalyticsProvider.trackPages(true);
        AnalyticsProvider.setDomainName('none');
        AnalyticsProvider.setPageEvent('$stateChangeSuccessAnalytics'); //$stateChangeSuccess
        AnalyticsProvider.useCrossDomainLinker(true);
        //AnalyticsProvider.setCrossLinkDomains(['bookin1.com']);

        // Ignore first page view (default is false).
        // Helpful when using hashes and whenever your bounce rate looks obscenely low.
        AnalyticsProvider.ignoreFirstPageLoad(true);

        //big HACK to make currency mutable
        var currency = new MutableString('USD');
        AnalyticsProvider.setCurrency(currency);
    }

    var MutableString = function(value) {
        String.call(this, value);
        this.text = value;
    };

    var tmp = function() {};
    tmp.prototype = String.prototype;
    MutableString.prototype = new tmp();
    MutableString.prototype.constructor = MutableString;

    MutableString.prototype.setText = function(text) {
        this.text = text;
    };
    MutableString.prototype.toString = function() {
        return this.text;
    };
    MutableString.prototype.valueOf = function() {
        return this.text;
    };
})();
