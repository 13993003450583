(function() {
    'use strict';

    angular.module('vobeApp').directive('ellipsisTextInner',
        ellipsisTextInner);

    ellipsisTextInner.$inject = ['$sce'];

    function ellipsisTextInner($sce) {
        var directive = {
            restrict : 'A',
            //scope: {
                //options : '=ellipsisTextInner'
            //},
            //transclude : true,
            //template: '<div class="w3-text-grey description-text-inner" style="overflow: hidden;"><div ng-transclude></div></div>',
            //Require another directive and inject its controller as the fourth argument to the linking function.
            //The require property can be a string, an array or an object:
            //require: 'siblingDirectiveName', // or // ['^parentDirectiveName', '?optionalDirectiveName', '?^optionalParent'],
            require: '^ellipsisText',
            link : postLinkFunc
        };

        return directive;

        function postLinkFunc($scope, $element, $attrs, ellipsisText) {

            $element.ready(function(){

                var height = $element.height();
                var fontSizePx = $element.css('font-size');
                var fontSize = parseInt(fontSizePx.replace('px',''));

                //console.log("ellipsisTextInner",height,fontSize);

                //reporting height to parent:
                ellipsisText.innerHeight(height,fontSize);
            });


        }
    };
})();
