(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PublicSiteTranslationPropertyDetailController', PublicSiteTranslationPropertyDetailController);

    PublicSiteTranslationPropertyDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PublicSiteTranslationProperty', 'PublicSitePartRelationship', 'PublicSitePartKey', 'PublicSiteLanguage'];

    function PublicSiteTranslationPropertyDetailController($scope, $rootScope, $stateParams, previousState, entity, PublicSiteTranslationProperty, PublicSitePartRelationship, PublicSitePartKey, PublicSiteLanguage) {
        var vm = this;

        vm.publicSiteTranslationProperty = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vobeApp:publicSiteTranslationPropertyUpdate', function(event, result) {
            vm.publicSiteTranslationProperty = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
