(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('CurrenciesService', CurrenciesService);

    CurrenciesService.$inject = ['$resource'];

    function CurrenciesService ($resource) {
        var service = $resource('api/currency/list', {}, {
            'get': {
                method: 'GET',
                isArray: true,
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
        return service;
    }
})();
