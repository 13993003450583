(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PublicSitePartRelationshipDetailController', PublicSitePartRelationshipDetailController);

    PublicSitePartRelationshipDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PublicSitePartRelationship', 'PublicSitePart', 'PublicSite'];

    function PublicSitePartRelationshipDetailController($scope, $rootScope, $stateParams, previousState, entity, PublicSitePartRelationship, PublicSitePart, PublicSite) {
        var vm = this;

        vm.publicSitePartRelationship = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vobeApp:publicSitePartRelationshipUpdate', function(event, result) {
            vm.publicSitePartRelationship = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
