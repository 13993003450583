(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PublicSiteLanguageRelationshipDetailController', PublicSiteLanguageRelationshipDetailController);

    PublicSiteLanguageRelationshipDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PublicSiteLanguageRelationship', 'PublicSite'];

    function PublicSiteLanguageRelationshipDetailController($scope, $rootScope, $stateParams, previousState, entity, PublicSiteLanguageRelationship, PublicSite) {
        var vm = this;

        vm.publicSiteLanguageRelationship = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vobeApp:publicSiteLanguageRelationshipUpdate', function(event, result) {
            vm.publicSiteLanguageRelationship = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
