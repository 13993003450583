(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$rootScope','$state', '$location', 'Auth', 'Principal', 'ProfileService', 'LoginService'];

    function NavbarController ($rootScope,$state,$location, Auth, Principal, ProfileService, LoginService) {
        var vm = this;        
        
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.stateParams = $location.search();

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }
        
        vm.openShoppingCartNavbar = openShoppingCartNavbar;
        function openShoppingCartNavbar() {
        	$rootScope.$emit('openShoppingCartDropdown');
        }        
        
        

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
    }
})();
