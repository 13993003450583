(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PublicSitePartKeyDialogController', PublicSitePartKeyDialogController);

    PublicSitePartKeyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PublicSitePartKey', 'PublicSitePart'];

    function PublicSitePartKeyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PublicSitePartKey, PublicSitePart) {
        var vm = this;

        vm.publicSitePartKey = entity;
        vm.clear = clear;
        vm.save = save;
        vm.publicsiteparts = PublicSitePart.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.publicSitePartKey.id !== null) {
                PublicSitePartKey.update(vm.publicSitePartKey, onSaveSuccess, onSaveError);
            } else {
                PublicSitePartKey.save(vm.publicSitePartKey, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('vobeApp:publicSitePartKeyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
