(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('HeroController', HeroController);

    HeroController.$inject = ['$state','$rootScope'];

    function HeroController ($state, $rootScope) {
        var vm = this;
        vm.property;
        
        $rootScope.$on('propertySelected', function(event, args) {
        	vm.property = args.property;
        }); 
        
    }
    
    
    
})();
