(function() {
    'use strict';

    angular
        .module('vobeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('app', {
                abstract: true,
                url : "?src&ref&passkey&channel&lang",
                views: {
                    'navbar@': {
                        templateUrl: 'app/layouts/navbar/navbar.html',
                        controller: 'NavbarController',
                        controllerAs: 'vm'
                    },
                    'header@': {
                        templateUrl: 'app/layouts/hero/hero.html',
                        controller: 'HeroController',
                        controllerAs: 'vm'
                    },
                    'footer@': {
                        //templateUrl: 'app/layouts/hero/hero.html',
                        //controller: 'HeroController',
                        //controllerAs: 'vm'
                    },
                    'legal@' : {
                        templateUrl: 'app/layouts/footer/legal.html',
                        controller: 'LegalFooterController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    authorize: ['Auth',
                        function (Auth) {
                            return Auth.authorize();
                        }
                    ],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        //console.log("in app state addPart global");
                        $translatePartialLoader.addPart('global');
                        //this state is abstract just add the part, but don't hit servers, don't return anything
                        //return $translate.refresh();
                    }]
                }
            });
    }
})();
