(function() {
    'use strict';

    angular.module('vobeApp').component('shoppingCartNavbarButton', {
        templateUrl: 'app/components/shopping-cart/shopping-cart-dropdown/shopping-cart-navbar.html',
        controller: 'ShoppingCartDropdownButtonController',//same controller as dropdown button
        controllerAs: 'vm',
        bindings: {
            shoppingCart: '<',
            shoppingCartConfigs: '<',
            property: '<',
            propertyGroup: '<'
        }
    });
})();
