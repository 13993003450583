(function() {
    'use strict';

    angular.module('vobeApp').component('shoppingCartDropdownButton', {
        templateUrl: 'app/components/shopping-cart/shopping-cart-dropdown/shopping-cart-dropdown.html',
        controller: 'ShoppingCartDropdownButtonController',
        controllerAs: 'vm',
        bindings: {
            shoppingCart: '<',
            shoppingCartConfigs: '<',
            property: '<',
            propertyGroup: '<',
            websiteType: '<',//group or property
        }
    });
})();
