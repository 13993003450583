(function() {
    'use strict';

    angular.module('vobeApp')
        .component('currencyDropdownButton', {
            templateUrl: 'app/components/shopping-cart/currencyDropdown/currency-dropdown-button.html',
            controller: 'CurrencyDropdownController',
            controllerAs: 'currencyVm',
            bindings: {
                onUpdate: '<'
            }
        });
})();
