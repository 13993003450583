(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('ShoppingCartDropdownController', ShoppingCartDropdownController);

    ShoppingCartDropdownController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'shoppingCart', 'ShoppingCartRemove', '$translate'];

	function ShoppingCartDropdownController($scope, $rootScope, $state, $stateParams, shoppingCart, ShoppingCartRemove, $translate) {
	    var vm = this;

	    vm.shoppingCart = shoppingCart;
	    vm.removeItemFromCartAndRefresh = removeItemFromCartAndRefresh;
	    vm.getAnyItemByPropertyId = getAnyItemByPropertyId;
	    vm.goToShoppingCart = goToShoppingCart;
	    vm.maxHeighStyle = maxHeighStyle();

        vm.shoppingCartOpen = false;
        vm.shoppingCartToggled = shoppingCartToggled;
        vm.perishableAlerts = [];

	    function maxHeighStyle(){
	    	var viewportHeight = $(window).height();
	    	return {'max-height':(viewportHeight - 50)+'px'};
	    }

	    function getAnyItemByPropertyId(propertyId){
	    	var anyItem;
	    	angular.forEach(vm.shoppingCart.shoppingCartItems.items,function(item,index){
	    		if(item.owningProperty.propertyId==propertyId){
	    			anyItem = item;
	    		}
	    	});
	    	return anyItem;
	    }


	    function removeItemFromCartAndRefresh(event, itemUUID){
	    	event.preventDefault();
	    	removeItem(itemUUID, backToCurrent);

	    }

	    function backToCurrent(shoppingCart){
	    	$rootScope.$broadcast('itemRemovedFromCart', {shoppingCart : shoppingCart, openShoppingCartDropdown : true});
	    	if(!$state.includes('property.list')){
	    		$state.go($state.current,{}, { reload: true }).then(function() {
	    			vm.shoppingCartOpen = true;
	    		});
	    	}

	    }

	    function goToShoppingCart(event){
	    	event.preventDefault();
	    	//console.log("State params is shopping cart dropdown");
	    	///console.log($stateParams);
	    	//console.log($state);
	    	vm.shoppingCartOpen = false;
	    	//we need to reload
	    	$state.go('shopping-cart',{}, { reload: true });

	    }


	    function removeItem(itemUUID, callback){

            var cb = callback || angular.noop;

            return ShoppingCartRemove.remove({itemUUID : itemUUID},
                function (shoppingCart) {
                    return cb(shoppingCart);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;

	    }

        $scope.$on('itemAddedToCart',function(event, args){
        	vm.shoppingCart = args.shoppingCart;
        	vm.shoppingCartOpen = args.openShoppingCartDropdown ? true : false;
        	//vm.perishableAlerts.push({
        	//	msg : $translate.instant('shopping-cart.alert.added.success'),
        	//	type : "success"
        	//});
        	if($('#snackbar').length>0) {
                angular.element($("#snackbar")).parent().data('propertyAlertErrorScope').vm.addSuccessAlert('Added', 'shopping-cart.alert.added.success');
            }
        });

        $scope.$on('itemAddedToCartError',function(event, args) {
            if($('#snackbar').length>0) {
                angular.element($("#snackbar")).parent().data('propertyAlertErrorScope').vm.addErrorAlert('Error');
            }
        });

        $scope.$on('itemRemovedFromCart',function(event, args){
        	vm.shoppingCart = args.shoppingCart;
        	vm.shoppingCartOpen = args.openShoppingCartDropdown ? true : false;
        	//vm.perishableAlerts.push({
        	//	msg : $translate.instant('shopping-cart.alert.removed.success'),
        	//	type : "success"
        	//});
            if($('#snackbar').length>0) {
                angular.element($("#snackbar")).parent().data('propertyAlertErrorScope').vm.addSuccessAlert('Removed', 'shopping-cart.alert.removed.success');
            }
        });

        $rootScope.$on('openShoppingCartDropdown',function(){
        	if(!vm.shoppingCartOpen){
        		vm.shoppingCartOpen = true;
        	}
        });

        function shoppingCartToggled(open){
        	//when dropdown is closed, clear perishable alerts
        	//console.log("Cart is open: "+open);
        	if(!open){
        		vm.perishableAlerts = [];
        	}
        }

	}
})();
