(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('SortNavbarDropdownController', SortNavbarDropdownController);

	function SortNavbarDropdownController($scope, $rootScope, $state, $stateParams, $uibModal, $document) {
	    'ngInject';
	    var vm = this;
        vm.shoppingCart = vm.shoppingCart || {};
        vm.shoppingCartConfigs = vm.shoppingCartConfigs || {};
        vm.property = vm.property || {};


	}
})();
