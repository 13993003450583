(function() {
	'use strict';

	angular.module('vobeApp').directive('adyenCheckout',
        adyenCheckout);

    adyenCheckout.$inject = ['$timeout'];

	function adyenCheckout($timeout) {
		var directive = {
			restrict : 'A',
			scope: {
                configuration : '=configuration'
			},
			link : linkFunc
		};

		return directive;

		function linkFunc(scope, element, attrs) {
			//check if form is in the visible DOM
			//https://stackoverflow.com/questions/5629684/how-to-check-if-element-exists-in-the-visible-dom/16820058#16820058
			//otherwise counter gets incremented but if form is not in visible DOM the submission gets cancelled without errors
			var elementInTheDom = document.body.contains(element[0]);

            if(elementInTheDom){
                // eslint-disable-next-line no-undef
                var checkout = new AdyenCheckout(scope.configuration);
                checkout.create('dropin', {openFirstPaymentMethod: true}).mount(element[0]);
            }

		}
	}
	;

})();
