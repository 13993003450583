(function() {
    'use strict';

    angular.module('vobeApp').directive('ellipsisText',
        ellipsisText);

    ellipsisText.$inject = ['$sce'];

    function ellipsisText($sce) {
        var directive = {
            restrict : 'A',
            scope: {
                options : '=ellipsisText'
            },
            controller : 'EllipsisTextController'
        };

        return directive;

    };

})();
