(function() {
    'use strict';

    angular
        .module('vobeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('shopping-cart', {
            parent: 'property',
            url: '/shopping-cart?revalidate',
            data: {
                authorities: [],
                autoscroll : true,
                pageTitle: "shopping-cart.page.title"
            },
            views: {
                'content@': {
                    templateUrl: 'app/site/shoppingcart/shopping-cart-edit.html',
                    controller: 'ShoppingCartEditController',
                    controllerAs: 'vm'
                },
                'header@': {
                    templateUrl: 'app/layouts/header/header.html',
                    controller: 'BrandedHeaderController',
                    controllerAs: 'vm'
                },
                'footer@': {
                    templateUrl: 'app/layouts/footer/footer.html',
                    controller: 'BrandedFooterController',
                    controllerAs: 'vm'
                },
                'cookies@': {
                    templateUrl: 'app/components/cookies-consent/cookies-banner.html',
                    controller: 'CookiesBannerController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'shopping-cart',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }],
                brandingTemplateUUID : ['brandingTemplateUUID',function(brandingTemplateUUID){
                	return brandingTemplateUUID;
                }],
                shoppingCartConfigs : ['shoppingCartConfigs',function(shoppingCartConfigs){
                    //from property state
                    return shoppingCartConfigs;
                }],
                emailConfig: ['VobeConfig', 'shoppingCart', function(vobeConfig, shoppingCart) {
                    var propertyIds = [];
                    angular.forEach(shoppingCart.shoppingCartItems.items, function(value, key) {
                        if(jQuery.inArray(value.owningProperty.propertyId, this) < 0 ){
                            this.push(value.owningProperty.propertyId);
                        }

                    }, propertyIds);
                    return vobeConfig.emailConfig.get({propertyIds : propertyIds}).$promise;
                }],
                currentProperty : ['currentProperty',function(currentProperty){
                	return currentProperty;
                }],
                currentPropertyGroup : ['currentPropertyGroup',function(currentPropertyGroup){
                    return currentPropertyGroup;
                }],
                currentLanguage : ['currentLanguage',function(currentLanguage){
                    return currentLanguage;
                }],
            	mainTranslatePartialLoader: ['$translate', '$translatePartialLoader','shoppingCart','currentProperty', '$stateParams', function ($translate,$translatePartialLoader,shoppingCart,currentProperty,$stateParams) {
            		var variant = "";
                	if($stateParams.contextType){
                		variant = "_"+$stateParams.contextType;
                	}
                	variant += "_"+currentProperty.hotelId;

            		$translatePartialLoader.addPart('vouchers'+variant);
	                var includeGiftCards = false;
                    var includeItems = false;
	                angular.forEach(shoppingCart.shoppingCartItems.items, function(value, key) {
	                	if(value.itemGiftCardId && value.itemGiftCardId>0 ){
	          			  includeGiftCards = true;
	                	}
	                	if(value.itemType=='package-product'){
                            includeItems = true;
                        }
	                });
	                if(includeGiftCards){
	                	$translatePartialLoader.addPart('giftcards'+variant);
	                }
                    if(includeItems){
                        $translatePartialLoader.addPart('items'+variant);
                    }
	                return $translate.refresh();
	            }]
            }
        })
        .state('shopping-cart.preview', {
            url: '/preview?uuid&emailCategoryId',
            data: {
                authorities: [],
                autoscroll : true
            },
            onEnter: function($transition$, $state, $uibModal, currentProperty, currentPropertyGroup, shoppingCart, currentLanguage, shoppingCartConfigs) {
                'ngInject';
                var params = $transition$.params();
                var modal = $uibModal.open({
                    templateUrl: 'app/site/shoppingcart/shopping-cart-preview.html',
                    controller: 'ShoppingCartPreviewController',
                    controllerAs: 'vm',
                    backdrop: 'true',
                    animation: false,
                    windowClass : '',
                    size: 'lg',
                    resolve : {
                        currentProperty: function(){
                            return currentProperty;
                        },
                        currentPropertyGroup: function(){
                            return currentPropertyGroup;
                        },
                        shoppingCart: function(){
                        	return shoppingCart;
                        },
                        currentLanguage: function(){
                        	return currentLanguage;
                        },
                        shoppingCartConfigs: function(){
                            return shoppingCartConfigs;
                        },
                        templateItem : function(){
                            return '';
                        },
                        params: function () {
                            return {
                                uuid: params.uuid,
                                src: params.src,
                                ref: params.ref,
                                emailCategoryId: params.emailCategoryId,
                                channel: params.channel
                            };
                        }
                    }
                });

                modal.result.then(function() {
                    var stillInShoppingCartPreview = $state.is('shopping-cart.preview');
                    if(stillInShoppingCartPreview){
                        $state.go('^', {}, { reload: false,location: 'replace' });
                    }
                }, function() {
                    var stillInShoppingCartPreview = $state.is('shopping-cart.preview');
                    if(stillInShoppingCartPreview){
                        $state.go('^', {}, { reload: false,location: 'replace' });
                    }
                });

                $state.currentModal = modal;

            },
            onExit: ['$state',function($state){
                $state.currentModal.close();
            }]
        })
        .state('shopping-cart.terms', {
            url: '/terms?uuid',
            data: {
                authorities: [],
                autoscroll : true
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'shoppingCart','currentLanguage', function($stateParams, $state, $uibModal, shoppingCart, currentLanguage) {
                var modal = $uibModal.open({
                    templateUrl: 'app/site/shoppingcart/shopping-cart-terms-dialog.html',
                    controller: 'ShoppingCartTermsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'false',
                    animation: false,
                    windowClass : '',
                    size: 'lg',
                    resolve : {
                        shoppingCart: shoppingCart,
                        terms : ['TermsService',function(TermsService){

                        	var cartItem;
                        	angular.forEach(shoppingCart.shoppingCartItems.items, function(value, key) {
                                if(value.uuid == $stateParams.uuid){
                                	cartItem = value;
                                }
                            });


                        	var language = currentLanguage;
                        	var delivery = cartItem.recipient.deliveryMethodType;
                		    var cb = angular.noop;

                            return TermsService.get(
                            		{propertyId : cartItem.owningProperty.propertyId,
                                     voucherId : cartItem.itemId,
                            		 lang : (language || 'en' ).split('_')[0].split('-')[0],
                            		 termsType : delivery
                            		},
                                    function (response) {
                                        return cb(response);
                                    },
                                    function (err) {
                                        return cb(err);
                                    }.bind(this)).$promise;
                        }]
                    }
                });

                modal.result.then(function() {
                    var stillIn = $state.is('shopping-cart.terms');
                    if(stillIn){
                        $state.go('^', {}, { reload: false });
                    }
                }, function() {
                    var stillIn = $state.is('shopping-cart.terms');
                    if(stillIn){
                        $state.go('^', {}, { reload: false });
                    }
                });

                $state.currentModal = modal;

            }],
            onExit: ['$state',function($state){
                $state.currentModal.close();
            }]
        })
        .state('shopping-cart-empty', {
            parent: 'shopping-cart',
            url: '/empty',
            data: {
                authorities: [],
                autoscroll : true
            },
            views: {
                'content@': {
                    templateUrl: 'app/site/shoppingcart/shopping-cart-empty.html',
                    controller: 'ShoppingCartEmptyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$stateParams','$translate', '$translatePartialLoader','currentProperty', function ($stateParams,$translate,$translatePartialLoader,currentProperty) {
                	var variant = "";
                	if($stateParams.contextType){
                		variant = "_"+$stateParams.contextType;
                	}
                	variant += "_"+currentProperty.hotelId;

                	$translatePartialLoader.addPart('shopping-cart'+variant);
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'shopping-cart',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        });
    }
})();
