(function() {
	'use strict';

	angular.module('vobeApp').directive('autoSumbitRedirectForm',
			autoSumbitRedirectForm);

	autoSumbitRedirectForm.$inject = ['$timeout', 'PaymentRedirectSingleton'];

	function autoSumbitRedirectForm($timeout, PaymentRedirectSingleton) {
		var directive = {
			restrict : 'A',
			scope: {
				uuid : '=autoSumbitRedirectForm'
			},
			link : linkFunc
		};

		return directive;

		function linkFunc(scope, element, attrs) {
			//check if form is in the visible DOM
			//https://stackoverflow.com/questions/5629684/how-to-check-if-element-exists-in-the-visible-dom/16820058#16820058
			//otherwise counter gets incremented but if form is not in visible DOM the submission gets cancelled without errors
			var submissionAllowed = document.body.contains(element[0]);
			var startSubmission = function(event, data){
				if(submissionAllowed && data.uuid==scope.uuid && PaymentRedirectSingleton.startIfNotBusy()==true){
					element[0].submit();
					//console.log("started");
				}
			}
			var stopSubmission = function(event, data){
				submissionAllowed=false;
				PaymentRedirectSingleton.stop();
				//console.log("stoped");
			}
			scope.$on('$destroy',stopSubmission);
			scope.$on('SUBMIT_PAYMENT_FORM',startSubmission);

		}
	}
	;

})();
