(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('VoucherPropertiesController', VoucherPropertiesController);

    VoucherPropertiesController.$inject = ['Principal', 'User', 'VoucherProperties', 'ParseLinks', 'AlertService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService'];

    function VoucherPropertiesController(Principal, User, VoucherProperties, ParseLinks, AlertService, $state, pagingParams, paginationConstants, JhiLanguageService) {
        var vm = this;

        vm.currentAccount = null;
        vm.loadAll = loadAll;
        vm.properties = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;

        vm.loadAll();
        
        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });


        function loadAll () {
        	VoucherProperties.get({
                page: pagingParams.page - 1,
                //sort: sort(),
                size: vm.itemsPerPage                
            }, onSuccess, onError);
        }

        function onSuccess(data, headers) {
            //hide anonymous user from user management: it's a required user for Spring Security

            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.properties = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }


        /*function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }*/

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                //sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
