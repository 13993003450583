(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('PaymentTermsDialogController', PaymentTermsDialogController);

    PaymentTermsDialogController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$uibModalInstance', 'terms' ];

	function PaymentTermsDialogController($scope, $rootScope, $state, $stateParams, $uibModalInstance, terms) {
	    var vm = this;

	    vm.terms = terms;

        vm.hideClear = false;
	    if($state.includes('property.terms')){
	        vm.hideClear = true;
        }

	    //console.log(terms);

	    vm.clear = clear;

	    function clear () {
            $uibModalInstance.dismiss('cancel');
        }

	}
})();
