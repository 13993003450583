(function () {
    'use strict';

    angular.module('vobeApp').service('ShoppingCartService', function ($filter) {
        'ngInject';
        this.sharedFunction = function (param) {
            console.log('shared function called with param: ' + param)
        };

        this.generateTotals = function (shoppingCartItems) {
            var totalsMap = {};
            //group items per property
            angular.forEach(shoppingCartItems,function(item,index){
                var key = item.owningProperty.propertyId;
                var totalValue = totalsMap[key] || {};
                totalValue.key = key;
                totalValue.currency = totalValue.currency || vm.currentCurrency.code || item.itemCurrency;
                totalValue.itemCurrency = item.itemCurrency;
                totalValue.propertyName = totalValue.propertyName || item.owningProperty.propertyName;
                totalValue.propertyId = totalValue.propertyId || item.owningProperty.propertyId;
                totalValue.deliveryPersonType = item.recipient.deliveryPersonType;
                totalValue.deliveryMethodType = item.recipient.deliveryMethodType;

                totalValue.itemTotalPriceOriginal = totalValue.itemTotalPriceOriginal || 0;
                totalValue.itemTotalPriceOriginal += item.itemTotalPrice;

                totalValue.itemTotalPrice = totalValue.itemTotalPrice || 0;
                totalValue.itemTotalPrice += $filter('exchangenumber')(item.itemTotalPrice,item.itemCurrency,totalValue.currency);

                totalValue.itemTotalPriceBeforeDiscount = totalValue.itemTotalPriceBeforeDiscount || 0;
                totalValue.itemTotalPriceBeforeDiscount += $filter('exchangenumber')(item.itemTotalPriceBeforeDiscount,item.itemCurrency,totalValue.currency);

                //next add total shipping
                totalValue.totalShipping =  0;

                //next add total service fee
                totalValue.serviceFeeOriginal = 0;
                totalValue.serviceFee = 0;

                totalsMap[key] = totalValue;
            });

            //add shipping per property
            updateShippingPerProperty(totalsMap);


            //add service fee per property
            angular.forEach(totalsMap, function (totalValue, propertyId) {
                var serviceFeeOriginal = 0;
                var serviceFeeCurrency = totalValue.currency;//?
                if (typeof vm.propertyGroup !== 'undefined') {
                    //search for service fee among hotels
                    angular.forEach(vm.propertyGroup.hotels, function (hotel, hi) {
                        if (hotel.hotelId == propertyId) {
                            serviceFeeCurrency = hotel.hotelCurrency;
                            if (hotel.serviceFee && hotel.serviceFee.amount > 0) {
                                var totalPrice = totalValue.itemTotalPriceOriginal;

                                if (hotel.serviceFee.percentage) {
                                    serviceFeeOriginal = totalValue.itemTotalPriceOriginal * hotel.serviceFee.amount / 100.0;
                                } else {
                                    serviceFeeOriginal = hotel.serviceFee.amount
                                }
                            }
                        }

                    });
                } else {
                    serviceFeeCurrency = vm.property.hotelCurrency;
                    if (vm.property.serviceFee && vm.property.serviceFee.amount > 0) {
                        var totalPrice = totalValue.itemTotalPriceOriginal;

                        if (vm.property.serviceFee.percentage) {
                            serviceFeeOriginal = totalValue.itemTotalPriceOriginal * vm.property.serviceFee.amount / 100.0;
                        } else {
                            serviceFeeOriginal = vm.property.serviceFee.amount
                        }
                    }
                }
                totalValue.serviceFeeOriginal = serviceFeeOriginal;
                totalValue.serviceFee = $filter('exchangenumber')(serviceFeeOriginal, serviceFeeCurrency, totalValue.currency);
            });

            return totalsMap;
        }

        function updateShippingPerProperty(map) {

            angular.forEach(map, function (totalValue, propertyId) {
                let totalShippingOriginal = 0;
                angular.forEach(vm.totals, function (totalItem, totalItemKey) {
                    if (totalItem.propertyId == propertyId) {
                        totalShippingOriginal += totalItem.shipping * totalItem.items.length;
                    }
                });
                totalValue.totalShippingOriginal = totalShippingOriginal;
                totalValue.totalShipping = $filter('exchangenumber')(totalShippingOriginal, vm.property.hotelCurrency, totalValue.currency);
            });
        }

    });

})();
