(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PublicSitePartRelationshipDialogController', PublicSitePartRelationshipDialogController);

    PublicSitePartRelationshipDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PublicSitePartRelationship', 'PublicSitePart', 'PublicSite'];

    function PublicSitePartRelationshipDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PublicSitePartRelationship, PublicSitePart, PublicSite) {
        var vm = this;

        vm.publicSitePartRelationship = entity;
        vm.clear = clear;
        vm.save = save;
        vm.publicsiteparts = PublicSitePart.query();
        vm.publicsites = PublicSite.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.publicSitePartRelationship.id !== null) {
                PublicSitePartRelationship.update(vm.publicSitePartRelationship, onSaveSuccess, onSaveError);
            } else {
                PublicSitePartRelationship.save(vm.publicSitePartRelationship, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('vobeApp:publicSitePartRelationshipUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
