(function() {
    'use strict';

    angular
        .module('vobeApp')
        .factory('GoogleAnalyticsCodes', GoogleAnalyticsCodes);

    GoogleAnalyticsCodes.$inject = ['$resource'];

    function GoogleAnalyticsCodes ($resource) {
        var service = $resource('api/property/:id/ga', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }


    angular
        .module('vobeApp')
        .factory('GoogleAnalyticsCodesService', GoogleAnalyticsCodesService);

    GoogleAnalyticsCodesService.$inject = ['$q','GoogleAnalyticsCodes'];

    function GoogleAnalyticsCodesService($q,GoogleAnalyticsCodes) {

        var dataPromise;

        var initialized = false;
        var initializedWithoutStorage = true;

        var service = {
            getCodes : getCodes,
            isInitialized : isInitialized,
            setInitialized : setInitialized,
            isInitializedWithoutStorage : isInitializedWithoutStorage,
            setInitializedWithoutStorage : setInitializedWithoutStorage
        };

        return service;

        function getCodes(options) {
            if (angular.isUndefined(dataPromise)) {
                dataPromise = GoogleAnalyticsCodes.get(options).$promise;
            }
            return dataPromise;
        }

        function isInitialized() {
            return initialized;
        }
        function setInitialized(boolean) {
        	initialized = boolean;
        }
        function isInitializedWithoutStorage() {
            return initialized;
        }
        function setInitializedWithoutStorage(boolean) {
            initialized = boolean;
        }
    }



})();
