(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('PropertyCreditCards', PropertyCreditCards);

    PropertyCreditCards.$inject = ['$resource'];

    function PropertyCreditCards($resource) {
        var service = $resource('api/property/:id/creditcards', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if (!data || (typeof data === 'string' && data.length == 0)) {
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }

    angular
        .module('vobeApp')
        .factory('PropertyPaymentOptions', PropertyPaymentOptions);

    function PropertyPaymentOptions($resource) {
        'ngInject';
        var service = $resource('api/property/:id/paymentoptions', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if (!data || (typeof data === 'string' && data.length == 0)) {
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
})();
