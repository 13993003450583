(function () {
    'use strict';


    angular
        .module('vobeApp')
        .filter('numberFraction', numberFraction);


    function numberFraction(XE_CURRENCIES) {
        'ngInject';
        return function (number, fractionSize, toCode) {

            if (isNaN(number)) {
                return '';
            }
            var toCurrencyDetails;
            if (toCode) {
                toCurrencyDetails = XE_CURRENCIES[toCode];
            }
            var fractionLength = isNaN(fractionSize) ? 2 : fractionSize;
            if (toCurrencyDetails) {
                fractionLength = toCurrencyDetails[0];
            }
            //console.log('numberFraction',number,fractionLength,toCode);
            //if currency has no fraction, don't do it
            if (fractionLength < 1) {
                return '';
            }
            var fraction = Math.ceil(((number < 1.0) ? number : (number % Math.floor(number)).toFixed(4)) * Math.pow(10, fractionLength));
            if (fraction > 0) {
                var paddedFraction = pad(fraction,fractionLength);
                var fractionRegex = /(\d{2})/.exec(paddedFraction);
                return '.'+fractionRegex[0];
            } else {
                return '.00';
            }
        };
    }

    function pad(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

})();
