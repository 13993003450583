(function(){
    'use strict';

    angular.module('vobeApp')
        .provider('TagManager', function () {
            var pageEvent = '$routeChangeSuccess',
                ignoreFirstPageLoad = false,
                created = {},
                readFromRoute = false,
                delayScriptTag = false,
                trackUrlParams = false,
                removeRegExp,
                trackCount = 0,
                ad_storage = true,
                analytics_storage = true;

            this.setPageEvent = function (name) {
                pageEvent = name;
                return this;
            };

            this.trackUrlParams = function (val) {
                trackUrlParams = !!val;
                return this;
            };

            this.ignoreFirstPageLoad = function (val) {
                ignoreFirstPageLoad = !!val;
                return this;
            };

            this.delayScriptTag = function (val) {
                delayScriptTag = !!val;
                return this;
            };

            this.setAdStorage = function(val){
                ad_storage = val;
            };

            this.setAnalyticsStorage = function(val){
                analytics_storage = val;
            };

            this.setRemoveRegExp = function (regex) {
                if (regex instanceof RegExp) {
                    removeRegExp = regex;
                }
                return this;
            };

            this.$get = ['$document', // To read page title
                '$location', //
                '$log',      //
                '$rootScope',//
                '$window',   //
                '$injector', // To access ngRoute module without declaring a fixed dependency
                '$state',
                function ($document, $location, $log, $rootScope, $window, $injector, $state) {
                    var that = this;

                    /**
                     * Side-effect Free Helper Methods
                     **/

                    var isPropertyDefined = function (key, config) {
                        return angular.isObject(config) && angular.isDefined(config[key]);
                    };

                    var isPropertySetTo = function (key, config, value) {
                        return isPropertyDefined(key, config) && config[key] === value;
                    };

                    var generateCommandName = function (commandName, config) {
                        if (angular.isString(config)) {
                            return config + '.' + commandName;
                        }
                        return isPropertyDefined('name', config) ? (config.name + '.' + commandName) : commandName;
                    };

                    // Try to read route configuration and log warning if not possible
                    var $route = {};
                    if (readFromRoute) {
                        if (!$injector.has('$route')) {
                            $log.warn('$route service is not available. Make sure you have included ng-route in your application dependencies.');
                        } else {
                            $route = $injector.get('$route');
                        }
                    }

                    // Get url for current page
                    var getUrl = function () {
                        // Using ngRoute provided tracking urls
                        if (readFromRoute && $route.current && ('pageTrack' in $route.current)) {
                            return $route.current.pageTrack;
                        }

                        // Otherwise go the old way
                        var url = trackUrlParams ? $location.url() : $location.path();
                        return removeRegExp ? url.replace(removeRegExp, '') : url;
                    };

                    /**
                     * Private Methods
                     */

                    var _getProtocol = function (httpPostfix, httpsPostfix) {
                        var protocol = '',
                            isSslEnabled = document.location.protocol === 'https:',
                            isChromeExtension = document.location.protocol === 'chrome-extension:';
                        httpPostfix = angular.isString(httpPostfix) ? httpPostfix : '';
                        httpsPostfix = angular.isString(httpsPostfix) ? httpsPostfix : '';
                        if (httpPostfix !== '') {
                            protocol = 'http:' + httpPostfix;
                        }
                        if (isChromeExtension || (isSslEnabled && httpsPostfix !== '')) {
                            protocol = 'https:' + httpsPostfix;
                        }
                        return protocol;
                    };

                    this._registerScriptTags = function (gtmCode, dataLayerName) {
                        console.log('registerScriptTags', gtmCode, dataLayerName);
                        var document = $document[0],
                            protocol = _getProtocol(),
                            scriptSource;

                        if (created[gtmCode] && created[gtmCode].created === true) {
                            console.warn('Script tags already created');
                            return;
                        }

                        (function (w, d, s, l, i) {
                            w[l] = w[l] || [];
                            w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
                            var f = d.getElementsByTagName(s)[0],
                                j = d.createElement(s),
                                dl = l != 'dataLayer' ? '&l=' + l : '';
                            j.async = true;
                            j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
                            f.parentNode.insertBefore(j, f);
                        })($window, document, 'script', dataLayerName, gtmCode);

                        created[gtmCode] = { created: true};
                        return true;
                    };

                    /**
                     * Track page
                     https://developers.google.com/analytics/devguides/collection/gajs/
                     https://developers.google.com/analytics/devguides/collection/analyticsjs/pages
                     * @param url
                     * @param title
                     * @param custom
                     * @private
                     */
                    this._trackPage = function (url, title, custom) {
                        var dataLayer = $window.dataLayer = $window.dataLayer || [];
                        if (!ignoreFirstPageLoad || trackCount > 0) {
                            url = url ? url : getUrl();
                            title = title ? title : $document[0].title;
                            dataLayer.push({
                                event: 'ngRouteChange',
                                attributes: {
                                    route: url,
                                    title: title
                                }
                            });
                        }
                        trackCount++;
                    };

                    /**
                     * Send page view
                     * @param trackerName
                     * @private
                     */
                    this._pageView = function (trackerName) {
                        //console.log('pageview');
                    };

                    this._gtag = function (){
                        var dataLayer = $window.dataLayer = $window.dataLayer || [];
                        dataLayer.push(arguments);
                    };

                    this._pushVariable = function (variableName, variableValue){
                        var variable = {};
                        variable[variableName] = variableValue;
                        //this._gtag(variable);
                        var dataLayer = $window.dataLayer = $window.dataLayer || [];
                        dataLayer.push(variable);
                    };

                    this._updateConsent = function (ad_storage, analytics_storage, isUpdate) {

                        var allowAdStorage = typeof ad_storage === "undefined" ? this.ad_storage : ad_storage;
                        var allowAnalyticsStorage = typeof analytics_storage === "undefined" ? this.analytics_storage : analytics_storage;
                        this.ad_storage = allowAdStorage;
                        this.analytics_storage = allowAnalyticsStorage;

                    };

                    this._pushSimpleEvent = function(eventName){

                        var dataLayer = $window.dataLayer = $window.dataLayer || [];

                        dataLayer.push({
                            event: eventName
                        });
                    }

                    //activates purchase tracking
                    this._trackPurchase = function (transaction, shoppingCart, confirmationUuid) {
                        console.log('trackPurchase', transaction, shoppingCart, confirmationUuid)

                        var dataLayer = $window.dataLayer = $window.dataLayer || [];

                        var products = [];
                        var productsGa4 = [];
                        var customer = shoppingCart.customer;
                        var overallRevenue = 0;
                        var rateId = [];
                        angular.forEach(shoppingCart.shoppingCartItems.items, function (item, index) {
                            if (transaction.shoppingCartItemUUIDs.indexOf(item.uuid) > -1) {
                                var product = {
                                    name: item.itemName,
                                    id: item.itemId.toString(),
                                    price: item.itemTotalPrice,
                                    brand: item.owningProperty.propertyName,
                                    category: item.itemType,
                                    quantity: 1,
                                    currency: item.owningProperty.propertyCurrency
                                };

                                productsGa4.push({
                                    item_id: item.itemId.toString(),
                                    item_name: item.itemName,
                                    item_brand: item.owningProperty.propertyName,
                                    index: index+1,
                                    price: item.itemTotalPrice,
                                    quantity: 1,
                                    affiliation: item.owningProperty.propertyName,
                                    item_list_id: item.itemCategoryId ? item.itemCategoryId : 1,
                                    item_list_name: item.itemCategoryName ? item.itemCategoryName : 'All',
                                });

                                overallRevenue += item.itemTotalPrice;
                                rateId.push(item.itemId);
                                products.push(product);
                            }
                        });

                        dataLayer.push({
                            ecommerce: {
                                currencyCode: transaction.transactionCurrency,
                                purchase: {
                                    actionField: {
                                        id: confirmationUuid,
                                        revenue: transaction.transactionAmount,
                                        shipping: transaction.transactionShippingAmount
                                    },
                                    products: products,
                                    customer: customer
                                }
                            },
                            event: 'ngTrackPurchase'
                        });


                        //same datalayer push but with `eec:purchase` event name...
                        dataLayer.push({
                            ecommerce: {
                                currencyCode: transaction.transactionCurrency,
                                purchase: {
                                    actionField: {
                                        id: confirmationUuid,
                                        revenue: transaction.transactionAmount,
                                        shipping: transaction.transactionShippingAmount
                                    },
                                    products: products,
                                    customer: customer
                                }
                            },
                            event: 'eec:purchase'
                        });

                        //ga4
                        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                        dataLayer.push({
                            event: "purchase",
                            ecommerce: {
                                transaction_id: confirmationUuid,
                                tax: 0,
                                value: transaction.transactionAmount,
                                currency: transaction.transactionCurrency,
                                items: productsGa4,
                            }
                        });
                    };



                    // creates the Google Analytics tracker
                    if (!delayScriptTag) {
                        this._registerScriptTags();
                    }

                    // activates page tracking
                    $rootScope.$on(pageEvent, function () {
                        that._trackPage();
                    });

                    return {
                        configuration: {
                            ignoreFirstPageLoad: ignoreFirstPageLoad,
                            pageEvent: pageEvent,
                            delayScriptTag: delayScriptTag,
                            trackUrlParams: trackUrlParams,
                            removeRegExp: removeRegExp
                        },
                        registerScriptTags: function (gtmCode, dataLayerName) {
                            return that._registerScriptTags(gtmCode, dataLayerName);
                        },
                        trackPage: function (url, title, custom) {
                            that._trackPage.apply(that, arguments);
                        },
                        pageView: function () {
                            that._pageView.apply(that, arguments);
                        },
                        set: function (name, value, trackerName) {
                            that._set.apply(that, arguments);
                        },
                        trackPurchase: function(transaction, shoppingCart, confirmationUuid) {
                            that._trackPurchase.apply(that, arguments);
                        },
                        updateConsent: function(ad_storage, analytics_storage, isUpdate) {
                            that._updateConsent.apply(that, arguments);
                        },
                        pushSimpleEvent: function(eventName) {
                            that._pushSimpleEvent.apply(that, arguments);
                        },
                        pushVariable: function(variableName, variableValue){
                            that._pushVariable.apply(that, arguments);
                        },
                        gtag : function(){
                            that._gtag.apply(that, arguments);
                        }

                    };
                }];
        })

})();
