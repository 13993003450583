(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('GroupVouchers', GroupVouchers);

    GroupVouchers.$inject = ['$resource'];

    function GroupVouchers ($resource) {
        var service = $resource('api/group/:code/vouchers', {}, {
            'query': {method: 'GET', isArray: false},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
})();
