(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('ConfirmationController', ConfirmationController);

    function ConfirmationController($window, $scope, $rootScope, $state, $stateParams, $anchorScroll, confirmation, DetermineShopMoreState, currentProperty, CurrencyExchange, voucherConfig) {
        'ngInject';
		var vm = this;

		vm.confirmation = confirmation;
		vm.property = currentProperty;
	    vm.browseMore = browseMore;
	    vm.channel = $stateParams.channel;
        vm.voucherConfig = voucherConfig;

        //currency
        vm.currentCurrency = {
            code : undefined
        }
        var currentCurrency = CurrencyExchange.currencies.current;//current currency might be NULL if this is group engine
        if (currentCurrency) {
            vm.currentCurrency.code = currentCurrency.code;
        }

        $rootScope.$on('CurrencyExchangeProvider.changeCurrency', function(event, args) {
            vm.currentCurrency.code = args.code;
        });

	    function browseMore(event){
	    	event.preventDefault();
	    	var shopMoreState = DetermineShopMoreState.getShopMoreState();
	    	var params = shopMoreState.params;
	    	$state.go(shopMoreState.name, params);
	    }

	}

})();
