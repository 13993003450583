(function() {
    'use strict';


    angular
        .module('vobeApp')
        .filter('trustHtml', trustHtml);

    trustHtml.$inject = ['$sce'];

    function trustHtml($sce) {
        return function (text) {
            return $sce.trustAsHtml(text);
        };
    }
})();
