(function () {
    'use strict';

    angular
        .module('vobeApp')
        .controller('ShoppingCartPreviewController', ShoppingCartPreviewController);


    function ShoppingCartPreviewController($scope, $rootScope, $state, $filter, $uibModalInstance, currentProperty, currentPropertyGroup, shoppingCart, $sce, currentLanguage, shoppingCartConfigs, templateItem, CurrencyExchange, params) {
        'ngInject';

        console.log('=> ShoppingCartPreviewController ...', params.uuid)

        var vm = this;
        vm.previewItem = {}
        vm.found = false;
        vm.clear = clear;

        if(templateItem && typeof templateItem !== 'string'){
            render(templateItem);
        } else {
            angular.forEach(shoppingCart.shoppingCartItems.items, function (v, i) {
                if (v.uuid === params.uuid) {
                    render(v);
                }
            });
        }



        //https://stackoverflow.com/questions/12793045/adding-months-to-a-date-in-javascript
        function addMonths(date, months) {
        	  var d = date.getDate();
        	  date.setMonth(date.getMonth() + +months);
        	  if (date.getDate() != d) {
        	    date.setDate(0);
        	  }
        	  return date;
        }

        function clear () {
            console.log('=> preview modal dismiss')
            $uibModalInstance.dismiss('cancel');
        }

        function render(v){
            console.log('=> preview render')

            //currency
            vm.currentCurrency = {
                code : undefined
            }
            var currentCurrency = CurrencyExchange.currencies.current;//current currency might be NULL if this is group engine
            if(currentCurrency && currentCurrency!=null){
                vm.currentCurrency.code = currentCurrency.code;
            }

            $rootScope.$on('CurrencyExchangeProvider.changeCurrency', function(event, args) {
                vm.currentCurrency.code = args.code;
            });


            vm.found = true;
            vm.previewItem = v;

            //fill in the values
            vm.voucherName = v.itemName;
            vm.voucherCurrency = v.itemCurrency;
            vm.voucherTotal = v.itemTotalPrice;
            vm.voucherTotalBeforeDiscount = v.itemTotalPriceBeforeDiscount && v.itemTotalPriceBeforeDiscount>0 ? v.itemTotalPriceBeforeDiscount : v.itemTotalPrice;
            if(v.itemType=='cash'){
                vm.voucherName = vm.voucherCurrency+' '+vm.voucherTotalBeforeDiscount;
            }

            var canUseDisplayCurrency = false;
            if (shoppingCartConfigs[currentProperty.hotelId] && shoppingCartConfigs[currentProperty.hotelId].configMap && shoppingCartConfigs[currentProperty.hotelId].configMap.canUseDisplayCurrency) {
                canUseDisplayCurrency = shoppingCartConfigs[currentProperty.hotelId].configMap.canUseDisplayCurrency;
            }
            if(canUseDisplayCurrency && vm.currentCurrency.code){
                vm.voucherCurrency = vm.currentCurrency.code;
                vm.voucherTotal = $filter('exchangenumber')(vm.voucherTotal,v.itemCurrency,vm.currentCurrency.code);
                vm.voucherTotalBeforeDiscount = $filter('exchangenumber')(vm.voucherTotalBeforeDiscount,v.itemCurrency,vm.currentCurrency.code);
                if(v.itemType=='cash'){
                    vm.voucherName = vm.voucherCurrency+' '+vm.voucherTotalBeforeDiscount;
                }
            }

            vm.dateOfPurchase = new Date();

            var expiryMonths = 12;
            if (v.itemMeta.validMonths && v.itemMeta.validMonths > 0) {
                expiryMonths = v.itemMeta.validMonths;
            } else if (v.owningProperty.propertyCode.toLowerCase() === 'mocouk') {
                expiryMonths = 24;
            } else if (v.owningProperty.propertyCode.toLowerCase() === 'iil') {
                expiryMonths = 18;
            } else if (shoppingCartConfigs && shoppingCartConfigs[v.owningProperty.propertyId] && shoppingCartConfigs[v.owningProperty.propertyId].configMap && shoppingCartConfigs[v.owningProperty.propertyId].configMap.validMonths) {
                var configExpiryMonths = 0;
                try {
                    configExpiryMonths = parseInt(shoppingCartConfigs[v.owningProperty.propertyId].configMap.validMonths);
                } catch (err) {
                }
                if (configExpiryMonths > 0) {
                    expiryMonths = configExpiryMonths;
                }
            }



            vm.expiryDate = addMonths(new Date(),expiryMonths);

            vm.voucherDescription = v.itemDescription;
            vm.recipientEmail = v.recipient.email;
            vm.senderName = '***';
            if(vm.previewItem.additionalFields && vm.previewItem.additionalFields['not_valid_before_date'] && vm.previewItem.additionalFields['not_valid_before_date']>0){
                vm.dateOfPurchase = new Date(vm.previewItem.additionalFields['not_valid_before_date']*1000);
            }
            if(vm.previewItem.additionalFields && vm.previewItem.additionalFields['not_valid_after_date'] && vm.previewItem.additionalFields['not_valid_after_date']>0){
                vm.expiryDate = new Date(vm.previewItem.additionalFields['not_valid_after_date']*1000);
            }

            vm.receiverName = '';
            if (v.recipient.firstName) {
                //console.log(v.recipient.firstName);
                vm.receiverName += v.recipient.firstName + ' ';
            }
            if (v.recipient.lastName) {
                //.log(v.recipient.lastName);
                vm.receiverName += v.recipient.lastName;
            }
            if (vm.receiverName == '') {
                vm.receiverName = '***  ';
            }

            //override if dedicationTo is set
            if (v.recipient.dedicationTo) {
                vm.receiverName = v.recipient.dedicationTo;
            }

            if (v.recipient.dedicationFrom) {
                vm.senderName = v.recipient.dedicationFrom;
            }


            vm.deliveryPersonType = v.recipient.deliveryPersonType;
            if(vm.deliveryPersonType === undefined){
                vm.deliveryPersonType = "customer";
            }

            vm.showValue = v.recipient.showValue;
            if(vm.showValue === undefined){
                vm.showValue = true;
            }
            if(vm.showValue === null){
                vm.showValue = true;
            }

            vm.receiverEmail = v.recipient.email;


            vm.message = '';
            if (!angular.isUndefined(v.recipient.message)) {
                vm.message = v.recipient.message;
            }

            vm.emailPropertyId = v.owningProperty.propertyId;

            var tgb = false;
            if(shoppingCart && shoppingCart.shoppingCartProperties && shoppingCart.shoppingCartProperties.additionalFields && shoppingCart.shoppingCartProperties.additionalFields['tgb_redemption_id']){
                tgb = true;
            }

            vm.templatePreview = '/assets/property/email/preview/sample?'+
                'propertyId='+vm.emailPropertyId+
                '&userLang='+(currentLanguage || 'en' ).split('_')[0].split('-')[0]+
                '&voucherType='+v.itemType+
                '&showValue='+vm.showValue+
                '&emailCategoryId='+params.emailCategoryId+
                '&deliveryPersonType='+vm.deliveryPersonType+
                '&voucherId='+v.itemId+
                '&source='+params.src+
                '&referrer='+params.ref+
                '&tgb='+tgb;

            if(params.channel){
                vm.templatePreview+='&channel='+params.channel;
            }
            if(typeof currentPropertyGroup !== 'undefined'){
                vm.templatePreview+='&groupCode='+currentPropertyGroup.code;
            }
        }

    }
})();
