(function() {
    'use strict';

    angular
        .module('vobeApp')
        .component('voucherTiles', {
            templateUrl: 'app/components/vouchers/layouts/voucher-tiles.html',
            controller: 'VoucherTilesController',
            controllerAs: 'vm',
            bindings: {
                allVouchers: '<',
                onlyFeatured: '<',
                viewLayoutTileDesign : '<',//default or text
                viewLayout : '<',
                tilesSettings: '<',//like vm.settings
                appliedPromotionCode: '<',
                channel: '<',
                voucherPoolObject: '<',
                currentProperty: '<',
                currentPropertyGroup: '<',
                shoppingCart: '<',
                websiteType: '<',//group or property
                descriptionLines: '<',
                openPackageModal: '<',//function
                openCashModal: '<',//function
                groupCategories: '<',
                propertyCategories: '<',
                filterPrice: '<',//function
                filterCategory: '<',//function
                filterGiftCardByVoucherClass: '<',
                filterCashOnly: '<',
                filterVouchersByFeatured: '<'
            }
        });
})();
