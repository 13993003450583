(function() {
	'use strict';

	angular.module('vobeApp').directive('sameValue', sameValue);

	function sameValue() {
		var directive = {
			restrict : 'A',
			require : '?ngModel',
			link : linkFunc
		};

		return directive;

		function linkFunc(scope, element, attrs, ngModel) {
			if (!ngModel) {
				return;
			}
			
			var compareActive = false;
			var compare = undefined;
			
			scope.$watch(attrs.sameValue, function(value) {
				compare = value;
				ngModel.$validate();
			});
			
			scope.$watch(attrs.sameValueRequired, function(value) {
				compareActive = value;
				ngModel.$validate();
			});
			
			var validator = function () {
				
				if(compareActive==false){
					return true;
				}
				else if(ngModel.$viewValue==compare){
					return true;
				}
				else {
					return false;
				}				
				
			};			
			
			ngModel.$validators.sameValue = validator;	
		}
	};

})();