(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('DetermineShopMoreState', DetermineShopMoreState);

    function DetermineShopMoreState ($localStorage) {
        'ngInject';
        var service = {
        	setShopMoreState : setShopMoreState,
        	getShopMoreState : getShopMoreState
        }

        function setShopMoreState(state){
            $localStorage.shopMoreState = state;
        }

        function getShopMoreState(){
        	//var lang = $translate.storage().get('NG_TRANSLATE_LANG_KEY');
        	//console.log(lang);
        	var state = $localStorage.shopMoreState;
        	if(state && state.params){
        		//state.params.lang = null;
        	}
        	return state;

        }

        return service;

    }
})();
