(function() {
    'use strict';

    angular
        .module('vobeApp')
        .factory('ShoppingCartItemDetailsService', ShoppingCartItemDetailsService);

    function ShoppingCartItemDetailsService($q, $http, $sce, $state) {
        'ngInject';

        var service = {
            getDefaultVoucher : getDefaultVoucher,
            getVoucherPropertyForIndividualSite: getVoucherPropertyForIndividualSite,
            getVoucherPropertyForGroupSite: getVoucherPropertyForGroupSite,

            calculateSelectedAmountAfterDiscount : calculateSelectedAmountAfterDiscount,
            defaultShowValue:defaultShowValue,
            getImageFileName: getImageFileName,
            useImgix: useImgix,
        };

        return service;

        function getDefaultVoucher(focusedAmount, voucherId, voucherPool) {
            voucherId = parseInt(voucherId);
            console.log('===getDefaultVoucher..')
            console.log('focusedAmount', focusedAmount)
            console.log('voucherId', voucherId)
            console.log('voucherPool', voucherPool)
            var useFirst = !focusedAmount || focusedAmount==0;
            var defaultVoucher;

            if (voucherPool) {
                angular.forEach(voucherPool.vouchers, function (value, key) {
                    console.log('value', value)
                    console.log('sanity check, ', value.id === voucherId, value.totalRate.increment > 0)
                    if (useFirst && value.id === voucherId) {
                        defaultVoucher = value;
                    } else if (value.id === voucherId && focusedAmount === value.totalRate.priceMin) {
                        console.log('price min check...');
                        defaultVoucher = value;
                    } else if (value.id === voucherId && value.totalRate.increment > 0) {
                        //this could be incremented value
                        console.log('incremented value check...');
                        var generatedRange = [];
                        for (var i = value.totalRate.priceMin; i <= value.totalRate.priceMax; i += value.totalRate.increment) {
                            generatedRange.push(i);
                        }
                        if (generatedRange.includes(focusedAmount)) {
                            defaultVoucher = value;
                        }
                    }
                });

                //if we still don't have voucher it could be typed amount
                if (!defaultVoucher && voucherPool.enableTypedAmounts) {
                    //TODO: validate typed min max range ?
                    angular.forEach(voucherPool.vouchers, function (value, key) {
                        if (value.id === voucherId && !defaultVoucher) {
                            defaultVoucher = value;
                        }
                    });
                }
            }



            if(defaultVoucher){
                defaultVoucher.voucherDescriptionTrustedHtml = $sce.trustAsHtml(defaultVoucher.voucherDescription);
                defaultVoucher.selectedAmount = focusedAmount;
                return defaultVoucher;
            } else {
                $state.transitionTo('property.list');
            }
        }

        function calculateSelectedAmountAfterDiscount(beforeDiscountAmount, appliedPromotionCode, itemQuantity) {

            console.log('calculateSelectedAmountAfterDiscount..', beforeDiscountAmount, appliedPromotionCode);
            if (typeof itemQuantity === 'undefined') {
                itemQuantity = 1;
            }
            console.log('itemQuantity', itemQuantity)



            var newSelectedAmountAfterDiscount = beforeDiscountAmount;

            if (!appliedPromotionCode) {
                newSelectedAmountAfterDiscount = beforeDiscountAmount;
            } else if (itemQuantity < appliedPromotionCode.minQuantity) {
                return newSelectedAmountAfterDiscount;
            } else {
                if (appliedPromotionCode.amountPercentage) {
                    newSelectedAmountAfterDiscount = beforeDiscountAmount - beforeDiscountAmount * (appliedPromotionCode.discountAmount / 100.0);
                } else {
                    newSelectedAmountAfterDiscount = beforeDiscountAmount - appliedPromotionCode.discountAmount;
                }
            }
            return newSelectedAmountAfterDiscount;
        }

        function defaultShowValue(itemType, voucherProperty, item) {
            if(itemType.toLowerCase().includes("refund")){
                return true;
            }
            if (['MDVHIE', 'CLYVIE', 'MDHVUK', 'CLYVUK', 'CWHVIE', 'CHSVIE', 'CHGVIE', 'MHSVIE'].indexOf(voucherProperty.hotelCode.toUpperCase()) != -1) {
                return undefined;
            }
            if (voucherProperty && ['BSHVUK'].indexOf(voucherProperty.hotelCode.toUpperCase()) != -1) {
                return 'true';
            }
            if(item && ['BSHVUK'].indexOf(item.owningProperty.propertyCode.toUpperCase())!=-1){
                return 'true';
            }
            if (itemType == 'package') {
                return 'false';
            }
        }


        function getImageFileName(voucherPool){

            var giftCard = voucherPool.giftCard;
            if(giftCard && giftCard.imageUrl && giftCard.imageUrl.trim().length>0){
                return useImgix( giftCard.imageUrl);
            }

            var firstVoucher = undefined;
            angular.forEach(voucherPool.vouchers,function(voucher,vkey){
                firstVoucher = firstVoucher || voucher;
            });
            if (firstVoucher && firstVoucher.images && firstVoucher!=null && firstVoucher.images[7]) {
                return useImgix(firstVoucher.images[7]);
            } else {
                return "//in1-image-library.imgix.net/no-image.jpg";
            }
        }

        function useImgix(imgUrl){
            var old_host = 'dddo4e1h8fdy4.cloudfront.net';
            var new_host = 'in1-image-library.imgix.net';
            return imgUrl.replace(old_host, new_host);
        }

        function getVoucherPropertyForIndividualSite(voucher,property){
            var propertyForIndividualSite = property;
            if(voucher && voucher.hotelId != property.hotelId){
                //try among master hotels
                if(property.masterProperties && property.masterProperties!=null){
                    angular.forEach(property.masterProperties,function(hotel,hkey){
                        if(hotel.hotelId==voucher.hotelId){
                            propertyForIndividualSite = hotel;
                        }
                    });
                }
            }

            return propertyForIndividualSite;
        }

        function getVoucherPropertyForGroupSite(voucher, propertyGroup){
            if(!voucher){
                return undefined;
            }
            if(!propertyGroup || !propertyGroup.hotels){
                return undefined;
            }
            var propertyForGroupSite = undefined;
            angular.forEach(propertyGroup.hotels,function(hotel,hkey){
                if(hotel.hotelId==voucher.hotelId){
                    propertyForGroupSite = hotel;
                }
            })
            return propertyForGroupSite;
        }


    }
})();
