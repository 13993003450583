(function() {
    'use strict';
    angular
        .module('vobeApp')
        .factory('PublicSiteTranslationPart', PublicSiteTranslationPart);

    PublicSiteTranslationPart.$inject = ['$resource'];

    function PublicSiteTranslationPart ($resource) {
        var resourceUrl =  'api/public-site-translations/part';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { 
            	method:'POST',
                isArray: true,
                transformResponse: function (data) {
	                if (data) {
	                	data = angular.fromJson(data);
	                }
	                return data;
                }	
            }
        });
    }
})();
