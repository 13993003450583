(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('CardPackageSingleController', CardPackageSingleController);

    function CardPackageSingleController($scope, $rootScope, $stateParams, ShoppingCart, PromotionCodeService, CurrencyExchange /*,previousState, entity, Property, PropertyVouchers, User, $animate, $timeout, $document*/) {
        'ngInject';
        var vm = this;

        vm.$onInit = function(){

            //this is from bindings:
            /*
            vm.voucherObject;
            vm.currentProperty;
            vm.currentPropertyGroup;
            vm.websiteType;
            vm.onOpenModal;
            vm.groupCategories;
            vm.propertyCategories;
            */
            vm.voucherPoolObject = vm.voucherPoolObject || {};
            vm.singleMode = (vm.voucherPoolObject && vm.voucherPoolObject.vouchers && getVoucherPoolLength()==1);
            vm.homogeneous = isVoucherPoolHomogeneous();
            vm.openPackageModal = vm.openPackageModal || angular.noop;
            vm.viewLayoutTileDesign =  vm.viewLayoutTileDesign  || 'default';
            vm.descriptionLines = vm.descriptionLines || 3;

            //vm.currentVoucher = vm.voucherObject;
            vm.voucherPoolObjectVouchersArray = [];
            angular.forEach(vm.voucherPoolObject.vouchers,function(voucher,vkey){
                if(!voucher.variationName){
                    voucher.variationName = voucher.voucherName;
                }
                vm.voucherPoolObjectVouchersArray.push(voucher);
            });
            vm.firstVoucher = firstVoucher();
            vm.voucherObject = vm.firstVoucher;//for the moment only first
            vm.voucherObjectFirstCategory = vm.getFirstApplicableCategory(vm.voucherObject,'');
            vm.poolCategory = getPoolCategory(vm.voucherObject);




            vm.currentProperty = vm.currentProperty || {};
            vm.currentPropertyGroup = vm.currentPropertyGroup;
            var isGroup = typeof vm.currentPropertyGroup !== 'undefined';
            vm.isGroup = isGroup;
            vm.voucherProperty = getVoucherProperty();

            vm.giftCard = vm.voucherPoolObject.giftCard || undefined;
            vm.getImageFileName = getImageFileName;
            vm.revealedDescription = false;


            //recheck applied promotion code in case we have 2 or more promotion codes
            vm.appliedPromotionCode = PromotionCodeService.findApplicablePromo(vm.shoppingCart, vm.currentProperty, isGroup, vm.firstVoucher);;

            $scope.$on('itemAddedToCart', function(event, args) {
                vm.shoppingCart = args.shoppingCart;
            });
            $scope.$on('itemRemovedFromCart', function(event, args) {
                vm.shoppingCart = args.shoppingCart;
            });

            $scope.$on('voucherClassActive', function(event, args) {
                var categoryShortCodes = args.voucherClassActive ? args.voucherClassActive.split(',') : undefined;
                vm.voucherObjectFirstCategory = vm.getFirstApplicableCategory(vm.voucherObject,categoryShortCodes && categoryShortCodes.length>0 ? categoryShortCodes[0] : undefined);
            });

            //currency
            vm.baseCurrency = {
                code : vm.voucherObject.currency
            };

            vm.currentCurrency = {
                code : vm.baseCurrency.code
            }
            var currentCurrency = CurrencyExchange.currencies.current;//current currency might be NULL if this is group engine
            if(currentCurrency && currentCurrency!=null){
                vm.currentCurrency.code = currentCurrency.code;
            }

            $rootScope.$on('CurrencyExchangeProvider.changeCurrency', function(event, args) {
                vm.currentCurrency.code = args.code;
            });

        };

        function firstVoucher(){
            var firstVoucher;
            angular.forEach(vm.voucherPoolObject.vouchers,function(voucher,vkey){
                firstVoucher = firstVoucher || voucher;
                if (voucher.sequence < firstVoucher.sequence) {
                    firstVoucher = voucher;
                }
            });
            return firstVoucher;
        }

        function getVoucherProperty(){
            if(!vm.firstVoucher){
                return undefined;
            }
            if(!vm.currentPropertyGroup || !vm.currentPropertyGroup.hotels){
                return undefined;
            }
            var property = undefined;
            angular.forEach(vm.currentPropertyGroup.hotels,function(hotel,hkey){
                if(hotel.hotelId==vm.firstVoucher.hotelId){
                    property = hotel;
                }
            });
            return property;
        }

        function getVoucherPoolLength(){

            var count = 0;
            angular.forEach( vm.voucherPoolObject.vouchers,function(voucher,vkey){
                count++;
            });
            return count;
        }

        function isVoucherPoolHomogeneous(){

            var voucherIds = [];
            angular.forEach( vm.voucherPoolObject.vouchers,function(voucher,vkey){
                if(!voucherIds.includes(voucher.id)){
                    voucherIds.push(voucher.id);
                }
            });
            return voucherIds.length<=1;
        }

        function getPoolCategory(voucher){
            var categories = vm.websiteType=='group' ? vm.groupCategories : vm.propertyCategories;

            var poolCategory;

            angular.forEach(categories,function(c,cKey){
                if(c.id==vm.voucherPoolObject.poolId){
                    poolCategory = c;
                }
            });
            return poolCategory;
        }

        //when we change the variation
        vm.onVoucherObjectChanged = onVoucherObjectChanged;
        function onVoucherObjectChanged(voucherObject){
            //recheck applied promotion code in case we have 2 or more promotion codes
            vm.appliedPromotionCode = PromotionCodeService.findApplicablePromo(vm.shoppingCart, vm.currentProperty, vm.isGroup, vm.voucherObject);


        }

        function getImageFileName(){
            if (vm.firstVoucher && vm.firstVoucher.images && vm.firstVoucher.images!=null && vm.firstVoucher.images[7]) {
                return useImgix(vm.firstVoucher.images[7]);
            } else {
                return "//in1-image-library.imgix.net/no-image.jpg";
            }
        }

        vm.getFirstApplicableCategory = function(voucher,voucherClassActive){
            var category;
            angular.forEach(voucher.categories,function(c,cKey){
                if(!category){
                    category = c;
                }
                if(''+voucherClassActive==''+c.classId){
                    category = c;
                }
            });
            return category;
        }

        vm.toggleDescription = function($event){
            $event.preventDefault();
            $event.stopPropagation();
            vm.revealedDescription = !vm.revealedDescription;
            return false;
        }


        function useImgix(imgUrl){
            var old_host = 'dddo4e1h8fdy4.cloudfront.net';
            var new_host = 'in1-image-library.imgix.net';
            return imgUrl.replace(old_host, new_host);
        }

        vm.isPoolAvailableToBuy = function(){
            var voucherPool = vm.voucherPoolObject;
            var shoppingCart = vm.shoppingCart;
            var countAvailable = 0;
            angular.forEach(voucherPool.vouchers, function(v, vkey) {
                var sold = v.vouchersSold;
                if(shoppingCart && shoppingCart.shoppingCartItems && shoppingCart.shoppingCartItems.items){
                    angular.forEach(shoppingCart.shoppingCartItems.items, function(value, key) {
                        if(value.itemId == v.id){
                            sold++;
                        }
                    });
                }

                var available = v.voucherCount >=999 ? 99999 : v.voucherCount;
                if(sold<available){
                    countAvailable++;
                }
            });
            return countAvailable>0;
        }

        vm.openModal = function(){
            vm.openPackageModal({voucherPool:vm.voucherPoolObject, voucherId: vm.voucherObject.id});
        }

        vm.stopPropagation = function($event){
            $event.stopPropagation();
        }
    }
})();
