(function() {
    'use strict';

    function randomIntFromInterval(min, max) {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function PriceFilterController($log, $scope, $timeout) {
        'ngInject';
        var ctrl = this;

        //for the moment $log prints in production profile, so overwriting with console
        var $log = console;

        ctrl.$onInit = function() {
            $log.info('$onInit PriceFilterController');
            ctrl.priceRange = ctrl.priceRange || {};
            $log.info('priceRange', ctrl.priceRange);
            ctrl.loadingData = false;
            var priceRangeKeysArray = Object.keys(ctrl.priceRange).map(function(v) {
                return parseInt(v);
            });
            ctrl.minPrice = Math.min.apply(null, priceRangeKeysArray);
            ctrl.maxPrice = Math.max.apply(null, priceRangeKeysArray);

            ctrl.priceOverlay = false;

            $log.info('min/maxPrice', ctrl.minPrice, ctrl.maxPrice);
            /*
            * this is the ideal approach, but it will only work if step is currency exchanged and amounts to 20 euro in value
            ctrl.stepPrice = 20;
            var numberOfBins = Math.ceil((ctrl.maxPrice - ctrl.minPrice) / ctrl.stepPrice);
            */
            var numberOfBins = 40;
            //when using ceil: (2.1 becomes 3, 2.9 becomes 3)
            ctrl.stepPrice = Math.ceil((ctrl.maxPrice - ctrl.minPrice) / numberOfBins);
            $log.info('result stepPrice 1', ctrl.stepPrice);
            //minimum step we want is 10 and then ceil to the nearest 10 (2 becomes 10, 19 becomes 20)
            //ctrl.stepPrice = Math.ceil(ctrl.stepPrice / 10) * 10;
            //$log.info('result stepPrice 2', ctrl.stepPrice);

            ctrl.graphItems = ctrl.generateGraphItems(ctrl.priceRange,ctrl.minPrice,ctrl.maxPrice,ctrl.stepPrice);

            //price range slider
            ctrl.slider = {
                minValue: ctrl.min || 0,
                maxValue: ctrl.max || ctrl.graphItems[ctrl.graphItems.length - 1].max,
                options: {
                    floor: 0,
                    ceil: ctrl.graphItems[ctrl.graphItems.length - 1].max,
                    step: ctrl.stepPrice,
                    hidePointerLabels: true,
                    hideLimitLabels: true,
                },
            };
        };

        ctrl.generateGraphItems = function(priceIntervals,minPrice,maxPrice,stepPrice){

            var graphItems = [];

            for (var i = 0; i <= maxPrice && stepPrice>0; i += stepPrice) {
                var graphItem = {
                    min: i,
                    max: i + stepPrice /*- 1*/,
                    height: 0,
                    count: 0,
                };

                graphItems.push(graphItem);
            }

            var maxGraphCount = 0;

            graphItems.forEach(function(v, i) {
                //$log.info('------------');
                Object.keys(priceIntervals).filter(function(prKey) {
                    var keyInt = parseInt(prKey);
                    //$log.info('keyInt', keyInt, v.min, v.max);
                    if (keyInt >= v.min && keyInt <= v.max) {
                        v.count += priceIntervals[keyInt];
                        if (v.count > maxGraphCount) {
                            maxGraphCount = v.count;
                        }
                    }
                });
            });

            graphItems.map(function(v, i) {
                v.height = Math.round((64 / maxGraphCount) * v.count);
            });

            $log.info('graphItems', graphItems, maxGraphCount);

            return graphItems;

        }


        ctrl.checkRange = function(min, max) {
            if (
                (min < ctrl.slider.minValue && max < ctrl.slider.minValue) ||
                (max > ctrl.slider.maxValue && max > ctrl.slider.maxValue)
            ) {
                return true;
            } else {
                return false;
            }
        };

        ctrl.togglePriceButton = function(open) {
            //$log.info('price toggled', open, ctrl.priceOverlay);
            $log.info('price toggled', open);
            $timeout(function() {
                $scope.$broadcast('rzSliderForceRender');
            });
        };

        ctrl.save = function() {
            $log.info('save...?!', ctrl.slider.maxValue, ctrl.stepPrice);
            ctrl.loadingData = true;
            var promise = ctrl.onUpdatePromise({
                minPrice: ctrl.slider.minValue,
                maxPrice:
                    ctrl.slider.maxValue === ctrl.graphItems[ctrl.graphItems.length - 1].max + 1
                        ? 0
                        : ctrl.slider.maxValue,
            });
            var timeoutPromise = $timeout(1000*10);//10 seconds timeout
            timeoutPromise.then(function(){
                //try to resolve the promise after 10sec just in case
                promise.resolve();
            });
            promise.promise.then(function(){
                ctrl.loadingData = false;//stop loader
                ctrl.priceOverlay = false; //close overlay
            });

        };

        ctrl.clear = function() {
            $log.info(
                'ctrl.graphItems[ctrl.graphItems.length - 1].max',
                ctrl.graphItems[ctrl.graphItems.length - 1].max
            );
            ctrl.slider.minValue = 0;
            ctrl.slider.maxValue = ctrl.graphItems[ctrl.graphItems.length - 1].max + 1;
        };

    }

    angular.module('vobeApp').component('priceFilter', {
        templateUrl: 'app/components/vouchers/priceFilter/priceFilter.html',
        controller: PriceFilterController,
        bindings: {
            min: '<',
            max: '<',
            currencyString: '<',
            priceRange: '<',
            onUpdatePromise: '&',
        },
    });
})();
