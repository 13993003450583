(function() {
    'use strict';

    angular
        .module('vobeApp')
        .factory('translationPartialLoaderErrorHandler', translationPartialLoaderErrorHandler);

    translationPartialLoaderErrorHandler.$inject = ['$q', '$log'];

    function translationPartialLoaderErrorHandler($q, $log) {
        return function (part, lang, response) {
            $log.error('The "' + part + '/' + lang + '" part was not loaded.');
            return $q.when({});
        };
    }
})();
