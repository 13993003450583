(function() {
	'use strict';

	angular.module('vobeApp').controller('ExplainModalInstanceCtrl', ExplainModalInstanceCtrl);

	function ExplainModalInstanceCtrl($uibModalInstance, customExplain, config) {
        'ngInject';
		var $ctrl = this;

		$ctrl.customExplain = customExplain;
        $ctrl.postalOption = (config.postalOption === 'true');


		$ctrl.ok = function() {
			$uibModalInstance.close();
		};

		$ctrl.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();
