(function() {
    'use strict';

    angular.module('vobeApp')
        .provider('StripeElements', stripeElementsProvider)
        .directive('stripeElementDecorator', stripeElementDecorator)
        .directive('stripeElement', stripeElement);

    function stripeElementsProvider() {

        this.$get = function () {

            var that = this;
            this.Stripe = null;

            this._initStripe = function(apiKey) {
                that.Stripe = Stripe(apiKey, {apiVersion: '2022-11-15'});
                return that.Stripe;
            };

            this._isActive = function() {
                console.log('_isActive', that.Stripe)
                if (that.Stripe && that.Stripe._apiKey) {
                    return true;
                } else {
                    return false;
                }
            }

            return {
                initStripe: function (apiKey) {
                    return that._initStripe(apiKey);
                },
                isActive: function () {
                    return that._isActive();
                }
            }
        }
    }


    function stripeElementDecorator () {
        return {
            restrict: 'A',
            link: link
        }

        function link($scope, $element, $attr) {
            var $ctrl = $scope;

            $scope.$on('$destroy', function () {
                $ctrl.instance.destroy()
            })

            $ctrl.instance.mount($element[0])
        }
    }

    function stripeElement() {
        var controller = ['$scope', function ($scope) {

                function init() {
                    $scope.instance = angular.copy($scope.instance);
                }

                init();

                $scope.addItem = function () {
                    $scope.add();

                    //Add new customer to directive scope
                    $scope.items.push({
                        name: 'New Directive Controller Item'
                    });
                };
            }],

            template = '<div stripe-element-decorator></div>';

        return {
            restrict: 'EA', //Default in 1.3+
            scope: {
                instance: '='
            },
            controller: controller,
            template: template
        };
    }

})();
