(function() {
    'use strict';

    angular
        .module('vobeApp')
        .factory('PublicSiteLanguageSearch', PublicSiteLanguageSearch);

    PublicSiteLanguageSearch.$inject = ['$resource'];

    function PublicSiteLanguageSearch($resource) {
        var resourceUrl =  'api/_search/public-site-languages/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
