(function() {
    'use strict';

    angular
        .module('vobeApp')
        .factory('PublicSiteSearch', PublicSiteSearch);

    PublicSiteSearch.$inject = ['$resource'];

    function PublicSiteSearch($resource) {
        var resourceUrl =  'api/_search/public-sites/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
