(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('PropertyGroup', PropertyGroup);

    PropertyGroup.$inject = ['$resource'];

    function PropertyGroup ($resource) {
        var service = $resource('api/group/:code', {}, {
            'query': {method: 'GET', isArray: false},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
})();
