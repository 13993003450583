(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PublicSiteLanguageRelationshipDialogController', PublicSiteLanguageRelationshipDialogController);

    PublicSiteLanguageRelationshipDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PublicSiteLanguageRelationship', 'PublicSite','PublicSiteLanguage'];

    function PublicSiteLanguageRelationshipDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PublicSiteLanguageRelationship, PublicSite, PublicSiteLanguage) {
        var vm = this;

        vm.publicSiteLanguageRelationship = entity;
        vm.clear = clear;
        vm.save = save;
        vm.publicsites = PublicSite.query();
        vm.publicsitelanguages = PublicSiteLanguage.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.publicSiteLanguageRelationship.id !== null) {
                PublicSiteLanguageRelationship.update(vm.publicSiteLanguageRelationship, onSaveSuccess, onSaveError);
            } else {
                PublicSiteLanguageRelationship.save(vm.publicSiteLanguageRelationship, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('vobeApp:publicSiteLanguageRelationshipUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
