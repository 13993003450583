(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('PaymentProcessTransaction', PaymentProcessTransaction);

    PaymentProcessTransaction.$inject = ['$resource'];

    function PaymentProcessTransaction($resource) {
        var service = $resource('api/payment/:uuid', {uuid: '@uuid'}, {
            'process': {
                method: 'POST',
                params: {paymentReply: '@paymentReply'}
            }
        });

        var status = $resource('api/payment/status/:uuid', {uuid: '@uuid'}, {
            'get': {
                method: 'GET'
            }
        });

        return {
            service: service,
            status: status
        };
    }
})();

(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('SnapScanRefreshTransaction', SnapScanRefreshTransaction);


    function SnapScanRefreshTransaction($resource) {
        'ngInject';
        var service = $resource('api/payment/snapscan/:transactionId', {transactionId: '@transactionId'}, {
            'refresh': {
                method: 'POST'
            }
        });

        return service;
    }
})();
