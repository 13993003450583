(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('ShoppingCartDropdownButtonController', ShoppingCartDropdownButtonController);

	function ShoppingCartDropdownButtonController($scope, $rootScope, $state, $stateParams, $uibModal, $document) {
	    'ngInject';
	    var vm = this;
        vm.shoppingCart = vm.shoppingCart || {};
        vm.shoppingCartConfigs = vm.shoppingCartConfigs || {};
        vm.property = vm.property || {};

        function openShoppingCartDropdownModal(size, parentSelector) {

            var defaultModalOptions = {
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/components/shopping-cart/shopping-cart-dropdown/shopping-cart-dropdown-modal.html',
                controller: 'ShoppingCartDropdownModalController',
                controllerAs: 'vm',
                size: 'lg',
                appendTo: 'body',
                resolve: {
                    shoppingCart: function () {
                        return vm.shoppingCart;
                    },
                    property: function () {
                        return vm.property;
                    },
                    shoppingCartConfigs: function () {
                        return vm.shoppingCartConfigs;
                    },
                    propertyGroup: function () {
                        return vm.propertyGroup;
                    },
                    clear: function() {
                        return clear;
                    }
                }
            }

            var parentElem = parentSelector ? angular.element($document[0].querySelector(parentSelector)) : undefined;
            var modalOptions = angular.extend({},defaultModalOptions);
            modalOptions.templateUrl = 'app/components/shopping-cart/shopping-cart-dropdown/shopping-cart-dropdown-modal.html';
            modalOptions.appendTo = parentElem || modalOptions.appendTo;
            modalOptions.size = size || modalOptions.size;
            vm.modalInstance = $uibModal.open(modalOptions);

            function clear() {
                vm.modalInstance.dismiss('cancel');
            }

            //modalInstance.result.then(function (something) {
            //}, function () {
            //  console.info('Modal dismissed at: ' + new Date());
            //});
        };
        vm.openShoppingCartDropdownModal = openShoppingCartDropdownModal;

        $scope.$on('itemAddedToCart',function(event, args){
            vm.shoppingCart = args.shoppingCart;
            //vm.perishableAlerts.push({
            //	msg : $translate.instant('shopping-cart.alert.added.success'),
            //	type : "success"
            //});
            if($('#snackbar').length>0) {
                angular.element($("#snackbar")).parent().data('propertyAlertErrorScope').vm.addSuccessAlert('Added', 'shopping-cart.alert.added.success');
            }
        });

        $scope.$on('itemAddedToCartError',function(event, args) {
            if($('#snackbar').length>0) {
                var message = 'Error';
                if (args.data && args.data.message) {
                    message = args.data.message;
                }
                angular.element($("#snackbar")).parent().data('propertyAlertErrorScope').vm.addWarningAlert(message);
            }
        });


        $scope.$on('itemRemovedFromCart',function(event, args){
            vm.shoppingCart = args.shoppingCart;
            //vm.perishableAlerts.push({
            //	msg : $translate.instant('shopping-cart.alert.removed.success'),
            //	type : "success"
            //});
            if($('#snackbar').length>0) {
                angular.element($("#snackbar")).parent().data('propertyAlertErrorScope').vm.addSuccessAlert('Removed', 'shopping-cart.alert.removed.success');
            }
        });

	}
})();
