(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PublicSitePartDeleteController',PublicSitePartDeleteController);

    PublicSitePartDeleteController.$inject = ['$uibModalInstance', 'entity', 'PublicSitePart'];

    function PublicSitePartDeleteController($uibModalInstance, entity, PublicSitePart) {
        var vm = this;

        vm.publicSitePart = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PublicSitePart.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
