(function() {
    'use strict';


    angular
        .module('vobeApp')
        .filter('currencySign', currencySign);

    currencySign.$inject = ['$filter'];

    function currencySign ($filter) {
    	return function (currency) {
    		if(currency=='USD'){
    			return '$';
    		}
    		else if(currency=='EUR'){
    			return '€';
    		}
    		else if(currency=='GBP'){
    			return '£';
    		}
    		return currency;
    	};
    }
})();
