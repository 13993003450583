(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('ShoppingCartAddBulkRecipientsAndItem', ShoppingCartAddBulkRecipientsAndItem);

    ShoppingCartAddBulkRecipientsAndItem.$inject = ['$resource'];

    function ShoppingCartAddBulkRecipientsAndItem ($resource) {
        var service = $resource('api/shoppingcart/addBulk', {
            ref: '@ref',
            src: '@src',
            lang: '@lang',
            contextType: '@contextType',
            propertyType: '@propertyType',
            propertyCode: '@propertyCode',
            customerRef: '@customerRef'
        }, {
            'add': {
                method: 'PUT',
                transformResponse: function (data) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
})();
