(function() {
    'use strict';

    angular.module('vobeApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        function isHotelIdInGroup(currentPropertyGroup, hotelId) {
            if (typeof currentPropertyGroup === 'undefined') {
                return false;
            }
            var found = false;
            angular.forEach(currentPropertyGroup.hotels, function(hotel, hotelIndex) {
                if (hotel.hotelId == hotelId) {
                    found = true;
                }
            });
            return found;
        }

        function isHotelIdInMasters(currentProperty, hotelId) {
            if (!currentProperty.masterProperties || currentProperty.masterProperties == null) {
                return false;
            }
            var found = false;
            angular.forEach(currentProperty.masterProperties, function(hotel, hotelIndex) {
                if (hotel.hotelId == hotelId) {
                    found = true;
                }
            });
            return found;
        }

        $stateProvider
            .state('property', {
                abstract: true,
                parent: 'site',
                url:
                    '/{propertyType:property|group}/:propertyId/{contextType:vouchers|items|events|giftcards|rewards|refunds}?descriptionLines&reset&currency&bulk&customerRef',
                data: {
                    authorities: [],
                    pageTitle: 'vouchers.page.title',
                },
                views: {

                    'legal@' : {
                        templateUrl: 'app/layouts/footer/legal-property.html',
                        controller: 'LegalFooterController',
                        controllerAs: 'vm'
                    }
                },
                onEnter: [
                    '$state',
                    '$stateParams',
                    '$timeout',
                    function($state, $stateParams, $timeout) {
                        console.info('entering property', $stateParams);
                        //console.log("in property state");
                    },
                ],
                resolve: {
                    currentPropertyGroup: [
                        '$rootScope',
                        '$stateParams',
                        'PropertyGroup',
                        function($rootScope, $stateParams, PropertyGroup) {
                            if ($stateParams.propertyType !== 'group') {
                                return undefined;
                            }
                            var propertyGroup = PropertyGroup.get({ code: $stateParams.propertyId }, function() {
                                //broadcast property
                                $rootScope.$broadcast('propertyGroupSelected', { propertyGroup: propertyGroup });
                            });
                            return propertyGroup.$promise;
                        },
                    ],
                    currentProperty: [
                        'currentPropertyGroup',
                        '$rootScope',
                        '$stateParams',
                        'Property',
                        function(currentPropertyGroup, $rootScope, $stateParams, Property) {
                            if (typeof currentPropertyGroup !== 'undefined') {
                                $rootScope.$broadcast('propertySelected', {
                                    property: currentPropertyGroup.masterProperty,
                                });
                                return currentPropertyGroup.masterProperty;
                            }
                            var propertyId = $stateParams.propertyId;
                            var property = Property.get({ id: propertyId }, function() {
                                //broadcast property
                                $rootScope.$broadcast('propertySelected', { property: property });
                            });
                            return property.$promise;
                        },
                    ],
                    preShoppingCart: [
                        'ShoppingCart',
                        '$stateParams',
                        'currentLanguage',
                        'currentProperty',
                        'currentPropertyGroup',
                        'ENV',
                        function(ShoppingCart, $stateParams, currentLanguage, currentProperty, currentPropertyGroup, ENV) {

                            //record property and propertyGroup to posthog
                            if (typeof posthog !== 'undefined') {
                                var propertyGroup = '';
                                if (angular.isDefined(currentPropertyGroup)) {
                                    propertyGroup = currentPropertyGroup.code;
                                }
                                posthog.register_once({
                                    propertyId: currentProperty.hotelCode.toLowerCase(),
                                    propertyGroup: propertyGroup
                                });
                            }
                            //build shopping cart locale
                            var variant = '';
                            if ($stateParams.contextType) {
                                variant = '_' + $stateParams.contextType;
                            }
                            variant += (variant.length === 0 ? '_' : '') + currentProperty.hotelId;

                            var src = undefined;
                            if (typeof currentPropertyGroup !== 'undefined') {
                                src = currentPropertyGroup.code;
                            }
                            if ($stateParams.src) {
                                src = $stateParams.src;
                            }

                            return ShoppingCart.api.get({
                                ref: $stateParams.ref,
                                src: src,
                                lang: currentLanguage + variant,
                                contextType: $stateParams.contextType,
                                propertyType: $stateParams.propertyType,
                                propertyCode: $stateParams.propertyId,
                                customerRef: $stateParams.customerRef,
                                reset : $stateParams.reset

                            }).$promise;
                        },
                    ],
                    shoppingCart: [
                        '$q',
                        '$stateParams',
                        'preShoppingCart',
                        'ShoppingCart',
                        'ShoppingCartRemove',
                        'currentProperty',
                        'currentPropertyGroup',
                        'currentLanguage',
                        function(
                            $q,
                            $stateParams,
                            preShoppingCart,
                            ShoppingCart,
                            ShoppingCartRemove,
                            currentProperty,
                            currentPropertyGroup,
                            currentLanguage
                        ) {
                            var variant = '';
                            if ($stateParams.contextType) {
                                variant = '_' + $stateParams.contextType;
                            }
                            variant += (variant.length === 0 ? '_' : '') + currentProperty.hotelId;

                            function removeItem(itemUUID, successcallback, errorcallback) {
                                var cb = successcallback || angular.noop;
                                var ecb = errorcallback || angular.noop;
                                return ShoppingCartRemove.remove(
                                    { itemUUID: itemUUID },
                                    function(response) {
                                        return cb(response);
                                    },
                                    function(err) {
                                        return ecb(err);
                                    }.bind(this)
                                ).$promise;
                            }
                            //remove unwanted items in shopping cart
                            var removedPromises = [];
                            angular.forEach(preShoppingCart.shoppingCartItems.items, function(value, key) {
                                if (
                                    ($stateParams.propertyType == 'property' &&
                                        currentProperty.hotelId !== value.owningProperty.propertyId &&
                                        !isHotelIdInMasters(currentProperty, value.owningProperty.propertyId)) ||
                                    ($stateParams.propertyType == 'group' &&
                                        (!currentPropertyGroup ||
                                            !isHotelIdInGroup(currentPropertyGroup, value.owningProperty.propertyId)))
                                ) {
                                    var removePromise = removeItem(value.uuid);
                                    removedPromises.push(removePromise);
                                }
                            });
                            if (removedPromises.length > 0) {
                                var singleRemovePromise = $q.all(removedPromises);
                                var shoppingCartPromise = singleRemovePromise.then(
                                    function() {
                                        return ShoppingCart.api.get({
                                            ref: $stateParams.ref,
                                            src: $stateParams.src,
                                            lang: currentLanguage + variant,
                                            contextType: $stateParams.contextType,
                                            propertyType: $stateParams.propertyType,
                                            propertyCode: $stateParams.propertyId,
                                            customerRef: $stateParams.customerRef
                                        }).$promise;
                                    },
                                    function(reason) {
                                        return $q.reject(reason);
                                    }
                                );
                                return shoppingCartPromise;
                            } else {
                                return preShoppingCart;
                            }
                        },
                    ],
                    shoppingCartConfigs: [
                        'shoppingCart',
                        'VobeConfig',
                        'currentProperty',
                        function(shoppingCart, VobeConfig, currentProperty) {
                            //gather property ids
                            var propertyIds = [];
                            if (currentProperty.hotelId) {
                                propertyIds.push(currentProperty.hotelId);
                            }
                            angular.forEach(
                                shoppingCart.shoppingCartItems.items,
                                function(value, key) {
                                    if (jQuery.inArray(value.owningProperty.propertyId, this) < 0) {
                                        this.push(value.owningProperty.propertyId);
                                    }
                                },
                                propertyIds
                            );
                            //shoppingCart.shoppingCartKey
                            posthog.people.set_once({shoppingCartKey: shoppingCart.shoppingCartKey});
                            return VobeConfig.config.get({ propertyIds: propertyIds }).$promise;
                        },
                    ],
                    selectedCurrency: [
                        '$stateParams', '$q', 'currentProperty', 'CurrencyExchange', 'currentPropertyGroup', 'shoppingCartConfigs','$localStorage', function($stateParams, $q, currentProperty, CurrencyExchange, currentPropertyGroup, shoppingCartConfigs, $localStorage){
                            var isGroup = typeof currentPropertyGroup !== 'undefined';
                            var currencyApi = CurrencyExchange;
                            var currencySettings = $localStorage.currencySettings;
                            var userSelectedCurrency = currencySettings && currencySettings['displayCurrency_'+currentProperty.hotelId];
                            var finalCurrency = undefined;//currency can be undefined for groups!
                            var defaultDisplayCurrency = undefined;
                            if (shoppingCartConfigs[currentProperty.hotelId] && shoppingCartConfigs[currentProperty.hotelId].configMap && shoppingCartConfigs[currentProperty.hotelId].configMap.defaultDisplayCurrency) {
                                defaultDisplayCurrency = shoppingCartConfigs[currentProperty.hotelId].configMap.defaultDisplayCurrency;
                            }
                            if($stateParams.currency){
                                finalCurrency = $stateParams.currency.toUpperCase();//priority no.1
                            } else if(userSelectedCurrency) {
                                finalCurrency = userSelectedCurrency.toUpperCase();//priority no.2
                            } else if(defaultDisplayCurrency) {
                                finalCurrency = defaultDisplayCurrency.toUpperCase();//priority no.3 - IMC config
                            } else if(!isGroup) {
                                finalCurrency = currentProperty.hotelCurrency.toUpperCase();//safe to use if we have only one property, priority no.4
                            }

                            var activeCurrencyCodesArray = [];
                            if (shoppingCartConfigs[currentProperty.hotelId] && shoppingCartConfigs[currentProperty.hotelId].configMap && shoppingCartConfigs[currentProperty.hotelId].configMap.currencies) {
                                var currenciesString = shoppingCartConfigs[currentProperty.hotelId].configMap.currencies;
                                activeCurrencyCodesArray = currenciesString.replace(/\s/g, '').split(',');
                            }
                            if(!isGroup){
                                if(!activeCurrencyCodesArray.includes(currentProperty.hotelCurrency)){
                                    activeCurrencyCodesArray.push(currentProperty.hotelCurrency);
                                }
                            } else if(isGroup){
                                angular.forEach(currentPropertyGroup.hotels, function(hotel,i){
                                    if(!activeCurrencyCodesArray.includes(hotel.hotelCurrency)){
                                        activeCurrencyCodesArray.push(hotel.hotelCurrency);
                                    }
                                });
                            }

                            var alwaysTruePromise = $q.defer();
                            currencyApi.initCurrencies().then(
                                function(response){
                                    if(finalCurrency){
                                        currencyApi.addBaseCurrency(finalCurrency);
                                    }
                                    currencyApi.setActiveCurrencies(activeCurrencyCodesArray);
                                    //resolve it with finalCurrency;
                                    alwaysTruePromise.resolve(finalCurrency);
                                },
                                function(reason){
                                    if(finalCurrency){
                                        currencyApi.addBaseCurrency(finalCurrency);
                                    }
                                    currencyApi.setActiveCurrencies(activeCurrencyCodesArray);
                                    //even if currencies endpoint failed, resolve it with finalCurrency;
                                    alwaysTruePromise.resolve(finalCurrency);
                                }
                            );
                            return alwaysTruePromise.promise;
                        }
                    ],
                    brandingTemplateUUID: [
                        '$stateParams',
                        'shoppingCart',
                        'HeaderFooterService',
                        'currentProperty',
                        function($stateParams, shoppingCart, HeaderFooterService, currentProperty) {
                            //check state params in hope we find propertyId there
                            var propertyId = currentProperty.hotelId || 0;
                            //if we cannot determine propertyId from the state, we need to look at the shopping cart
                            if (propertyId == 0) {
                                var propertyIds = [];
                                angular.forEach(
                                    shoppingCart.shoppingCartItems.items,
                                    function(value, key) {
                                        if (jQuery.inArray(value.owningProperty.propertyId, this) < 0) {
                                            this.push(value.owningProperty.propertyId);
                                        }
                                    },
                                    propertyIds
                                );
                                if (propertyIds.length === 1) {
                                    propertyId = propertyIds[0];
                                }
                            }
                            return HeaderFooterService.get({
                                hotelId: propertyId,
                                ref: $stateParams.ref,
                                src: $stateParams.src,
                            }).$promise;
                        },
                    ],
                    previousState: function($state, $transition$, currentProperty, currentPropertyGroup, DetermineShopMoreState) {
                        var shopMoreState = DetermineShopMoreState.getShopMoreState();
                        if (
                            shopMoreState &&
                            shopMoreState.params &&
                            shopMoreState.params.propertyId &&
                            shopMoreState.params.propertyId.toLowerCase() != currentProperty.hotelCode.toLowerCase()
                        ) {
                            shopMoreState.params.propertyId =
                                $transition$.params().propertyType == 'group'
                                    ? currentPropertyGroup.code
                                    : currentProperty.hotelCode.toLowerCase();
                            DetermineShopMoreState.setShopMoreState(shopMoreState);
                        }
                        var currentStateData = {
                            name: $state.current.name || 'property',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params),
                        };
                        return currentStateData;
                    },
                    mainTranslatePartialLoader: [
                        '$stateParams',
                        '$translate',
                        '$translatePartialLoader',
                        'currentProperty',
                        function($stateParams, $translate, $translatePartialLoader, currentProperty) {
                            var variant = '';
                            if ($stateParams.contextType) {
                                variant = '_' + $stateParams.contextType;
                            }
                            variant += '_' + currentProperty.hotelId;
                            //console.log("in property state adding part 'shopping-cart"+variant);
                            $translatePartialLoader.addPart('global' + variant);
                            $translatePartialLoader.addPart('shopping-cart' + variant);
                            return $translate.refresh();
                        },
                    ],
                    profileInfo: [
                        'ProfileService',
                        function(ProfileService) {
                            return ProfileService.getProfileInfo();
                        },
                    ],
                    isChina : ['$window', function($window){
                        var isChina = false;
                        //no GTM and GA in China!
                        if($window.IN1 && $window.IN1.countryCodeTwoLetter && $window.IN1.countryCodeTwoLetter=="CN"){
                            console.log("detected chinese user");
                            isChina = true;
                        }
                        return isChina;
                    }],
                    gtmAccounts: [
                        'currentProperty',
                        '$stateParams',
                        'GoogleTagManagerService',
                        function(currentProperty, $stateParams, GoogleTagManagerService) {
                            return GoogleTagManagerService.getCodes({
                                id: currentProperty.hotelId,
                                ref: $stateParams.ref,
                            });
                        },
                    ],
                    gtm: [
                        'profileInfo',
                        'gtmAccounts',
                        'currentProperty',
                        'GoogleTagManagerService',
                        'TagManager',
                        'isChina',
                        '$rootScope',
                        'CookiesConsentStorageService',
                        'IN1_CONSENT',
                        '$window',
                        function(profileInfo, gtmAccounts, currentProperty, GoogleTagManagerService, TagManager, isChina, $rootScope, CookiesConsentStorageService, IN1_CONSENT, $window) {

                            var inProduction = profileInfo.inProduction;
                            if (!inProduction) {
                                console.log("deactivating google GTM in dev profile");
                                //return;
                            }

                            //no GTM and GA in China!
                            if(isChina){
                                console.log("no GTM in China");
                                return;
                            }

                            //COOKIE POLICY:
                            var isGDPR = ($window && $window.IN1 && $window.IN1.isGDPR) ? $window.IN1.isGDPR : false;

                            function getDefaultStorage(){
                                var defaultConsent = CookiesConsentStorageService.get();

                                //1. HANDLE GOOGLE ADSENSE / GOOGLE ADWORDS VIA GTM
                                var allowGTMAdStorage = isGDPR ? false : true;//by default it's false for people in the GDPR territories
                                //check if it is explicitly allowed
                                var adConsentAllowed = defaultConsent[IN1_CONSENT.ADVERTISING]==IN1_CONSENT.GRANTED || (typeof defaultConsent[IN1_CONSENT.ADVERTISING]  === "undefined" && defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.GRANTED) || false;
                                if(adConsentAllowed){
                                    allowGTMAdStorage = true;
                                }
                                //check if it is explicitly denied
                                var adConsentDenied = defaultConsent[IN1_CONSENT.ADVERTISING]==IN1_CONSENT.DENIED || (typeof defaultConsent[IN1_CONSENT.ADVERTISING]  === "undefined" && defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.DENIED) || true;
                                if(adConsentDenied){
                                    allowGTMAdStorage = false;
                                }

                                //2. HANDLE GOOGLE ANALYTICS VIA GTM
                                var allowGTMAnalyticsStorage = isGDPR ? false : true;//we don't know if the account has anonymizeIp ON and allowAdFeatures OFF, so we must set to false for people in the GDPR territories
                                //check if it is explicitly allowed
                                /*var analyticsConsentAllowed = defaultConsent[IN1_CONSENT.ANALYTICS]==IN1_CONSENT.GRANTED || (typeof defaultConsent[IN1_CONSENT.ANALYTICS]  === "undefined" && defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.GRANTED);
                                if(analyticsConsentAllowed){
                                    allowGTMAnalyticsStorage = true;
                                }
                                //check if it is explicitly denied
                                var analyticsConsentDenied = defaultConsent[IN1_CONSENT.ANALYTICS]==IN1_CONSENT.DENIED || (typeof defaultConsent[IN1_CONSENT.ANALYTICS]  === "undefined" && defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.DENIED);
                                if(analyticsConsentDenied){
                                    allowGTMAnalyticsStorage = false;
                                }
                                */
                                 //check if it is explicitly allowed
                                var analyticsConsentAllowed = defaultConsent[IN1_CONSENT.PERFORMANCE]==IN1_CONSENT.GRANTED || (typeof defaultConsent[IN1_CONSENT.PERFORMANCE]  === "undefined" && defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.GRANTED);
                                if(analyticsConsentAllowed){
                                    allowGTMAnalyticsStorage = true;
                                }
                                //check if it is explicitly denied
                                var analyticsConsentDenied = defaultConsent[IN1_CONSENT.PERFORMANCE]==IN1_CONSENT.DENIED || (typeof defaultConsent[IN1_CONSENT.PERFORMANCE]  === "undefined" && defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.DENIED);
                                if(analyticsConsentDenied){
                                    allowGTMAnalyticsStorage = false;
                                }

                                var processedConsent = CookiesConsentStorageService.processDefaultConsent(defaultConsent);

                                return {
                                    consent : defaultConsent,
                                    processedConsent: processedConsent,
                                    adConsentDenied : adConsentDenied,
                                    allDenied : defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.DENIED,
                                    allowGTMAnalyticsStorage : true,
                                    allowGTMAdStorage: true
                                }
                            }

                            var defaultGTMStorage = getDefaultStorage();

                            //3. CONFIGURE GTM based on initial consent
                            //Products that support Consent Mode include:
                            // Google Ads (including Google Ads Conversion Tracking and Remarketing)
                            // Google Analytics
                            // Floodlight
                            // Conversion Linker
                            var ad_storage = defaultGTMStorage.allowGTMAdStorage;
                            var analytics_storage = defaultGTMStorage.allowGTMAnalyticsStorage;

                            //set GTM variables
                            TagManager.updateConsent(ad_storage, analytics_storage);

                            //default datalayer variables
                            TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ANALYTICS,isGDPR && !defaultGTMStorage.processedConsent[IN1_CONSENT.ANALYTICS] ? IN1_CONSENT.DENIED : IN1_CONSENT.GRANTED);
                            TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ADVERTISING,isGDPR && !defaultGTMStorage.processedConsent[IN1_CONSENT.ADVERTISING] ? IN1_CONSENT.DENIED : IN1_CONSENT.GRANTED);
                            TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_PERFORMANCE,isGDPR && !defaultGTMStorage.processedConsent[IN1_CONSENT.PERFORMANCE] ? IN1_CONSENT.DENIED : IN1_CONSENT.GRANTED);
                            TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ENHANCEMENT,isGDPR && !defaultGTMStorage.processedConsent[IN1_CONSENT.ENHANCEMENT] ? IN1_CONSENT.DENIED : IN1_CONSENT.GRANTED);
                            TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_FUNCTIONAL,isGDPR && !defaultGTMStorage.processedConsent[IN1_CONSENT.FUNCTIONAL] ? IN1_CONSENT.DENIED : IN1_CONSENT.GRANTED);
                            TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_TRACKING,isGDPR && !defaultGTMStorage.processedConsent[IN1_CONSENT.TRACKING] ? IN1_CONSENT.DENIED : IN1_CONSENT.GRANTED);

                            TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ALL,isGDPR && (typeof defaultGTMStorage.consent[IN1_CONSENT.ALL] !== 'undefined' && !defaultGTMStorage.processedConsent[IN1_CONSENT.ALL]) ? IN1_CONSENT.DENIED : IN1_CONSENT.GRANTED);

                            //4. make sure to manage consent properly on update
                            $rootScope.$on(IN1_CONSENT.EVENT_GRANTED_ALL, function(){
                                TagManager.updateConsent(true, true, true);

                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ALL,IN1_CONSENT.GRANTED);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_STRICTLY_NECESSARY,IN1_CONSENT.GRANTED);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ANALYTICS,IN1_CONSENT.GRANTED);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ADVERTISING,IN1_CONSENT.GRANTED);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_TRACKING,IN1_CONSENT.GRANTED);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_PERFORMANCE,IN1_CONSENT.GRANTED);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ENHANCEMENT,IN1_CONSENT.GRANTED);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_FUNCTIONAL,IN1_CONSENT.GRANTED);
                                registerGTMScripts();
                            });
                            $rootScope.$on(IN1_CONSENT.EVENT_GRANTED_ADVERTISING, function(){
                                TagManager.updateConsent(true, undefined, true);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ADVERTISING,IN1_CONSENT.GRANTED);
                                registerGTMScripts();
                            });
                            $rootScope.$on(IN1_CONSENT.EVENT_GRANTED_PERFORMANCE, function(){
                                TagManager.updateConsent(undefined, true, true);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_PERFORMANCE,IN1_CONSENT.GRANTED);
                                registerGTMScripts(true);
                            });
                            $rootScope.$on(IN1_CONSENT.EVENT_GRANTED_ANALYTICS, function(){
                                TagManager.updateConsent(undefined, true, true);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ANALYTICS,IN1_CONSENT.GRANTED);
                                registerGTMScripts(true);
                            });
                            $rootScope.$on(IN1_CONSENT.EVENT_GRANTED_TRACKING, function(){
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_TRACKING,IN1_CONSENT.GRANTED);
                                registerGTMScripts(true);
                            });
                            $rootScope.$on(IN1_CONSENT.EVENT_GRANTED_ENHANCEMENT, function(){
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ENHANCEMENT,IN1_CONSENT.GRANTED);
                                registerGTMScripts(true);
                            });
                            $rootScope.$on(IN1_CONSENT.EVENT_GRANTED_FUNCTIONAL, function(){
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_FUNCTIONAL,IN1_CONSENT.GRANTED);
                                registerGTMScripts(true);
                            });
                            $rootScope.$on(IN1_CONSENT.EVENT_GRANTED_STRICTLY_NECESSARY, function(){
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_STRICTLY_NECESSARY,IN1_CONSENT.GRANTED);
                                registerGTMScripts(true);
                            });

                            $rootScope.$on(IN1_CONSENT.EVENT_DENIED_ALL, function(){
                                TagManager.updateConsent(false, false, true);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ALL,IN1_CONSENT.DENIED);

                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ANALYTICS,IN1_CONSENT.DENIED);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ADVERTISING,IN1_CONSENT.DENIED);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_TRACKING,IN1_CONSENT.DENIED);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_PERFORMANCE,IN1_CONSENT.DENIED);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ENHANCEMENT,IN1_CONSENT.DENIED);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_FUNCTIONAL,IN1_CONSENT.DENIED);
                            });

                            $rootScope.$on(IN1_CONSENT.EVENT_DENIED_ANALYTICS, function(){
                                TagManager.updateConsent(undefined, false, true);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ANALYTICS,IN1_CONSENT.DENIED);
                            });
                            $rootScope.$on(IN1_CONSENT.EVENT_DENIED_ADVERTISING, function(){
                                TagManager.updateConsent(false, undefined, true);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ADVERTISING,IN1_CONSENT.DENIED);
                            });
                            $rootScope.$on(IN1_CONSENT.EVENT_DENIED_PERFORMANCE, function(){
                                TagManager.updateConsent(false, undefined, true);
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_PERFORMANCE,IN1_CONSENT.DENIED);
                            });
                            $rootScope.$on(IN1_CONSENT.EVENT_DENIED_TRACKING, function(){
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_TRACKING,IN1_CONSENT.DENIED);
                            });
                            $rootScope.$on(IN1_CONSENT.EVENT_DENIED_ENHANCEMENT, function(){
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_ENHANCEMENT,IN1_CONSENT.DENIED);
                            });
                            $rootScope.$on(IN1_CONSENT.EVENT_DENIED_FUNCTIONAL, function(){
                                TagManager.pushVariable(IN1_CONSENT.GTM_EVENT_DENIED_FUNCTIONAL,IN1_CONSENT.DENIED);
                            });

                            //5. check if we can register the scripts
                            if(!isGDPR || defaultGTMStorage.processedConsent[IN1_CONSENT.ALL] || defaultGTMStorage.processedConsent[IN1_CONSENT.ADVERTISING]){
                                registerGTMScripts();
                            } else {
                                console.info('GTM will register scripts on consent');
                            }


                            function registerGTMScripts(safeOnly){

                                console.info('GTM registerGTMScripts with safeOnly: '+safeOnly);

                                //add generic IN1 solutions tag manager, it's safe to add it as we control it
                                /*
                                if (inProduction && !GoogleTagManagerService.isInitializedTag('GTM-TKLJPR')) {
                                    TagManager.registerScriptTags('GTM-TKLJPR', 'dataLayer');
                                    GoogleTagManagerService.setInitializedTag('GTM-TKLJPR',true);
                                    console.info('Registering Safe In1 Own GTM-TKLJPR');
                                } else if(GoogleTagManagerService.isInitializedTag('GTM-TKLJPR')) {
                                    console.info('GoogleTagManagerService GTM-TKLJPR already initialised');
                                }*/

                                //add DEV IN1 solutions tag manager, it's safe to add it as we control it
                                if (!inProduction && !GoogleTagManagerService.isInitializedTag('GTM-WLJRCF9')) {
                                    //TagManager.registerScriptTags('GTM-WLJRCF9', 'dataLayer');
                                    //GoogleTagManagerService.setInitializedTag('GTM-WLJRCF9',true);
                                    //console.info('Registering Safe DEV In1 Own GTM-WLJRCF9');
                                } else if(GoogleTagManagerService.isInitializedTag('GTM-WLJRCF9')) {
                                    console.info('GoogleTagManagerService GTM-WLJRCF9 already initialised');
                                }

                                function initializeTags(){
                                    //TODO: add here only GTM containers that we control, add rest only if advertising consent
                                    if (gtmAccounts) {
                                        angular.forEach(gtmAccounts, function(gtmAccount, key) {
                                            if(safeOnly && !gtmAccount.safe){
                                                //skip tag if it's unsafe
                                                console.info('GoogleTagManagerService skipping unsafe tag '+gtmAccount.containerTag);
                                                return;
                                            }
                                            //always use same name: 'dataLayer'
                                            if (!GoogleTagManagerService.isInitializedTag(gtmAccount.containerTag)) {
                                                TagManager.registerScriptTags(gtmAccount.containerTag, 'dataLayer');
                                                GoogleTagManagerService.setInitializedTag(gtmAccount.containerTag,true);
                                                console.info('Registering '+gtmAccount.containerTag);
                                            } else {
                                                console.info('GoogleTagManagerService '+gtmAccount.containerTag+' already initialised');
                                            }
                                        });
                                    }
                                }

                                if (inProduction) {
                                    initializeTags();
                                }
                            }

                        },
                    ],
                    analyticsAccounts: [
                        'profileInfo',
                        'currentProperty',
                        '$stateParams',
                        'GoogleAnalyticsCodesService',
                        function(profileInfo, currentProperty, $stateParams, GoogleAnalyticsCodesService) {
                            //var inProduction = profileInfo.inProduction;
                            //if(!inProduction){
                            //	return;
                            //}
                            return GoogleAnalyticsCodesService.getCodes({
                                id: currentProperty.hotelId,
                                ref: $stateParams.ref,
                            });
                        },
                    ],
                    analytics: [
                        'profileInfo',
                        'analyticsAccounts',
                        'currentProperty',
                        'Analytics',
                        'GoogleAnalyticsCodesService',
                        '$window',
                        'isChina',
                        '$rootScope',
                        'CookiesConsentStorageService',
                        'shoppingCart',
                        '$timeout',
                        'IN1_CONSENT',
                        function(
                            profileInfo,
                            analyticsAccounts,
                            currentProperty,
                            Analytics,
                            GoogleAnalyticsCodesService,
                            $window,
                            isChina,
                            $rootScope,
                            CookiesConsentStorageService,
                            shoppingCart,
                            $timeout,
                            IN1_CONSENT
                        ) {

                            var inProduction = profileInfo.inProduction;
                            if (!inProduction) {
                                console.log("deactivating google in dev profile");
                                //return;
                            }

                            console.log("doing google analytics scripts");
                            //if($window && $window.navigator && $window.navigator.userAgent && ~navigator.userAgent.indexOf("Speed Insights")){
                            //	return;
                            //}
                            //console.log("isProduction: "+inProduction);

                            try {
                                Analytics.configuration.currency = currentProperty.hotelCurrency;
                            } catch (e){
                                console.error(e);
                            }

                            //ga('set', 'currencyCode', 'EUR');//does not work
                            //Analytics.set('currencyCode', currentProperty.hotelCurrency);//does not work

                            //COOKIE POLICY:
                            var isGDPR = ($window && $window.IN1 && $window.IN1.isGDPR) ? $window.IN1.isGDPR : false;

                            var defaultConsent = getDefaultConsent();

                            function getDefaultConsent() {
                                var defaultConsent = CookiesConsentStorageService.get();

                                var allowAds = isGDPR ? false : true;//by default it's false for people in the GDPR territories
                                //check if it is explicitly allowed
                                var adConsentAllowed = defaultConsent[IN1_CONSENT.ADVERTISING]==IN1_CONSENT.GRANTED || (typeof defaultConsent[IN1_CONSENT.ADVERTISING]  === "undefined" && defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.GRANTED);
                                if(adConsentAllowed){
                                    allowAds = true;
                                }
                                //check if it is explicitly denied
                                var adConsentDenied = defaultConsent[IN1_CONSENT.ADVERTISING]==IN1_CONSENT.DENIED || (typeof defaultConsent[IN1_CONSENT.ADVERTISING]  === "undefined" && defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.DENIED);
                                if(adConsentDenied){
                                    allowAds = false;
                                }

                                var allowAnalytics = isGDPR ? false : true;//we don't know if the account has anonymizeIp ON and allowAdFeatures OFF, so we must set to false for people in the GDPR territories
                                //check if it is explicitly allowed
                                /*var analyticsConsentAllowed = defaultConsent[IN1_CONSENT.ANALYTICS]==IN1_CONSENT.GRANTED || (typeof defaultConsent[IN1_CONSENT.ANALYTICS]  === "undefined" && defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.GRANTED);
                                if(analyticsConsentAllowed){
                                    allowAnalytics = true;
                                }
                                //check if it is explicitly denied
                                var analyticsConsentDenied = defaultConsent[IN1_CONSENT.ANALYTICS]==IN1_CONSENT.DENIED || (typeof defaultConsent[IN1_CONSENT.ANALYTICS]  === "undefined" && defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.DENIED);
                                if(analyticsConsentDenied){
                                    allowAnalytics = false;
                                }*/
                                var analyticsConsentAllowed = defaultConsent[IN1_CONSENT.PERFORMANCE]==IN1_CONSENT.GRANTED || (typeof defaultConsent[IN1_CONSENT.PERFORMANCE]  === "undefined" && defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.GRANTED);
                                if(analyticsConsentAllowed){
                                    allowAnalytics = true;
                                }
                                //check if it is explicitly denied
                                var analyticsConsentDenied = defaultConsent[IN1_CONSENT.PERFORMANCE]==IN1_CONSENT.DENIED || (typeof defaultConsent[IN1_CONSENT.PERFORMANCE]  === "undefined" && defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.DENIED);
                                if(analyticsConsentDenied){
                                    allowAnalytics = false;
                                }

                                return {
                                    consent : defaultConsent,
                                    adsConsentDenied :!allowAds,
                                    analyticsConsentDenied : !allowAnalytics,
                                    analyticsConsentAllowed : analyticsConsentAllowed,
                                    allDenied : defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.DENIED,
                                    analyticsDenied : defaultConsent[IN1_CONSENT.PERFORMANCE]==IN1_CONSENT.DENIED,
                                    allGranted : defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.GRANTED,
                                    analyticsAllowed : defaultConsent[IN1_CONSENT.PERFORMANCE]==IN1_CONSENT.GRANTED,
                                }
                            }

                            function addTrackers(noStorage){

                                console.log("Registering Google Analytics with storage: "+!noStorage)

                                if (inProduction) {
                                    if (analyticsAccounts && analyticsAccounts.length > 0) {
                                        for (var i = 0; i < analyticsAccounts.length; i++) {
                                            var otherAccount = analyticsAccounts[i];

                                            var tracker_fields = {
                                                cookieName:  'tracker_' + i,
                                                cookieExpires: 20000,
                                                cookieFlags: 'secure'
                                                // See: [Analytics Field Reference](https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference) for a list of all fields.
                                            };

                                            if(noStorage){
                                                tracker_fields['storage'] = 'none';
                                                //should this be sha256 encoded?
                                                tracker_fields['clientId'] = shoppingCart.shoppingCartKey;
                                            }

                                            var tracker = {
                                                tracker: otherAccount.ua,
                                                name: 'tracker_' + i,
                                                fields: tracker_fields,
                                                crossDomainLinker: true,
                                                displayFeatures: true,
                                                enhancedLinkAttribution: true,
                                                trackEvent: true,
                                                trackEcommerce: true,
                                                select: function(args) {
                                                    return true;
                                                },
                                                set: {
                                                    'currencyCode': currentProperty.hotelCurrency,
                                                    'forceSSL': true,
                                                    //COOKIE POLICY:
                                                    // (https://www.simoahava.com/analytics/allow-block-advertising-features-google-analytics/)
                                                    'allowAdFeatures' : noStorage || defaultConsent.analyticsConsentDenied || defaultConsent.allDenied || defaultConsent.adsConsentDenied ? false : true,
                                                    'anonymizeIp' : noStorage || defaultConsent.allDenied || defaultConsent.analyticsConsentDenied  ? true : false,
                                                },

                                            };
                                            Analytics.configuration.accounts.push(tracker);
                                        }
                                    }
                                }
                            }




                            //console.log("will register trackers");
                            // Manually create either classic analytics (ga.js) or universal analytics (analytics.js) script tags
                            function registerScriptTags(noStorage){
                                if (GoogleAnalyticsCodesService.isInitialized()) {
                                    console.log("GoogleAnalyticsCodesService already initialized");
                                    return false;
                                }

                                // Manually setup the tracker object(s)
                                addTrackers(noStorage);
                                Analytics.registerScriptTags();
                                Analytics.registerTrackers();

                                //mark this as done' so we won't init GA next time
                                GoogleAnalyticsCodesService.setInitialized(true);
                                GoogleAnalyticsCodesService.setInitializedWithoutStorage(noStorage);

                                return true;
                            }

                            //self hosted GA in China!
                            if(isChina){
                                //var scriptSource = '/ga/in1analytics-v1.4.js';
                                var scriptSource = 'https://in1-obe-resources.imgix.net/ga/in1analytics-v1.4.js';
                                console.log("using GA hosted on our server");
                                (function (i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function (){
                                    (i[r].q=i[r].q||[]).push(arguments);},i[r].l=1*new Date();a=s.createElement(o),
                                    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);
                                })(window,document,'script',scriptSource,'ga');

                                // Manually setup the tracker object(s)
                                registerScriptTags();
                            } else {
                                if (!isGDPR) {
                                    registerScriptTags(true);
                                } else if(defaultConsent.allGranted || defaultConsent.analyticsAllowed) {
                                    //register with cookies
                                    registerScriptTags();
                                }
                            }

                            $rootScope.$on(IN1_CONSENT.EVENT_GRANTED_ALL, function(){
                                registerScriptTags();
                                window['ga-disable-UA-25206573-4'] = false;
                                Analytics.set('anonymizeIp', false, 'tracker_in1');
                                Analytics.set('allowAdFeatures', true, 'tracker_in1');
                                if (analyticsAccounts && analyticsAccounts.length > 0) {
                                    for (var i = 0; i < analyticsAccounts.length; i++) {
                                        var otherAccount = analyticsAccounts[i];
                                        window['ga-disable-'+otherAccount.ua] = false;
                                        Analytics.set('anonymizeIp', false, 'tracker_' + i);
                                        Analytics.set('allowAdFeatures', true, 'tracker_' + i);
                                    }
                                }
                            });

                            $rootScope.$on(IN1_CONSENT.EVENT_GRANTED_PERFORMANCE, function(){
                                registerScriptTags();
                                window['ga-disable-UA-25206573-4'] = false;
                                Analytics.set('anonymizeIp', false, 'tracker_in1');
                                if (analyticsAccounts && analyticsAccounts.length > 0) {
                                    for (var i = 0; i < analyticsAccounts.length; i++) {
                                        var otherAccount = analyticsAccounts[i];
                                        window['ga-disable-'+otherAccount.ua] = false;
                                        Analytics.set('anonymizeIp', false, 'tracker_' + i);
                                    }
                                }
                            });

                            $rootScope.$on(IN1_CONSENT.EVENT_GRANTED_ADVERTISING, function(){
                                registerScriptTags();
                                window['ga-disable-UA-25206573-4'] = false;
                                Analytics.set('anonymizeIp', false, 'tracker_in1');
                                Analytics.set('allowAdFeatures', true, 'tracker_in1');
                                if (analyticsAccounts && analyticsAccounts.length > 0) {
                                    for (var i = 0; i < analyticsAccounts.length; i++) {
                                        var otherAccount = analyticsAccounts[i];
                                        window['ga-disable-'+otherAccount.ua] = false;
                                        Analytics.set('anonymizeIp', false, 'tracker_' + i);
                                        Analytics.set('allowAdFeatures', true, 'tracker_' + i);
                                    }
                                }
                            });

                            $rootScope.$on(IN1_CONSENT.EVENT_DENIED_ALL, function(){
                                window['ga-disable-UA-25206573-4'] = true;
                                if (analyticsAccounts && analyticsAccounts.length > 0) {
                                    for (var i = 0; i < analyticsAccounts.length; i++) {
                                        var otherAccount = analyticsAccounts[i];
                                        window['ga-disable-'+otherAccount.ua] = true;
                                    }
                                }
                            });

                            $rootScope.$on(IN1_CONSENT.EVENT_DENIED_PERFORMANCE, function(){
                                window['ga-disable-UA-25206573-4'] = true;
                                if (analyticsAccounts && analyticsAccounts.length > 0) {
                                    for (var i = 0; i < analyticsAccounts.length; i++) {
                                        var otherAccount = analyticsAccounts[i];
                                        window['ga-disable-'+otherAccount.ua] = true;
                                    }
                                }
                            });

                            $rootScope.$on(IN1_CONSENT.EVENT_DENIED_ADVERTISING, function(){
                                Analytics.set('anonymizeIp', true, 'tracker_in1');
                                Analytics.set('allowAdFeatures', false, 'tracker_in1');
                                if (analyticsAccounts && analyticsAccounts.length > 0) {
                                    for (var i = 0; i < analyticsAccounts.length; i++) {
                                        Analytics.set('anonymizeIp', true, 'tracker_' + i);
                                        Analytics.set('allowAdFeatures', false, 'tracker_' + i);
                                    }
                                }
                            });
                        },
                    ],
                },
            })
            .state('list', {
                parent: 'property',
                url: '/list?cat&promotionCode&minPrice&maxPrice&page&pRef',
                params: {
                    cat: 'all',
                },
                data: {
                    authorities: [],
                },
                reloadOnSearch: false, //THIS WILL PREVENT RELOADING WHEN CAT(?cat=category) in URL CHANGES!
                views: {
                    'content@': {
                        templateUrl: 'app/site/property/vouchers/property-vouchers.html',
                        controller: 'PropertyVouchersController',
                        controllerAs: 'vm',
                    },
                    'header@': {
                        templateUrl: 'app/layouts/header/header.html',
                        controller: 'BrandedHeaderController',
                        controllerAs: 'vm',
                    },
                    'footer@': {
                        templateUrl: 'app/layouts/footer/footer.html',
                        controller: 'BrandedFooterController',
                        controllerAs: 'vm',
                    }
                },
                onEnter: [
                    'propertyVouchers',
                    'propertyCashVouchers',
                    'propertyGiftCards',
                    '$state',
                    '$stateParams',
                    '$timeout',
                    '$document',
                    'AlertService',
                    '$window',
                    '$interval',
                    function(
                        propertyVouchers,
                        propertyCashVouchers,
                        propertyGiftCards,
                        $state,
                        $stateParams,
                        $timeout,
                        $document,
                        AlertService,
                        $window,
                        $interval
                    ) {
                        console.info('entering property.list', $stateParams);
                        //if (propertyVouchers.voucherPoolsCount == 0 && propertyCashVouchers && propertyCashVouchers.cashVouchersWrapper && propertyCashVouchers.cashVouchersWrapper.length > 0 && propertyGiftCards.length == 0) {
                        //	$timeout(function(){
                        //		$state.transitionTo("property.list.cash-detail-fullpage", $stateParams, {
                        //    	  reload: false, inherit: true, notify: true
                        //	    });
                        //	});
                        //};
                        var lastActivity = Date.now();
                        var TIMEOUT = 1000*60*30;//30 minutes
                        var lastIdle = undefined;
                        var refreshInterval;
                        var repeat = function() {
                            var refresh = false;
                            if ($document.get(0).visibilityState === 'hidden') {
                                //if user is idle, do not refresh
                                if(!lastIdle){
                                    lastIdle = Date.now();
                                }
                                return;
                            }
                            //case 1: we enter from 30 min or more idle
                            if ($document.get(0).visibilityState !== 'hidden' && lastIdle && Date.now()-lastIdle>TIMEOUT) {
                               refresh = true;
                            }
                            //case 2: if timeout pass
                            if (Date.now()-lastActivity>TIMEOUT) {
                                refresh = true;
                            }

                            if(refresh && $state.includes('property.list')){
                                if(refreshInterval){
                                    $interval.cancel(refreshInterval);
                                }
                                $window.location.reload();
                            }
                            if ($document.get(0).visibilityState !== 'hidden') {
                                //reset last idle
                                lastIdle = undefined;
                            }

                        };

                        refreshInterval = $interval(repeat, 1000*10);//run every 10 seconds even if idle

                        var resetLastActivity = function (){
                            lastActivity = Date.now();
                        }

                        $document.mousemove(resetLastActivity);
                        $document.keypress(resetLastActivity);
                    },
                ],
                resolve: {
                    voucherConfig: [
                        'VobeConfig',
                        'currentProperty',
                        function(VobeConfig, currentProperty) {
                            //gather property ids
                            var propertyIds = [];
                            propertyIds.push(currentProperty.hotelId);
                            return VobeConfig.config.get({ propertyIds: propertyIds }).$promise;
                        },
                    ],
                    shoppingCart: [
                        '$state',
                        '$stateParams',
                        'shoppingCart',
                        'currentProperty',
                        'PromotionCodeAdd',
                        '$q',
                        function($state, $stateParams, shoppingCart, currentProperty, PromotionCodeAdd, $q) {
                            if ($stateParams.promotionCode) {
                                var shoppingCartWithPromoPromise = $q.defer();

                                var promotionCodeVM = {
                                    propertyId: currentProperty.hotelId,
                                    code: $stateParams.promotionCode,
                                };

                                PromotionCodeAdd.add(
                                    promotionCodeVM,
                                    function(response) {
                                        shoppingCartWithPromoPromise.resolve(response);
                                    },
                                    function(err) {
                                        //if promotion code that we want to apply is invalid
                                        if (err.data && err.data.shoppingCartItems) {
                                            //check if we have already items in the cart
                                            //because if we have we cannot remove previously applied discount
                                            shoppingCartWithPromoPromise.resolve(err.data);
                                        } else {
                                            //if we don't have any items in the basket
                                            //proceed with
                                            shoppingCartWithPromoPromise.resolve(shoppingCart);
                                        }
                                    }
                                );

                                return shoppingCartWithPromoPromise.promise;
                            } else {
                                return shoppingCart;
                            }
                        },
                    ],
                    propertyGiftCards: [
                        //'$stateParams',
                        //'GiftCards',
                        //'currentProperty',
                        //'currentPropertyGroup',
                        //'currentLanguage',
                        //'voucherConfig',
                        function(
                            $stateParams,
                            GiftCards,
                            currentProperty,
                            currentPropertyGroup,
                            currentLanguage,
                            voucherConfig
                        ) {
                            return [];
                            /*var propertyConfig = voucherConfig['' + currentProperty.hotelId];
                            if (
                                propertyConfig &&
                                propertyConfig.configMap &&
                                propertyConfig.configMap.hideCashVouchers &&
                                propertyConfig.configMap.hideCashVouchers === 'true'
                            ) {
                                return [];
                            }
                            if (
                                propertyConfig &&
                                propertyConfig.configMap &&
                                (!propertyConfig.configMap.postalOption ||
                                    (propertyConfig.configMap.postalOption &&
                                        propertyConfig.configMap.postalOption != 'true')) &&
                                (!propertyConfig.configMap.forceGiftCards ||
                                    (propertyConfig.configMap.forceGiftCards &&
                                        propertyConfig.configMap.forceGiftCards != 'true'))
                            ) {
                                return [];
                            }
                            if (typeof currentPropertyGroup !== 'undefined') {
                                //at the moment for groups don't return them, as this colodes with pagination
                                //it's small so we can enable
                                return [];
                            }
                            return GiftCards.get({ id: currentProperty.hotelId, languageKey: currentLanguage }).$promise;
                            */
                        },
                    ],
                    //don't remove shoppingCart from here as parameters otherwise it will be called before shoppingCart resolves
                    groupVouchers: [
                        '$stateParams',
                        'voucherConfig',
                        'propertyGiftCards',
                        'GroupVouchers',
                        'currentProperty',
                        'currentPropertyGroup',
                        'currentLanguage',
                        'shoppingCart',
                        'VouchersProcessService',
                        function(
                            $stateParams,
                            voucherConfig,
                            propertyGiftCards,
                            GroupVouchers,
                            currentProperty,
                            currentPropertyGroup,
                            currentLanguage,
                            shoppingCart,
                            VouchersProcessService
                        ) {
                            if (typeof currentPropertyGroup === 'undefined') {
                                return undefined;
                            }
                            return GroupVouchers.get(
                                {
                                    id: currentPropertyGroup.id,
                                    code: currentPropertyGroup.code,
                                    channel: $stateParams.channel,
                                    passkey: $stateParams.passkey,
                                    languageKey: (currentLanguage || 'en' ).split('_')[0].split('-')[0],
                                    promoCode: undefined,
                                    selectedCategoryCodes: $stateParams.cat || [],
                                    minPrice: $stateParams.minPrice || 0,
                                    maxPrice: $stateParams.maxPrice || 0,
                                    intervalBars: 50,
                                    sort: 'price',
                                    sortOrder: 'asc',
                                    page: 1,
                                    pageSize: 24,
                                },
                                function(groupVouchers) {
                                    VouchersProcessService.processGroupVouchers(
                                        groupVouchers,
                                        currentProperty,
                                        shoppingCart,
                                        voucherConfig,
                                        propertyGiftCards
                                    );
                                }
                            ).$promise.then(function(result) {
                                return result.toJSON();
                            });
                        },
                    ],
                    //don't remove shoppingCart from here as parameters otherwise it will be called before shoppingCart resolves
                    propertyVouchers: [
                        '$transition$',
                        'PropertyVouchers',
                        'currentProperty',
                        'currentPropertyGroup',
                        'currentLanguage',
                        'shoppingCart',
                        'VouchersProcessService',
                        function(
                            $transition$,
                            PropertyVouchers,
                            currentProperty,
                            currentPropertyGroup,
                            currentLanguage,
                            shoppingCart,
                            VouchersProcessService
                        ) {
                            if (typeof currentPropertyGroup === 'undefined') {
                                return PropertyVouchers.get(
                                    {
                                        id: currentProperty.hotelId,
                                        channel: $transition$.params().channel,
                                        passkey: $transition$.params().passkey,
                                        languageKey: (currentLanguage || 'en' ).split('_')[0].split('-')[0],
                                        contextType: $transition$.params().contextType,
                                    },
                                    function(propertyVouchers) {
                                        //1. if there are discounts, filter them out here, it's not nice to do it out in the controller or html via filters... trust me.
                                        if (!shoppingCart.shoppingCartDiscounts.empty) {
                                            var voucherPoolsGenerated = [];
                                            angular.forEach(propertyVouchers.wrapper.voucherPools, function(voucherPool, key) {
                                                var voucherFirstKey = Object.keys(voucherPool.vouchers)[0];
                                                var voucherId = voucherPool.vouchers[voucherFirstKey].id;
                                                var voucherHotelId = voucherPool.vouchers[voucherFirstKey].hotelId;
                                                var isVoucherApplicable = false;
                                                //loop all promos
                                                angular.forEach(shoppingCart.shoppingCartDiscounts.discounts,function(d,dIndex){
                                                    if(!d.hideInapplicable){
                                                        isVoucherApplicable = true;//if discount is not hiding voucher, then it's applicable
                                                    }
                                                    if (d.propertyId && d.propertyId!=null && d.propertyId>0 && d.propertyId!=voucherHotelId){
                                                        //voucher is applicable if belongs to different property
                                                        isVoucherApplicable = true;
                                                    }
                                                    else if (d.filteredItemIds.includes(voucherId)) {
                                                        //voucher is applicable if is assigned to this discount
                                                        isVoucherApplicable = true;
                                                    }
                                                });
                                                if(isVoucherApplicable){
                                                    voucherPoolsGenerated.push(voucherPool);
                                                }
                                            });
                                            propertyVouchers.wrapper.voucherPools = voucherPoolsGenerated;
                                            propertyVouchers.voucherPoolsCount = voucherPoolsGenerated.length;
                                            //TODO: voucherClass
                                        }

                                        if (propertyVouchers.wrapper.voucherPools) {
                                            var voucherPoolsAvailable = [];
                                            angular.forEach(propertyVouchers.wrapper.voucherPools, function(voucherPool, key) {
                                                var countAvailable = 0;
                                                angular.forEach(voucherPool.vouchers, function(v, vkey) {
                                                    //1. generate minValue/maxValue
                                                    VouchersProcessService.setPoolMinMaxValues(voucherPool, v);

                                                    //2. if some vouchers are sold out, don't show them
                                                    var sold = v.vouchersSold;
                                                    var available = v.voucherCount >= 999 ? 99999 : v.voucherCount;
                                                    if (sold < available) {
                                                        countAvailable++;
                                                    }
                                                });
                                                if (countAvailable > 0) {
                                                    voucherPoolsAvailable.push(voucherPool);
                                                }
                                            });
                                            propertyVouchers.wrapper.voucherPools = voucherPoolsAvailable;
                                            propertyVouchers.voucherPoolsCount = voucherPoolsAvailable.length;
                                            //TODO: voucherClass
                                        }
                                    }
                                ).$promise.then(function(result) {
                                    return result.toJSON();
                                });
                            } else {
                                return undefined;
                            }
                        },
                    ],
                    //don't remove shoppingCart from here as parameters otherwise it will be called before shoppingCart resolves
                    propertyCashVouchers: [
                        '$transition$',
                        'PropertyCashVouchers',
                        'currentProperty',
                        'currentPropertyGroup',
                        'propertyGiftCards',
                        'currentLanguage',
                        'voucherConfig',
                        '$filter',
                        'shoppingCart',
                        'VouchersProcessService',
                        function(
                            $transition$,
                            PropertyCashVouchers,
                            currentProperty,
                            currentPropertyGroup,
                            propertyGiftCards,
                            currentLanguage,
                            voucherConfig,
                            $filter,
                            shoppingCart,
                            VouchersProcessService
                        ) {
                            if (typeof currentPropertyGroup === 'undefined') {
                                var propertyConfig = voucherConfig['' + currentProperty.hotelId];
                                console.log('propertyCashVouchers resolve start', propertyConfig);
                                if (
                                    propertyConfig &&
                                    propertyConfig.configMap &&
                                    propertyConfig.configMap.hideCashVouchers &&
                                    propertyConfig.configMap.hideCashVouchers === 'true'
                                ) {
                                    //GENERATE MIN/MAX VALUES
                                    var propertyCashVouchers = {};
                                    propertyCashVouchers.minValue = 0;
                                    propertyCashVouchers.maxValue = 0;
                                    propertyCashVouchers.minValueAfterDiscount = 0;
                                    propertyCashVouchers.maxValueAfterDiscount = 0;
                                    propertyCashVouchers.currency = currentProperty.hotelCurrency;
                                    return propertyCashVouchers;
                                }
                                return PropertyCashVouchers.get(
                                    {
                                        id: currentProperty.hotelId,
                                        languageKey: (currentLanguage || 'en' ).split('_')[0].split('-')[0],
                                        passkey: $transition$.params().passkey,
                                        contextType: $transition$.params().contextType,
                                    },
                                    function(propertyCashVouchers) {
                                        console.log('propertyCashVouchers promise', propertyCashVouchers);
                                        var cashVouchersPresent =
                                            propertyCashVouchers &&
                                            propertyCashVouchers.wrapper &&
                                            propertyCashVouchers.wrapper.voucherPools &&
                                            propertyCashVouchers.wrapper.voucherPools.length > 0;
                                        if (cashVouchersPresent) {
                                            //double check in case vouchers are empty object
                                            var countVouchers = 0;
                                            angular.forEach(propertyCashVouchers.wrapper.voucherPools, function(
                                                voucherPool,
                                                pkey
                                            ) {
                                                angular.forEach(voucherPool.vouchers, function(voucher, vkey) {
                                                    countVouchers++;
                                                });
                                            });
                                            if (countVouchers === 0) {
                                                propertyCashVouchers.vouchersClassCounter = {};
                                                propertyCashVouchers.voucherPoolsCount = 0;
                                                propertyCashVouchers.wrapper.voucherPools = [];
                                                return propertyCashVouchers;
                                            }

                                            if (
                                                !shoppingCart.shoppingCartDiscounts.empty) {
                                                var voucherPoolsGenerated = [];
                                                angular.forEach(propertyCashVouchers.wrapper.voucherPools, function(
                                                    voucherPool,
                                                    pkey
                                                ) {
                                                    var voucherFirstKey = Object.keys(voucherPool.vouchers)[0];
                                                    var voucherId = voucherPool.vouchers[voucherFirstKey].id;
                                                    var voucherHotelId = voucherPool.vouchers[voucherFirstKey].hotelId;
                                                    var isVoucherApplicable = false;
                                                    angular.forEach(shoppingCart.shoppingCartDiscounts.discounts,function(d,dIndex){
                                                        if(!d.hideInapplicable){
                                                            isVoucherApplicable = true;//if discount is not hiding voucher, then it's applicable
                                                        }
                                                        if (d.propertyId && d.propertyId!=null && d.propertyId>0 && d.propertyId!=voucherHotelId){
                                                            //voucher is applicable if belongs to different property
                                                            isVoucherApplicable = true;
                                                        }
                                                        else if (d.filteredItemIds.includes(voucherId)) {
                                                            //voucher is applicable if is assigned to this discount
                                                            isVoucherApplicable = true;
                                                        }
                                                    });
                                                    if(isVoucherApplicable){
                                                        voucherPoolsGenerated.push(voucherPool);
                                                    }
                                                });
                                                propertyCashVouchers.wrapper.voucherPools = voucherPoolsGenerated;
                                            }
                                            var generatedCashPoolsWithGiftCard = [];
                                            var generatedCashPoolsFeatured = [];
                                            var featuredVouchers = []; //these are amounts
                                            var propertyConfig = voucherConfig[currentProperty.hotelId] || {};
                                            if (
                                                propertyConfig &&
                                                propertyConfig.configMap &&
                                                propertyConfig.configMap.featuredCashVouchers
                                            ) {
                                                var value = propertyConfig.configMap.featuredCashVouchers.replace(
                                                    / /g,
                                                    ''
                                                );
                                                featuredVouchers = value.split(',');
                                            }
                                            //iterate each pool
                                            angular.forEach(propertyCashVouchers.wrapper.voucherPools, function(
                                                voucherPool,
                                                pkey
                                            ) {
                                                //GENERATE MIN/MAX VALUES
                                                voucherPool.minValue = 0;
                                                voucherPool.maxValue = 0;
                                                voucherPool.minValueAfterDiscount = 0;
                                                voucherPool.maxValueAfterDiscount = 0;
                                                voucherPool.currency = currentProperty.hotelCurrency;
                                                //todo: names here?
                                                voucherPool.cashVoucherName = 'Monetary';
                                                angular.forEach(voucherPool.vouchers, function(voucher, vkey) {
                                                    //fix currency
                                                    voucherPool.currency = voucher.currency;
                                                    //fix name
                                                    //voucher.currency + ' ' + $filter('number')(voucher.totalRate.minvoucher, 2);
                                                    //fix sequence
                                                    voucherPool.sequence = Number.MIN_SAFE_INTEGER + 1; //voucher.totalRate.priceMin;

                                                    //generate minValue/maxValue
                                                    VouchersProcessService.setPoolMinMaxValues(voucherPool, voucher);

                                                    //generate pool with featured cash voucher
                                                    if (~featuredVouchers.indexOf('' + voucher.totalRate.priceMin)) {
                                                        var generatedFeaturedPool = {};
                                                        generatedFeaturedPool.minValue = voucher.totalRate.priceMin;
                                                        generatedFeaturedPool.maxValue =
                                                            voucher.totalRate.priceMax == 0
                                                                ? voucher.totalRate.priceMin
                                                                : voucher.totalRate.priceMax;
                                                        generatedFeaturedPool.minValueAfterDiscount =
                                                            voucher.totalRateAfterDiscount.priceMin;
                                                        generatedFeaturedPool.maxValueAfterDiscount =
                                                            voucher.totalRateAfterDiscount.priceMax == 0
                                                                ? voucher.totalRateAfterDiscount.priceMin
                                                                : voucher.totalRateAfterDiscount.priceMax;
                                                        generatedFeaturedPool.currency = voucher.currency;
                                                        generatedFeaturedPool.vouchers = [];
                                                        generatedFeaturedPool.vouchers.push(voucher);
                                                        generatedFeaturedPool.sequence = Number.MIN_SAFE_INTEGER; //voucher.totalRate.priceMin;
                                                        generatedFeaturedPool.cashVoucherName =
                                                            voucherPool.cashVoucherName;
                                                        generatedFeaturedPool.type = voucherPool.type;
                                                        generatedFeaturedPool.featured = true;
                                                        generatedCashPoolsFeatured.push(generatedFeaturedPool);
                                                    }
                                                });
                                                if (propertyGiftCards) {
                                                    angular.forEach(propertyGiftCards, function(giftCard, gkey) {
                                                        var generatedGiftCardPool = {};
                                                        generatedGiftCardPool.minValue = voucherPool.minValue;
                                                        generatedGiftCardPool.maxValue = voucherPool.maxValue;
                                                        generatedGiftCardPool.minValueAfterDiscount =
                                                            voucherPool.minValueAfterDiscount;
                                                        generatedGiftCardPool.maxValueAfterDiscount =
                                                            voucherPool.maxValueAfterDiscount;
                                                        generatedGiftCardPool.currency = voucherPool.currency;
                                                        generatedGiftCardPool.vouchers = voucherPool.vouchers;
                                                        generatedGiftCardPool.sequence = voucherPool.sequence + 2;
                                                        generatedGiftCardPool.cashVoucherName =
                                                            voucherPool.cashVoucherName;
                                                        generatedGiftCardPool.giftCard = giftCard;
                                                        generatedGiftCardPool.type = voucherPool.type;
                                                        generatedCashPoolsWithGiftCard.push(generatedGiftCardPool);
                                                    });
                                                }
                                            });
                                            //add generated gift cards
                                            if (generatedCashPoolsWithGiftCard.length > 0) {
                                                propertyCashVouchers.wrapper.voucherPools = generatedCashPoolsWithGiftCard.concat(
                                                    propertyCashVouchers.wrapper.voucherPools
                                                );
                                            }
                                            if (generatedCashPoolsFeatured.length > 0) {
                                                propertyCashVouchers.wrapper.voucherPools = generatedCashPoolsFeatured.concat(
                                                    propertyCashVouchers.wrapper.voucherPools
                                                );
                                            }
                                        }
                                        console.log('new', propertyCashVouchers);
                                    }
                                ).$promise.then(function(result) {
                                    console.log('cash result', result.toJSON());
                                    return result.toJSON();
                                });
                            } else {
                                return undefined;
                            }
                        },
                    ],
                    allVouchers: [
                        'currentProperty',
                        'currentPropertyGroup',
                        'propertyCashVouchers',
                        'propertyVouchers',
                        'groupVouchers',
                        function(
                            currentProperty,
                            currentPropertyGroup,
                            propertyCashVouchers,
                            propertyVouchers,
                            groupVouchers
                        ) {
                            if (typeof currentPropertyGroup === 'undefined') {
                                var packageVouchers = propertyVouchers || {};
                                var cashVouchersPresent =
                                    propertyCashVouchers &&
                                    propertyCashVouchers.wrapper &&
                                    propertyCashVouchers.wrapper.voucherPools &&
                                    propertyCashVouchers.wrapper.voucherPools.length > 0;
                                //for property only-> generate pool in package vouchers (from cash)
                                if (cashVouchersPresent) {
                                    packageVouchers.wrapper.empty = false;
                                    packageVouchers.voucherPoolsCount = packageVouchers.voucherPoolsCount || 0;
                                    packageVouchers.voucherPoolsCount++;
                                    //add cash voucher pool at the beginning of array
                                    angular.forEach(propertyCashVouchers.wrapper.voucherPools, function(
                                        cashVoucherPool,
                                        pkey
                                    ) {
                                        packageVouchers.wrapper.voucherPools.unshift(cashVoucherPool);
                                    });
                                }
                                return packageVouchers;
                            } else {
                                return groupVouchers;
                            }
                        },
                    ],
                    propertyCategories: [
                        '$stateParams',
                        'Categories',
                        'currentProperty',
                        'currentLanguage',
                        function($stateParams, Categories, currentProperty, currentLanguage) {
                            return Categories.get({
                                id: currentProperty.hotelId,
                                languageKey: (currentLanguage || 'en' ).split('_')[0].split('-')[0],
                            }).$promise;
                        },
                    ],
                    groupCategories: [
                        '$stateParams',
                        'GroupCategories',
                        'currentPropertyGroup',
                        'currentLanguage',
                        function($stateParams, GroupCategories, currentPropertyGroup, currentLanguage) {
                            if (typeof currentPropertyGroup === 'undefined') {
                                return undefined;
                            }
                            return GroupCategories.get({
                                code: currentPropertyGroup.code,
                                languageKey: (currentLanguage || 'en' ).split('_')[0].split('-')[0],
                            }).$promise;
                        },
                    ],
                    previousState: [
                        '$state',
                        function($state) {
                            var currentStateData = {
                                name: $state.current.name || 'property.list',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params),
                            };
                            return currentStateData;
                        },
                    ],
                    shopMoreState: function($state, $transition$, DetermineShopMoreState) {
                        'ngInject';
                        var shopMoreStateData = {
                            name: 'list',
                            params: {
                                cat: $transition$.params().cat,
                                contextType: $transition$.params().contextType,
                                propertyId: $transition$.params().propertyId,
                                propertyType: $transition$.params().propertyType,
                            },
                            url: $state.href('list', $transition$.params()),
                        };
                        DetermineShopMoreState.setShopMoreState(shopMoreStateData);
                        return shopMoreStateData;
                    },

                    mainTranslatePartialLoader: [
                        '$translate',
                        '$translatePartialLoader',
                        'currentProperty',
                        '$stateParams',
                        function($translate, $translatePartialLoader, currentProperty, $stateParams) {
                            var variant = '';
                            if ($stateParams.contextType) {
                                variant = '_' + $stateParams.contextType;
                            }
                            variant += '_' + currentProperty.hotelId;

                            $translatePartialLoader.addPart('vouchers' + variant);
                            $translatePartialLoader.addPart('giftcards' + variant);
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state('package-detail', {
                parent: 'list',
                url: '/item/{voucherId}',
                params: {
                    voucherPool: undefined,
                    doNotZoomIn: undefined
                },
                data: {
                    authorities: [],
                },
                reloadOnSearch: true, //WHEN POOL VOUCHERID CHANGES, RELOAD
                resolve: {
                    preShoppingCart: [
                        'ShoppingCart',
                        '$stateParams',
                        'currentProperty',
                        'currentLanguage',
                        function(ShoppingCart, $stateParams, currentProperty, currentLanguage) {
                            console.log('ModalService preShoppingCart', $stateParams);
                            //build shopping cart locale
                            var variant = '';
                            if ($stateParams.contextType) {
                                variant = '_' + $stateParams.contextType;
                            }
                            variant += (variant.length == 0 ? '_' : '') + currentProperty.hotelId;

                            console.log('variant', variant);
                            return ShoppingCart.api.get({
                                ref: $stateParams.ref,
                                src: $stateParams.src,
                                lang: currentLanguage + variant,
                                contextType: $stateParams.contextType,
                                propertyType: $stateParams.propertyType,
                                propertyCode: $stateParams.propertyId,
                            }).$promise;
                        },
                    ],
                    shoppingCart: [
                        '$q',
                        '$stateParams',
                        'preShoppingCart',
                        'ShoppingCart',
                        'ShoppingCartRemove',
                        'currentProperty',
                        'currentPropertyGroup',
                        'currentLanguage',
                        function(
                            $q,
                            $stateParams,
                            preShoppingCart,
                            ShoppingCart,
                            ShoppingCartRemove,
                            currentProperty,
                            currentPropertyGroup,
                            currentLanguage
                        ) {
                            var variant = '';
                            if ($stateParams.contextType) {
                                variant = '_' + $stateParams.contextType;
                            }
                            variant += (variant.length === 0 ? '_' : '') + currentProperty.hotelId;

                            function removeItem(itemUUID, successcallback, errorcallback) {
                                var cb = successcallback || angular.noop;
                                var ecb = errorcallback || angular.noop;
                                return ShoppingCartRemove.remove(
                                    { itemUUID: itemUUID },
                                    function(response) {
                                        return cb(response);
                                    },
                                    function(err) {
                                        return ecb(err);
                                    }.bind(this)
                                ).$promise;
                            }
                            //remove unwanted items in shopping cart
                            var removedPromises = [];
                            angular.forEach(preShoppingCart.shoppingCartItems.items, function(value, key) {
                                if (
                                    ($stateParams.propertyType == 'property' &&
                                        currentProperty.hotelId !== value.owningProperty.propertyId &&
                                        !isHotelIdInMasters(currentProperty, value.owningProperty.propertyId)) ||
                                    ($stateParams.propertyType == 'group' &&
                                        (!currentPropertyGroup ||
                                            !isHotelIdInGroup(currentPropertyGroup, value.owningProperty.propertyId)))
                                ) {
                                    var removePromise = removeItem(value.uuid);
                                    removedPromises.push(removePromise);
                                }
                            });
                            if (removedPromises.length > 0) {
                                var singleRemovePromise = $q.all(removedPromises);
                                var shoppingCartPromise = singleRemovePromise.then(
                                    function() {
                                        return ShoppingCart.api.get({
                                            ref: $stateParams.ref,
                                            src: $stateParams.src,
                                            lang: currentLanguage + variant,
                                            contextType: $stateParams.contextType,
                                            propertyType: $stateParams.propertyType,
                                            propertyCode: $stateParams.propertyId,
                                        }).$promise;
                                    },
                                    function(reason) {
                                        return $q.reject(reason);
                                    }
                                );
                                return shoppingCartPromise;
                            } else {
                                return preShoppingCart;
                            }
                        },
                    ],
                },
                onEnter:
                    function(
                        $transition$,
                        $state,
                        $uibModal,
                        $stateParams,
                        currentLanguage,
                        currentProperty,
                        currentPropertyGroup,
                        voucherConfig,
                        allVouchers,
                        propertyCategories,
                        groupCategories,
                        shoppingCart,
                        ModalService
                    ) {
                        'ngInject';
                        console.log('onEnter', propertyCategories)
                        var voucherId = $transition$.params().voucherId;
                        var voucherPool = $transition$.params().voucherPool;
                        var params = $transition$.params();
                        var modal = ModalService.open({
                            templateUrl: 'app/site/property/vouchers/voucher-package-detail-dialog.html',
                            controller: 'PropertyVoucherPackageDetailDialogController',
                            controllerAs: 'vm',
                            backdrop: true,
                            animation: false,
                            windowClass: $transition$.params().doNotZoomIn ? '' : 'zoomInModal modal-animated',
                            size: 'lg',
                            resolve: {
                                voucherId: function() {
                                    return voucherId;
                                },
                                currentLanguage: function(){return currentLanguage;},
                                propertyCategories: function() {
                                    return propertyCategories;
                                },
                                groupCategories: function() { return groupCategories; },
                                currentProperty: currentProperty,
                                currentPropertyGroup: currentPropertyGroup,
                                allVouchers: allVouchers,
                                voucherConfig: voucherConfig,
                                shoppingCart: shoppingCart,
                                voucherPool: [
                                    function() {
                                        if (voucherPool) {
                                            return voucherPool;
                                        }
                                        //get the first pool that contains this voucher id
                                        var firstPool;
                                        var fallbackPool;
                                        var selectedPool;
                                        if (allVouchers && allVouchers.wrapper && allVouchers.wrapper.voucherPools) {
                                            angular.forEach(allVouchers.wrapper.voucherPools, function(
                                                voucherPool,
                                                pkey
                                            ) {
                                                var poolHasVoucher = false;
                                                angular.forEach(voucherPool.vouchers, function(voucher, vkey) {
                                                    //voucherId might be string or undefined
                                                    if (!voucherId || voucher.id == voucherId) {
                                                        poolHasVoucher = true;
                                                    }
                                                });
                                                //this is the top most fallback
                                                if (!firstPool) {
                                                    firstPool = voucherPool;
                                                }
                                                //next fallback is a combined pool with this cashVoucherId
                                                if (!fallbackPool && poolHasVoucher) {
                                                    fallbackPool = voucherPool;
                                                }
                                                //then we can choose selected pool
                                                if (!voucherId || poolHasVoucher) {
                                                    selectedPool = voucherPool;
                                                }
                                            });
                                        }
                                        return selectedPool || fallbackPool || firstPool;
                                    },
                                ],
                                contextType: function() { return $stateParams.contextType; }
                            },
                        });
                        modal.result.then(
                            function (reason) {
                                console.log('closed modal success ', reason)
                                //this is called when modal is closed
                                if (reason === 'shoppingCart') {
                                    $state.transitionTo('shopping-cart', params, { inherit: true, notify: true, reload: true });
                                } else if (reason === 'addToCart') {
                                    $state.transitionTo('list', params, { inherit: true, notify: true, reload: false });
                                }else {
                                    $state.transitionTo('^', {}, {reload: false, notify: true});
                                }

                            },
                            function (reason) {
                                console.log('closed modal error ', reason)
                                //this is called when modal is dismissed, i.e: vm.clear() is called with reason 'cancel'
                                if (reason === "voucherIdChanged") {
                                    return;//do not go to parent state if we are already changing the state do to other reason like pool change
                                } else if (reason === "doNotGoToParent") {
                                    return;//do not go to parent state
                                } else if (reason === 'shoppingCart') {
                                    $state.go('shopping-cart', params, { inherit: true, notify: true, reload: true });
                                } else {
                                    $state.go('^', {}, {reload: false, notify: true});
                                }


                            }
                        );
                        $state.currentModal = modal;
                    }
                ,
            })
            .state('cash-detail', {
                parent: 'list',
                url: '/cashitem?giftCardId&cashVoucherId&cashPropertyId&focusedAmount',
                params: {
                    //params hidden from URL
                    voucherPool: undefined,
                },
                data: {
                    authorities: [],
                },
                reloadOnSearch: false, //THIS WILL PREVENT RELOADING WHEN CAT in URL CHANGES!
                resolve: {
                    preShoppingCart: [
                        'ShoppingCart',
                        '$stateParams',
                        'currentProperty',
                        'currentLanguage',
                        function(ShoppingCart, $stateParams, currentProperty, currentLanguage) {
                            //build shopping cart locale
                            var variant = '';
                            if ($stateParams.contextType) {
                                variant = '_' + $stateParams.contextType;
                            }
                            variant += (variant.length == 0 ? '_' : '') + currentProperty.hotelId;

                            return ShoppingCart.api.get({
                                ref: $stateParams.ref,
                                src: $stateParams.src,
                                lang: currentLanguage + variant,
                                contextType: $stateParams.contextType,
                                propertyType: $stateParams.propertyType,
                                propertyCode: $stateParams.propertyId,
                            }).$promise;
                        },
                    ],
                    shoppingCart: [
                        '$q',
                        '$stateParams',
                        'preShoppingCart',
                        'ShoppingCart',
                        'ShoppingCartRemove',
                        'currentProperty',
                        'currentPropertyGroup',
                        'currentLanguage',
                        function(
                            $q,
                            $stateParams,
                            preShoppingCart,
                            ShoppingCart,
                            ShoppingCartRemove,
                            currentProperty,
                            currentPropertyGroup,
                            currentLanguage
                        ) {
                            var variant = '';
                            if ($stateParams.contextType) {
                                variant = '_' + $stateParams.contextType;
                            }
                            variant += (variant.length === 0 ? '_' : '') + currentProperty.hotelId;

                            function removeItem(itemUUID, successcallback, errorcallback) {
                                var cb = successcallback || angular.noop;
                                var ecb = errorcallback || angular.noop;
                                return ShoppingCartRemove.remove(
                                    { itemUUID: itemUUID },
                                    function(response) {
                                        return cb(response);
                                    },
                                    function(err) {
                                        return ecb(err);
                                    }.bind(this)
                                ).$promise;
                            }
                            //remove unwanted items in shopping cart
                            var removedPromises = [];
                            angular.forEach(preShoppingCart.shoppingCartItems.items, function(value, key) {
                                if (
                                    ($stateParams.propertyType == 'property' &&
                                        currentProperty.hotelId !== value.owningProperty.propertyId &&
                                        !isHotelIdInMasters(currentProperty, value.owningProperty.propertyId)) ||
                                    ($stateParams.propertyType == 'group' &&
                                        (!currentPropertyGroup ||
                                            !isHotelIdInGroup(currentPropertyGroup, value.owningProperty.propertyId)))
                                ) {
                                    var removePromise = removeItem(value.uuid);
                                    removedPromises.push(removePromise);
                                }
                            });
                            if (removedPromises.length > 0) {
                                var singleRemovePromise = $q.all(removedPromises);
                                var shoppingCartPromise = singleRemovePromise.then(
                                    function() {
                                        return ShoppingCart.api.get({
                                            ref: $stateParams.ref,
                                            src: $stateParams.src,
                                            lang: currentLanguage + variant,
                                            contextType: $stateParams.contextType,
                                            propertyType: $stateParams.propertyType,
                                            propertyCode: $stateParams.propertyId,
                                        }).$promise;
                                    },
                                    function(reason) {
                                        return $q.reject(reason);
                                    }
                                );
                                return shoppingCartPromise;
                            } else {
                                return preShoppingCart;
                            }
                        },
                    ],
                },
                onEnter:
                    function(
                        $transition$,
                        $stateParams,
                        $state,
                        $uibModal,
                        currentLanguage,
                        currentProperty,
                        currentPropertyGroup,
                        voucherConfig,
                        allVouchers,
                        propertyGiftCards,
                        shoppingCart,
                        ModalService
                    ) {
                        'ngInject';
                        console.log('opening modal...')
                        var modal = ModalService.open({
                            templateUrl: 'app/site/property/vouchers/voucher-cash-detail-dialog.html',
                            controller: 'PropertyVoucherCashDetailDialogController',
                            controllerAs: 'vm',
                            backdrop: true,
                            animation: false,
                            windowClass: 'zoomInModal modal-animated',
                            size: 'lg',
                            resolve: {
                                currentLanguage: function(){return currentLanguage;},
                                currentProperty: currentProperty,
                                currentPropertyGroup: currentPropertyGroup,
                                allVouchers: allVouchers,
                                voucherConfig: voucherConfig,
                                //propertyGiftCards : propertyGiftCards,
                                shoppingCart: shoppingCart,
                                voucherPool: [
                                    function() {
                                        if ($stateParams.voucherPool) {
                                            return $stateParams.voucherPool;
                                        }
                                        //we are looking for particular pool that holds this cash voucher
                                        var cashVoucherId;
                                        if ($stateParams.cashVoucherId && $stateParams.cashVoucherId > 0) {
                                            cashVoucherId = $stateParams.cashVoucherId;
                                        }
                                        //are we looking for a pool with specific amount only (featured voucher?)
                                        var featured = false;
                                        if ($stateParams.focusedAmount && $stateParams.focusedAmount > 0) {
                                            featured = true;
                                        }
                                        //are we looking for a pool with specific gift card?
                                        var giftCardId;
                                        if ($stateParams.giftCardId && $stateParams.giftCardId > 0) {
                                            giftCardId = $stateParams.giftCardId;
                                        }
                                        //get the first pool that contains this voucher id
                                        var firstPool;
                                        var fallbackPool;
                                        var selectedPool;
                                        if (allVouchers && allVouchers.wrapper && allVouchers.wrapper.voucherPools) {
                                            angular.forEach(allVouchers.wrapper.voucherPools, function(
                                                voucherPool,
                                                pkey
                                            ) {
                                                if (!voucherPool.type.includes('cash')) {
                                                    return;
                                                }
                                                var poolIsFeatured = voucherPool.featured;
                                                var poolHasGiftCard =
                                                    voucherPool.giftCard && voucherPool.giftCard.id == giftCardId;
                                                var poolHasCashVoucher = false;
                                                angular.forEach(voucherPool.vouchers, function(voucher, vkey) {
                                                    if (!cashVoucherId || voucher.id == cashVoucherId) {
                                                        poolHasCashVoucher = true;
                                                    }
                                                });
                                                //this is the top most fallback
                                                if (!firstPool) {
                                                    firstPool = voucherPool;
                                                }
                                                //next fallback is a combined pool with this cashVoucherId
                                                if (!fallbackPool && poolHasCashVoucher) {
                                                    fallbackPool = voucherPool;
                                                }
                                                //then we can choose selected pool
                                                if (
                                                    (!featured || (featured && poolIsFeatured)) &&
                                                    (!giftCardId || poolHasGiftCard) &&
                                                    (!cashVoucherId || poolHasCashVoucher)
                                                ) {
                                                    selectedPool = voucherPool;
                                                }
                                            });
                                        }
                                        return cashVoucherId ? selectedPool : (selectedPool || fallbackPool || firstPool);
                                    }],
                            },
                        });

                        $state.currentModal = modal;

                        modal.result.then(
                            function() {
                                //this is called when modal is closed
                                $state.go('^', {}, { reload: false, notify: false });
                            },
                            function() {
                                //this is called when modal is dismissed, i.e: vm.clear() is called with reason 'cancel'
                                //every time item is added to the card this modal is dismissed
                                $state.go('^', {}, { reload: false, notify: false });
                            }
                        );
                    },

            })
            .state('cash-detail-fullpage', {
                parent: 'property.list',
                url: '/cashitem/full?giftCardId&cashVoucherId&focusedAmount',
                data: {
                    authorities: [],
                    hideClear: true,
                },
                reloadOnSearch: false, //THIS WILL PREVENT RELOADING WHEN CAT in URL CHANGES!
                views: {
                    'content@': {
                        templateUrl: 'app/site/property/vouchers/voucher-cash-detail-dialog.html',
                        controller: 'PropertyVoucherCashDetailDialogController',
                        controllerAs: 'vm',
                    },
                    'header@': {
                        templateUrl: 'app/layouts/header/header.html',
                        controller: 'BrandedHeaderController',
                        controllerAs: 'vm',
                    },
                    'footer@': {
                        templateUrl: 'app/layouts/footer/footer.html',
                        controller: 'BrandedFooterController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    voucherConfig: [
                        'VobeConfig',
                        'currentProperty',
                        function(VobeConfig, currentProperty) {
                            //gather property ids
                            var propertyIds = [];
                            propertyIds.push(currentProperty.hotelId);
                            return VobeConfig.config.get({ propertyIds: propertyIds }).$promise;
                        },
                    ],
                    $uibModalInstance: [function() {}],
                    voucherPool: [
                        function() {
                            if ($stateParams.voucherPool) {
                                return $stateParams.voucherPool;
                            }
                            //we are looking for particular pool that holds this cash voucher
                            var cashVoucherId;
                            if ($stateParams.cashVoucherId && $stateParams.cashVoucherId > 0) {
                                cashVoucherId = $stateParams.cashVoucherId;
                            }
                            //are we looking for a pool with specific amount only (featured voucher?)
                            var featured = false;
                            if ($stateParams.focusedAmount && $stateParams.focusedAmount > 0) {
                                featured = true;
                            }
                            //are we looking for a pool with specific gift card?
                            var giftCardId;
                            if ($stateParams.giftCardId && $stateParams.giftCardId > 0) {
                                giftCardId = $stateParams.giftCardId;
                            }
                            //get the first pool that contains this voucher id
                            var firstPool;
                            var fallbackPool;
                            var selectedPool;
                            if (propertyCashVouchers) {
                                angular.forEach(propertyCashVouchers.wrapper.voucherPools, function(voucherPool, pkey) {
                                    var poolIsFeatured = voucherPool.featured;
                                    var poolHasGiftCard = voucherPool.giftCard && voucherPool.giftCard.id == giftCardId;
                                    var poolHasCashVoucher = false;
                                    angular.forEach(voucherPool.vouchers, function(voucher, vkey) {
                                        if (!cashVoucherId || voucher.id == cashVoucherId) {
                                            poolHasCashVoucher = true;
                                        }
                                    });
                                    //this is the top most fallback
                                    if (!firstPool) {
                                        firstPool = voucherPool;
                                    }
                                    //next fallback is a combined pool with this cashVoucherId
                                    if (!fallbackPool && poolHasCashVoucher) {
                                        fallbackPool = voucherPool;
                                    }
                                    //then we can choose selected pool
                                    if (
                                        (!featured || (featured && poolIsFeatured)) &&
                                        (!giftCardId || poolHasGiftCard) &&
                                        (!cashVoucherId || poolHasCashVoucher)
                                    ) {
                                        selectedPool = voucherPool;
                                    }
                                });
                            }
                            return selectedPool || fallbackPool || firstPool;
                        },
                    ],
                    shoppingCart: [
                        'shoppingCart',
                        function(shoppingCart) {
                            return shoppingCart;
                        },
                    ],
                },
            })
            .state('cookie-policy', {
                parent: 'property',
                url : "/cookie-policy",
                data: {
                    pageTitle: 'legal.cookies.policy.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/components/cookies-consent/cookies-policy-modal.html',
                        controller: 'CookiesPolicyController',
                        controllerAs: 'vm',
                    },
                    'header@': {
                        templateUrl: 'app/layouts/header/header.html',
                        controller: 'BrandedHeaderController',
                        controllerAs: 'vm',
                    },
                    'footer@': {
                        templateUrl: 'app/layouts/footer/footer.html',
                        controller: 'BrandedFooterController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        //console.log("in app state addPart global");
                        $translatePartialLoader.addPart('legal');
                        return $translate.refresh();
                    }]
                }
            })
            .state('cookie-preferences', {
            parent: 'property',
            url : "/cookie-preferences",
            data: {
                pageTitle: 'legal.cookies.policy.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/cookies-consent/cookies-policy-modal.html',
                    controller: 'CookiesPolicyController',
                    controllerAs: 'vm',
                },
                'header@': {
                    templateUrl: 'app/layouts/header/header.html',
                    controller: 'BrandedHeaderController',
                    controllerAs: 'vm',
                },
                'footer@': {
                    templateUrl: 'app/layouts/footer/footer.html',
                    controller: 'BrandedFooterController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    //console.log("in app state addPart global");
                    $translatePartialLoader.addPart('legal');
                    return $translate.refresh();
                }]
            },

        });
    }
})();
