(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PublicSiteTranslationDetailController', PublicSiteTranslationDetailController);

    PublicSiteTranslationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PublicSiteTranslation', 'PublicSitePartRelationship', 'PublicSitePartKey', 'PublicSiteLanguage'];

    function PublicSiteTranslationDetailController($scope, $rootScope, $stateParams, previousState, entity, PublicSiteTranslation, PublicSitePartRelationship, PublicSitePartKey, PublicSiteLanguage) {
        var vm = this;

        vm.publicSiteTranslation = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vobeApp:publicSiteTranslationUpdate', function(event, result) {
            vm.publicSiteTranslation = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
