(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('GiftCards', GiftCards);

    GiftCards.$inject = ['$resource'];

    function GiftCards ($resource) {
        var service = $resource('api/property/:id/giftcards', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
})();
