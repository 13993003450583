(function() {
    'use strict';

    angular
        .module('vobeApp')
        .directive('vouchersNavbarFixed', vouchersNavbarFixed);

    vouchersNavbarFixed.$inject = ['$window', '$rootScope'];

    function vouchersNavbarFixed($window, $rootScope) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {

        	var back2top_offset = 220;
		    var back2top_duration = 500;

		    $window.jQuery(window).scroll(function() {

		        if($window.innerWidth>767){
		            //we only do it for small viewports
		            return;
                }

                var top_of_screen = $(window).scrollTop();
                var top_of_nav = $window.jQuery(element).offset().top;
		        if (top_of_screen>top_of_nav && !$window.jQuery(element).hasClass('vouchers-navbar-is-fixed')) {
                    //if this element has negative offset (we scroll past visible view on mobiles)
                    $window.jQuery(element).addClass('vouchers-navbar-is-fixed');
                    $window.jQuery(element).find('.vouchers-navbar.allow-fixed').addClass('vouchers-navbar-fixed');
		        } else if(top_of_screen<=top_of_nav) {
                    $window.jQuery(element).removeClass('vouchers-navbar-is-fixed');
                    $window.jQuery(element).find('.vouchers-navbar.allow-fixed').removeClass('vouchers-navbar-fixed');
		        }
		    });



        }
    }
})();
