(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('BrandedHeaderController', BrandedHeaderController);

    BrandedHeaderController.$inject = ['$state', '$stateParams', '$rootScope','brandingTemplateUUID', 'currentLanguage', 'currentProperty', '$location'];

    function BrandedHeaderController ($state, $stateParams, $rootScope, brandingTemplateUUID, currentLanguage, currentProperty, $location) {
        var vm = this;
        vm.property = currentProperty;

        $rootScope.$on('propertySelected', function(event, args) {
        	vm.property = args.property;
        });

        vm.brandingUUID = brandingTemplateUUID.uuid;
        vm.currentLanguage = (currentLanguage || 'en' ).split('_')[0].split('-')[0];
        vm.channel = $stateParams.channel || '';
        vm.url = $location.url();

        if(!vm.brandingUUID){
        	vm.headerUrl = 'app/site/property/hero-property.html';
        }
        else{
        	vm.headerUrl = '/assets/property/header?uuid='+vm.brandingUUID+'&includeStyle=true&userLang='+vm.currentLanguage+'&channel='+vm.channel+'&propertyId='+vm.property.hotelId;
        }
    }



})();
