(function() {
    'use strict';

    angular.module('currencyExchangeModule').factory('CurrencyExchangeUtils', currencyExchangeUtils);

    function currencyExchangeUtils(
        $locale
    ) {
        'ngInject';
        return {
            /*XE_CURRENCIES_FORMAT object type is = { format: string, fractional?: boolean, };*/
            format: function (amount /*must be number*/,currency /*one of XE_CURRENCIES_FORMAT*/, rounding) {
                rounding = rounding || Math.round;
                var multiplier = Math.pow(10, currency.fractional === false ? 0 : 2);//TODO should it be 2 ???
                var price = Math.round(amount * multiplier) / multiplier;
                var readablePrice = price.toLocaleString().replace(/(\.[\d]{1})$/, '$10');
                return currency.format.replace('#', readablePrice);

            }
        }



    }
})();
