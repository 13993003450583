(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('PromotionCodeRemove', PromotionCodeRemove);

    PromotionCodeRemove.$inject = ['$resource'];

    function PromotionCodeRemove ($resource) {
        var service = $resource('api/shoppingcart/promotionCode/:id', {}, {
            'remove': {
                method: 'DELETE',
                transformResponse: function (data, headers, status) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                	data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }

    angular
    .module('vobeApp')
    .factory('PromotionCodeAdd', PromotionCodeAdd);

    PromotionCodeAdd.$inject = ['$resource'];

    function PromotionCodeAdd ($resource) {
        var service = $resource('api/shoppingcart/promotionCode', {}, {
            'add': {
                method: 'POST',
                transformResponse: function (data, headers, status) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                	data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }

})();
