(function() {
    'use strict';

    angular
        .module('vobeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('theguestbookfatal', {
            parent: 'app',
            url: '/theguestbook/fatal',
            data: {
                authorities: [],
                pageTitle : 'global.error'
            },
            views: {
                'content@': {
                    templateUrl: 'app/site/fatal/theguestbook-fatal.html',
                    controller: 'TheGuestbookFatalController',
                    controllerAs: 'vm'
                },
                'header@': {
                    //templateUrl: 'app/layouts/header/header.html',
                    //controller: 'BrandedHeaderController',
                    //controllerAs: 'vm'
                },
                'footer@': {
                    //templateUrl: 'app/layouts/footer/footer.html',
                    //controller: 'BrandedFooterController',
                    //controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                   // console.log("here fatal global");
                }]
            }
        })
        .state('site', {
            abstract: true,
            parent: 'app',
            /*views: {
            	//shopping cart view is in the navbar.html (in navbar ui-view of 'app' state)
                'shoppingCart@app': {
                    templateUrl: 'app/site/shoppingcart/shopping-cart-dropdown.html',
                    controller: 'ShoppingCartDropdownController',
                    controllerAs: 'vm'
                }
            },*/
            resolve : {
                currentLanguage: ['$stateParams','$translate','JhiLanguageService','$q','translationStorageProvider',function($stateParams, $translate, JhiLanguageService, $q, translationStorageProvider){
                	//the below will work before stateHandler:
                	/*if($stateParams.lang){
                		//console.log("$stateParams.lang: "+$stateParams.lang.toLowerCase());
                		translationStorageProvider.put($translate.storageKey(),$stateParams.lang.toLowerCase());

                		return $translate.use($stateParams.lang.toLowerCase()).then(function(v){
                			//console.log("$translate.use: "+v);
                			return JhiLanguageService.getCurrent().then(function(v){
                    			//console.log("JhiLanguageService.getCurrent(): "+v);
                    			return v;
                    		});
                		});
                	}
                	else {
                		return JhiLanguageService.getCurrent().then(function(v){
                			//console.log("JhiLanguageService.getCurrent(): "+v);
                			return v;
                		});
                	}*/
                    return JhiLanguageService.getCurrent().then(function(v){
                        //console.log("JhiLanguageService.getCurrent(): "+v);
                        return v;
                    });
                }]
            }
        });
    }
})();
