(function() {
    'use strict';

    angular.module('vobeApp').factory('PropertyCashVouchers', PropertyCashVouchers);

    PropertyCashVouchers.$inject = ['$resource'];

    function PropertyCashVouchers($resource, $http) {
        var service = $resource(
            'api/property/:id/cashvouchers',
            {},
            {
                query: { method: 'GET', isArray: false },
                get: {
                    method: 'GET',
                    transformResponse: function(data) {
                        if(!data || (typeof data === 'string' && data.length==0)){
                            return data;
                        }
                        data = angular.fromJson(data);
                        return data;
                    }
                }
            }
        );

        return service;
    }
})();
