(function() {
	'use strict';

	angular.module('vobeApp').controller('PaymentInfoModalInstanceCtrl', PaymentInfoModalInstanceCtrl);

    PaymentInfoModalInstanceCtrl.$inject = [ '$uibModalInstance' ];

	function PaymentInfoModalInstanceCtrl($uibModalInstance) {

		var $ctrl = this;


		$ctrl.ok = function() {
			$uibModalInstance.close();
		};

		$ctrl.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();
