(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('PaymentRedirectSingleton', PaymentRedirectSingleton);

    PaymentRedirectSingleton.$inject = [];

    function PaymentRedirectSingleton () {
    	
    	var redirectInProgress = 0;
    	    	    	
        var service = {
        		isBusy : function(){
        			return redirectInProgress > 0;
        		},
        		start : function(){
        			redirectInProgress += 1;
        		},
        		stop : function(){
        			if(redirectInProgress > 0){
        				redirectInProgress -= 1;
        			};
        		},
        		startIfNotBusy : function(){
        			if(redirectInProgress > 0){
        				return false;
        			}
        			redirectInProgress += 1;
        			return true;
        		}
        		
        }

        return service;
    }
})();
