(function() {
    'use strict';

    angular.module('vobeApp').factory('ShoppingCart', ShoppingCart);

    function ShoppingCart($resource) {
        'ngInject';
        var service = $resource(
            'api/shoppingcart',
            {},
            {
                query: { method: 'GET', isArray: false },
                get: {
                    method: 'GET',
                    transformResponse: function(data) {
                        if(!data || (typeof data === 'string' && data.length==0)){
                            return data;
                        }
                        data = angular.fromJson(data);
                        return data;
                    }
                }
            }
        );


        return {
            api: service
        };
    }
})();
