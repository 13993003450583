(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('PropertyVouchersClassController', PropertyVouchersClassController);

    PropertyVouchersClassController.$inject = ['$element', '$scope', '$rootScope', '$stateParams', 'User'];

	function PropertyVouchersClassController($element, $scope, $rootScope, $stateParams,  User) {
	    var vm = this;

	    vm.voucherClass = $scope.vc.classId;

	    $scope.$on('voucherClassActive', function(event, args) {
    		var _$element = $($element);
    		var categoryShortCodes = args.voucherClassActive ? args.voucherClassActive.split(',') : [];
	    	if(categoryShortCodes.includes(vm.voucherClass)){
	    		var _$parent = _$element.parent();
	    		//_$parent.prepend(_$element);
	    		//_$element.addClass("col-xs-12 col-sm-8 col-md-6",1000);
	    		//_$element.removeClass("col-xs-6 col-sm-4 col-md-3",1000);
	    		_$element.find('.voucher-class-card ').removeClass("");
	    		_$element.find('.voucher-class-card ').addClass("w3-card-4");

	    	}
	    	else {
	    		//_$element.removeClass("col-xs-12 col-sm-8 col-md-6",1000);
	    		//_$element.addClass("col-xs-6 col-sm-4 col-md-3",1000);
	    		_$element.find('.voucher-class-card ').addClass("");
	    		_$element.find('.voucher-class-card ').removeClass("w3-card-4");
	    	}
	    });





	}
})();
