(function() {
	'use strict';

	angular.module('vobeApp').directive('cardExpiryInFuture2',
			cardExpiryInFuture2);

	function cardExpiryInFuture2() {
		var directive = {
			restrict : 'A',
			require : '?ngModel',
			link : linkFunc
		};

		return directive;

		function linkFunc(scope, element, attrs, ngModel) {
			if (!ngModel) {
				return;
			}

			var yearValue = undefined;
			var monthNgModel = ngModel;

			var validator = function () {

				if(!yearValue || !yearValue.value || yearValue.value.length==0){
					return false;
				}

				var d = new Date();
				var dMilis = d.getTime();
				try {
					var intYear = parseInt(yearValue.value);
					var intMonth = parseInt(monthNgModel.$viewValue.value);
					d.setFullYear(intYear, intMonth - 1);
					var dMilis2 = d.getTime();
					return (dMilis <= dMilis2);
				} catch (err) {
					//console.log(err);
				}
				// it is invalid
				return false;
			};

			ngModel.$validators.cardExpiryInFuture2 = validator;

			scope.$watch("$ctrl.card.cardExpiryYear", function (newValue, oldValue) {
				yearValue = newValue;
				monthNgModel.$validate();

			});





		}
	}
	;

})();
