(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PublicSiteTranslationPropertyPartController', PublicSiteTranslationPropertyPartController);

    PublicSiteTranslationPropertyPartController.$inject = ['$scope', '$state', '$stateParams', 'PublicSiteTranslation','PublicSiteTranslationPart','PublicSiteTranslationPropertyPart','PublicSitePartKeys', 'PublicSiteTranslationSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'siteLanguages','translations','sitePart','hotelTranslations','currentProperty'];

    function PublicSiteTranslationPropertyPartController ($scope, $state, $stateParams, PublicSiteTranslation, PublicSiteTranslationPart, PublicSiteTranslationPropertyPart, PublicSitePartKeys, PublicSiteTranslationSearch, ParseLinks, AlertService, pagingParams, paginationConstants, siteLanguages, translations, sitePart, hotelTranslations, currentProperty) {
        var vm = this;        
        
        vm.sitePart = sitePart;
        vm.partName = sitePart.publicSitePartName;
        vm.siteLanguages = siteLanguages;
        vm.currentProperty = currentProperty;
        vm.translations = translations;
        vm.hotelTranslations = hotelTranslations;
        vm.getMessage = getMessage;
        vm.getHotelMessage = getHotelMessage;
        vm.calculateIndex = calculateIndex;
        vm.saveForm = saveForm;
        vm.formTranslations = [];
        
        
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();

        function loadAll () {
        	PublicSitePartKeys.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                partName: sitePart.publicSitePartName
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.publicSitePartKeys = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                siteName: sitePart.publicSiteName,
                partName: sitePart.publicSitePartName,
                publicSitePartRelationshipId : sitePart.id,//needed for state resolve
                propertyId : currentProperty.hotelId//needed for state resolve
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
        
        
        function calculateIndex(outerIndex,innerIndex){
        	return (outerIndex*vm.siteLanguages.length)+innerIndex;
        }
        
        
        function getMessage(publicSitePartKeyId,publicSiteLanguageId){
        	var translation;
        	angular.forEach(vm.translations,function(value,k){
        		
        		if(value.publicSiteLanguageId==publicSiteLanguageId 
        				&& value.publicSitePartKeyId==publicSitePartKeyId){
        			translation = value;
        		}
        		
        	});
        	
        	return translation;
        }
        
        function getHotelMessage(publicSitePartKeyId,publicSiteLanguageId){
        	var translation;
        	angular.forEach(vm.hotelTranslations,function(value,k){
        		
        		if(value.publicSiteLanguageId==publicSiteLanguageId 
        				&& value.publicSitePartKeyId==publicSitePartKeyId){
        			translation = value;
        		}
        		
        	});
        	
        	return translation;
        }        
        
        
        function saveForm(event){
        	event.preventDefault();
        	//console.log(vm.formTranslations);
        	saveAll();
        }
        
        
        function saveAll () {
        	PublicSiteTranslationPropertyPart.update({
        		translations : vm.formTranslations
        		}, onSuccess, onError);
            function onSuccess(data, headers) {
            	$state.transitionTo($state.current, $stateParams, {reload:true, inherit:true})
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }        
    }
})();
