(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('HeaderFooterService', HeaderFooterService);

    HeaderFooterService.$inject = ['$resource'];

    function HeaderFooterService ($resource) {
        var service = $resource('api/headerfooter', {}, {
            'get': {
                method: 'GET',
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
})();
