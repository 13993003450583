(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('ShoppingCartDropdownModalController', ShoppingCartDropdownModalController);

	function ShoppingCartDropdownModalController($scope, $rootScope, $state, $stateParams, ShoppingCartRemove, $translate, CurrencyExchange, $document) {
	    'ngInject';
	    var vm = this;

        vm.shoppingCart = vm.shoppingCart || $scope.$resolve.shoppingCart || {};
        vm.property = vm.property || $scope.$resolve.property;
        vm.shoppingCartConfigs = vm.shoppingCartConfigs || $scope.$resolve.shoppingCartConfigs;
        vm.propertyGroup = vm.propertyGroup || $scope.$resolve.propertyGroup;
        vm.clear = vm.clear || $scope.$resolve.clear || angular.noop;
        vm.removeItemFromCartAndRefresh = removeItemFromCartAndRefresh;
        vm.getAnyItemByPropertyId = getAnyItemByPropertyId;
        vm.goToShoppingCart = goToShoppingCart;
        vm.maxHeighStyle = maxHeighStyle();
        vm.appendToEl = angular.element($document).find('.vobe-cards-container');

        vm.shoppingCartOpen = false;
        vm.perishableAlerts = [];


        //currency
        vm.currentCurrency = {
            code : undefined
        }
        var currentCurrency = CurrencyExchange.currencies.current;//current currency might be NULL if this is group engine
        if(currentCurrency && currentCurrency!=null){
            vm.currentCurrency.code = currentCurrency.code;
        }

        $rootScope.$on('CurrencyExchangeProvider.changeCurrency', function(event, args) {
            vm.currentCurrency.code = args.code;
        });

        vm.$onInit = function() {



        };



        function maxHeighStyle(){
            var viewportHeight = $(window).height();
            return {'max-height':(viewportHeight - 50)+'px'};
        }

        function getAnyItemByPropertyId(propertyId){
            var anyItem;
            angular.forEach(vm.shoppingCart.shoppingCartItems.items,function(item,index){
                if(item.owningProperty.propertyId==propertyId){
                    anyItem = item;
                }
            });
            return anyItem;
        }


        function removeItemFromCartAndRefresh(event, itemUUID){
            event.preventDefault();
            removeItem(itemUUID, backToCurrent);

        }

        function backToCurrent(shoppingCart){
            $rootScope.$broadcast('itemRemovedFromCart', {shoppingCart : shoppingCart, openShoppingCartDropdown : true});
            if(!$state.includes('property.list')){
                $state.go($state.current,{}, { reload: true }).then(function() {
                    vm.shoppingCartOpen = true;
                });
            }

        }

        function goToShoppingCart(event){
            event.preventDefault();
            //we need to reload
            $state.go('shopping-cart',{}, { reload: true });

        }


        function removeItem(itemUUID, callback){

            var cb = callback || angular.noop;

            return ShoppingCartRemove.remove({itemUUID : itemUUID},
                function (shoppingCart) {
                    return cb(shoppingCart);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;

        }

        $scope.$on('itemAddedToCart',function(event, args){
            vm.shoppingCart = args.shoppingCart;
        });

        $scope.$on('itemRemovedFromCart',function(event, args){
            vm.shoppingCart = args.shoppingCart;
        });


	}
})();
