(function() {
    'use strict';

    angular.module('vobeApp').component('cardCashCombinedText', {
        templateUrl: 'app/components/vouchers/card/text/card-cash-combined-text.html',
        controller: 'CardCashCombinedController',
        controllerAs: '$ctrl',
        bindings: {
            viewLayoutTileDesign : '<',//default or text
            tiles: '<', //3 or 4 or 6
            descriptionLines : '<',
            settings: '<',//like vm.settings
            appliedPromotionCode: '<',
            channel: '<',
            //voucherObject: '<',
            voucherPoolObject: '<',
            currentProperty: '<',
            currentPropertyGroup: '<',
            shoppingCart: '<',
            openCashModal: '<',//function
            websiteType: '<',//group or property
            onlyFeatured: '<',
        }
    });
})();
