(function() {
    'use strict';

    angular
        .module('vobeApp')
        .directive('verticalButtons', verticalButtons);
    
    verticalButtons.$inject = ['$window', '$rootScope'];

    function verticalButtons($window, $rootScope) {
        var directive = {
            restrict: 'C',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
        	
        	var back2top_offset = 220;
		    var back2top_duration = 500;
		    
		    $window.jQuery(window).scroll(function() {
		        if ($window.jQuery(this).scrollTop() > back2top_offset) {
		        	$window.jQuery(element).fadeIn(back2top_duration);
		        } else {
		        	$window.jQuery(element).fadeOut(back2top_duration);
		        }
		    });
		    
		    $window.jQuery(element).click(function(event) {
		        //event.preventDefault();
		        $window.jQuery('html, body').animate({scrollTop: 0}, back2top_duration);
		        //return false;
		    });
		    
		    
		    $window.jQuery(element).find('.open-shopping-cart-dropdown').click(function(event) {
		        //event.preventDefault();
		        $rootScope.$broadcast('openShoppingCartDropdown'); 
		        //return false;
		    }); 
		    
		    
        	        	
        }
    }
})();