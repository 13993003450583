/* eslint-disable no-console */
(function() {
    'use strict';

    angular.module('vobeApp').controller('EllipsisTextController', EllipsisTextController);

    EllipsisTextController.$inject = [
        '$scope',
        '$element',
        '$window'
    ];

    function EllipsisTextController(
        $scope,
        $element,
        $window
    ) {

        //console.log("ellipsisText",$element.height());

        //reporting height to parent:
        this.innerHeight = function(innerHeight, innerFontSize){

            if(innerHeight==0){
                //console.error("Inner height is 0");
                return;
            }

            handleEllipsis(innerHeight, innerFontSize, $element, $scope.options || {})
        };


        function handleEllipsis(innerHeight, innerFontSize, $element, options) {

            var requiredLines = options.descriptionLines || 3;
            var lineHeight = options.lineHeight || 1.36;//average and will work with most of the fonts, must be same as in CSS
            var ellipsisHeight = 50;//as in CSS

            var descriptionConteinerHeight = Math.floor(innerFontSize * lineHeight) * requiredLines;

            //set height of the container based on required lines
            //console.log("heightOfContainer",descriptionConteinerHeight);
            //console.log("heightOfText",innerHeight);

            var textLargerThanContainerAndRequireEllipsis = (descriptionConteinerHeight + ellipsisHeight) < innerHeight;

            var ellipsisEl = $element.find('.ellipsis');
            if (ellipsisEl.length == 0) {
                return;
            }

            if (textLargerThanContainerAndRequireEllipsis) {
                ellipsisEl.show();
                $element.find('.ellipsis-more').show();
                $element.height(descriptionConteinerHeight + ellipsisHeight);
            }
            else {
                ellipsisEl.hide();
                $element.find('.ellipsis-more').hide();
            }

            if(options.revealHeightIfNoHeight && $element.height()==0){
                $element.height('auto');
            }

            if(options.revealHeight){
                $element.height('auto');
            }

            if (options.scrollToId) {
                var childOffset = $window.jQuery('#' + options.scrollToId).offset();
                if(childOffset){
                    var parentOffset = $window.jQuery('#' + options.scrollToId).parent().offset();
                    if(parentOffset){
                        var childTopOffset = childOffset.top;
                        var parentTopOffset = parentOffset.top;
                        if(childTopOffset>parentTopOffset+60) {
                            $element.animate({scrollTop: childTopOffset}, 1000);
                        }
                    }
                }
            }
        }


    }
})();
