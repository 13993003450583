(function() {
    'use strict';

    angular.module('vobeApp').component('voucherNavbar', {
        templateUrl: 'app/components/vouchers/navbar/vouchers-navbar.html',
        controller: 'PropertyVouchersNavbarController',
        controllerAs: 'vm',
        bindings: {
            shoppingCart: '<',
            shoppingCartConfigs: '<',
            currentProperty: '<',
            currentPropertyGroup: '<',
            onCurrencyUpdated: '<',
            //needed for sorting & columns layout:
            isGroup: '<',
            showCategoriesBoxType: '<',
            showSortControls: '<',
            settings: '<',
            showColumnControls: '<',
            viewLayoutTiles : '<',
            //needed for promotion box:
            showPromoControls: '<',
            processingPromotionCode: '<',
            appliedPromotionCode: '<',
            promotionCode: '<',
            applyPromotionCode: '<',
            removePromotionCode: '<',
            voucherClassActive: '<'
        }
    });
})();
