(function() {
    'use strict';

    var propertyAlertError = {
        template: '<div id="snackbar" ng-show="vm.alerts.length>0">' +
                        '<div uib-alert ng-repeat="alert in vm.alerts" ng-class="\'alert alert-\'+alert.type" close="alert.close(vm.alerts)"><span>{{ alert.msg }}</span></div>' +
                  '</div>',
        controller: propertyAlertErrorController,
        controllerAs: 'vm',
        bindToController: true
    };

    angular
        .module('vobeApp')
        .component('propertyAlertError', propertyAlertError);

    function propertyAlertErrorController ($scope, $element, AlertService, $rootScope, $translate) {
        'ngInject';
        var vm = this;

        vm.alerts = [];
        vm.addErrorAlert = addErrorAlert;
        vm.addSuccessAlert = addSuccessAlert;
        vm.addWarningAlert = addWarningAlert;

        $element.data('propertyAlertErrorScope', $scope);

        function addErrorAlert (message, key, data) {
            key = key && key !== null ? key : message;
            vm.alerts.push(
                AlertService.add(
                    {
                        type: 'danger',
                        msg: key,
                        params: data,
                        timeout: 5000,
                        toast: AlertService.isToast(),
                        scoped: true
                    },
                    vm.alerts
                )
            );
        }

        function addWarningAlert (message, key, data) {
            key = key && key !== null ? key : message;
            vm.alerts.push(
                AlertService.add(
                    {
                        type: 'warning',
                        msg: key,
                        params: data,
                        timeout: 5000,
                        toast: AlertService.isToast(),
                        scoped: true
                    },
                    vm.alerts
                )
            );
        }

        function addSuccessAlert (message, key, data) {
            key = key && key !== null ? key : message;
            vm.alerts.push(
                AlertService.add(
                    {
                        type: 'success',
                        msg: key,
                        params: data,
                        timeout: 5000,
                        toast: AlertService.isToast(),
                        scoped: true
                    },
                    vm.alerts
                )
            );
        }


        var cleanPropertyErrorListener = $rootScope.$on('vobeApp.propertyError', function (event, httpResponse) {
            var i;
            event.stopPropagation();
            if(httpResponse){
                var errorHeader = httpResponse.headers('X-vobeApp-error') || httpResponse.headers('x-vobeapp-error');
                switch (errorHeader) {
                    case 'error.maxreached':
                        addErrorAlert('Max reached','global.messages.error.maxreached');
                        break;
                    default:
                        if (httpResponse.data && httpResponse.data.message) {
                            addErrorAlert(httpResponse.data.message);
                        } else {
                            addErrorAlert(angular.toJson(httpResponse));
                        }
                }
            }

        });


        $scope.$on('$destroy', function () {
            if(angular.isDefined(cleanPropertyErrorListener) && cleanPropertyErrorListener !== null){
                cleanPropertyErrorListener();
                vm.alerts = [];
            }
        });
    }
})();
