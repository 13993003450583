(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PublicSitePartDetailController', PublicSitePartDetailController);

    PublicSitePartDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PublicSitePart', 'PublicSitePartKey'];

    function PublicSitePartDetailController($scope, $rootScope, $stateParams, previousState, entity, PublicSitePart, PublicSitePartKey) {
        var vm = this;

        vm.publicSitePart = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vobeApp:publicSitePartUpdate', function(event, result) {
            vm.publicSitePart = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
