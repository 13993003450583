(function() {
    'use strict';

    angular.module('vobeApp').controller('LanguageDropdownButtonController', LanguageDropdownButtonController);

    LanguageDropdownButtonController.$inject = [
        '$scope',
        '$rootScope',
        '$state',
        '$stateParams',
        '$translate',
        'JhiLanguageService',
        'tmhDynamicLocale',
        '$q'
    ];

    function LanguageDropdownButtonController(
        $scope,
        $rootScope,
        $state,
        $stateParams,
        $translate,
        JhiLanguageService,
        tmhDynamicLocale,
        $q
    ) {
        var vm = this;

        vm.$onInit = function(){
            vm.currentProperty = vm.currentProperty || {};
            vm.shoppingCartConfigs = vm.shoppingCartConfigs || {};

            vm.changeLanguage = changeLanguage;
            vm.languages = ['en'];

            vm.currentLanguageCode = $translate.use();

            var propertyId = vm.currentProperty.hotelId;
            if (vm.shoppingCartConfigs[propertyId] && vm.shoppingCartConfigs[propertyId].configMap && vm.shoppingCartConfigs[propertyId].configMap.languages) {
                var languagesString = vm.shoppingCartConfigs[propertyId].configMap.languages;
                vm.languages = languagesString.replace(/\s/g, '').split(',');
            }
            //JhiLanguageService.getAll().then(function (languages) {
            //    vm.languages = languages;
            //});

            if(vm.onAvailableLanguages){
                vm.onAvailableLanguages(vm.languages);
            }
        };



        function changeLanguage(languageKey) {
            vm.currentLanguageCode =languageKey;
            var languageChangePromises = [];
            languageChangePromises.push( $translate.use(languageKey));
            try {
                languageChangePromises.push(tmhDynamicLocale.set(languageKey));
            } catch (e) {
                // TODO: handle exception
            }
            var promise = $q.all(languageChangePromises);
            promise.then(function() {
                var params = angular.copy($stateParams);
                params.language = vm.currentLanguageCode;
                //params.lang= null;
                $state.transitionTo($state.current, params, { reload: true, inherit: false, notify: true });
                //$state.reload();
            });
        }
    }
})();
