(function() {
    'use strict';

    angular
        .module('vobeApp')
        .factory('GoogleTagManager', GoogleTagManager);

    GoogleTagManager.$inject = ['$resource'];

    function GoogleTagManager ($resource) {
        var service = $resource('api/property/:id/gtm', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if (data === '') {
                        return [];
                    } else {
                        data = angular.fromJson(data);
                        return data;
                    }
                }
            }
        });

        return service;
    }


    angular
        .module('vobeApp')
        .factory('GoogleTagManagerService', GoogleTagManagerService);

    GoogleTagManagerService.$inject = ['$q','GoogleTagManager', '$window', '$rootScope'];

    function GoogleTagManagerService($q,GoogleTagManager, $window, $rootScope) {

        var dataPromise;

        var initialized = false;

        var initializedTag = {};

        var service = {
            getCodes : getCodes,
            isInitialized : isInitialized,
            setInitialized : setInitialized,
            isInitializedTag : isInitializedTag,
            setInitializedTag : setInitializedTag
        };


        return service;

        function getCodes(options) {
            if (angular.isUndefined(dataPromise)) {
                dataPromise = GoogleTagManager.get(options).$promise;
            }
            return dataPromise;
        }

        function isInitialized() {
            return initialized;
        }
        function setInitialized(boolean) {
            initialized = boolean;
        }
        function isInitializedTag(tagName) {
            return initializedTag[tagName];
        }
        function setInitializedTag(tagName, boolean) {
            initializedTag[tagName] = boolean;
        }
    }

})();
