(function() {
    'use strict';


    angular
        .module('vobeApp')
        .controller('VoucherTilesSlickController', VoucherTilesSlickController);


    function VoucherTilesSlickController($log) {
        'ngInject';
        var vm = this;

        vm.$onInit = function() {
            vm.slickTiles = true;
            vm.tilesSettings = angular.copy(vm.tilesSettings);  //make copy, don't redraw slick on parent object updates
            vm.tilesSettings.voucherPoolOrderBy = 'sequence_featured';
            vm.viewLayout = angular.copy(vm.viewLayout);        //make copy, don't redraw on parent object updates
            vm.viewLayout.activeBreakpoint.cards = 1;
        };

    }
})();
