(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('CountriesService', CountriesService);

    CountriesService.$inject = ['$resource'];

    function CountriesService ($resource) {
        var service = $resource('/i18n/en/countries.json', {}, {
            'query': {method: 'GET', isArray: false, ignoreLoadingBar: true},
            'get': {
                method: 'GET',
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;

    }
})();
