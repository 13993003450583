(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('ExplainDeliveryPersonService', ExplainDeliveryPersonService);

    ExplainDeliveryPersonService.$inject = ['$resource'];

    function ExplainDeliveryPersonService($resource) {
        var service = $resource('api/property/popup/:id/explain/deliveryPerson', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;

    }
})();
