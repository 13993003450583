(function() {
    'use strict';

    angular
        .module('vobeApp')
        .factory('errorHandlerInterceptor', errorHandlerInterceptor);

    errorHandlerInterceptor.$inject = ['$q', '$rootScope'];

    function errorHandlerInterceptor ($q, $rootScope) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError (response) {
            if (!response || response instanceof Error || !(response.status === 401 && (response.data === '' || (response.data.path && response.data.path.indexOf('/api/account') === 0 )))) {
                $rootScope.$emit('vobeApp.httpError', response);
                //if(response.data.path && response.data.path.indexOf('/api/shoppingcart/add') && response.data.path.endsWith('add')==-1){
                //    $rootScope.$broadcast('itemAddedToCartError', {});
                //}
            }
            return $q.reject(response);
        }
    }
})();
