(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('ShoppingCartSummaryController', ShoppingCartSummaryController);

	function ShoppingCartSummaryController($scope, $rootScope, $state, $stateParams, ShoppingCartRemove, PromotionCodeService, CurrencyExchange, ShoppingCartService, $filter, $translate, $window) {
	    'ngInject';
	    var vm = this;

	    this.$onInit = function () {

            this.shoppingCartConfigs = this.shoppingCartConfigs || {};
            this.property = this.property || {};
            console.log('ShoppingCartSummaryController', this.shoppingCartConfigs);

            this.perBasketShipping = this.shoppingCartConfigs[''+this.property.hotelId].configMap.perBasketShipping === 'true';
            this.shoppingCart = this.shoppingCart || {};

            vm.removeItemFromCartAndRefresh = removeItemFromCartAndRefresh;
            vm.removeItemFromCartAndRefreshById = removeItemFromCartAndRefreshById;
            vm.removeItemFromCartAndRefreshByKey = removeItemFromCartAndRefreshByKey;
            vm.getAnyItemByPropertyId = getAnyItemByPropertyId;
            vm.getAnyItemByPropertyIdAndItemId = getAnyItemByPropertyIdAndItemId;
            vm.getAnyItemByPropertyIdAndItemUuid = getAnyItemByPropertyIdAndItemUuid;
            vm.countPropertyIds = countPropertyIds;

            vm.promotionCode = '';
            vm.addPromotionCode = addPromotionCode;
            vm.removePromotionCode = removePromotionCode;

            var propertyConfig = vm.shoppingCartConfigs[''+vm.property.hotelId];
            vm.showPromoControls = true;
            if(propertyConfig && propertyConfig.configMap && propertyConfig.configMap.hasActivePromotionCodes && propertyConfig.configMap.hasActivePromotionCodes=='false'){
                try {
                    vm.showPromoControls = false;
                } catch (e) {}
            }
            if(vm.forceHidePromoBox){
                vm.showPromoControls = false;
            }

            //currency
            vm.currentCurrency = {
                code : undefined
            }
            var currentCurrency = CurrencyExchange.currencies.current;//current currency might be NULL if this is group engine
            if(currentCurrency && currentCurrency!=null){
                vm.currentCurrency.code = currentCurrency.code;
            }

            $rootScope.$on('CurrencyExchangeProvider.changeCurrency', function(event, args) {
                vm.currentCurrency.code = args.code;
            });

            vm.applyOnceShipping = false;

            renderTotals();
            vm.updateShipping = updateShipping;


            var addPromotionCodeSuccessCallback = function(result){clearPromoCode()};
            var addPromotionCodeErrorCallback = function(reason){clearPromoCode()};
            var removePromotionCodeSuccessCallback = function(result){clearPromoCode()};
            var removePromotionCodeErrorCallback = function(reason){clearPromoCode()};
            vm.keyLength = keyLength;

            try {
                trackViewCart();
            } catch (e) {
                console.error(e);
            }

        }

        function trackViewCart() {
            console.log('=> trackViewCart ...')

            //tag manager
            var dataLayer = $window.dataLayer = $window.dataLayer || [];

            var items = [];
            var totalValue = 0;

            angular.forEach(vm.shoppingCart.shoppingCartItems.items, function(item) {
                var itemData = {
                    item_id: item.itemId.toString(),
                    item_name: item.itemName,
                    affiliation: item.owningProperty.propertyName,
                    item_category: 'Gift Voucher',
                    discount: item.itemTotalPriceBeforeDiscount - item.itemTotalPrice,
                    item_brand: item.owningProperty.propertyName,
                    price: item.itemTotalPrice,
                    quantity: 1,
                    item_list_id: item.itemCategoryId ? item.itemCategoryId : 1,
                    item_list_name: item.itemCategoryName ? item.itemCategoryName : 'All',
                };

                items.push(itemData);
                totalValue += item.itemTotalPrice;
            });

            dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            dataLayer.push({
                event: "view_cart",
                ecommerce: {
                    currency: vm.shoppingCart.shoppingCartItems.items[0].itemCurrency, // Assuming all items have the same currency
                    value: totalValue,
                    items: items
                }
            });

            console.log('=> trackViewCart ...', dataLayer[dataLayer.length - 1])
        }

        function renderTotals(){
            vm.totals = generateTotals(vm.shoppingCart.shoppingCartItems.items);
            vm.totalsPerPropertyInCurrentCurrency = generateTotalsPerPropertyInCurrentCurrency(vm.totals);
            vm.onUpdateTotals({value: {totals: vm.totals, totalsPerPropertyInCurrentCurrency: vm.totalsPerPropertyInCurrentCurrency}});
        }

        function generateTotals(shoppingCartItems) {
            var totalsMap = {};
            //group items per key
            angular.forEach(shoppingCartItems, function (item, index) {
                var key = item.itemId+'_'+$filter('number')(item.itemTotalPrice,2)+'_'+$filter('number')(item.itemTotalPriceBeforeDiscount,2)+'_'+item.recipient.deliveryMethodType+'_'+item.recipient.deliveryPersonType;
                //key = item.uuid;
                var totalValue = totalsMap[key] || {};
                totalValue.key = key;
                totalValue.currency = totalValue.currency || item.itemCurrency;
                totalValue.propertyId = totalValue.propertyId || item.owningProperty.propertyId;
                totalValue.deliveryPersonType = item.recipient.deliveryPersonType;
                totalValue.deliveryMethodType = item.recipient.deliveryMethodType;
                totalValue.itemName = totalValue.itemName || item.itemName;
                totalValue.itemId = totalValue.itemId || item.itemId;
                totalValue.items = totalValue.items || [];
                totalValue.items.push(item);
                totalValue.itemTotalPrice = totalValue.itemTotalPrice || item.itemTotalPrice;
                totalValue.itemTotalPriceBeforeDiscount = totalValue.itemTotalPriceBeforeDiscount || item.itemTotalPriceBeforeDiscount;

                //shipping

                //add total shipping per property
                totalValue.fulfillmentOptions = totalValue.fulfillmentOptions || (!vm.applyOnceShipping ? filterFulfillments(item, index) : []);

                //use fulfillment as priority over simple postage (id:0)
                let fulfillmentIds = [];
                if(totalValue.fulfillmentOptions){
                    angular.forEach(totalValue.fulfillmentOptions,function(fo){
                        fulfillmentIds.push(fo.id);
                    });
                }

                totalValue.fulfillmentOptionPrices = totalValue.fulfillmentOptionPrices || item.fulfillmentOptionPrices;

                let fulfillmentId = typeof item.recipient.fulfillmentId === 'undefined' || item.recipient.fulfillmentId==0 ? undefined : item.recipient.fulfillmentId;
                //if selected fulfillment is not on the list, reset it
                if(!fulfillmentIds.includes(fulfillmentId)){
                    fulfillmentId = undefined;
                }
                //if nothing selected, select the first one
                if(!fulfillmentId) {
                    fulfillmentId = fulfillmentIds[0];
                }
                totalValue.fulfillmentId = fulfillmentId;

                if (totalValue.fulfillmentOptions.length > 0) {
                    angular.forEach(totalValue.fulfillmentOptions, function (fo) {
                        if (fo.id === totalValue.fulfillmentId && fo.applyOnce) {
                            vm.applyOnceShipping = true;
                        }
                    });

                }
                totalsMap[key] = totalValue;
            });

            updateShippingPerItemKey(totalsMap);

            return totalsMap;
        }


        //this is to mitigate currency conversion rounding on the display
        function generateTotalsPerPropertyInCurrentCurrency() {
            var totalsMap = {};
            //group items per property
            angular.forEach(vm.shoppingCart.shoppingCartItems.items,function(item,index){
                var key = item.owningProperty.propertyId;
                var totalValue = totalsMap[key] || {};
                totalValue.key = key;
                totalValue.currency = totalValue.currency || vm.currentCurrency.code || item.itemCurrency;
                totalValue.itemCurrency = item.itemCurrency;
                totalValue.propertyName = totalValue.propertyName || item.owningProperty.propertyName;
                totalValue.propertyId = totalValue.propertyId || item.owningProperty.propertyId;
                totalValue.deliveryPersonType = item.recipient.deliveryPersonType;
                totalValue.deliveryMethodType = item.recipient.deliveryMethodType;

                totalValue.itemTotalPriceOriginal = totalValue.itemTotalPriceOriginal || 0;
                totalValue.itemTotalPriceOriginal += item.itemTotalPrice;

                totalValue.itemTotalPrice = totalValue.itemTotalPrice || 0;
                totalValue.itemTotalPrice += $filter('exchangenumber')(item.itemTotalPrice,item.itemCurrency,totalValue.currency);

                totalValue.itemTotalPriceBeforeDiscount = totalValue.itemTotalPriceBeforeDiscount || 0;
                totalValue.itemTotalPriceBeforeDiscount += $filter('exchangenumber')(item.itemTotalPriceBeforeDiscount,item.itemCurrency,totalValue.currency);

                //next add total shipping
                totalValue.totalShipping =  0;



                //next add total service fee
                totalValue.serviceFeeOriginal = 0;
                totalValue.serviceFee = 0;


                totalsMap[key] = totalValue;
            });

            //add shipping per property
            updateShippingPerProperty(totalsMap);


            //add service fee per property
            angular.forEach(totalsMap,function(totalValue,propertyId) {
                var serviceFeeOriginal = 0;
                var serviceFeeCurrency = totalValue.currency;//?
                if(typeof vm.propertyGroup!== 'undefined'){
                    //search for service fee among hotels
                    angular.forEach(vm.propertyGroup.hotels,function(hotel,hi){
                        if(hotel.hotelId==propertyId){
                            serviceFeeCurrency = hotel.hotelCurrency;
                            if(hotel.serviceFee && hotel.serviceFee.amount>0) {
                                var totalPrice = totalValue.itemTotalPriceOriginal;

                                if (hotel.serviceFee.percentage) {
                                    serviceFeeOriginal = totalValue.itemTotalPriceOriginal * hotel.serviceFee.amount / 100.0;
                                }
                                else {
                                    serviceFeeOriginal = hotel.serviceFee.amount
                                }
                            }
                        }

                    });
                } else {
                    serviceFeeCurrency = vm.property.hotelCurrency;
                    if(vm.property.serviceFee && vm.property.serviceFee.amount>0) {
                        var totalPrice = totalValue.itemTotalPriceOriginal;

                        if (vm.property.serviceFee.percentage) {
                            serviceFeeOriginal = totalValue.itemTotalPriceOriginal * vm.property.serviceFee.amount / 100.0;
                        }
                        else {
                            serviceFeeOriginal = vm.property.serviceFee.amount
                        }
                    }
                }
                totalValue.serviceFeeOriginal = serviceFeeOriginal;
                totalValue.serviceFee = $filter('exchangenumber')(serviceFeeOriginal,serviceFeeCurrency,totalValue.currency);
            });

            return totalsMap;
        }


        function updateShipping(){
            updateShippingPerItemKey(vm.totals);
            updateShippingPerProperty(vm.totalsPerPropertyInCurrentCurrency);
            vm.onUpdateTotals({value: {totals: vm.totals, totalsPerPropertyInCurrentCurrency: vm.totalsPerPropertyInCurrentCurrency}});
        }

        function updateShippingPerItemKey(totals){
            angular.forEach(totals, function (totalValue, totalItemKey) {
                totalValue.shipping = 0;

                if (totalValue.fulfillmentOptions && typeof totalValue.fulfillmentId !== 'undefined') {
                    totalValue.shipping = totalValue.fulfillmentOptionPrices[totalValue.fulfillmentId];
                }

                if (vm.customer && totalValue.fulfillmentOptions.length > 0) {
                    vm.customer.fulfillmentOptions = vm.customer.fulfillmentOptions || {};
                    vm.customer.fulfillmentOptions[totalItemKey] = totalValue.fulfillmentId;
                    angular.forEach(totalValue.fulfillmentOptions, function (fo) {
                        if (fo.id === totalValue.fulfillmentId) {
                            vm.customer.fulfillmentIdApplyOnce = fo.applyOnce;
                        }
                    });

                }
            });
        }

        function updateShippingPerProperty(totals) {
            console.log('updateShippingPerProperty', totals)

            angular.forEach(totals, function (totalValue, propertyId) {
                let totalShippingOriginal = 0;
                //convert propertyId to number
                propertyId = parseInt(propertyId);

                angular.forEach(vm.totals, function (totalItem, totalItemKey) {
                    if (totalItem.propertyId === propertyId && totalItem.fulfillmentOptions.length > 0) {
                        var applyOnce = false;
                        angular.forEach(totalItem.fulfillmentOptions, function (fo) {
                            if (fo.id === totalItem.fulfillmentId) {
                                applyOnce = fo.applyOnce;
                            }
                        });
                        totalShippingOriginal += totalItem.shipping * (applyOnce ? 1 : totalItem.items.length);
                        console.log('totalShippingOriginal', totalShippingOriginal)
                    }
                });
                totalValue.totalShippingOriginal = totalShippingOriginal;
                totalValue.totalShipping = $filter('exchangenumber')(totalShippingOriginal, vm.property.hotelCurrency, totalValue.currency);
            });
        }

	    function getAnyItemByPropertyId(propertyId){
	    	var anyItem;
	    	angular.forEach(vm.shoppingCart.shoppingCartItems.items,function(item,index){
                if (item.owningProperty.propertyId == propertyId) {
	    			anyItem = item;
	    		}
	    	});
	    	return anyItem;
	    }

        function getAnyItemByPropertyIdAndItemId(propertyId, itemId) {
            console.log('getAnyItemByPropertyIdAndItemId', propertyId, itemId)
            var anyItem;
            angular.forEach(vm.shoppingCart.shoppingCartItems.items, function (item, index) {
                console.log(item, item.owningProperty.propertyId)
                if (item.owningProperty.propertyId == propertyId && item.itemId == itemId) {
                    anyItem = item;
                }
            });
            return anyItem;
        }

        function getAnyItemByPropertyIdAndItemUuid(propertyId, itemUuid) {
            console.log('getAnyItemByPropertyIdAndItemId', propertyId, itemUuid)
            var anyItem;
            angular.forEach(vm.shoppingCart.shoppingCartItems.items, function (item, index) {
                if (item.uuid == itemUuid) {
                    anyItem = item;
                }
            });
            return anyItem;
        }

        function countPropertyIds(){
            var properties=[];
            angular.forEach(vm.shoppingCart.shoppingCartItems.items,function(item,index){
                if(!properties.includes(item.owningProperty.propertyId)){
                    properties.push(item.owningProperty.propertyId)
                }
            });
            return properties.length;
        }

        function removeItemFromCartAndRefreshById(event, itemId){

	        //find last item in totals and remove
            var removedTotalItemKey;//make sure we remove only one key
            angular.forEach(vm.totals,function(totalItem,totalItemKey){
                if(!removedTotalItemKey && totalItem.items){
                    angular.forEach(totalItem.items,function(item,i){
                        if(item.itemId == itemId){
                            removedTotalItemKey = totalItemKey;
                            var removedCartItem = totalItem.items.pop();
                            removeItemFromCartAndRefresh(event, removedCartItem.uuid);
                        }
                    });
                }

            });
        }

        function removeItemFromCartAndRefreshByKey(event, itemKey) {
            angular.forEach(vm.totals, function (totalItem, totalItemKey) {
                if (totalItemKey == itemKey) {
                    var itemsSize = totalItem.items.length;
                    angular.forEach(totalItem.items, function (item, i) {
                        if (i == itemsSize - 1) {
                            //refresh on the last item
                            removeItemFromCartAndRefresh(event, item.uuid);
                        } else {
                            console.log('>> removeItem', item.uuid);
                            removeItem(item.uuid);
                        }
                    });
                }
            });
        }

	    function removeItemFromCartAndRefresh(event, itemUUID){
	    	event.preventDefault();
	    	removeItem(itemUUID, refreshSite);
	    }

	    $scope.$watch('itemVM.promotionCode', function (value, oldValue) {
	        if(value=='' && value!=oldValue){
	        	clearPromoCode();
	        }
	    });

	    function clearPromoCode(){
	    	if(vm.promotionCodeForm){
	    		PromotionCodeService.clearPromoCode(vm.promotionCodeForm['promotionCode']);
	        }
	    	vm.promotionCode = '';
            vm.totals = generateTotals(vm.shoppingCart.shoppingCartItems.items);
            vm.totalsPerPropertyInCurrentCurrency = generateTotalsPerPropertyInCurrentCurrency();
	    }

		function addPromotionCode(event){
			event.preventDefault();
			PromotionCodeService.addPromocode(vm.shoppingCart, vm.promotionCodeForm['promotionCode'], vm.property.hotelId, vm.promotionCode)
                .then(addPromotionCodeSuccessCallback,addPromotionCodeErrorCallback);
			//clearPromoCode();
	    }

		function removePromotionCode(event, id){
			event.preventDefault();
			PromotionCodeService.removePromocode(vm.shoppingCart,vm.promotionCodeForm['promotionCode'], id)
                .then(removePromotionCodeSuccessCallback,removePromotionCodeErrorCallback);
	    }

	    function refreshSite(){
            console.log('>> refreshSite', $state.current, $stateParams);
		    $state.transitionTo($state.current, $stateParams, {
		    	  reload: 'site', inherit: true, notify: true
		    });
	    }

	    function removeItem(itemUUID, callback){
            console.log('>> removeItem', itemUUID, callback);

            var cb = callback || angular.noop;

            var item = getAnyItemByPropertyIdAndItemUuid(vm.property.hotelId, itemUUID);
            console.log('>> pre -- trackRemoveItemFromCart', item);

            return ShoppingCartRemove.remove({itemUUID : itemUUID},
                function (response) {
                    trackRemoveItemFromCart(item);
                    return cb(response);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;
	    }

        function trackRemoveItemFromCart(item){
            console.log('trackRemoveItemFromCart', item);
            //ga4
            var dataLayer = window.dataLayer || [];
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: "remove_from_cart",
                ecommerce: {
                    currency: item.itemCurrency,
                    value: item.itemTotalPrice,
                    items: [
                        {
                            item_id: item.itemId,
                            item_name: item.itemName,
                            discount: item.itemTotalPriceBeforeDiscount - item.itemTotalPrice,
                            index: 0,
                            item_brand: item.owningProperty.propertyName,
                            price: item.itemTotalPrice,
                            quantity: 1
                        }
                    ]
                }
            });
        }

        function keyLength(obj) {
            return Object.keys(obj).length;
        }


        function filterFulfillments(item, itemIndex) {
            let fulfillments = [];
            if(item.fulfillmentOptions){
                angular.forEach(item.fulfillmentOptions,function(fo){
                    if(item.recipient.deliveryMethodType=="post" && fo.postal){
                        fulfillments.push(fo);
                    } else if(item.recipient.deliveryMethodType=="collect" && fo.collection){
                        fulfillments.push(fo);
                    } else if (item.recipient.deliveryMethodType == "email" && fo.electronic) {
                        fulfillments.push(fo);
                    }
                });
            }

            if (fulfillments.length > 1) {
                //remove generic one
                for (let i = 0; i < fulfillments.length; i++) {
                    if (fulfillments[i].id == 0) {
                        fulfillments.splice(i, 1);
                        i--;
                    }
                }
            }

            return fulfillments.sort(compareFulfillments);
        }

        function compareFulfillments(a, b) {
            if (a.price<b.price) {
                return -1;
            }
            if (a.price>b.price) {
                return 1;
            }
            // a must be equal to b
            return 0;
        }


        vm.getFulfillmentNameInLang = function(fo) {
            let lang = ($translate.use() || 'en' ).split('_')[0].split('-')[0];
            let name = fo.name;
            if (lang!='en' && fo.fulfillmentOptionTranslations){
                angular.forEach(fo.fulfillmentOptionTranslations,function(t){
                    if(t.languageCode==lang){
                        name = t.name;
                    }
                });
            }
            return name;
        }

        vm.getFulfillmentDescriptionInLang = function(fo) {
            let lang = ($translate.use() || 'en' ).split('_')[0].split('-')[0];
            let description = fo.description;
            if (lang!='en' && fo.fulfillmentOptionTranslations){
                angular.forEach(fo.fulfillmentOptionTranslations,function(t){
                    if(t.languageCode==lang){
                        description = t.description;
                    }
                });
            }
            return description;
        }

	}
})();
