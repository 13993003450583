(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('TheGuestbookFatalController', TheGuestbookFatalController);

    TheGuestbookFatalController.$inject = ['$scope', '$rootScope', '$state', '$stateParams'];
	
	function TheGuestbookFatalController($scope, $rootScope, $state, $stateParams) {
	    var vm = this;
	    //console.log("here");
	}
})();
