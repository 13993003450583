(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('CartItemVoucherController', CartItemVoucherController);

	function CartItemVoucherController(
	    $scope,
        $rootScope,
        $state,
        $stateParams,
        ShoppingCartRemove,
        CountriesService,
        CountriesWithoutPostcodeService,
        ExplainDeliveryPersonService,
        $translate,
        $uibModal,
        $document,
        $window,
        $sce,
        $q,
        $filter,
        CurrencyExchange,
        CldrDataService
        ) {
	    'ngInject';
	    var itemVM = this;

        var defaultModalOptions = {
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'app/site/shoppingcart/explain/explain-delivery-modal.html',
            controller: 'ExplainModalInstanceCtrl',
            controllerAs: '$ctrl',
            size: 'lg',
            appendTo: 'body',
            resolve: {
                customExplain: function () {
                    return itemVM.customExplain;
                },
                config: function () {
                    return itemVM.configMap;
                }
            }
        }

	    this.$onInit = function () {
            itemVM.property = itemVM.property || {}; //not item property, but master property
            itemVM.shoppingCart = itemVM.shoppingCart || {};
            //itemVM.shoppingCartConfigs = itemVM.shoppingCartConfigs || {};
            itemVM.emailConfig = itemVM.emailConfig || {};
            console.log('itemVM.cartItem', itemVM.cartItem);
            itemVM.cartItem = itemVM.cartItem || {};
            itemVM.translateContextType = itemVM.cartItem.itemType && itemVM.cartItem.itemType.includes("product") ? "items" : "vouchers";
            itemVM.cartItemIndex = itemVM.cartItemIndex || 0;
            itemVM.cartItemFirst = typeof itemVM.cartItemFirst === 'undefined' ? true : itemVM.cartItemFirst;
            itemVM.isRefundsContext = $stateParams.contextType=='refunds';
            itemVM.isGroup = typeof itemVM.propertyGroup !== 'undefined';
            console.log(itemVM.propertyGroup)

            itemVM.explicitlyTrustedItemDescription = $sce.trustAsHtml(itemVM.cartItem.itemDescription);

            if(itemVM.cartItem.collectionDescription) {
                itemVM.explicitlyTrustedItCollectionDescription = $sce.trustAsHtml(itemVM.cartItem.collectionDescription);
            }

            itemVM.getFullName = getFullName;
            itemVM.copyRecipientFromPrevious = copyRecipientFromPrevious;
            itemVM.copyDedicationFromPrevious = copyDedicationFromPrevious;

            itemVM.removeItemFromCartAndRefresh = removeItemFromCartAndRefresh;
            itemVM.previewVoucher = previewVoucher;



            itemVM.configMap = {
                cashDefault : "1",
                customerIsRecipient : "false",
                disableDeliverToCustomer : "false",
                disableRecipientMessage : "false",
                disableAmountVisibilityOption : (itemVM.cartItem.itemType=='cash' || itemVM.cartItem.itemType=='cash-wildcard' ? 'true' : 'false'),
                displayShowValue: (itemVM.cartItem.itemType=='cash' || itemVM.cartItem.itemType=='cash-wildcard' ? 'DISABLE_SHOW_VALUE' : 'ENABLE'),
                emailOption : "true",
                postalOption : "false",
                collectOption : "false",
                limitQuantity : 999,
                confirmEmail: "false",
                maxBulkRows: 30,
                disableScheduledDelivery: defaultScheduledDeliveryDisabled(itemVM.cartItem),
                dedicationMessageLength: 2000,
                dedicationMessageLengthPost: 2000,
                displayInformalDedication: false,
                disableRecipientDetails: false,
                allowBulkPostal: false,
            };

            console.log(`quick config map check : ${itemVM.configMap.displayShowValue} and type: ${itemVM.cartItem.itemType}`);

            itemVM.emailConfigMap = {
                emailPreview: "true"
            };

            itemVM.emailCategories = [];

            var currentPropertyId = itemVM.cartItem.owningProperty.propertyId;
            //TODO: group shopping cart and email config
            //extend default config with property config
            if(itemVM.shoppingCartConfigs && itemVM.shoppingCartConfigs[currentPropertyId]){
                angular.extend(itemVM.configMap,itemVM.shoppingCartConfigs[currentPropertyId].configMap);
            }

            //extend the emailConfig
            if (itemVM.emailConfig && itemVM.emailConfig[currentPropertyId]) {
                angular.extend(itemVM.emailConfigMap, itemVM.emailConfig[currentPropertyId].configMap);
                //console.info('emailCategories', itemVM.emailConfig[currentPropertyId].emailCategories)
                itemVM.emailCategories = itemVM.emailConfig[currentPropertyId].emailCategories;
            }

            if(itemVM.configMap.customerIsRecipient==='true'){
                itemVM.configMap.disableDeliverToRecipient = "true";
                itemVM.configMap.disableScheduledDelivery = "true";
            }

            //fix the string value to boolean
            itemVM.configMap.displayInformalDedication = itemVM.configMap.displayInformalDedication === 'true';

            //fix the displayShowValue for cash/cash-wildcard -- in case the override is ENABLE or DISABLE_HIDE_VALUE
            //cash and cash-wildcard should always be DISABLE_SHOW_VALUE
            if (itemVM.cartItem.itemType == 'cash' || itemVM.cartItem.itemType == 'cash-wildcard') {
                itemVM.configMap.displayShowValue = 'DISABLE_SHOW_VALUE';
            }


            //gift cards can only be sent by post // legacy, not used anymore
            if(itemVM.cartItem.itemGiftCardId && itemVM.cartItem.itemGiftCardId>0){
                itemVM.configMap.emailOption = "false";
                itemVM.configMap.postalOption = "true";
            }

            //read voucher settings
            //please note 'itemFixedDeliveryType' is now legac and has been replaced by email-, post- or collectOption
            if(!itemVM.cartItem.itemMeta || (!itemVM.cartItem.emailOption && !itemVM.cartItem.itemMeta.postOption && !itemVM.cartItem.itemMeta.collectOption)){
                //use 'itemFixedDeliveryType'
                if(itemVM.cartItem.itemFixedDeliveryType && "post"==itemVM.cartItem.itemFixedDeliveryType){
                    itemVM.configMap.emailOption = "false";
                    itemVM.configMap.postalOption = "true";
                }
                else if(itemVM.cartItem.itemFixedDeliveryType && "email"==itemVM.cartItem.itemFixedDeliveryType){
                    itemVM.configMap.emailOption = "true";
                    itemVM.configMap.postalOption = "false";
                }
            } else {
                if(itemVM.cartItem.itemMeta && itemVM.cartItem.itemMeta.emailOption && "true"==itemVM.cartItem.itemMeta.emailOption){
                    itemVM.configMap.emailOption = "true";
                } else if(itemVM.cartItem.itemMeta && itemVM.cartItem.itemMeta.emailOption && "false"==itemVM.cartItem.itemMeta.emailOption) {
                    itemVM.configMap.emailOption = "false";
                }
                if(itemVM.cartItem.itemMeta && itemVM.cartItem.itemMeta.postalOption && "true"==itemVM.cartItem.itemMeta.postalOption){
                    itemVM.configMap.postalOption = "true";
                } else if(itemVM.cartItem.itemMeta && itemVM.cartItem.itemMeta.postalOption && "false"==itemVM.cartItem.itemMeta.postalOption) {
                    itemVM.configMap.postalOption = "false";
                }
                if(itemVM.cartItem.itemMeta && itemVM.cartItem.itemMeta.collectOption && "true"==itemVM.cartItem.itemMeta.collectOption){
                    itemVM.configMap.collectOption = "true";
                } else if(itemVM.cartItem.itemMeta && itemVM.cartItem.itemMeta.collectOption && "false"==itemVM.cartItem.itemMeta.collectOption) {
                    itemVM.configMap.collectOption = "false";
                }
            }

            if(itemVM.shoppingCartConfigs && itemVM.shoppingCartConfigs[currentPropertyId] && itemVM.shoppingCartConfigs[currentPropertyId].disableScheduledDelivery){
                itemVM.configMap.disableScheduledDelivery = itemVM.shoppingCartConfigs[currentPropertyId].disableScheduledDelivery;
            }

            if(itemVM.cartItem.itemMeta && itemVM.cartItem.itemMeta.disableScheduledDelivery=='true'){
                //disabled for now due to voucher override.
                itemVM.configMap.disableScheduledDelivery = "true";
            }
            if(itemVM.cartItem.itemMeta && itemVM.cartItem.itemMeta.disableScheduledDelivery=='false'  && itemVM.configMap.disableScheduledDelivery!="true"){
                //we can enable, but only if property level is not disabled
                itemVM.configMap.disableScheduledDelivery = "false";
            }
            if(itemVM.cartItem.itemMeta && itemVM.cartItem.itemMeta.disableScheduledDelivery=='force_false'){
                //enable regardless of property level
                itemVM.configMap.disableScheduledDelivery = "false";
            }


            //the guest book has defaults
            if($stateParams.channel==20 || $stateParams.channel=='20'){
                itemVM.configMap.emailOption = "true";
                itemVM.configMap.postalOption = "false";
                itemVM.configMap.collectOption = "false";
                itemVM.configMap.customerIsRecipient = "false";
                itemVM.configMap.disableDeliverToCustomer = "true";
                itemVM.configMap.disableRecipientMessage = "true";
                itemVM.configMap.disableAmountVisibilityOption = "true";
                itemVM.configMap.displayShowValue = 'DISABLE_SHOW_VALUE';
                itemVM.configMap.showTAndC = "true";
                itemVM.configMap.disableScheduledDelivery = "true";
                itemVM.cartItem.termsAgreed = false;
                itemVM.cartItem.recipient.scheduledDelivery = 'now';

            }

            //refunds have defaults
            if($stateParams.contextType=='refunds'){
                itemVM.configMap.emailOption = "true";
                itemVM.configMap.postalOption = "false";
                itemVM.configMap.collectOption = "false";
                itemVM.configMap.disableDeliveryMethod = "true";
                itemVM.configMap.customerIsRecipient = "false";
                itemVM.configMap.disableDeliverToCustomer = "true";
                itemVM.configMap.disableDeliverToRecipient = "true";
                itemVM.configMap.disableRecipientMessage = "true";
                itemVM.configMap.enableComment = "true";
                itemVM.configMap.disableAmountVisibilityOption = "true";
                itemVM.configMap.displayShowValue = 'DISABLE_SHOW_VALUE';
                itemVM.configMap.disableScheduledDelivery = "true";
                itemVM.cartItem.termsAgreed = true;
                itemVM.cartItem.recipient.deliveryMethodType = "email";
                itemVM.cartItem.recipient.deliveryPersonType = "recipient";
            }

            //bulk have defaults
            if(itemVM.isBulk) {
                itemVM.configMap.emailOption = "true";
                itemVM.configMap.postalOption = itemVM.configMap.allowBulkPostal === 'true' ? "true" : "false"
                itemVM.configMap.collectOption = (itemVM.configMap.allowBulkClickCollect === 'true' && itemVM.cartItem.itemMeta.collectOption === 'true') ? "true" : "false";
                itemVM.configMap.disableDeliveryMethod = "false";
                itemVM.configMap.customerIsRecipient = "false";
                itemVM.configMap.disableDeliverToCustomer = "true";
                itemVM.configMap.disableDeliverToRecipient = "false";
                itemVM.configMap.disableRecipientDetails = false;
                itemVM.configMap.disableRecipientMessage = "true";//will be handled by spreadsheet
                itemVM.configMap.enableComment = "false";//will be handled by spreadsheet
                itemVM.configMap.disableAmountVisibilityOption = itemVM.configMap.disableAmountVisibilityOption || "false";
                itemVM.configMap.displayShowValue = itemVM.configMap.displayShowValue || 'ENABLE';
                itemVM.configMap.disableScheduledDelivery = "true";
                itemVM.cartItem.termsAgreed = itemVM.cartItem.termsAgreed || true;
                itemVM.cartItem.recipient.deliveryMethodType = itemVM.cartItem.recipient.deliveryMethodType || "email";
                itemVM.cartItem.recipient.deliveryPersonType = itemVM.cartItem.recipient.deliveryPersonType || "recipient";

                if (itemVM.shoppingCartConfigs && itemVM.shoppingCartConfigs[currentPropertyId] && itemVM.shoppingCartConfigs[currentPropertyId].maxBulkRows){
                    itemVM.configMap.maxBulkRows = itemVM.shoppingCartConfigs[currentPropertyId].maxBulkRows;
                }

                if (itemVM.isGroup && itemVM.shoppingCartConfigs[itemVM.property.hotelId] && itemVM.shoppingCartConfigs[itemVM.property.hotelId].configMap && itemVM.shoppingCartConfigs[itemVM.property.hotelId].configMap.maxBulkRows) {
                    itemVM.configMap.maxBulkRows = parseInt(itemVM.shoppingCartConfigs[itemVM.property.hotelId].configMap.maxBulkRows);
                }

            }

            //end of configuration

            var deliveryOptionsCount = 0;
            if(itemVM.configMap.emailOption == "true") {
                deliveryOptionsCount++;
            }
            if(itemVM.configMap.postalOption == "true") {
                deliveryOptionsCount++;
            }
            if(itemVM.configMap.collectOption == "true") {
                deliveryOptionsCount++;
            }
            itemVM.deliveryOptionsCount = deliveryOptionsCount>0 ? deliveryOptionsCount : 2;

            if(itemVM.deliveryOptionsCount==1 && itemVM.configMap.collectOption == "true"){
                activateCollectionOption();
            }


            console.log('before baseRecipients', itemVM.cartItem.recipient.showValue);

            var baseRecipient = {
                firstName : itemVM.cartItem.recipient.firstName || '',
                lastName : itemVM.cartItem.recipient.lastName || '',
                country : '',
                dedicationFrom: itemVM.cartItem.recipient.dedicationTo || '',
                dedicationTo: itemVM.cartItem.recipient.dedicationFrom || '',
                message: itemVM.cartItem.recipient.message || '',
                comment: itemVM.cartItem.recipient.comment || undefined,
                deliveryMethodType : itemVM.cartItem.recipient.deliveryMethodType || defaultDeliveryMethodTypeFromConfig(itemVM.shoppingCartConfigs[currentPropertyId].configMap.defaultDeliveryMethodType, itemVM.cartItem),
                deliveryPersonType : itemVM.cartItem.recipient.deliveryPersonType || defaultDeliveryPersonType(itemVM.cartItem),
                scheduledDelivery: 'now',
                showValue : itemVM.cartItem.recipient.showValue || (itemVM.cartItem.itemType=='cash' || itemVM.cartItem.itemType=='cash-wildcard' ? 'true' : defaultShowValue(itemVM.cartItem)),
                emailCategoryId: availableEmailCaregory(itemVM.cartItem.recipient.emailCategoryId)
            };


            if(!itemVM.cartItem.recipient || !itemVM.cartItem.recipient.uuid || itemVM.cartItem.recipient.uuid.length==0){
                angular.extend(itemVM.cartItem.recipient , baseRecipient);
            }

            if(!itemVM.cartItem.recipient.scheduledDelivery){
                itemVM.cartItem.recipient.scheduledDelivery = baseRecipient.scheduledDelivery;
            }


            itemVM.hideCustomizationBox = (
                itemVM.configMap.customerIsRecipient==='true'
                && (itemVM.configMap.disableRecipientMessage==='true'
                    || itemVM.configMap.disableRecipientMessage==='trueWithDefault'
                    || (itemVM.configMap.disableRecipientMessage == 'trueForPost' && itemVM.cartItem.recipient.deliveryMethodType=='post' )
                    || (itemVM.configMap.disableRecipientMessage == 'trueForEmail' && itemVM.cartItem.recipient.deliveryMethodType=='email' )
                )
                && itemVM.configMap.disableAmountVisibilityOption==='true'
                && (itemVM.cartItem.recipient.deliveryMethodType=='post' || itemVM.emailCategories.length==0)
            );

            itemVM.selectCountry = [];
            itemVM.noPostcodeCountries = [];
            itemVM.isPostCodeRequired = true;

            loadCountries(loadCountriesCallback);
            loadCountriesWithoutPostcode(loadCountriesWithoutPostcodeCallback);

            itemVM.customExplain = {
                post: {
                    customer : false,
                    recipient : false
                },
                email : {
                    customer : false,
                    recipient : false
                }
            };

            updateExplainDescriptions('customer','email');
            updateExplainDescriptions('customer','post');
            updateExplainDescriptions('recipient','email');
            updateExplainDescriptions('recipient','post');


            var defaultModalOptions = {
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/site/shoppingcart/explain/explain-delivery-modal.html',
                controller: 'ExplainModalInstanceCtrl',
                controllerAs: '$ctrl',
                size: 'lg',
                appendTo: 'body',
                resolve: {
                    customExplain: function () {
                        return itemVM.customExplain;
                    },
                    config: function () {
                        return itemVM.configMap;
                    }
                }
            }

            //currency
            itemVM.baseCurrency = {
                code : itemVM.cartItem.itemCurrency
            };

            itemVM.currentCurrency = {
                code : itemVM.baseCurrency.code
            }
            var currentCurrency = CurrencyExchange.currencies.current;//current currency might be NULL if this is group engine
            if(currentCurrency && currentCurrency!=null){
                itemVM.currentCurrency.code = currentCurrency.code;
            }

            itemVM.isCollapsedDescr = shouldCollapsedDescr();

            //SCHEDULED DELIVERY


            itemVM.zones = [];
            var scheduledZone = {continentKey:'',value:itemVM.property.hotelTimeZone || '',display:itemVM.property.hotelCountry,territoryName:itemVM.property.hotelCountry};
            itemVM.zones.push(scheduledZone);

            //get timezones in user's locale (using Common Locale Data Repository)
            loadCldrData(itemVM.currentLanguage);

            var scheduledTime = new Date();
            scheduledTime.setMinutes(0);
            scheduledTime.setSeconds(0);
            scheduledTime.setMilliseconds(0);
            var scheduledDate = new Date();
            scheduledDate.setMinutes(0);
            scheduledDate.setSeconds(0);
            scheduledDate.setMilliseconds(0);

            if(itemVM.cartItem.recipient.scheduledDeliveryTime){
                var scheduledDateString = itemVM.cartItem.recipient.scheduledDeliveryTime;
                try {
                    scheduledTime = new Date(scheduledDateString);
                    itemVM.cartItem.recipient.scheduledDelivery = 'later';
                } catch(e){
                    scheduledTime = new Date();
                    scheduledTime.setMinutes(0);
                    scheduledTime.setSeconds(0);
                    scheduledTime.setMilliseconds(0);
                }
                try {
                    scheduledDate = new Date(scheduledDateString);
                } catch(e){
                    scheduledDate = new Date();
                    scheduledDate.setMinutes(0);
                    scheduledDate.setSeconds(0);
                    scheduledDate.setMilliseconds(0);
                }
            }

            if(itemVM.cartItem.recipient.scheduledDeliveryTimeZone){

            }

            var scheduledDeliveryMaxDays = 90;
            if(itemVM.shoppingCartConfigs && itemVM.shoppingCartConfigs[currentPropertyId] && itemVM.shoppingCartConfigs[currentPropertyId].configMap && itemVM.shoppingCartConfigs[currentPropertyId].configMap.scheduledDeliveryMaxDays){
                try {
                    scheduledDeliveryMaxDays = parseInt(itemVM.shoppingCartConfigs[currentPropertyId].configMap.scheduledDeliveryMaxDays);
                } catch(e){
                }
                if (isNaN(scheduledDeliveryMaxDays)) { scheduledDeliveryMaxDays = 90; }
            }
            var scheduledDeliveryMaxDate = new Date();
            scheduledDeliveryMaxDate.setDate(scheduledDeliveryMaxDate.getDate()+scheduledDeliveryMaxDays);

            itemVM.scheduledDeliveryData = {
                scheduledTime : scheduledTime,
                scheduledDate : scheduledDate,
                scheduledZone : scheduledZone,
                scheduledDatePickerOptions : {
                    minDate : new Date(),
                    maxDate : scheduledDeliveryMaxDate,
                    showWeeks: false,
                    language: 'en-gb',
                    maxMode: 'month'
                }
            };

            itemVM.cartItem.recipient.scheduledDate = $filter('date')(itemVM.scheduledDeliveryData.scheduledDate, 'yyyy-MM-dd'/*, timezone*/)//using timezone of the browser;
            itemVM.cartItem.recipient.scheduledTime = $filter('date')(itemVM.scheduledDeliveryData.scheduledTime, 'HH:mm'/*, timezone*/)//using timezone of the browser;
            itemVM.cartItem.recipient.scheduledZone = itemVM.scheduledDeliveryData.scheduledZone.value;


            //SCHEDULED WATCHERS
            $scope.$watch('itemVM.scheduledDeliveryData.scheduledZone', function(newValue, oldValue) {
                console.info("itemVM.scheduledDeliveryData.scheduledZone",newValue, oldValue);
                itemVM.cartItem.recipient.scheduledZone = newValue.value;
            });

            $scope.$watch('itemVM.scheduledDeliveryData.scheduledDate', function(newValue, oldValue) {
                var dateString = $filter('date')(newValue, 'yyyy-MM-dd'/*, timezone*/)//using timezone of the browser
                console.info("itemVM.scheduledDeliveryData.scheduledDate",newValue, oldValue);
                itemVM.cartItem.recipient.scheduledDate = dateString;
            });

            $scope.$watch('itemVM.scheduledDeliveryData.scheduledTime', function(newValue, oldValue) {
                console.info("itemVM.scheduledDeliveryData.scheduledTime",newValue, oldValue);
                var timeString = $filter('date')(newValue, 'HH:mm'/*, timezone*/)//using timezone of the browser
                itemVM.cartItem.recipient.scheduledTime = timeString;
            });


            //OTHER WATCHERS
            $rootScope.$on('CurrencyExchangeProvider.changeCurrency', function(event, args) {
                itemVM.currentCurrency.code = args.code;
            });

            $scope.$watch('itemVM.cartItem.recipient.deliveryMethodType', function(newValue, oldValue) {
                //1. if you interact with delivery method type, you must approve t&c again:
                itemVM.cartItem.termsAgreed = false;

                //2. FIRST DEACTIVATE AND RETURN TO CACHED CONFIG AND RECIPIENT
                //if old value is collection:
                if(oldValue=='collect' && newValue!='collect'){
                    deactivateCollectionOption();
                }
                //if old value is post:
                if(oldValue=='post' && newValue!='post'){
                    deactivatePostOption();
                }

                //3. THEN ACTIVATE REQUIRED OPTION
                //if new value is collection:
                if(newValue=='collect'){
                    activateCollectionOption();
                }
                //if new value is post:
                if(newValue=='post'){
                    activatePostOption();
                }

                // remove the recipient message if the post option doesn't allow recipient message via config.
                if (newValue == 'post' && itemVM.configMap.disableRecipientMessage == 'trueForPost') {
                    itemVM.cartItem.recipient.message = '';
                }

                // remove the recipient message if the email option doesn't allow recipient message via config.
                if (newValue == 'email' && itemVM.configMap.disableRecipientMessage == 'trueForEmail') {
                    itemVM.cartItem.recipient.message = '';
                }

                // check the dedication message length rules
                changeDedicationMessageLength(newValue);

                //modify display of post recipient message if isBulk
                if (itemVM.isBulk) {
                    //check if newValue is either `post` or `collect` then set itemVM.configMap.disableRecipientDetails to false, else true
                    itemVM.configMap.disableRecipientDetails = (newValue == 'post' || newValue == 'collect') ? false : true;
                }

            });

            /*$scope.$watch('itemVM.cartItem.recipient.deliveryPersonType', function(newValue, oldValue) {
                //2. FIRST DEACTIVATE AND RETURN TO CACHED CONFIG
                //if old value is customer:
                if(oldValue=='customer' && newValue!='customer'){
                    activateScheduledDelivery();
                }
                //3. THEN ACTIVATE REQUIRED OPTION
                //if new value is customer:
                if(newValue=='customer'){
                    deactivateScheduledDelivery();
                }
            });*/

            $scope.$watch("itemVM.cartItem.recipient.country", function (newValue, oldValue) {
                isPostCodeRequired();
            });

        }

        function changeDedicationMessageLength(deliveryMethodType) {
            console.log('changeDedicationMessageLength', deliveryMethodType, itemVM.configMap.dedicationMessageLengthPost)
            if (deliveryMethodType == 'post' && itemVM.configMap.dedicationMessageLengthPost) {
                itemVM.configMap.dedicationMessageLength = parseInt(itemVM.configMap.dedicationMessageLengthPost);
            } else if (deliveryMethodType == 'email' && itemVM.configMap.dedicationMessageLengthEmail) {
                itemVM.configMap.dedicationMessageLength = parseInt(itemVM.configMap.dedicationMessageLengthEmail);
            } else {
                itemVM.configMap.dedicationMessageLength = 2000;
            }
        }


        function loadCldrData(currentLanguage){

            var dataPromises = [];

            var territoryKeyToNameMap = {};
            var zonesMap = {};
            var zoneKeyToTerritoryKeyMap = {};

            var zonesPromise = CldrDataService
                .getMain({language:currentLanguage,name:'timeZoneNames'})
                .$promise
                .then(function(data) {
                    angular.forEach(data.main[currentLanguage.replace('_','-')].dates.timeZoneNames.zone,function(zoneCityObject,continentKey){
                        angular.forEach(zoneCityObject,function(cityObject,cityKey){
                            zonesMap[continentKey+'_'+cityKey] = {
                                continentKey:continentKey,
                                territoryName:continentKey,
                                cityKey: cityKey,
                                exemplarCity: cityObject.exemplarCity,
                                value:continentKey+'/'+cityKey,
                                display:continentKey+'/'+cityObject.exemplarCity ? cityObject.exemplarCity : cityKey
                            };
                        });
                    });
                },function(reason){
                    //try loading data without country
                    loadCldrData((currentLanguage || 'en').split('_')[0].split('-')[0]);
                });

            var windowsZonesPromise = CldrDataService
                .getSupplemental({name:'windowsZones'})
                .$promise
                .then(function(data) {
                    angular.forEach(data.supplemental.windowsZones.mapTimezones,function(mapTimezoneObject,mI){
                        var zones = mapTimezoneObject.mapZone._type.split(" ");
                        angular.forEach(zones,function(zoneName,zI){
                            var zoneKey = zoneName.replace("/","_");//replace only first '/'
                            zoneKey = zoneKey.split('/')[0];//remove everything after the second '/'
                            zoneKeyToTerritoryKeyMap[zoneKey]=mapTimezoneObject.mapZone._territory;
                        });
                    });
                },function(reason){

                });

            var territoryPromise = CldrDataService
                .getMain({language:currentLanguage,name:'territories'})
                .$promise
                .then(function(data) {
                    angular.forEach(data.main[currentLanguage.replace('_','-')].localeDisplayNames.territories,function(territory,territoryKey){
                        territoryKeyToNameMap[territoryKey]=territory;
                    });
                },function(reason){

                });

            dataPromises.push(zonesPromise);
            dataPromises.push(windowsZonesPromise);
            dataPromises.push(territoryPromise);

            $q.all(dataPromises).then(cldrDataCbSuccess({territoryKeyToNameMap:territoryKeyToNameMap,zoneKeyToTerritoryKeyMap:zoneKeyToTerritoryKeyMap,zonesMap:zonesMap}));

        }

        function cldrDataCbSuccess(dataHash){
            return function(){
                var territoryKeyToNameMap = dataHash.territoryKeyToNameMap;
                var zonesMap = dataHash.zonesMap;
                var zoneKeyToTerritoryKeyMap = dataHash.zoneKeyToTerritoryKeyMap;
                //prepare zones
                angular.forEach(zonesMap,function(zoneObject,zoneKey){
                    //localize continentName
                    var territoryKey = zoneKeyToTerritoryKeyMap[zoneKey];
                    if(!territoryKey){
                        return;
                    }
                    var localizedTerritoryName = territoryKeyToNameMap[territoryKey];
                    if(!localizedTerritoryName){
                        return;
                    }
                    zoneObject.territory = localizedTerritoryName;
                    zoneObject.display = localizedTerritoryName+'/'+(zoneObject.exemplarCity ? zoneObject.exemplarCity : zoneObject.cityKey);

                    itemVM.zones.push(zoneObject);
                });
            }
        }

	    function availableEmailCaregory(id){
	    	var matched = false;
	    	if(itemVM.emailCategories && id){
	    		angular.forEach(itemVM.emailCategories,function(c,i){
	    			if(c.id==id){
	    				matched= true;
	    			}
	    		});
	    	}

	    	return matched ? id : 1;
	    }

        function defaultDeliveryMethodTypeFromConfig(defaultValue, item) {
            console.log('defaultDeliveryMethodTypeFromConfig', defaultValue, itemVM.configMap);
            if (item.itemGiftCardId && item.itemGiftCardId > 0) {
                return 'post';
            }

            if (defaultValue === 'none' && $stateParams.contextType != "refunds" && !itemVM.isBulk) {
                return undefined;
            } else if (typeof defaultValue !== 'undefined') {
                return defaultValue;
            }

            if (itemVM.configMap.emailOption == 'true') {
                return 'email';
            } else if (itemVM.configMap.postalOption == 'true') {
                return 'post';
            } else if (itemVM.configMap.collectOption == 'true') {
                return 'collect';
            }

            return 'email';

        }

	    function defaultDeliveryMethodType (item){
	    	if(item.itemGiftCardId && item.itemGiftCardId>0){
	    		return 'post';
	    	}
		    if(item.owningProperty.propertyId==297 /*IIL*/ && $stateParams.contextType!="refunds" && !itemVM.isBulk){
		    	return undefined;
		    }
		    if(['MDVHIE','CLYVIE','MDHVUK','CLYVUK','CWHVIE','CHSVIE','CHGVIE','MHSVIE'].indexOf(item.owningProperty.propertyCode.toUpperCase())!=-1){
		    	return undefined;
		    }
		    if(itemVM.configMap.emailOption=='true'){
		    	return 'email';
		    }
		    else if(itemVM.configMap.postalOption=='true'){
		    	return 'post';
		    }
            else if(itemVM.configMap.collectOption=='true'){
                return 'collect';
            }

		    return 'email';
	    }

	    function defaultDeliveryPersonType (item){
	    	if(['MDVHIE','CLYVIE','MDHVUK','CLYVUK','CWHVIE','CHSVIE','CHGVIE','MHSVIE'].indexOf(item.owningProperty.propertyCode.toUpperCase())!=-1){
		    	return undefined;
		    }
		    if($stateParams.contextType=="refunds" || itemVM.isBulk){
                return 'recipient';
            }
		    return 'customer';
	    }

	    function defaultShowValue(item){
            console.log('defaultShowValue', item);

            if(item.itemType.toLowerCase().includes("refund")){
                return true;
            }

            if(['MDVHIE','CLYVIE','MDHVUK','CLYVUK','CWHVIE','CHSVIE','CHGVIE','MHSVIE'].indexOf(item.owningProperty.propertyCode.toUpperCase())!=-1){
		    	return undefined;
		    }

            if(['BSHVUK'].indexOf(item.owningProperty.propertyCode.toUpperCase())!=-1){
                return 'true';
            }

            if (itemVM.configMap.displayShowValue === 'DISABLE_SHOW_VALUE') {
                return 'true';
            }

            if (itemVM.configMap.displayShowValue === 'DISABLE_HIDE_VALUE') {
                return 'false'
            }

            if(itemVM.configMap.displayShowValue === 'ENABLE' && item.itemType.toLowerCase().includes('package')){
                return 'false';
            }

		    return 'true';
	    }

        function defaultScheduledDeliveryDisabled(item){
            if(['TSTDEMO'/*,'PVHVAE'*/].indexOf(item.owningProperty.propertyCode.toUpperCase())!=-1){
                return 'false';
            }
            return 'true';
        }


	    function activateCollectionOption(){

            console.log("Collection option activated");

            //preserve config
            itemVM.cachedConfigMap = itemVM.cachedConfigMap || angular.copy(itemVM.configMap);
            itemVM.cachedConfigMap.deliveryPersonType = itemVM.cartItem.recipient.deliveryPersonType;

            itemVM.configMap.customerIsRecipient = "true";
            itemVM.configMap.disableDeliverToCustomer = "true";
            itemVM.configMap.disableDeliverToRecipient = "true";
            itemVM.configMap.disableRecipientMessage = itemVM.isBulk ? "true" : "false";
            itemVM.configMap.disableAmountVisibilityOption = "true";
            itemVM.configMap.deliveryPersonType = "customer";

            itemVM.configMap.showCollectionInfo = "true";

            //remove scheduled delivery option, as set it to now
            itemVM.configMap.disableScheduledDelivery = "true";
            itemVM.cachedConfigMap.scheduledDelivery = itemVM.cartItem.recipient.scheduledDelivery;
            itemVM.cartItem.recipient.scheduledDelivery = "now";

        }

        function deactivateCollectionOption(){

            console.log("Collection option deactivated");

            //recreate config from preserved one
            itemVM.configMap.customerIsRecipient = itemVM.cachedConfigMap ? itemVM.cachedConfigMap.customerIsRecipient : itemVM.configMap.customerIsRecipient;
            itemVM.configMap.disableDeliverToCustomer = itemVM.cachedConfigMap ? itemVM.cachedConfigMap.disableDeliverToCustomer : itemVM.configMap.disableDeliverToCustomer;
            itemVM.configMap.disableDeliverToRecipient = itemVM.cachedConfigMap ? itemVM.cachedConfigMap.disableDeliverToRecipient : itemVM.configMap.disableDeliverToRecipient;
            itemVM.configMap.disableRecipientMessage = itemVM.cachedConfigMap ? itemVM.cachedConfigMap.disableRecipientMessage : itemVM.configMap.disableRecipientMessage;
            itemVM.configMap.disableAmountVisibilityOption = itemVM.cachedConfigMap ? itemVM.cachedConfigMap.disableAmountVisibilityOption : itemVM.configMap.disableAmountVisibilityOption;
            itemVM.cartItem.recipient.deliveryPersonType = itemVM.cachedConfigMap ? itemVM.cachedConfigMap.deliveryPersonType : itemVM.cartItem.recipient.deliveryPersonType;
            itemVM.configMap.showCollectionInfo = "false";

            //set scheduled delivery option to what it was previously
            itemVM.cartItem.recipient.scheduledDelivery = itemVM.cachedConfigMap ? itemVM.cachedConfigMap.scheduledDelivery : itemVM.cartItem.recipient.scheduledDelivery;
            itemVM.configMap.disableScheduledDelivery = itemVM.cachedConfigMap ? itemVM.cachedConfigMap.disableScheduledDelivery : itemVM.configMap.disableScheduledDelivery;

        }

        function activatePostOption(){

            console.log("Post option activated");

            //preserve config
            itemVM.cachedConfigMap = itemVM.cachedConfigMap || angular.copy(itemVM.configMap);

            deactivateScheduledDelivery();

        }

        function deactivatePostOption(){

            console.log("Post option deactivated");

            activateScheduledDelivery();

        }

        function deactivateScheduledDelivery(){
            //preserve config
            itemVM.cachedConfigMap = itemVM.cachedConfigMap || angular.copy(itemVM.configMap);

            //remove scheduled delivery option, as set it to now
            itemVM.configMap.disableScheduledDelivery = "true";
            itemVM.cachedConfigMap.scheduledDelivery = itemVM.cartItem.recipient.scheduledDelivery;
            itemVM.cartItem.recipient.scheduledDelivery = "now";

        }
        function activateScheduledDelivery(){
            //set scheduled delivery option to what it was previously
            itemVM.cartItem.recipient.scheduledDelivery = itemVM.cachedConfigMap ? itemVM.cachedConfigMap.scheduledDelivery : itemVM.cartItem.recipient.scheduledDelivery;
            itemVM.configMap.disableScheduledDelivery = itemVM.cachedConfigMap ? itemVM.cachedConfigMap.disableScheduledDelivery : itemVM.configMap.disableScheduledDelivery;
        }

	    function getFullName(firstName,lastName){
	    	var arr = [];
	    	if(firstName){
	    		arr.push(firstName);
	    	}
	    	if(lastName){
	    		arr.push(lastName);
	    	}

	    	return arr.join(' ');
	    }

	    function copyRecipientFromPrevious(event, currentIndex){
	    	event.preventDefault();
	    	var previousItem = itemVM.shoppingCart.shoppingCartItems.items[currentIndex-1];

	    	itemVM.cartItem.recipient.email = previousItem.recipient.email;
	    	itemVM.cartItem.recipient.address = previousItem.recipient.address;
	    	itemVM.cartItem.recipient.town = previousItem.recipient.town;
	    	itemVM.cartItem.recipient.county = previousItem.recipient.county;
	    	itemVM.cartItem.recipient.postcode = previousItem.recipient.postcode;
	    	itemVM.cartItem.recipient.country = previousItem.recipient.country;
	    	itemVM.cartItem.recipient.phone = previousItem.recipient.phone;
	    }

	    function copyDedicationFromPrevious(event, currentIndex){
	    	event.preventDefault();
	    	var previousItem = itemVM.shoppingCart.shoppingCartItems.items[currentIndex-1];

	    	itemVM.cartItem.recipient.message = previousItem.recipient.message;
	    	itemVM.cartItem.recipient.firstName = previousItem.recipient.firstName;
	    	itemVM.cartItem.recipient.lastName = previousItem.recipient.lastName;
	    	itemVM.cartItem.recipient.hideValue = previousItem.recipient.hideValue;
			itemVM.cartItem.recipient.emailCategoryId = previousItem.recipient.emailCategoryId;

	    }

	    function loadCountries(callback){

	    	var cb = callback || angular.noop;

	    	CountriesService.get(function (response) {
                return cb(response);
            });

	    }

	    function loadCountriesWithoutPostcode(callback){

	    	var cb = callback || angular.noop;

	    	CountriesWithoutPostcodeService.get(function (response) {
                return cb(response);
            });

	    }

	    function loadCountriesCallback(data){

	    	//get countries from shopping basket and make them a priority
	    	var alreadyUsedCountryNames = [];

	    	if(typeof itemVM.cartItem.recipient.country === 'string' && itemVM.cartItem.recipient.country.length>0){
	    		alreadyUsedCountryNames.push(itemVM.cartItem.recipient.country);
	    	}

	    	if(itemVM.shoppingCart.shoppingCartItems){
                angular.forEach(itemVM.shoppingCart.shoppingCartItems.items ,function(item, index){
                    if(item.recipient.country){
                        alreadyUsedCountryNames.unshift(item.recipient.country);
                    }
                    if(item.owningProperty.propertyCountry){
                        alreadyUsedCountryNames.push(item.owningProperty.propertyCountry);
                    }
                });
            }



	    	//add priorities to countries
	    	angular.forEach(data.countries.country ,function(c, index){
	    		//add country priority
	    		var aIndex = alreadyUsedCountryNames.indexOf(c.countryName);
	    		var isPriority = aIndex>-1;
	    		var priorityIndex = aIndex-alreadyUsedCountryNames.length;
	    		if(isPriority){
	    			if(itemVM.cartItem.recipient.country && (typeof itemVM.cartItem.recipient.country==='string' || priorityIndex<itemVM.cartItem.recipient.country.priorityCountryIndex)){
	    				itemVM.cartItem.recipient.country = c;
	    	    	}
	    			var copy = angular.copy(c, {});
	    			copy.priorityGroupIndex = 1;//like any other
	    			copy.priorityCountryIndex = 1;//like any other
	    			data.countries.country.push(copy);
	    		}
	    		c.priorityCountryIndex = isPriority ? priorityIndex : 1;
	    		c.priorityGroupIndex = isPriority ? priorityIndex : 1;

	    	});

	    	itemVM.selectCountry = data.countries.country;

	    }

	    function loadCountriesWithoutPostcodeCallback(data){

	    	itemVM.noPostcodeCountries = data.countries.country;
	    	isPostCodeRequired();
	    }

	    function isPostCodeRequired(){

	    	var isPostCodeRequired = true;
	    	if(itemVM.cartItem.recipient === undefined || itemVM.cartItem.recipient === null){
	    		isPostCodeRequired = true;
	    	}
	    	else if(itemVM.cartItem.recipient.country === undefined || itemVM.cartItem.recipient.country === null){
	    		isPostCodeRequired = true;
	    	}
	    	else if(typeof itemVM.cartItem.recipient.country === 'string' && itemVM.cartItem.recipient.country.length==0){
	    		isPostCodeRequired = false;
	    	}
	    	else if(typeof itemVM.cartItem.recipient.country === 'string' && itemVM.cartItem.recipient.country.length>0){
		    	angular.forEach(itemVM.noPostcodeCountries ,function(c, index){
		    		if(c[0]==itemVM.cartItem.recipient.country){
		    			isPostCodeRequired = false;
		    		}
		    	});
	    	}
	    	else {
		    	angular.forEach(itemVM.noPostcodeCountries ,function(c, index){
		    		if(c[0]==itemVM.cartItem.recipient.country.countryName){
		    			isPostCodeRequired = false;
		    		}
		    	});
	    	}

	    	itemVM.isPostCodeRequired = isPostCodeRequired;

	    }


	    function removeItemFromCartAndRefresh(event){
	    	event.preventDefault();
	    	removeItem(refreshSite);

	    }

	    function refreshSite(){
		    $state.transitionTo($state.current, $stateParams, {
		    	  reload: 'site', inherit: true, notify: true
		    });
	    }

	    function removeItem(callback){

            var cb = callback || angular.noop;

            return ShoppingCartRemove.remove({itemUUID : itemVM.cartItem.uuid},
                function (response) {
                    return cb(response);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;

	    }

	    function previewVoucher() {
	        if(itemVM.isBulk){
                $state.go('bulk-add.preview', {
                    'uuid': itemVM.cartItem.uuid,
                    'emailCategoryId' : itemVM.cartItem.recipient.emailCategoryId || 1,
                    'templateItem': itemVM.cartItem
                });
            } else {
                $state.go('shopping-cart.preview', {
                    'uuid': itemVM.cartItem.uuid,
                    'emailCategoryId' : itemVM.cartItem.recipient.emailCategoryId || 1
                });
            }

        }

        function updateExplainDescriptions(person,type){
	    	ExplainDeliveryPersonService.get({
		    	id : itemVM.cartItem.owningProperty.propertyId,
		    	deliveryPerson : person,
		    	deliveryMethod : type,
		    	languageKey : ($translate.use() || 'en' ).split('_')[0].split('-')[0]
		    }).$promise.then(function(response){
		    	//console.log("RESPONSE",response);
		    	if(response.text){
    				itemVM.customExplain[type][person] = response.text;
    			}
	    	});
	    }

	    itemVM.openExplainDeliveryModal = function (size, parentSelector) {
	        var parentElem = parentSelector ? angular.element($document[0].querySelector(parentSelector)) : undefined;
	        var modalOptions = angular.extend({}, defaultModalOptions);
	        modalOptions.templateUrl = 'app/site/shoppingcart/explain/explain-delivery-modal.html';
	        modalOptions.appendTo = parentElem || modalOptions.appendTo;
	        modalOptions.size = size || modalOptions.size;
	        var modalInstance = $uibModal.open(modalOptions);
	        //modalInstance.result.then(function (something) {
	        //}, function () {
	        //  console.info('Modal dismissed at: ' + new Date());
	        //});
	    };

        itemVM.openExplainRecipientModal = function (size, parentSelector) {
            var parentElem = parentSelector ? angular.element($document[0].querySelector(parentSelector)) : undefined;
            var modalOptions = angular.extend({},defaultModalOptions);
            modalOptions.templateUrl = 'app/site/shoppingcart/explain/explain-recipient-modal.html';
            modalOptions.appendTo = parentElem || modalOptions.appendTo;
            modalOptions.size = size || modalOptions.size;
            var modalInstance = $uibModal.open(modalOptions);
        };

	    itemVM.openExplainDedicationModal = function (size, parentSelector) {
	        var parentElem = parentSelector ? angular.element($document[0].querySelector(parentSelector)) : undefined;
	        var modalOptions = angular.extend({},defaultModalOptions);
	        modalOptions.templateUrl = 'app/site/shoppingcart/explain/explain-dedication-modal.html';
	        modalOptions.appendTo = parentElem || modalOptions.appendTo;
	        modalOptions.size = size || modalOptions.size;
	        var modalInstance = $uibModal.open(modalOptions);
	    };

	    itemVM.openExplainValueModal = function (size, parentSelector) {
	    	var parentElem = parentSelector ? angular.element($document[0].querySelector(parentSelector)) : undefined;
	        var modalOptions = angular.extend({},defaultModalOptions);
	        modalOptions.templateUrl = 'app/site/shoppingcart/explain/explain-value-modal.html';
	        modalOptions.appendTo = parentElem || modalOptions.appendTo;
	        modalOptions.size = size || modalOptions.size;
	        var modalInstance = $uibModal.open(modalOptions);
	    };

	    itemVM.openExplainCopyModal = function (size, parentSelector) {
	    	var parentElem = parentSelector ? angular.element($document[0].querySelector(parentSelector)) : undefined;
	        var modalOptions = angular.extend({},defaultModalOptions);
	        modalOptions.templateUrl = 'app/site/shoppingcart/explain/explain-copy-modal.html';
	        modalOptions.appendTo = parentElem || modalOptions.appendTo;
	        modalOptions.size = size || modalOptions.size;
	        var modalInstance = $uibModal.open(modalOptions);
	    };


        itemVM.openExplainScheduledLaterModal = function (size, parentSelector) {
            var parentElem = parentSelector ? angular.element($document[0].querySelector(parentSelector)) : undefined;
            var modalOptions = angular.extend({},defaultModalOptions);
            modalOptions.templateUrl = 'app/site/shoppingcart/explain/explain-schedule-later-modal.html';
            modalOptions.appendTo = parentElem || modalOptions.appendTo;
            modalOptions.size = size || modalOptions.size;
            var modalInstance = $uibModal.open(modalOptions);
        };

        function shouldCollapsedDescr() {
            var viewportWidth = $window.jQuery($window).width();
            if (viewportWidth < 767) {
                return true;
            }
            return false;
        }

	}
})();
