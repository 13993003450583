(function() {
    'use strict';

    angular.module('vobeApp').factory('ModalService', ModalService);

    ModalService.$inject = ['$uibModal'];

    function ModalService($uibModal) {
        var service = {};
        var currentModal;
        var clearModal = function() {
            currentModal = undefined;
        };

        service.getCurrentModal = function() {
            return currentModal;
        };

        service.open = function(options) {
            currentModal = $uibModal.open(options);
            currentModal.result['finally'](clearModal);
            return currentModal;
        };

        return service;
    }
})();
