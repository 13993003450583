(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('VoucherSaleConfirmationDetailController', VoucherSaleConfirmationDetailController);

    VoucherSaleConfirmationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'VoucherSaleConfirmation', 'VobeUser', 'VoucherSale'];

    function VoucherSaleConfirmationDetailController($scope, $rootScope, $stateParams, previousState, entity, VoucherSaleConfirmation, VobeUser, VoucherSale) {
        var vm = this;

        vm.voucherSaleConfirmation = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vobeApp:voucherSaleConfirmationUpdate', function(event, result) {
            vm.voucherSaleConfirmation = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
