(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PublicSiteTranslationPropertyDialogController', PublicSiteTranslationPropertyDialogController);

    PublicSiteTranslationPropertyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PublicSiteTranslationProperty', 'PublicSitePartRelationship', 'PublicSitePartKey', 'PublicSiteLanguage'];

    function PublicSiteTranslationPropertyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PublicSiteTranslationProperty, PublicSitePartRelationship, PublicSitePartKey, PublicSiteLanguage) {
        var vm = this;

        vm.publicSiteTranslationProperty = entity;
        vm.clear = clear;
        vm.save = save;
        vm.publicsitepartrelationships = PublicSitePartRelationship.query();
        vm.publicsitepartkeys = PublicSitePartKey.query();
        vm.publicsitelanguages = PublicSiteLanguage.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.publicSiteTranslationProperty.id !== null) {
                PublicSiteTranslationProperty.update(vm.publicSiteTranslationProperty, onSaveSuccess, onSaveError);
            } else {
                PublicSiteTranslationProperty.save(vm.publicSiteTranslationProperty, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('vobeApp:publicSiteTranslationPropertyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
