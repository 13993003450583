(function() {
    'use strict';


    angular
        .module('vobeApp')
        .controller('VoucherTilesController', VoucherTilesController);


    function VoucherTilesController($log) {
        'ngInject';
        var vm = this;

        vm.$onInit = function() {
            $log.info('$onInit VoucherTilesController');
            //vm.tiles = vm.viewLayout.activeBreakpoint.cards;
        };

    }
})();
