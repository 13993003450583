(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PublicSiteDetailController', PublicSiteDetailController);

    PublicSiteDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PublicSite'];

    function PublicSiteDetailController($scope, $rootScope, $stateParams, previousState, entity, PublicSite) {
        var vm = this;

        vm.publicSite = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vobeApp:publicSiteUpdate', function(event, result) {
            vm.publicSite = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
