(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('JhiLanguageService', JhiLanguageService);

    JhiLanguageService.$inject = ['$q', '$http', '$translate', 'LANGUAGES','translationStorageProvider'];

    function JhiLanguageService ($q, $http, $translate, LANGUAGES, translationStorageProvider) {
        var service = {
            getAll: getAll,
            getCurrent: getCurrent
        };

        return service;

        function getAll () {
            var deferred = $q.defer();
            deferred.resolve(LANGUAGES);
            return deferred.promise;
        }

        function getCurrent () {
            var deferred = $q.defer();
            //var language = $translate.storage().get('LANG_JhiLanguageService');
            var language = translationStorageProvider.get($translate.storageKey());
            deferred.resolve(language);

            return deferred.promise;
        }
    }
})();
