(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('CardCashCombinedController', CardCashCombinedController);

    function CardCashCombinedController(
        $scope,
        $rootScope,
        $stateParams,
        ShoppingCart,
        PromotionCodeService,
        CurrencyExchange
        /*,previousState, entity, Property, PropertyVouchers, User, $animate, $timeout, $document*/) {
        'ngInject';
        var vm = this;

        vm.$onInit = function(){

            //vm.voucherObject = vm.voucherObject || {};
            vm.voucherPoolObject = vm.voucherPoolObject || {};
            if(vm.voucherPoolObject.minValue<vm.voucherPoolObject.maxValue){
                vm.singleMode = false;
            }
            else if(vm.voucherPoolObject.type=='cash'){
                vm.singleMode = false;
            }
            else if(vm.voucherPoolObject.featured) {
                vm.singleMode = true;
            } else {
                vm.singleMode = (vm.voucherPoolObject && vm.voucherPoolObject.vouchers && getVoucherPoolLength() == 1);
            }



            vm.openCashModal = vm.openCashModal || angular.noop;
            vm.viewLayoutTileDesign =  vm.viewLayoutTileDesign  || 'default';
            vm.descriptionLines = vm.descriptionLines || 3;

            //vm.currentVoucher = vm.voucherObject;
            vm.currentVoucherPool = vm.voucherPoolObject;
            vm.firstVoucher = firstVoucher();
            vm.voucherObjectFirstCategory = getFirstApplicableCategory(vm.firstVoucher,'');


            vm.currentProperty = vm.currentProperty || {};
            vm.currentPropertyGroup = vm.currentPropertyGroup;
            var isGroup = typeof vm.currentPropertyGroup !== 'undefined';
            vm.isGroup = isGroup;
            vm.voucherProperty = getVoucherProperty();

            vm.giftCard = vm.voucherPoolObject.giftCard || undefined;
            vm.getGiftCardImageFileName = getGiftCardImageFileName;
            vm.getCategoryImageFileName = getCategoryImageFileName;
            vm.getImageFileName = getImageFileName;
            vm.revealedDescription = false;
            vm.toggleDescription = toggleDescription;

            //recheck applied promotion code in case we have 2 or more promotion codes
            vm.appliedPromotionCode = PromotionCodeService.findApplicablePromo(vm.shoppingCart, vm.currentProperty, isGroup, vm.firstVoucher);

            //currency
            vm.baseCurrency = {
                code : vm.firstVoucher.currency
            };

            vm.currentCurrency = {
                code : vm.baseCurrency.code
            }
            var currentCurrency = CurrencyExchange.currencies.current;//current currency might be NULL if this is group engine
            if(currentCurrency && currentCurrency!=null){
                vm.currentCurrency.code = currentCurrency.code;
            }

            $rootScope.$on('CurrencyExchangeProvider.changeCurrency', function(event, args) {
                vm.currentCurrency.code = args.code;
            });

        }

        function firstVoucher(){
            var firstVoucher;
            angular.forEach(vm.currentVoucherPool.vouchers,function(voucher,vkey){
                firstVoucher = firstVoucher || voucher;
            });
            return firstVoucher;
        }

        function getVoucherProperty(){
            if(!vm.firstVoucher){
                return undefined;
            }
            if(!vm.currentPropertyGroup || !vm.currentPropertyGroup.hotels){
                return undefined;
            }
            var property = undefined;
            angular.forEach(vm.currentPropertyGroup.hotels,function(hotel,hkey){
                if(hotel.hotelId==vm.firstVoucher.hotelId){
                    property = hotel;
                }
            });
            return property;
        }

        function getVoucherPoolLength(){

            var count = 0;
            angular.forEach( vm.voucherPoolObject.vouchers,function(voucher,vkey){
                count++;
            });
            return count;
        }


        //when we change the variation
        vm.onVoucherObjectChanged = onVoucherObjectChanged;
        function onVoucherObjectChanged(voucherObject){
            //recheck applied promotion code in case we have 2 or more promotion codes
            vm.appliedPromotionCode = PromotionCodeService.findApplicablePromo(vm.shoppingCart, vm.currentProperty, vm.isGroup, vm.voucherObject);

        }

        function getCategoryImageFileName(category){
            if(category.category.imageUrl){
                return useImgix(category.category.imageUrl);
            }
            return "//in1-image-library.imgix.net/no-image.jpg";
        }

        function getGiftCardImageFileName(){
            return getImageFileName();
        }

        function getImageFileName(){
            var giftCard = vm.giftCard;
            if(giftCard && giftCard.imageUrl && giftCard.imageUrl!=null && giftCard.imageUrl.trim().length>0){
                return useImgix( giftCard.imageUrl);
            }
            if (vm.firstVoucher && vm.firstVoucher.images && vm.firstVoucher.images!=null && vm.firstVoucher.images[7]) {
                return useImgix(vm.firstVoucher.images[7]);
            } else {
                return "//in1-image-library.imgix.net/no-image.jpg";
            }
        }

        function getFirstApplicableCategory(voucher,voucherClassActive){
            var category;
            angular.forEach(voucher.categories,function(c,cKey){
                if(!category){
                    category = c;
                }
                if(''+voucherClassActive==''+c.classId){
                    category = c;
                }
            });
            return category;
        }

        function toggleDescription($event){
            $event.preventDefault();
            $event.stopPropagation();
            vm.revealedDescription = !vm.revealedDescription;
            return false;
        }

        function useImgix(imgUrl){
            var old_host = 'dddo4e1h8fdy4.cloudfront.net';
            var new_host = 'in1-image-library.imgix.net';
            return imgUrl.replace(old_host, new_host);
        }


    }
})();
