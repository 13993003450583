(function () {
    'use strict';
    angular
        .module('currencyExchangeModule')
        .constant('XE_CURRENCIES', {
            'AED': [2, 'dh', '\u062f.\u0625.', 'DH'],
            'AUD': [2, '$', 'AU$'],
            'BDT': [2, '\u09F3', 'Tk'],
            'BRL': [2, 'R$', 'R$'],
            'CAD': [2, '$', 'C$'],
            'CHF': [2, 'CHF', 'CHF'],
            'CLP': [0, '$', 'CL$'],
            'CNY': [2, '¥', 'RMB¥'],
            'COP': [0, '$', 'COL$'],
            'CRC': [0, '\u20a1', 'CR\u20a1'],
            'CZK': [2, 'Kč', 'Kč'],
            'DKK': [2, 'kr', 'kr'],
            'DOP': [2, '$', 'RD$'],
            'EGP': [2, '£', 'LE'],
            'EUR': [2, '€', '€'],
            'GBP': [2, '£', 'GB£'],
            'HKD': [2, '$', 'HK$'],
            'ILS': [2, '\u20AA', 'IL\u20AA'],
            'INR': [2, '\u20B9', 'Rs'],
            'ISK': [0, 'kr', 'kr'],
            'JMD': [2, '$', 'JA$'],
            'JPY': [0, '¥', 'JP¥'],
            'KRW': [0, '\u20A9', 'KR?'],
            'LKR': [2, 'Rs', 'SLRs'],
            'MNT': [0, '\u20AE', 'MN?'],
            'MXN': [2, '$', 'Mex$'],
            'MYR': [2, 'RM', 'RM'],
            'NOK': [2, 'kr', 'NOkr'],
            'PAB': [2, 'B/.', 'B/.'],
            'PEN': [2, 'S/.', 'S/.'],
            'PHP': [2, '\u20B1', 'Php'],
            'PKR': [0, 'Rs', 'PKRs.'],
            'RUB': [0, '₽', '₽'],
            'SAR': [2, 'Rial', 'Rial'],
            'SEK': [2, 'kr', 'kr'],
            'SGD': [2, '$', 'S$'],
            'THB': [2, '\u0e3f', 'THB'],
            'TRY': [2, 'TL', 'YTL'],
            'TWD': [2, 'NT$', 'NT$'],
            'USD': [2, '$', 'US$'],
            'VND': [0, '\u20AB', 'VN\u20AB'],
            'YER': [0, 'Rial', 'Rial'],
            'ZAR': [2, 'R', 'ZAR'],
            'AFN': [16, 'Af.', 'AFN'],
            'ALL': [0, 'Lek', 'Lek'],
            'AMD': [0, 'Dram', 'dram'],
            'AOA': [2, 'Kz', 'Kz'],
            'ARS': [2, '$', 'AR$'],
            'AWG': [2, 'Afl.', 'Afl.'],
            'AZN': [2, 'man.', 'man.'],
            'BAM': [18, 'KM', 'KM'],
            'BBD': [2, '$', 'Bds$'],
            'BGN': [2, 'lev', 'lev'],
            'BHD': [3, 'din', 'din'],
            'BIF': [0, 'FBu', 'FBu'],
            'BMD': [2, '$', 'BD$'],
            'BND': [2, '$', 'B$'],
            'BOB': [2, 'Bs', 'Bs'],
            'BSD': [2, '$', 'BS$'],
            'BTN': [2, 'Nu.', 'Nu.'],
            'BWP': [2, 'P', 'pula'],
            'BYR': [0, 'BYR', 'BYR'],
            'BZD': [2, '$', 'BZ$'],
            'CDF': [2, 'FrCD', 'CDF'],
            'CUC': [1, '$', 'CUC$'],
            'CUP': [2, '$', 'CU$'],
            'CVE': [2, 'CVE', 'Esc'],
            'DJF': [0, 'Fdj', 'Fdj'],
            'DZD': [2, 'din', 'din'],
            'ERN': [2, 'Nfk', 'Nfk'],
            'ETB': [2, 'Birr', 'Birr'],
            'FJD': [2, '$', 'FJ$'],
            'FKP': [2, '£', 'FK£'],
            'GEL': [2, 'GEL', 'GEL'],
            'GHS': [2, 'GHS', 'GHS'],
            'GIP': [2, '£', 'GI£'],
            'GMD': [2, 'GMD', 'GMD'],
            'GNF': [0, 'FG', 'FG'],
            'GTQ': [2, 'Q', 'GTQ'],
            'GYD': [0, '$', 'GY$'],
            'HNL': [2, 'L', 'HNL'],
            'HRK': [2, 'kn', 'kn'],
            'HTG': [2, 'HTG', 'HTG'],
            'HUF': [0, 'Ft', 'Ft'],
            'IDR': [0, 'Rp', 'Rp'],
            'IQD': [0, 'din', 'IQD'],
            'IRR': [0, 'Rial', 'IRR'],
            'JOD': [3, 'din', 'JOD'],
            'KES': [2, 'Ksh', 'Ksh'],
            'KGS': [2, 'KGS', 'KGS'],
            'KHR': [2, 'Riel', 'KHR'],
            'KMF': [0, 'CF', 'KMF'],
            'KPW': [0, '\u20A9KP', 'KPW'],
            'KWD': [3, 'din', 'KWD'],
            'KYD': [2, '$', 'KY$'],
            'KZT': [2, '\u20B8', 'KZT'],
            'LAK': [0, '\u20AD', '\u20AD'],
            'LBP': [0, 'L£', 'LBP'],
            'LRD': [2, '$', 'L$'],
            'LSL': [2, 'LSL', 'LSL'],
            'LTL': [2, 'Lt', 'Lt'],
            'LVL': [2, 'Ls', 'Ls'],
            'LYD': [3, 'din', 'LD'],
            'MAD': [2, 'dh', 'MAD'],
            'MDL': [2, 'MDL', 'MDL'],
            'MGA': [0, 'Ar', 'MGA'],
            'MKD': [2, 'din', 'MKD'],
            'MMK': [0, 'K', 'MMK'],
            'MOP': [2, 'MOP', 'MOP$'],
            'MRO': [0, 'MRO', 'MRO'],
            'MUR': [0, 'MURs', 'MURs'],
            'MWK': [2, 'MWK', 'MWK'],
            'MZN': [2, 'MTn', 'MTn'],
            'NAD': [2, '$', 'N$'],
            'NGN': [2, '\u20A6', 'NG\u20A6'],
            'NIO': [2, 'C$', 'C$'],
            'NPR': [2, 'Rs', 'NPRs'],
            'NZD': [2, '$', 'NZ$'],
            'OMR': [3, 'Rial', 'OMR'],
            'PGK': [2, 'PGK', 'PGK'],
            'PLN': [2, 'z\u0142', 'z\u0142'],
            'PYG': [0, 'Gs', 'PYG'],
            'QAR': [2, 'Rial', 'QR'],
            'RON': [2, 'RON', 'RON'],
            'RSD': [0, 'din', 'RSD'],
            'RWF': [0, 'RF', 'RF'],
            'SBD': [2, '$', 'SI$'],
            'SCR': [2, 'SCR', 'SCR'],
            'SDG': [2, 'SDG', 'SDG'],
            'SHP': [2, '£', 'SH£'],
            'SLL': [0, 'SLL', 'SLL'],
            'SOS': [0, 'SOS', 'SOS'],
            'SRD': [2, '$', 'SR$'],
            'STD': [0, 'Db', 'Db'],
            'SYP': [16, '£', 'SY£'],
            'SZL': [2, 'SZL', 'SZL'],
            'TJS': [2, 'Som', 'TJS'],
            'TND': [3, 'din', 'DT'],
            'TOP': [2, 'T$', 'T$'],
            'TTD': [2, '$', 'TT$'],
            'TZS': [0, 'TSh', 'TSh'],
            'UAH': [2, '\u20B4', 'UAH'],
            'UGX': [0, 'UGX', 'UGX'],
            'UYU': [1, '$', '$U'],
            'UZS': [0, 'so\u02bcm', 'UZS'],
            'VEF': [2, 'Bs', 'Bs'],
            'VUV': [0, 'VUV', 'VUV'],
            'WST': [2, 'WST', 'WST'],
            'XAF': [0, 'FCFA', 'FCFA'],
            'XCD': [2, '$', 'EC$'],
            'XOF': [0, 'CFA', 'CFA'],
            'XPF': [0, 'FCFP', 'FCFP'],
            'ZMK': [0, 'ZMK', 'ZMK']
        });


})();
