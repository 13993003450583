(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('ShoppingCartEmptyController', ShoppingCartEmptyController);


	function ShoppingCartEmptyController($scope, $rootScope, $state, $stateParams, previousState, $anchorScroll, shoppingCart, shoppingCartConfigs, DetermineShopMoreState, currentProperty) {
	    'ngInject';
	    var vm = this;

	    vm.shoppingCart = shoppingCart;
	    vm.property=currentProperty;
	    vm.browseMore = browseMore;


	    function browseMore(event){
	    	event.preventDefault();
	    	var shopMoreState = DetermineShopMoreState.getShopMoreState();
	    	console.log('shopMoreState.name', shopMoreState.name)
	    	$state.go(shopMoreState.name, shopMoreState.params);
	    }

	}
})();
