(function() {
    'use strict';

    angular.module('vobeApp').component('cartItemVoucher', {
        templateUrl: 'app/components/shopping-cart/item-details/shopping-cart-item-voucher.html',
        controller: 'CartItemVoucherController',
        controllerAs: 'itemVM',
        bindings: {
            shoppingCart: '<',
            shoppingCartConfigs: '<',
            emailConfig: '<',
            cartItem: '<',
            cartItemIndex: '<',
            cartItemFirst: '<',
            isBulk: '<',
            checkoutForm: '<',
            checkoutFormInvalid: '<',
            channel: '<',
            property: '<',
            currentLanguage: '<',
            propertyGroup: '<',
        }
    });
})();
