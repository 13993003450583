(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('FatalController', FatalController);

    FatalController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'DetermineShopMoreState'];
	
	function FatalController($scope, $rootScope, $state, $stateParams,  DetermineShopMoreState) {
	    var vm = this;
	    vm.browseMore = browseMore;

	    
	    function browseMore(event){
	    	event.preventDefault();
	    	var shopMoreState = DetermineShopMoreState.getShopMoreState();
	    	$state.go(shopMoreState.name, shopMoreState.params);
	    };
	    
	}
})();
