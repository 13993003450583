(function() {
    'use strict';

    function CategoryFilterController($log, $timeout) {
        'ngInject';
        var ctrl = this;

        //for the moment $log prints in production profile, so overwriting with console
        var $log = console;

        ctrl.$onInit = function() {
            $log.info('$onInit FilterBarController');
            //used for voucherIds count
            ctrl.loadingData = false;
            ctrl.categoriesOpen = false;
            ctrl.voucherFilterArray = [];
            ctrl.categoriesSelected = ctrl.categoriesSelected || {};
            ctrl.syncViewData();
            ctrl.categoryChanged();
        };

        ctrl.syncViewData = function(){
            ctrl.selectedCategoriesApplied = (ctrl.voucherClassActive ? ctrl.voucherClassActive.split(',') : [])
                .filter(function(value, index, arr){
                return value!='all';
            });
            angular.forEach(ctrl.selectedCategoriesApplied,function (friendlyName, i) {
                ctrl.categoriesSelected[friendlyName] = true;
            });
        }

        ctrl.countSelected = function(){
            if(typeof ctrl.categoriesSelected === 'undefined' || ctrl.categoriesSelected==null){
                return 0;
            }
            var counter = 0;
            for (var selectedFriendlyNameKey in ctrl.categoriesSelected) {
                var selectedValue = ctrl.categoriesSelected[selectedFriendlyNameKey];

                if (selectedValue) {
                    counter++;
                }
            }
            return counter;
        }

        ctrl.toggled = function(open) {
            $log.info('toggled', open);
            ctrl.syncViewData();
            //when closing, run through the selected and applied categories and remove the checkboxes as needed.
            if (!open) {
                for (var selectedFriendlyNameKey in ctrl.categoriesSelected) {
                    var selectedValue = ctrl.categoriesSelected[selectedFriendlyNameKey];

                    if (!ctrl.selectedCategoriesApplied.includes(selectedFriendlyNameKey)) {
                        ctrl.categoriesSelected[selectedFriendlyNameKey] = false;
                    }
                }
            }
        };

        ctrl.categoryChanged = function() {
            $log.info('categoryChanged', ctrl.categoriesSelected);
            var newFilterArray = [];

            angular.forEach(ctrl.voucherClassCounter, function(vc, index) {
                //has category object
                if (vc.category) {
                    //$log.info('category selected', vc);
                    //iterate over keys selected or unselected..
                    for (var selectedFriendlyNameKey in ctrl.categoriesSelected) {
                        var selectedValue = ctrl.categoriesSelected[selectedFriendlyNameKey];

                        //check if selected then add to new array
                        if (selectedFriendlyNameKey === vc.category.friendlyName && selectedValue) {
                            angular.forEach(vc.category.voucherIds, function(vid) {
                                if(!newFilterArray.includes(vid)){
                                    newFilterArray.push(vid);
                                }
                            });
                        }
                    }
                }
            });

            $log.info('newFilterArray', newFilterArray);
            //commenting out as ES6 syntax breaks production build with no error (gulp has --no-notifications oprion ON)
            //ctrl.voucherFilterArray = [...new Set(newFilterArray)]; //todo: does this work everywhere
            ctrl.voucherFilterArray = newFilterArray;
        };

        ctrl.clear = function() {
            //clears all category filters
            ctrl.voucherFilterArray = [];
            ctrl.categoriesSelected = {};
            ctrl.selectedCategoriesApplied =[];
            //notifyChange(ctrl.selectedCategoriesApplied);
        };

        ctrl.filterVouchers = function() {
            var selectedCategories = [];
            for (var key in ctrl.categoriesSelected) {
                if (ctrl.categoriesSelected[key]) {
                    selectedCategories.push(key);
                }
            }
            $log.info('filterVouchers', selectedCategories);
            ctrl.selectedCategoriesApplied = selectedCategories;
            notifyChange(selectedCategories);

        };

        /**
         * PRIVATE FUNCTIONS
         */
        function notifyChange(selectedCategories){
            //notify others about new categories and close after
            ctrl.loadingData = true;
            var promise = ctrl.onUpdatePromise({ selectedCategories: selectedCategories });
            var timeoutPromise = $timeout(1000*10);//10 seconds timeout
            timeoutPromise.then(function(){
                //try to resolve the promise after 10sec just in case
                promise.resolve();
            });
            promise.promise.then(function(){
                ctrl.loadingData = false;//stop spinner
                ctrl.categoriesOpen = false; //close dropdown
            });
        }
    }



    angular.module('vobeApp').component('categoryFilter', {
        templateUrl: 'app/components/vouchers/categoryFilter/categoryFilter.html',
        controller: CategoryFilterController,
        bindings: {
            voucherClassCounter: '<',
            voucherClassActive: '<',
            sortVoucherClass: '<',
            onUpdatePromise: '&',
        },
    });
})();
