(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('vobeApp')
        .constant('VERSION', "2.0.0")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('ENABLE_ROLLBAR', true)
        .constant('ENV', "prod")
        .constant('BUILD_LANGUAGES', [
	"ar",
	"en",
	"en-gb",
	"en-ie",
	"es",
	"de",
	"fr",
	"he",
	"it",
	"iw",
	"ja",
	"ko",
	"nl",
	"pl",
	"ru",
	"zh",
	"zh-cn",
	"pt",
	"hr"
])
;
})();
