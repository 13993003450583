(function() {
    'use strict';

    angular
        .module('vobeApp')
        .factory('GoogleAdwords', GoogleAdwords);

    GoogleAdwords.$inject = ['$resource'];

    function GoogleAdwords ($resource) {
        var service = $resource('api/property/:hotelCode/adwords', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: false,
                ignoreLoadingBar: true,
                transformResponse: function (data) {
                    if(!data || (typeof data === 'string' && data.length==0)) {
                        return {};
                    } else {
                        data = angular.fromJson(data);
                        return data;
                    }
                }
            }
        });

        return service;
    }

    angular
        .module('vobeApp')
        .factory('GoogleAdwordsService', GoogleAdwordsService);

    GoogleAdwordsService.$inject = ['$q','GoogleAdwords', '$window', '$rootScope'];

    function GoogleAdwordsService($q,GoogleAdwords, $window, $rootScope) {

        var dataPromise;

        var initialized = false,
            account = {
                google_conversion_id: '',
                google_conversion_language: '',
                google_conversion_format: '',
                google_conversion_color: '',
                google_conversion_label: '',
                google_conversion_value: 0,
                google_remarketing_only: false,
                google_conversion_currency: 'EUR'
            };

        var service = {
            getCode : getCode,
            isInitialized : isInitialized,
            setInitialized : setInitialized,
            registerScript: registerScript,
            googleTrackConversion: googleTrackConversion,
            setAccount: setAccount
        };


        return service;

        function getCode(options) {
            if (angular.isUndefined(dataPromise)) {
                dataPromise = GoogleAdwords.get(options).$promise;
            }
            return dataPromise;
        }

        function isInitialized() {
            return initialized;
        }
        function setInitialized(boolean) {
            initialized = boolean;
        }

        function setAccount(adwordsAccount) {
            account.google_conversion_color = adwordsAccount.conversionColour;
            account.google_conversion_format = adwordsAccount.conversionFormat;
            account.google_conversion_id = adwordsAccount.conversionId;
            account.google_conversion_label = adwordsAccount.conversionLabel;
            account.google_conversion_language = adwordsAccount.conversionLanguage;
        }

        function registerScript() {
            (function (w, d, s) {
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s);
                j.src = '//www.googleadservices.com/pagead/conversion_async.js';
                f.parentNode.insertBefore(j, f);
            })($window, document, 'script');
        }

        function googleTrackConversion(value, currency, orderId) {
            if (this.isInitialized()) {
                account.google_conversion_value = value;
                account.google_conversion_currency = currency
                account.google_conversion_order_id = orderId;
                $window.google_trackConversion(account);
            }
        };
    }

})();
