(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PublicSiteDeleteController',PublicSiteDeleteController);

    PublicSiteDeleteController.$inject = ['$uibModalInstance', 'entity', 'PublicSite'];

    function PublicSiteDeleteController($uibModalInstance, entity, PublicSite) {
        var vm = this;

        vm.publicSite = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PublicSite.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
