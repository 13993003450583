(function() {
    'use strict';

    angular.module('vobeApp').config(translationConfig);

    translationConfig.$inject = ['$translateProvider', 'tmhDynamicLocaleProvider', 'LANGUAGES', 'LANGUAGES_MAPPINGS'];

    function translationConfig($translateProvider, tmhDynamicLocaleProvider, LANGUAGES, LANGUAGES_MAPPINGS) {
        // Initialize angular-translate

        //must be the same linke in JAVA config: LocaleConfiguration.java
        var STORAGE_KEY = 'NG_TRANSLATE_LANG_KEY';

        $translateProvider.storageKey(STORAGE_KEY);
        $translateProvider.useStorage('translationStorageProvider');

        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: 'api/i18n/{lang}/{part}.json',
            loadFailureHandler: 'translationPartialLoaderErrorHandler',
        });

        //var prefferedLanguage = $translateProvider.resolveClientLocale() || 'en';
        //prefferedLanguage =  (prefferedLanguage || '').split('-').join('_');
        //console.log("preferred language: ",prefferedLanguage);

        //https://angular-translate.github.io/docs/#/guide/09_language-negotiation
        $translateProvider.registerAvailableLanguageKeys(LANGUAGES, LANGUAGES_MAPPINGS);

        //NOTE:
        // 1. the preferredLanguage will be set cookie from the server if "&language=ZYX" is present
        // 2. preferred language will come from pre-existing cookie
        // 3. the preferred language will come from the browser
        //$translateProvider.preferredLanguage(prefferedLanguage);//not needed thanks to LANGUAGES_MAPPINGS
        //$translateProvider.fallbackLanguage('en');//not needed thanks to LANGUAGES_MAPPINGS
        $translateProvider.determinePreferredLanguage();

        $translateProvider.useSanitizeValueStrategy('escaped');
        //$translateProvider.useMessageFormatInterpolation();
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');
        // $translateProvider.useMessageFormatInterpolation();

        tmhDynamicLocaleProvider.storageKey(STORAGE_KEY);
        tmhDynamicLocaleProvider.defaultLocale($translateProvider.resolveClientLocale());
        tmhDynamicLocaleProvider.localeLocationPattern('i18n/angular-locale_{{locale}}.js');
        tmhDynamicLocaleProvider.useCookieStorage();
    }
})();
