(function() {
    'use strict';

    angular
        .module('vobeApp')
        .constant('IN1_CONSENT',{
            //generic strings
            COOKIE_NAME: "in1_vobe_consent",
            GRANTED: "granted",
            DENIED: "denied",
            MODIFIED: "modified",
            //consent category names
            ALL : 'in1_consent_granted_all',
            ANALYTICS : 'in1_consent_analytics',//ie. GA
            ADVERTISING : 'in1_consent_advertising',//ie. profiling and re-marketing by google ads
            PERFORMANCE : 'in1_consent_performance',//ie. posthog
            ENHANCEMENT : 'in1_consent_enhancement',//ie. posthog
            FUNCTIONAL : 'in1_consent_functional',//ie. language preferences
            TRACKING : 'in1_consent_tracking',//ie. conversion tracking
            STRICTLY_NECESSARY : 'in1_consent_strictly_necessary',

            //internal vobe events
            EVENT_GRANTED_ALL : 'in1_consent_granted_all',
            EVENT_GRANTED_ANALYTICS : 'in1_consent_granted_analytics',//ie. GA
            EVENT_GRANTED_ADVERTISING : 'in1_consent_granted_advertising',
            EVENT_GRANTED_PERFORMANCE : 'in1_consent_granted_performance',//ie. posthog
            EVENT_GRANTED_ENHANCEMENT : 'in1_consent_granted_enhancement',//ie. triptease?
            EVENT_GRANTED_FUNCTIONAL : 'in1_consent_granted_functional',//ie. language preferences
            EVENT_GRANTED_TRACKING : 'in1_consent_granted_tracking',//ie. profiling and re-marketing by google ads
            EVENT_GRANTED_STRICTLY_NECESSARY : 'in1_consent_granted_strictly_necessary',
            EVENT_DENIED_ALL : 'in1_consent_denied_all',
            EVENT_DENIED_ANALYTICS : 'in1_consent_denied_analytics',//ie. GA
            EVENT_DENIED_ADVERTISING : 'in1_consent_denied_advertising',
            EVENT_DENIED_PERFORMANCE : 'in1_consent_denied_performance',//ie. posthog
            EVENT_DENIED_ENHANCEMENT : 'in1_consent_denied_enhancement',//ie. triptease?
            EVENT_DENIED_FUNCTIONAL : 'in1_consent_denied_functional',//ie. language preferences
            EVENT_DENIED_TRACKING : 'in1_consent_denied_taracking',//ie. profiling and re-marketing by google ads
            EVENT_DENIED_STRICTLY_NECESSARY : 'in1_consent_denied_strictly_necessary',

            //Events sent to GTM
            GTM_EVENT_GRANTED_ALL : 'in1_consent_granted_all',
            GTM_EVENT_GRANTED_ANALYTICS : 'in1_consent_granted_analytics',//ie. GA
            GTM_EVENT_GRANTED_ADVERTISING : 'in1_consent_granted_advertising',
            GTM_EVENT_GRANTED_PERFORMANCE : 'in1_consent_granted_performance',//ie. posthog
            GTM_EVENT_GRANTED_ENHANCEMENT : 'in1_consent_granted_enhancement',//ie. triptease?
            GTM_EVENT_GRANTED_FUNCTIONAL : 'in1_consent_granted_functional',//ie. language preferences
            GTM_EVENT_GRANTED_TRACKING : 'in1_consent_granted_tracking',//ie. profiling and re-marketing by google ads
            GTM_EVENT_GRANTED_STRICTLY_NECESSARY : 'in1_consent_granted_strictly_necessary',
            GTM_EVENT_DENIED_ALL : 'in1_consent_denied_all',
            GTM_EVENT_DENIED_ANALYTICS : 'in1_consent_denied_analytics',//ie. GA
            GTM_EVENT_DENIED_ADVERTISING : 'in1_consent_denied_advertising',
            GTM_EVENT_DENIED_PERFORMANCE : 'in1_consent_denied_performance',//ie. posthog
            GTM_EVENT_DENIED_ENHANCEMENT : 'in1_consent_denied_enhancement',//ie. triptease?
            GTM_EVENT_DENIED_FUNCTIONAL : 'in1_consent_denied_functional',//ie. language preferences
            GTM_EVENT_DENIED_TRACKING : 'in1_consent_denied_tracking',//ie. profiling and re-marketing by google ads
            GTM_EVENT_DENIED_STRICTLY_NECESSARY : 'in1_consent_denied_strictly_necessary',
        });

    angular
        .module('vobeApp')
        .factory('CookiesConsentStorageService', CookiesConsentStorageService);

    //CookiesConsentStorageService.$inject = ['$cookies', '$log', '$window', 'IN1_CONSENT', '$rootScope'];

    function CookiesConsentStorageService($cookies, $log, $window, IN1_CONSENT, $rootScope, Analytics) {
        'ngInject';
        return {
            get: get,

            consentGrantedAll: consentGrantedAll,
            consentGrantedAnalytics: consentGrantedAnalytics,
            consentGrantedAdvertising: consentGrantedAdvertising,
            consentGrantedPerformance: consentGrantedPerformance,
            consentGrantedEnhancement: consentGrantedEnhancement,
            consentGrantedFunctional: consentGrantedFunctional,
            consentGrantedTracking: consentGrantedTracking,
            consentGrantedStrictlyNecessary: consentGrantedStrictlyNecessary,

            consentDeniedAll: consentDeniedAll,
            consentDeniedAnalytics: consentDeniedAnalytics,
            consentDeniedAdvertising: consentDeniedAdvertising,
            consentDeniedPerformance: consentDeniedPerformance,
            consentDeniedEnhancement: consentDeniedEnhancement,
            consentDeniedFunctional: consentDeniedFunctional,
            consentDeniedTracking: consentDeniedTracking,
            //consentDeniedStrictlyNecessary: consentDeniedStrictlyNecessary,

            processDefaultConsent: processDefaultConsent
        };


        function init() {
            //we need to record consent, so initial values are not determined
            var consent = {};
            consent[IN1_CONSENT.ALL] = undefined;
            consent[IN1_CONSENT.ANALYTICS] = undefined;
            consent[IN1_CONSENT.ADVERTISING] = undefined;
            consent[IN1_CONSENT.PERFORMANCE] = undefined;
            consent[IN1_CONSENT.FUNCTIONAL] = undefined;
            consent[IN1_CONSENT.ENHANCEMENT] = undefined;
            consent[IN1_CONSENT.TRACKING] = undefined;
            consent[IN1_CONSENT.STRICTLY_NECESSARY] = undefined;
            return consent;
        }

        function get() {
            var consent;
            try {
                consent = $cookies.getObject(IN1_CONSENT.COOKIE_NAME);
            } catch (e){
                $log.error("Cannot get cookie "+IN1_CONSENT.COOKIE_NAME);
            }
            $log.info("getting cookie "+IN1_CONSENT.COOKIE_NAME+", value: ",consent);
            if (!consent) {
                consent = init();
                put(consent, true);
            }
            return consent;
        }

        function put(value, initial) {
            value[IN1_CONSENT.MODIFIED] = initial ? false : true;
            $log.info("putting cookie "+IN1_CONSENT.COOKIE_NAME+": ",value);
            try {
                $cookies.putObject(IN1_CONSENT.COOKIE_NAME, value);
            } catch (e){
                $log.error("Cannot put cookie "+IN1_CONSENT.COOKIE_NAME);
            }

        }

        /*HANDLE GRANT EVENTS*/
        function consentGrantedAll() {
            var consent = get();
            consent[IN1_CONSENT.ALL] = IN1_CONSENT.GRANTED;
            consent[IN1_CONSENT.ANALYTICS] = IN1_CONSENT.GRANTED;
            consent[IN1_CONSENT.ADVERTISING] = IN1_CONSENT.GRANTED;
            consent[IN1_CONSENT.PERFORMANCE] = IN1_CONSENT.GRANTED;
            consent[IN1_CONSENT.FUNCTIONAL] = IN1_CONSENT.GRANTED;
            consent[IN1_CONSENT.ENHANCEMENT] = IN1_CONSENT.GRANTED;
            consent[IN1_CONSENT.TRACKING] = IN1_CONSENT.GRANTED;
            consent[IN1_CONSENT.STRICTLY_NECESSARY] = IN1_CONSENT.GRANTED;

            put(consent);//save

            $rootScope.$broadcast(IN1_CONSENT.EVENT_GRANTED_ALL);// $broadcast() sends an even downwards from parent to child controllers
            $rootScope.$broadcast(IN1_CONSENT.EVENT_GRANTED_ADVERTISING);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_GRANTED_ANALYTICS);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_GRANTED_PERFORMANCE);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_GRANTED_FUNCTIONAL);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_GRANTED_ENHANCEMENT);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_GRANTED_TRACKING);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_GRANTED_STRICTLY_NECESSARY);

            Analytics.trackEvent('consent', IN1_CONSENT.ALL, IN1_CONSENT.GRANTED);

        };

        function consentGrantedAnalytics() {
            var consent = get();
            consent[IN1_CONSENT.ALL] = undefined;
            consent[IN1_CONSENT.ANALYTICS] = IN1_CONSENT.GRANTED;
            put(consent);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_GRANTED_ANALYTICS);

            Analytics.trackEvent('consent', IN1_CONSENT.ANALYTICS, IN1_CONSENT.GRANTED);
        };

        function consentGrantedAdvertising() {
            var consent = get();
            consent[IN1_CONSENT.ALL] = undefined;
            consent[IN1_CONSENT.ADVERTISING] = IN1_CONSENT.GRANTED;
            put(consent);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_GRANTED_ADVERTISING);

            Analytics.trackEvent('consent', IN1_CONSENT.ADVERTISING, IN1_CONSENT.GRANTED);
        };

        function consentGrantedPerformance() {
            var consent = get();
            consent[IN1_CONSENT.ALL] = undefined;
            consent[IN1_CONSENT.PERFORMANCE] = IN1_CONSENT.GRANTED;
            put(consent);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_GRANTED_PERFORMANCE);

            Analytics.trackEvent('consent', IN1_CONSENT.PERFORMANCE, IN1_CONSENT.GRANTED);
        };

        function consentGrantedEnhancement() {
            var consent = get();
            consent[IN1_CONSENT.ALL] = undefined;
            consent[IN1_CONSENT.ENHANCEMENT] = IN1_CONSENT.GRANTED;
            put(consent);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_GRANTED_ENHANCEMENT);

            Analytics.trackEvent('consent', IN1_CONSENT.ENHANCEMENT, IN1_CONSENT.GRANTED);
        };

        function consentGrantedFunctional() {
            var consent = get();
            consent[IN1_CONSENT.ALL] = undefined;
            consent[IN1_CONSENT.FUNCTIONAL] = IN1_CONSENT.GRANTED;
            put(consent);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_GRANTED_FUNCTIONAL);

            Analytics.trackEvent('consent', IN1_CONSENT.FUNCTIONAL, IN1_CONSENT.GRANTED);
        };

        function consentGrantedTracking() {
            var consent = get();
            consent[IN1_CONSENT.ALL] = undefined;
            consent[IN1_CONSENT.TRACKING] = IN1_CONSENT.GRANTED;
            put(consent);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_GRANTED_TRACKING);

            Analytics.trackEvent('consent', IN1_CONSENT.TRACKING, IN1_CONSENT.GRANTED);
        };

        function consentGrantedStrictlyNecessary() {
            var consent = get();
            consent[IN1_CONSENT.ALL] = undefined;
            consent[IN1_CONSENT.STRICTLY_NECESSARY] = IN1_CONSENT.GRANTED;
            put(consent);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_GRANTED_STRICTLY_NECESSARY);

            Analytics.trackEvent('consent', IN1_CONSENT.STRICTLY_NECESSARY, IN1_CONSENT.GRANTED);
        };

        /*HANDLE DENIED EVENTS*/
        function consentDeniedAll() {
            var consent = get();
            consent[IN1_CONSENT.ALL] = IN1_CONSENT.DENIED;
            consent[IN1_CONSENT.ANALYTICS] = IN1_CONSENT.DENIED;
            consent[IN1_CONSENT.ADVERTISING] = IN1_CONSENT.DENIED;
            consent[IN1_CONSENT.PERFORMANCE] = IN1_CONSENT.DENIED;
            consent[IN1_CONSENT.FUNCTIONAL] = IN1_CONSENT.DENIED;
            consent[IN1_CONSENT.ENHANCEMENT] = IN1_CONSENT.DENIED;
            consent[IN1_CONSENT.TRACKING] = IN1_CONSENT.DENIED;
            consent[IN1_CONSENT.STRICTLY_NECESSARY] = IN1_CONSENT.DENIED;
            put(consent);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_DENIED_ALL);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_DENIED_ADVERTISING);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_DENIED_ANALYTICS);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_DENIED_PERFORMANCE);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_DENIED_FUNCTIONAL);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_DENIED_ENHANCEMENT);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_DENIED_TRACKING);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_DENIED_STRICTLY_NECESSARY);

            Analytics.trackEvent('consent', IN1_CONSENT.ALL, IN1_CONSENT.DENIED);
        };

        function consentDeniedAnalytics() {
            var consent = get();
            consent[IN1_CONSENT.ALL] = undefined;
            consent[IN1_CONSENT.ANALYTICS] = IN1_CONSENT.DENIED;
            put(consent);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_DENIED_ANALYTICS);

            Analytics.trackEvent('consent', IN1_CONSENT.ANALYTICS, IN1_CONSENT.DENIED);
        };

        function consentDeniedAdvertising() {
            var consent = get();
            consent[IN1_CONSENT.ALL] = undefined;
            consent[IN1_CONSENT.ADVERTISING] = IN1_CONSENT.DENIED;
            put(consent);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_DENIED_ADVERTISING);

            Analytics.trackEvent('consent', IN1_CONSENT.ADVERTISING, IN1_CONSENT.DENIED);
        };

        function consentDeniedPerformance() {
            var consent = get();
            consent[IN1_CONSENT.ALL] = undefined;
            consent[IN1_CONSENT.PERFORMANCE] = IN1_CONSENT.DENIED;
            put(consent);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_DENIED_PERFORMANCE);

            Analytics.trackEvent('consent', IN1_CONSENT.PERFORMANCE, IN1_CONSENT.DENIED);
        };

        function consentDeniedFunctional() {
            var consent = get();
            consent[IN1_CONSENT.ALL] = undefined;
            consent[IN1_CONSENT.FUNCTIONAL] = IN1_CONSENT.DENIED;
            put(consent);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_DENIED_FUNCTIONAL);

            Analytics.trackEvent('consent', IN1_CONSENT.FUNCTIONAL, IN1_CONSENT.DENIED);
        };

        function consentDeniedEnhancement() {
            var consent = get();
            consent[IN1_CONSENT.ALL] = undefined;
            consent[IN1_CONSENT.ENHANCEMENT] = IN1_CONSENT.DENIED;
            put(consent);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_DENIED_ENHANCEMENT);

            Analytics.trackEvent('consent', IN1_CONSENT.ENHANCEMENT, IN1_CONSENT.DENIED);
        };

        function consentDeniedTracking() {
            var consent = get();
            consent[IN1_CONSENT.ALL] = undefined;
            consent[IN1_CONSENT.TRACKING] = IN1_CONSENT.DENIED;
            put(consent);
            $rootScope.$broadcast(IN1_CONSENT.EVENT_DENIED_TRACKING);

            Analytics.trackEvent('consent', IN1_CONSENT.TRACKING, IN1_CONSENT.DENIED);
        };

        //function consentDeniedStrictlyNecessary() {
        //    $rootScope.$broadcast(IN1_CONSENT.EVENT_DENIED_STRICTLY_NECESSARY);
        //};

        function processDefaultConsent(defaultConsent){
            var consent = {};
            consent[IN1_CONSENT.ALL] = defaultConsent[IN1_CONSENT.ALL] == IN1_CONSENT.GRANTED;
            consent[IN1_CONSENT.ANALYTICS] = defaultConsent[IN1_CONSENT.ANALYTICS] == IN1_CONSENT.GRANTED;
            consent[IN1_CONSENT.ADVERTISING] = defaultConsent[IN1_CONSENT.ADVERTISING] == IN1_CONSENT.GRANTED;
            consent[IN1_CONSENT.PERFORMANCE] = defaultConsent[IN1_CONSENT.PERFORMANCE] == IN1_CONSENT.GRANTED;
            consent[IN1_CONSENT.FUNCTIONAL] = defaultConsent[IN1_CONSENT.FUNCTIONAL] == IN1_CONSENT.GRANTED;
            consent[IN1_CONSENT.ENHANCEMENT] = defaultConsent[IN1_CONSENT.ENHANCEMENT] == IN1_CONSENT.GRANTED;
            consent[IN1_CONSENT.TRACKING] = defaultConsent[IN1_CONSENT.TRACKING] == IN1_CONSENT.GRANTED;
            consent[IN1_CONSENT.STRICTLY_NECESSARY] = defaultConsent[IN1_CONSENT.STRICTLY_NECESSARY] == IN1_CONSENT.GRANTED;
            if(typeof defaultConsent[IN1_CONSENT.STRICTLY_NECESSARY] === 'undefined'){
                //always allow
                consent[IN1_CONSENT.STRICTLY_NECESSARY] = true;
            }
            consent[IN1_CONSENT.MODIFIED] = typeof defaultConsent[IN1_CONSENT.MODIFIED] !== 'undefined' && defaultConsent[IN1_CONSENT.MODIFIED] == true;
            return consent;
        }


    }
})();
