(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('ShoppingCartTermsDialogController', ShoppingCartTermsDialogController);

    ShoppingCartTermsDialogController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$uibModalInstance', 'terms' ];

	function ShoppingCartTermsDialogController($scope, $rootScope, $state, $stateParams, $uibModalInstance, terms) {
	    var vm = this;

	    vm.terms = terms;
	    vm.clear = clear;
	    
	    function clear () {
            $uibModalInstance.dismiss('cancel');
        }

	}
})();
