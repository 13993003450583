(function() {
    'use strict';

    angular.module('currencyExchangeModule')
        .filter('forex', function(CurrencyExchange, CurrencyExchangeUtils) {
            'ngInject';
            return function(rate, override) {
                var outputCurrent;
                //console.log('forex...', input)

                if (CurrencyExchange.currencies.base.code === CurrencyExchange.currencies.current.code) {
                    return rate;
                } else if (CurrencyExchange.currencies.base.code === 'EUR') {
                    console.log('base currency: EUR');
                    outputCurrent = rate * CurrencyExchange.currencies.current.rate;
                } else if (CurrencyExchange.currencies.current.code === 'EUR') {
                    console.log('current currency: EUR');
                    outputCurrent = rate * (1 / CurrencyExchange.currencies.base.rate);
                } else {
                    console.log('current currency: else...');
                    outputCurrent = rate * (CurrencyExchange.currencies.current.rate * (1 / CurrencyExchange.currencies.base.rate));
                }
                console.log('converted from '+CurrencyExchange.currencies.base.code+' to '+CurrencyExchange.currencies.current.code+': '+outputCurrent);

                return outputCurrent;
            };
        })
        .filter('forexsingle', function(CurrencyExchange) {
            'ngInject';
            return function(rate, override) {
                var outputCurrent;

                if (CurrencyExchange.currencies.base.code === CurrencyExchange.currencies.current.code) {
                    return rate;
                } else if (CurrencyExchange.currencies.base.code === 'EUR') {
                    outputCurrent = rate * CurrencyExchange.currencies.current.rate;
                } else if (CurrencyExchange.currencies.current.code === 'EUR') {
                    outputCurrent = rate * (1 / CurrencyExchange.currencies.base.rate);
                } else {
                    outputCurrent = rate * (CurrencyExchange.currencies.current.rate * (1 / CurrencyExchange.currencies.base.rate));
                }
                return outputCurrent;
            };
        })
        .filter('exchangenumber', function (CurrencyExchange, XE_CURRENCIES, $filter) {
            'ngInject';
            return function (input, fromCode, toCode, toString) {
                var outputCurrent;

                var fromCurrencyCode = fromCode || CurrencyExchange.currencies.base.code;
                var fromCurrency = CurrencyExchange.currencies.all[fromCurrencyCode];

                //for groups we might not have current currency so treat FROM as CURRENT
                var currentCurrency = CurrencyExchange.currencies.current || fromCurrency;

                var toCurrencyCode = toCode || currentCurrency.code;
                var toCurrency = CurrencyExchange.currencies.all[toCurrencyCode];

                if (fromCurrency.code === toCurrency.code) {
                    outputCurrent = input;
                } else if (fromCurrency.code === 'EUR') {
                    outputCurrent = input * toCurrency.rate;
                } else if (toCurrency.code === 'EUR') {
                    outputCurrent = input * (1 / fromCurrency.rate);
                } else {
                    outputCurrent = input * (toCurrency.rate * (1 / fromCurrency.rate));
                }

                //return outputCurrent;
                var toCurrencyDetails = XE_CURRENCIES[toCurrencyCode];

                if(!toCurrencyDetails){
                    return outputCurrent;
                }

                var fractionLength = toCurrencyDetails[0];//first element is fraction length
                var multiplier = Math.pow(10, fractionLength);
                var price = Math.round(outputCurrent * multiplier) / multiplier;

                if(toString){
                    return $filter('number')(price, fractionLength);
                }

                return price;
            }
        })
        .filter('c', function($locale){
            'ngInject';
            var formats = $locale.NUMBER_FORMATS;

            return function(amount, currencySymbol, fractionSize) {
                if (angular.isUndefined(currencySymbol)) {
                    currencySymbol = formats.CURRENCY_SYM;
                }

                if (angular.isUndefined(fractionSize)) {
                    fractionSize = formats.PATTERNS[1].maxFrac;
                }

                // If the currency symbol is empty, trim whitespace around the symbol
                var currencySymbolRe = !currencySymbol ? /\s*\u00A4\s*/g : /\u00A4/g;

                // if null or undefined pass it through
                return amount === null ? amount : CurrencyExchangeUtils.format(amount, XE_CURRENCIES_FORMAT[currencySymbolRe], fractionSize);
            };
        });


})();
