(function() {
    'use strict';

    angular.module('vobeApp')
        .component('currencyNavDropdown', {
            templateUrl: 'app/components/shopping-cart/currencyDropdown/currency-nav-dropdown.html',
            controller: 'CurrencyDropdownController',//same controller as currency button
            controllerAs: 'vm',
            bindings: {
                onUpdate: '<',
                collapseWorldNavbar:'<',
                onAvailableCurrencies: '<'
            }
        });
})();
