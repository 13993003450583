(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('VouchersViewSettings', VouchersViewSettings);

    VouchersViewSettings.$inject = ['$state', '$stateParams', '$localStorage'];

    function VouchersViewSettings ($state,$stateParams, $localStorage) {
        var service = {
        	setSettings : setSettings,
        	getSettings : getSettings,
            setCurrencySettings : setCurrencySettings,
            getCurrencySettings : getCurrencySettings
        }

        function setSettings(settings){
        	$localStorage.vouchersViewSettings = settings;
        }

        function getSettings(){
        	return $localStorage.vouchersViewSettings;
        }

        function setCurrencySettings(settings){
            $localStorage.currencySettings = settings;
        }

        function getCurrencySettings(){
            return $localStorage.currencySettings;
        }

        return service;

    }
})();
