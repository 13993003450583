(function() {
    'use strict';

    angular.module('vobeApp').component('confirmationItem', {
        templateUrl: 'app/components/confirmation/confirmation-item.html',
        controller: 'ConfirmationItemController',
        controllerAs: '$ctrl',
        bindings: {
            voucherItem : '<',
            voucherConfig : '<',
            confirmationUuid : '<',
            index: '<',
        }
    });
})();
