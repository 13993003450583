(function() {
    'use strict';

    angular.module('vobeApp')
        .component('cookiesBanner', {
            templateUrl: 'app/components/cookies-consent/cookies-banner.html',
            controller: 'CookiesBannerController',
            controllerAs: 'vm',
            bindings: {
                onUpdate: '<'
            }
        });
})();
