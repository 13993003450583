(function() {
    'use strict';

    angular
        .module('vobeApp')
        .factory('PublicSitePartRelationshipSearch', PublicSitePartRelationshipSearch);

    PublicSitePartRelationshipSearch.$inject = ['$resource'];

    function PublicSitePartRelationshipSearch($resource) {
        var resourceUrl =  'api/_search/public-site-part-relationships/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': { method: 'GET', isArray: true}
        });
    }
})();
