(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('PaymentFinishProcess', PaymentFinishProcess);

    PaymentFinishProcess.$inject = ['$resource'];

    function PaymentFinishProcess ($resource) {
        var service = $resource('api/shoppingcart/finish', {}, {
            'finish': {
                method: 'GET',
                transformResponse: function (data) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
})();
