(function() {
    'use strict';

    angular
        .module('vobeApp')
        .factory('propertyErrorHandlerInterceptor', propertyErrorHandlerInterceptor);

    propertyErrorHandlerInterceptor.$inject = ['$q', '$rootScope'];

    function propertyErrorHandlerInterceptor ($q, $rootScope) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError (response) {
            if (response && !(response instanceof Error) && (response.headers('X-vobeApp-error-property') || response.headers('x-vobeapp-error-property')) ) {
                $rootScope.$emit('vobeApp.propertyError', response);
            }
            return $q.reject(response);
        }
    }
})();
