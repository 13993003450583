(function() {
    'use strict';
    angular
        .module('vobeApp')
        .factory('PublicSite', PublicSite);

    PublicSite.$inject = ['$resource'];

    function PublicSite ($resource) {
        var resourceUrl =  'api/public-sites/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
