(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('DetermineItemLanguage', DetermineItemLanguage);

    DetermineItemLanguage.$inject = ['$state', '$stateParams', '$translate'];

    function DetermineItemLanguage ($state,$stateParams, $translate) {
        var service = {
        	getLanguage : getLanguage
        }
        
        function getLanguage(item){

        	var language = $translate.use();
    		var variant = "";
    		if($stateParams.contextType){
    			variant = "_"+$stateParams.contextType;
    		}
    		if(item && item.owningProperty && item.owningProperty.propertyId){
    			variant += (variant.length==0 ? "_" : "") + item.owningProperty.propertyId;
    		}
    		if(item && item.propertyId){
    			variant += (variant.length==0 ? "_" : "") + item.propertyId;
    		}

        	return language+variant;
        }

        return service;
        
    }
})();
