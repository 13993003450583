(function() {
    'use strict';

    angular.module('vobeApp').component('languageNavDropdown', {
        templateUrl: 'app/components/shopping-cart/language-dropdown/language-nav-dropdown.html',
        controller: 'LanguageDropdownButtonController',//same controller like for the button
        controllerAs: 'vm',
        bindings: {
            shoppingCartConfigs: '<',
            currentProperty: '<',
            collapseWorldNavbar:'<',
            onAvailableLanguages: '<'
        }
    });
})();
