(function() {
    'use strict';

    angular.module('vobeApp').filter('findLanguageFromKey', findLanguageFromKey);

    function findLanguageFromKey() {
        return findLanguageFromKeyFilter;

        function findLanguageFromKeyFilter(lang) {
            return {
                ca: 'Català',
                cs: 'Český',
                da: 'Dansk',
                de: 'Deutsch',
                el: 'Ελληνικά',
                en: 'English',
                'en-gb': 'English (UK)',
                'en_GB': 'English (UK)',
                es: 'Español',
                fr: 'Français',
                gl: 'Galego',
                hu: 'Magyar',
                hi: 'हिंदी',
                it: 'Italiano',
                ja: '日本語',
                ko: '한국어',
                mr: 'मराठी',
                nl: 'Nederlands',
                pl: 'Polski',
                'pt-br': 'Português (Brasil)',
                'pt': 'Português',
                'pt-pt': 'Português',
                'pt_BR': 'Português (Brasil)',
                'pt_PT': 'Português',
                ro: 'Română',
                ru: 'Русский',
                sk: 'Slovenský',
                sv: 'Svenska',
                he: 'עברי',
                iw: 'עברי',
                ta: 'தமிழ்',
                tr: 'Türkçe',
                'zh-cn': '中文（简体）',
                'zh-tw': '繁體中文',
                zh: '中文（简体）', /*this is zh-cn just IMC don't use country*/
                ar: 'عربى',
                hr: 'Hrvatski'
            }[lang];
        }
    }
})();
