(function() {
    'use strict';

    angular
    .module('vobeApp')
    .directive('onFinishRender', OnFinishRenderDirective);

    OnFinishRenderDirective.$inject = ['$timeout'];

    function OnFinishRenderDirective($timeout){
        return {
            restrict: 'A',
            compile: function(element, attributes){

                return {
                    post: function(scope, element, attr, controller, transcludeFn){
                        if (scope.$last === true) {
                            $timeout(function () {
                                console.log("fireing OnFinishRenderDirective",scope);
                                scope.$emit(attr.onFinishRender,{element:element, scopeId:scope.$id});
                                //scope.$broadcast(attr.onFinishRender,{scope:scope,element:scope,attr:attr});
                            },0);
                        }
                    }
                }
            }
            /*

            link: function (scope, element, attr) {
                if (scope.$last === true) {
                    $timeout(function () {
                        console.log("fireing OnFinishRenderDirective",scope);
                        scope.$emit(attr.onFinishRender,{element:element});
                        //scope.$broadcast(attr.onFinishRender,{scope:scope,element:scope,attr:attr});
                    },0);
                }
            }
            */
        }
	}

})();
