(function() {
    'use strict';

    angular.module('vobeApp').component('shoppingCartSummary', {
        templateUrl: 'app/site/shoppingcart/shopping-cart-summary.html',
        controller: 'ShoppingCartSummaryController',
        controllerAs: 'itemVM',
        bindings: {
            shoppingCart: '<',
            shoppingCartConfigs: '<',
            property: '<',
            propertyGroup: '<',
            forceHidePromoBox: '<',
            customer: '<',
            paymentForm: '<',
            paymentFormInvalid: '<',
            onUpdateTotals: '&',
        }
    });

    angular.module('vobeApp').component('shoppingCartSummaryTgb', {
        templateUrl: 'app/site/shoppingcart/shopping-cart-summary.html',
        controller: 'ShoppingCartSummaryController',
        controllerAs: 'itemVM',
        bindings: {
            shoppingCart: '<',
            shoppingCartConfigs: '<',
            property: '<',
            propertyGroup: '<',
            forceHidePromoBox: '<',
            paymentForm: '<',
            paymentFormInvalid: '<',
            onUpdateTotals: '&',
        }
    });
})();
