(function() {
    'use strict';

    angular.module('vobeApp').controller('CookiesBannerController', CookiesBannerController);

    function CookiesBannerController(
        $scope,
        $state,
        $translate,
        $q,
        $window,
        CookiesConsentStorageService,
        IN1_CONSENT,
        ModalService,
        $transitions,
        $cookies

    ) {
        'ngInject';
        var vm = this;

        vm.isGDPR = ($window && $window.IN1 && $window.IN1.isGDPR) ? $window.IN1.isGDPR : false;

        var storedConsent = CookiesConsentStorageService.get();

        vm.consent = CookiesConsentStorageService.processDefaultConsent(storedConsent);


        vm.IN1_CONSENT = IN1_CONSENT;

        //TODO: should we delete all the cookies here?

        vm.onConsentDirty = function(){
            vm.consentDirty = true;
            console.log("Current consent ", vm.consent);
        };

        vm.consentDirty = false;

        vm.saveConsent = function(refresh){
            console.log("Save consent ", vm.consent);

            //analytics
            if(vm.consent[IN1_CONSENT.ANALYTICS] == true){
                CookiesConsentStorageService.consentGrantedAnalytics();
            }
            else {
                CookiesConsentStorageService.consentDeniedAnalytics();
            }

            //advertising
            if(vm.consent[IN1_CONSENT.ADVERTISING] == true){
                CookiesConsentStorageService.consentGrantedAdvertising();
            }
            else {
                CookiesConsentStorageService.consentDeniedAdvertising();
            }

            //TRACKING
            if(vm.consent[IN1_CONSENT.TRACKING] == true){
                CookiesConsentStorageService.consentGrantedTracking();
            }
            else {
                CookiesConsentStorageService.consentDeniedTracking();
            }

            //enhancement
            if(vm.consent[IN1_CONSENT.ENHANCEMENT] == true){
                CookiesConsentStorageService.consentGrantedEnhancement();
            }
            else {
                CookiesConsentStorageService.consentDeniedEnhancement();
            }

            //PERFORMANCE
            if(vm.consent[IN1_CONSENT.PERFORMANCE] == true){
                CookiesConsentStorageService.consentGrantedPerformance();
            }
            else {
                CookiesConsentStorageService.consentDeniedPerformance();
            }

            //FUNCTIONAL
            if(vm.consent[IN1_CONSENT.FUNCTIONAL] == true){
                CookiesConsentStorageService.consentGrantedFunctional();
            }
            else {
                CookiesConsentStorageService.consentDeniedFunctional();
            }

            angular.extend(vm.consent, CookiesConsentStorageService.processDefaultConsent(CookiesConsentStorageService.get()));
            console.log("Current consent ", vm.consent);

            if(refresh) {
                clear();
                $state.reload('property');
            }

        }

        vm.allowAll = function(refresh){
            console.log("Allow All consent ", vm.consent);
            CookiesConsentStorageService.consentGrantedAll();
            angular.extend(vm.consent, CookiesConsentStorageService.processDefaultConsent(CookiesConsentStorageService.get()));
            if(refresh) {
                clear();
                $state.reload('property');
            }
        }

        vm.denyAll = function(refresh){
            console.log("Deny All consent ", vm.consent);
            CookiesConsentStorageService.consentDeniedAll();
            angular.extend(vm.consent, CookiesConsentStorageService.processDefaultConsent(CookiesConsentStorageService.get()));

            //TODO: remove cookies that were denied
            var cookies = $cookies.getAll();
            angular.forEach(cookies, function (v, k) {
                if(k!='SESSION' && k!='CSRF-TOKEN' && k!='NG_TRANSLATE_LANG_KEY' && k!='in1_vobe_consent'){
                    console.log('removing cookie with key: '+k);
                    $cookies.remove(k, {expires : new Date(), path: '/'});
                }

            });

            if(refresh) {
                clear();
                $state.reload('property');
            }


        }

        vm.clear = clear;

        function clear () {
            if($state.cookiePreferencesModal){
                $state.cookiePreferencesModal.dismiss('cancel');
            }
        }





        //init cookie consent, send default events and handle changes

    }
})();
