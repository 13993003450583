(function() {
    'use strict';

    angular
        .module('vobeApp')
        .factory('stateHandler', stateHandler);



    function stateHandler($rootScope, $state, $transitions, $sessionStorage, $translate, JhiLanguageService, translationHandler, faviconHandler, $window,
        Auth, Principal, VERSION) {
        'ngInject';
        return {
            initialize: initialize
        };

        function initialize() {
            console.log('stateHandler initialize')
            $rootScope.VERSION = VERSION;

            var stateChangeStart = $transitions.onStart({}, function(transition) {
                console.log('onStart: ', transition.to().name, transition.to())
                $rootScope.toState = transition.to();
                $rootScope.toStateParams = transition.to().params;
                $rootScope.fromState = transition.from();

                // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
                if (transition.to().external) {
                    //event.preventDefault();
                    $window.open(toState.url, '_self');
                }

                if (Principal.isIdentityResolved()) {
                    Auth.authorize();
                }

                // Update the language

                //JhiLanguageService.getCurrent().then(function (language) {
                //    $translate.use(language);
                //});

            });

            var stateChangeSuccess = $transitions.onSuccess({}, function(transition) {
                console.log('onSuccess: ', transition.to().name, transition.to())
                console.log('==========')
                var titleKey = 'global.title' ;

                // Set the page title key to the one configured in state or use default one
                if (transition.to().data.pageTitle) {
                    titleKey = transition.to().data.pageTitle;
                }
                translationHandler.updateTitle(titleKey);
                faviconHandler.updateFavicon();
            });

            $rootScope.$on('$destroy', function () {
                console.log('======== $destroy!')
                if(angular.isDefined(stateChangeStart) && stateChangeStart !== null){
                    stateChangeStart();
                }
                if(angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null){
                    stateChangeSuccess();
                }
            });

            //$rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
            $transitions.onError({}, function (transition) {
                console.warn('$stateChangeError!!', transition._error.message)
                var toParams = transition.params();
                if (transition._error.detail === "Payment: Recipient missing") {
                    toParams.revalidate = true;
                    $state.transitionTo('shopping-cart', toParams, {
                        reload: 'site', inherit: true, notify: true
                    });
                } else if (transition._error.detail === 'Payment: Basket empty') {
                    //due to introduction of a special case for HILTON Refunds, we cannot go back to LIST if we started in bulk
                    if (toParams.contextType === "refunds" && toParams.bulk && toParams.bulk.length > 0) {
                        $state.transitionTo('bulk-add', toParams, {
                            reload: 'site', inherit: true, notify: true
                        });
                    } else {
                        $state.transitionTo('list', toParams, {
                            reload: 'site', inherit: true, notify: true
                        });
                    }

                }
                var toState = transition.to();
                if(toState && toState.name=='bulk-add'){
                    //if there are no vouchers pools and we tried to open /bulk-add
                    $state.transitionTo('list', toParams, {
                        reload: 'site', inherit: true, notify: true
                    });
                }
            });
        }
    }
})();
