(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('ConfirmationItemController', ConfirmationItemController);

    function ConfirmationItemController($scope, $sce) {
        'ngInject';
        var vm = this;

        vm.$onInit = function() {
            console.log(vm);
            vm.voucherItem.voucher.voucherDescriptionTrustedHtml = $sce.trustAsHtml(vm.voucherItem.voucher.voucherDescription);

            vm.deliveryEmail = vm.voucherItem.voucherPurchaser.email;
            vm.customerEmail = vm.voucherItem.voucherPurchaser.customerEmail;

            vm.signetProperties  = ["HSAVUK", "ERNVUK"];
            vm.isSignetProperty = vm.signetProperties.includes(vm.voucherItem.hotel.hotelCode.toUpperCase());

            vm.hideSavvyProperties = vm.signetProperties;
            vm.hideSavvy = vm.hideSavvyProperties.includes(vm.voucherItem.hotel.hotelCode.toUpperCase()) && vm.deliveryEmail != vm.customerEmail;
        }


    }

})();
