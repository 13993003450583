(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PublicSiteBrowsePropertyController', PublicSiteBrowsePropertyController);

    PublicSiteBrowsePropertyController.$inject = ['$scope', '$state', '$stateParams', 'PropertySearch'];

    function PublicSiteBrowsePropertyController ($scope, $state, $stateParams, PropertySearch) {
        var vm = this;


        vm.selectedProperty = {};

        vm.properties = [];

        vm.refreshProperties = function(propertyName) {
          if(!propertyName || propertyName.length==0){
        	  return;
          }
          var params = {search: propertyName};
          vm.properties = PropertySearch.get(params,function() {
        	  angular.forEach(vm.properties,function(p,key){
        		  p.formatted_name = '(' +p.hotelCode+ ') '+p.hotelName
        	  });
          });
        };

        vm.onSelect = function(){
        	$state.transitionTo('translation.property', {
                publicSitePartRelationshipId : $stateParams.publicSitePartRelationshipId,//needed for state resolve
                propertyId : vm.selectedProperty.hotelId//needed for state resolve
            });

        }

    }
})();
