(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('PaymentAddCustomer', PaymentAddCustomer);

    PaymentAddCustomer.$inject = ['$resource'];

    function PaymentAddCustomer ($resource) {
        var service = $resource('api/payment/start', {}, {
            'add': {
                method: 'POST'
            }
        });

        return service;
    }
})();
