(function() {
    'use strict';

    angular.module('vobeApp').controller('CookiesPolicyController', CookiesPolicyController);

    function CookiesPolicyController(
        $anchorScroll

    ) {
        'ngInject';
        var vm = this;

        $anchorScroll();


    }
})();
