(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('BrandedFooterController', BrandedFooterController);

    BrandedFooterController.$inject = ['$state','$stateParams','$rootScope','brandingTemplateUUID','currentLanguage'];

    function BrandedFooterController ($state, $stateParams, $rootScope, brandingTemplateUUID, currentLanguage) {
        var vm = this;
        vm.property;

        $rootScope.$on('propertySelected', function(event, args) {
        	vm.property = args.property;
        });

        vm.brandingUUID = brandingTemplateUUID.uuid;
        vm.currentLanguage = (currentLanguage || 'en' ).split('_')[0].split('-')[0];
        vm.channel = $stateParams.channel || '';

        if(!vm.brandingUUID){
        	vm.footerUrl = '';
        }
        else{
        	vm.footerUrl = '/assets/property/footer?uuid='+vm.brandingUUID+'&userLang='+vm.currentLanguage+'&channel='+vm.channel;
        }
    }



})();
