(function() {
    'use strict';

    angular.module('vobeApp').component('sortNavbarDropdown', {
        templateUrl: 'app/components/shopping-cart/sort-dropdown/sort-nav-dropdown.html',
        controller: 'SortNavbarDropdownController',//same controller as dropdown button
        controllerAs: 'vm',
        bindings: {
            isGroup: '<',
            showCategoriesBoxType: '<',
            showSortControls: '<',
            settings: '<'
        }
    });
})();
