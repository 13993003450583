(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('ShoppingCartAdd', ShoppingCartAdd);

    ShoppingCartAdd.$inject = ['$resource','AlertService'];

    function ShoppingCartAdd ($resource,AlertService) {
        var service = $resource('api/shoppingcart/add', {
            ref: '@requestParams.ref',
            src: '@requestParams.src',
            lang: '@requestParams.lang',
            contextType: '@requestParams.contextType',
            propertyType: '@requestParams.propertyType',
            propertyCode: '@requestParams.propertyCode',
            customerRef: '@requestParams.customerRef'
        }, {
            'add': {
                method: 'PUT',
                transformResponse: function (data, headers) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                    if (headers('X-vobeApp-error-property') || headers('x-vobeapp-error-property') ) {
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
})();
