(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('ShoppingCartEditController', ShoppingCartEditController);

    function ShoppingCartEditController($scope, $rootScope, $state, $stateParams, previousState, $anchorScroll, shoppingCart, shoppingCartConfigs, ShoppingCartAddRecipients, ShoppingCartRemove, DetermineShopMoreState, emailConfig, currentProperty, currentPropertyGroup, currentLanguage, Analytics, $window) {
	    'ngInject';
	    var vm = this;

	    this.$onInit = function() {
            vm.shoppingCart = shoppingCart;

            if(shoppingCart.shoppingCartItems.items.length==0){
                console.log('go to empty shopping cart...')
                goToEmptyShoppingCart();
            }

            vm.currentProperty = currentProperty;
            vm.currentPropertyGroup = currentPropertyGroup;
            vm.currentLanguage = currentLanguage;
            vm.property = currentProperty;
            $rootScope.$broadcast('propertySelected', { 'property': vm.property });

            vm.channel = $stateParams.channel;


            vm.shoppingCartConfigs = shoppingCartConfigs;
            vm.emailConfig = emailConfig;
            vm.checkoutFormInvalid = false;

            vm.removeItemFromCartAndRefresh = removeItemFromCartAndRefresh;

            vm.browseMore = browseMore;
            vm.goToPayment = goToPayment;
            vm.goToConfirmation = goToConfirmation;

            $anchorScroll();

            trackCheckout();

            if($stateParams.revalidate){
                vm.checkoutFormInvalid = true;
            }
            sendDataLayerCheckoutStep();
        }


	    function browseMore(event){
	    	event.preventDefault();
	    	var shopMoreState = DetermineShopMoreState.getShopMoreState();
	    	$state.go(shopMoreState.name, shopMoreState.params);
	    }

	    function goToConfirmation(event){
	    	event.preventDefault();

	    	if(vm.checkoutForm.$invalid){
	    		vm.checkoutFormInvalid = true;
	    		return;
	    	}
	    	else {
	    		vm.checkoutFormInvalid = false;
	    		addRecipients(goToPaymentTransactionsState,refreshAndRevalidate);
	    	}
	    }


	    function goToPayment(event){
	    	event.preventDefault();

	    	if(vm.checkoutForm.$invalid){
	    		vm.checkoutFormInvalid = true;
	    		return;
	    	}
	    	else {
	    		vm.checkoutFormInvalid = false;
	    		addRecipients(goToPaymentState,refreshAndRevalidate);
	    	}
	    }


	    function goToEmptyShoppingCart(){
		    $state.go("shopping-cart-empty");
	    }

	    function goToPaymentState(){
		    $state.transitionTo("payment", $stateParams, {
		    	  reload: 'site', inherit: true, notify: true
		    });
	    }

	    //this is used by TGB to skip payments page
	    function goToPaymentTransactionsState(){
		    $state.transitionTo("transactions", $stateParams, {
		    	  reload: 'site', inherit: true, notify: true
		    });
	    };

	    function refreshAndRevalidate(){
	    	$stateParams.revalidate = true;
		    $state.transitionTo($state.current, $stateParams, {
		    	  reload: 'site', inherit: false, notify: true
		    });
	    };

        function addRecipients (callback, errorCallback) {
            var cb = callback || angular.noop;
            var ecb = errorCallback || angular.noop;

            var recipients = {
            		item : {}
            }

            angular.forEach(shoppingCart.shoppingCartItems.items, function(value, key) {

            	recipients.item[value.uuid] = value.recipient;
            	if(value.recipient && value.recipient.country && value.recipient.country.countryName){
            		if(value.recipient.deliveryMethodType=="post"){
            			recipients.item[value.uuid].country =  value.recipient.country.countryName;
            		}
            		else {
            			//nullify pre-selected recipient country
            			recipients.item[value.uuid].country = null;
            		}
            	}


            });

            return ShoppingCartAddRecipients.add(recipients,
                function (response) {
                    return cb(response);
                },
                function (err) {
                    return ecb(err);
                }.bind(this)).$promise;
        }


	    function removeItemFromCartAndRefresh(event, itemUUID){
	    	event.preventDefault();
	    	removeItem(itemUUID, refreshSite);

	    }

	    function refreshSite(){
		    $state.transitionTo($state.current, $stateParams, {
		    	  reload: 'site', inherit: true, notify: true
		    });
	    }


	    function removeItem(itemUUID, callback){

            var cb = callback || angular.noop;

            return ShoppingCartRemove.remove({itemUUID : itemUUID},
                function (response) {
                    return cb(response);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;

	    }

        function trackCheckout(){

        	angular.forEach(shoppingCart.shoppingCartItems.items, function(item,index){
        		Analytics.addProduct(
	    				item.itemId,//productId
	    				item.itemName,//name
	    				item.itemType,//category
	    				item.owningProperty.propertyCode,//brand
	    				'',//variant
	    				item.itemTotalPrice,//price
	    				'1',//quantity
	    				'',//coupon
	    				''//position
	    				);
    	    });
        	Analytics.trackCheckout(1, 'Details');
        }

        var sendDataLayerCheckoutStep = function() {
            var dataLayer = $window.dataLayer = $window.dataLayer || [];

            var productsInCartGa4 = [];
            var overallRevenue = 0;
            angular.forEach(shoppingCart.shoppingCartItems.items, function (cartItem, index) {

                var itemData = {
                    item_id: cartItem.itemId.toString(),
                    item_name: cartItem.itemName,
                    index: index + 1,
                    price: cartItem.itemTotalPrice,
                    quantity: 1,
                    affiliation: cartItem.owningProperty.propertyName,
                };

                if (cartItem.itemCategoryId) {
                    itemData.item_list_id = cartItem.itemCategoryId;
                }

                if (cartItem.itemCategoryName) {
                    itemData.item_list_name = cartItem.itemCategoryName;
                }

                productsInCartGa4.push(itemData);
                overallRevenue += cartItem.itemTotalPrice;
            });

            //ga4
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: "begin_checkout",
                ecommerce: {
                    currency: vm.currentProperty.hotelCurrency,
                    value: overallRevenue,
                    items: productsInCartGa4
                }
            });

            console.log('=> trackBeginCheckout ...', dataLayer[dataLayer.length - 1]);
        };

	}
})();
