(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('PromotionCodeService', PromotionCodeService);

    PromotionCodeService.$inject = ['PromotionCodeAdd', 'PromotionCodeRemove'];

    function PromotionCodeService (PromotionCodeAdd, PromotionCodeRemove) {

		var promotionCodeErrorCallback = function(httpResponse, shoppingCart, ngModel){
            switch (httpResponse.status) {
	            case 400:
	                var errorHeader = httpResponse.headers('X-vobeApp-error') || httpResponse.headers('x-vobeapp-error') ;
	                if (ngModel && errorHeader=='error.promocodeinvalid') {
	                	ngModel.$setValidity(errorHeader, false);

	                }
	                else if (ngModel && errorHeader=='error.promocodeexists') {
	                	ngModel.$setValidity(errorHeader, false);
	                }
	                break;
	            default :
	            	if(ngModel){
	            		ngModel.$setValidity('error.promocodeinvalid', false);
	            	}

            }
		}

		var promotionCodeSuccessCallback = function(response, shoppingCart, ngModel){
			shoppingCart.shoppingCartDiscounts = response.shoppingCartDiscounts;
			shoppingCart.shoppingCartItems = response.shoppingCartItems;
			shoppingCart.shoppingCartPaymentTransactions = response.shoppingCartPaymentTransactions;
		}



        var service = {
        	addPromocode: addPromocode,
            removePromocode: removePromocode,
            clearPromoCode: clearPromoCode,
            findApplicablePromo: findApplicablePromo
        };

        return service;

	    function clearPromoCode(ngModel){

	    	if(ngModel){
	    		ngModel.$setValidity('error.promocodeinvalid', null);
	    		ngModel.$setValidity('error.promocodeexists', null);
	        }

	    }

        function addPromocode(shoppingCart, ngModel, hotelId, code, callback, errCallback) {
            var cb = callback || promotionCodeSuccessCallback;
            var ecb = errCallback || promotionCodeErrorCallback;

            var promotionCodeVM = {
            		propertyId : hotelId,
            		code : code
            }

            return PromotionCodeAdd.add(promotionCodeVM,
                function (response) {
                    return cb(response, shoppingCart, ngModel);
                },
                function (err) {
                    return ecb(err, shoppingCart, ngModel);
                }.bind(this)).$promise;
        }

        function removePromocode(shoppingCart, ngModel, id, callback, errCallback) {
            var cb = callback || promotionCodeSuccessCallback;
            var ecb = errCallback || promotionCodeErrorCallback;

            var promotionCodeVM = {
            		id : id
            }

            return PromotionCodeRemove.remove(promotionCodeVM,
                function (response) {
                    return cb(response, shoppingCart, ngModel);
                },
                function (err) {
                    return ecb(err, shoppingCart, ngModel);
                }.bind(this)).$promise;
        }

        function findApplicablePromo(shoppingCart, currentProperty, isGroup, currentVoucher){
            console.log('findApplicablePromo')
            var appliedPromotionCodeForVoucher;
            if (
                shoppingCart &&
                shoppingCart.shoppingCartDiscounts &&
                shoppingCart.shoppingCartDiscounts.promotionCodeIds &&
                shoppingCart.shoppingCartDiscounts.promotionCodeIds.length > 0
            ) {

                //var promotionCodeId = shoppingCart.shoppingCartDiscounts.promotionCodeIds[0];
                var promotionCodeIds = shoppingCart.shoppingCartDiscounts.promotionCodeIds;

                angular.forEach(shoppingCart.shoppingCartDiscounts.discounts, function(d, i) {
                    if (!appliedPromotionCodeForVoucher){
                        //console.log('discount propertyId vs currentVoucher..., ', d.propertyId, currentVoucher)
                        if (d.discountType=="TGB") {
                            appliedPromotionCodeForVoucher = d;
                        } else if (d.discountType=="PROMO" && promotionCodeIds.includes(d.id) && d.propertyId == currentVoucher.hotelId && !isGroup) {
                            if(!d.filteredItemIds || typeof d.filteredItemIds === 'undefined'){
                                appliedPromotionCodeForVoucher = d;
                            } else if (d.filteredItemIds.includes(currentVoucher.id)) {
                                appliedPromotionCodeForVoucher = d;
                            }
                        }
                        //TODO: please test
                        else if (d.discountType=="PROMO" && promotionCodeIds.includes(d.id) && d.propertyId == currentVoucher.hotelId && isGroup){
                            if(!d.filteredItemIds || typeof d.filteredItemIds === 'undefined'){
                                appliedPromotionCodeForVoucher = d;
                            } else if (d.filteredItemIds.includes(currentVoucher.id)) {
                                appliedPromotionCodeForVoucher = d;
                            }
                        }
                    }

                });
            }
            return appliedPromotionCodeForVoucher;
        }
    }
})();
