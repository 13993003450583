(function () {

    'use strict';
    angular.module('vobeApp')
        .component('cfTurnstile', {
            bindings: {
                siteKey: '@',
                onChallengeCompleted: '&'
            },
            controller: CfTurnstile,
            template: '<div id="turnstile-container"></div>',
        });

    function CfTurnstile($window, $document, PaymentProcessTransaction) {
        'ngInject';
        var vm = this;

        vm.$onInit = function () {

            function loadAndRenderTurnstile() {
                var containerId = 'turnstile-container';
                if (!$window.onloadTurnstileCallback) {
                    $window.onloadTurnstileCallback = function () {
                        $window.turnstile.render('#' + containerId, {
                            sitekey: vm.siteKey,
                            callback: function (token) {
                                var validationSuccess = false; // Placeholder for validation logic
                                //call the rest endpoint `/api/payment/turnstile` with request payload "cf-turnstile-response"
                                PaymentProcessTransaction.turnstile.verify({ 'cf-turnstile-response': token }, function (response) {
                                    console.log('success response', response);
                                    if (response.success) {
                                        validationSuccess = true;
                                        vm.onChallengeCompleted({isValid: validationSuccess});
                                    } else {
                                        validationSuccess = false;
                                        vm.onChallengeCompleted({isValid: validationSuccess});
                                        $window.turnstile.reset(); //reset when validation fails
                                    }
                                }, function (error) {
                                    validationSuccess = false;
                                    vm.onChallengeCompleted({isValid: validationSuccess});
                                    $window.turnstile.reset(); //reset when validation errors
                                });
                            },
                        });
                    };
                }
            }

            if (!$window.turnstileLoaded) {
                var script = $document[0].createElement('script');
                script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback";
                script.defer = true;
                $document[0].body.appendChild(script);
                $window.turnstileLoaded = true;
            } else {
                // If script is already loaded, immediately initialize the widget
                $window.onloadTurnstileCallback();
            }

            loadAndRenderTurnstile();
        }
    }


})();
