(function() {
    'use strict';

    angular
        .module('vobeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('payment', {
            parent: 'property',
            url: '/payment',
            data: {
                authorities: [],
                autoscroll : true,
                pageTitle : 'payment.page.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/site/payment/payment.html',
                    controller: 'PaymentController',
                    controllerAs: 'vm'
                },
                'header@': {
                    templateUrl: 'app/layouts/header/header.html',
                    controller: 'BrandedHeaderController',
                    controllerAs: 'vm'
                },
                'footer@': {
                    templateUrl: 'app/layouts/footer/footer.html',
                    controller: 'BrandedFooterController',
                    controllerAs: 'vm'
                },
                'cookies@': {
                    templateUrl: 'app/components/cookies-consent/cookies-banner.html',
                    controller: 'CookiesBannerController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                mainTranslatePartialLoader: function ($stateParams,$translate,$translatePartialLoader,currentProperty) {
                    'ngInject';
                	var variant = "";
                	if($stateParams.contextType){
                		variant = "_"+$stateParams.contextType;
                	}
                	variant += "_"+currentProperty.hotelId;

                	$translatePartialLoader.addPart('payment'+variant);
                    return $translate.refresh();
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'payment',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }],
                brandingTemplateUUID : ['brandingTemplateUUID',function(brandingTemplateUUID){
                	return brandingTemplateUUID;
                }],
                shoppingCart : ['shoppingCart','$q',function(shoppingCart,$q){
                	var d = $q.defer();
                	var allRecipientsExists = true;
                    var itemsInBasket = shoppingCart.shoppingCartItems.items.length>0;
            	    angular.forEach(shoppingCart.shoppingCartItems.items ,function(item, index){
                		if(item.recipient.uuid==null){
                			allRecipientsExists = false;
                		}
                	});
                    if (itemsInBasket && allRecipientsExists) {
                      d.resolve(shoppingCart);
                    } else if (!allRecipientsExists) {
                      //here the rejection
                      //it is handled in app/blocks/handlers/state.handler.js
                      d.reject('Payment: Recipient missing');
                    } else if (!itemsInBasket) {
                        //here the rejection
                        //it is handled in app/blocks/handlers/state.handler.js
                        d.reject('Payment: Basket empty');
                    }
                    return d.promise;
                }],
                availableCreditCards : function(PropertyCreditCards, currentProperty, shoppingCart) {
                    'ngInject';
                    console.log('shoppingCart contents', shoppingCart)
                    return PropertyCreditCards.get({id: currentProperty.hotelId}).$promise;
                },
                availablePaymentOptions: function (PropertyPaymentOptions, currentProperty, shoppingCart, currentLanguage, currentPropertyGroup, $cookies) {
                    'ngInject';
                    console.log('currentPropertyGroup', currentPropertyGroup);
                    var propertyId = currentProperty.hotelId;
                    if (currentPropertyGroup && !currentPropertyGroup.useMasterPayment) {
                        propertyId = shoppingCart.shoppingCartItems.items[0].owningProperty.propertyId;
                    }
                    var paymentRefToken = '';
                    $cookies.get('paymentRefToken') ? paymentRefToken = $cookies.get('paymentRefToken') : paymentRefToken = '';
                    return PropertyPaymentOptions.get({
                        id: propertyId,
                        lang: currentLanguage,
                        currency: currentProperty.hotelCurrency,
                        paymentRefToken: paymentRefToken
                    }).$promise;
                },
                propertyConfig: function (shoppingCartConfigs, currentProperty, currentPropertyGroup, shoppingCart) {
                    'ngInject';
                    var currentPropertyId = currentProperty.hotelId;
                    console.log('propertyConfig currentPropertyId', currentPropertyId, (currentPropertyGroup && !currentPropertyGroup.useMasterPayment))
                    if (currentPropertyGroup && !currentPropertyGroup.useMasterPayment) {
                        currentPropertyId = shoppingCart.shoppingCartItems.items[0].owningProperty.propertyId;
                    }
                    console.log('propertyConfig currentPropertyId', currentPropertyId)
                    var propertyConfig = (shoppingCartConfigs && shoppingCartConfigs[currentPropertyId]) ? shoppingCartConfigs[currentPropertyId].configMap : {};
                    console.log('propertyConfig', propertyConfig)
                    return propertyConfig;
                },
                stripe: function (propertyConfig, StripeElements, $q) {
                    'ngInject';
                    if (propertyConfig.stripe === 'true') {

                        var deferred = $q.defer();
                        var script = document.createElement('script');
                        script.type = 'text/javascript';
                        document.getElementsByTagName('head')[0].appendChild(script);
                        script.src = 'https://js.stripe.com/v3/';
                        script.onload = function () {
                            console.log('success')
                            deferred.resolve(StripeElements.initStripe(propertyConfig.stripePublicKey));
                        }
                        document.body.appendChild(script)
                        console.info('stripe enabled: ', propertyConfig.stripe, propertyConfig.stripePublicKey)
                        return deferred.promise;
                    } else {
                        return false;
                    }
                }
            }
        })
        .state('transactions', {
            parent: 'payment',
            url: '/result',
            data: {
                authorities: [],
                autoscroll : true,
                pageTitle : 'payment.page.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/site/payment/payment-transactions-dialog.html',
                    controller: 'PaymentTransactionsDialogController',
                    controllerAs: 'vm'
                },
                'hero@': {
                //	templateUrl: 'app/site/shoppingcart/hero-shopping.html',
                //    controller: 'HeroController',
                //    controllerAs: 'vm'
                }
            },
            resolve : {
                loadDependencies:['$ocLazyLoad', function($ocLazyLoad) {
                    return $ocLazyLoad.load('zapper');
                }],
                adwordsAccount: ['currentProperty', 'GoogleAdwordsService', function(currentProperty, GoogleAdwordsService) {
                    return GoogleAdwordsService.getCode({ hotelCode: currentProperty.hotelCode.toLowerCase()});
                }],
                adwords: ['GoogleAdwordsService', 'adwordsAccount', '$rootScope','CookiesConsentStorageService', 'IN1_CONSENT','$window', function(GoogleAdwordsService, adwordsAccount, $rootScope, CookiesConsentStorageService, IN1_CONSENT,$window) {

                    //COOKIE POLICY:
                    var isGDPR = ($window && $window.IN1 && $window.IN1.isGDPR) ? $window.IN1.isGDPR : false;

                    var defaultConsent = getDefaultConsent();

                    function getDefaultConsent() {
                        var defaultConsent = CookiesConsentStorageService.get();

                        var allowAnalytics = isGDPR ? false : true;//we don't know if the account has anonymizeIp ON and allowAdFeatures OFF, so we must set to false for people in the GDPR territories
                        var analyticsConsentAllowed = defaultConsent[IN1_CONSENT.PERFORMANCE]==IN1_CONSENT.GRANTED || (typeof defaultConsent[IN1_CONSENT.PERFORMANCE]  === "undefined" && defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.GRANTED);
                        if(analyticsConsentAllowed){
                            allowAnalytics = true;
                        }
                        //check if it is explicitly denied
                        var analyticsConsentDenied = defaultConsent[IN1_CONSENT.PERFORMANCE]==IN1_CONSENT.DENIED || (typeof defaultConsent[IN1_CONSENT.PERFORMANCE]  === "undefined" && defaultConsent[IN1_CONSENT.ALL]==IN1_CONSENT.DENIED);
                        if(analyticsConsentDenied){
                            allowAnalytics = false;
                        }

                        return {
                            consent : defaultConsent,
                            allowAnalytics : allowAnalytics
                        }
                    }


                    if (adwordsAccount.conversionId && !GoogleAdwordsService.isInitialized()) {

                        if(defaultConsent.allowAnalytics){

                            GoogleAdwordsService.setAccount(adwordsAccount);
                            GoogleAdwordsService.registerScript();
                            GoogleAdwordsService.setInitialized(true);
                        }
                    }
                    return '';
                }],
                stripeEnabled: ['$q', 'stripe', function ($q, stripe) {
                    var d = $q.defer();
                    console.log('testing stripeEnabled', stripe)
                    if (stripe) {
                        d.resolve(true);
                    } else {
                        d.resolve(false);
                    }
                    return d.promise;
                }],
                propertyConfig: function (shoppingCartConfigs, currentProperty) {
                    'ngInject';
                    console.log('payment/result propertyConfig...', currentProperty.hotelId)
                    console.log('shoppingCartConfigs', shoppingCartConfigs)
                    var currentPropertyId = currentProperty.hotelId;
                    var propertyConfig = (shoppingCartConfigs && shoppingCartConfigs[currentPropertyId]) ? shoppingCartConfigs[currentPropertyId].configMap : {};
                    return propertyConfig;
                },
            }
        })
        .state('fatal', {
            parent: 'transactions',
            url: '/payment/fatal',
            data: {
                authorities: [],
                autoscroll : true,
                pageTitle : 'payment.page.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/site/fatal/fatal.html',
                    controller: 'FatalController',
                    controllerAs: 'vm'
                },
                'header@': {
                    //templateUrl: 'app/layouts/header/header.html',
                    //controller: 'BrandedHeaderController',
                    //controllerAs: 'vm'
                },
                'footer@': {
                    //templateUrl: 'app/layouts/footer/footer.html',
                    //controller: 'BrandedFooterController',
                    //controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('payment');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'payment',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('terms-payment', {
            parent: 'payment',
            url: '/terms',
            data: {
                authorities: [],
                autoscroll : true
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'shoppingCart','$q','currentLanguage','currentPropertyGroup', function($stateParams, $state, $uibModal, shoppingCart,$q,currentLanguage,currentPropertyGroup) {
                var modal = $uibModal.open({
                    templateUrl: 'app/site/payment/payment-terms-dialog.html',
                    controller: 'PaymentTermsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'false',
                    animation: false,
                    windowClass : '',
                    size: 'lg',
                    resolve : {
                        shoppingCart: shoppingCart,
                        terms : ['TermsService',function(TermsService){

                        	var language = currentLanguage;
                        	var cb = function(termsText, shoppingCartItem){
                        	    return {terms : "<h5>"+shoppingCartItem.itemName+"</h5>"+termsText.terms}
                            };
                            var errCb = angular.noop;

                        	var termsPromises = [];
                        	angular.forEach(shoppingCart.shoppingCartItems.items, function(value, key) {

                        		var delivery = value.recipient.deliveryMethodType;
                        		var propertyId = value.owningProperty.propertyId;
                                var voucherId = value.itemId;

                        		var termsPromise = TermsService.get({
                                        propertyId : propertyId,
                                        voucherId : voucherId,
                                        lang : (language || 'en').split('-')[0].split('_')[0],
                                        termsType : delivery,
                                        groupCode: typeof currentPropertyGroup !== 'undefined' ?  currentPropertyGroup.code : undefined
                                }).$promise.then(
                                        function (response) {
                                            return cb(response, value);
                                        },
                                        function (err) {
                                            return errCb(err);
                                        }.bind(this)
                                );

                        		termsPromises.push(termsPromise);
                            });

                        	var terms = $q.all(termsPromises);
                        	return terms;

                        }]
                    }
                });

                modal.result.then(function() {
                    var stillIn = $state.is('terms-payment');
                    if(stillIn){
                        $state.go('^', {}, { reload: false });
                    }
                }, function() {
                    var stillIn = $state.is('terms-payment');
                    if(stillIn){
                        $state.go('^', {}, { reload: false });
                    }
                });

                $state.currentModal = modal;

            }],
            onExit: ['$state',function($state){
                $state.currentModal.close();
            }]
        })
        .state('terms-property', {
                parent: 'property',
                url: '/terms?termsType&termsVoucherId&termsPropertyId&termsId',
                data: {
                    authorities: [],
                    autoscroll : true
                },
                params: {
                    termsType: 'email',
                    lang : "en"
                },
                views: {
                    'content@': {
                        templateUrl: 'app/site/payment/payment-terms-dialog.html',
                        controller: 'PaymentTermsDialogController',
                        controllerAs: 'vm'
                    },
                    'header@': {
                        templateUrl: 'app/layouts/header/header.html',
                        controller: 'BrandedHeaderController',
                        controllerAs: 'vm'
                    },
                    'footer@': {
                        templateUrl: 'app/layouts/footer/footer.html',
                        controller: 'BrandedFooterController',
                        controllerAs: 'vm'
                    },
                    'cookies@': {
                        templateUrl: 'app/components/cookies-consent/cookies-banner.html',
                        controller: 'CookiesBannerController',
                        controllerAs: 'vm',
                    }
                },
                resolve : {
                    $uibModalInstance : [function(){
                        return {};
                    }],
                    terms : ['TermsService','$stateParams','currentPropertyGroup','$q',function(TermsService,$stateParams,currentPropertyGroup,$q){

                        var cb = function(termsText){
                            return {
                                terms : termsText.terms
                            }
                        };
                        var errCb = angular.noop;

                        var termsPromises = [];
                        var lang = $stateParams.lang;
                        var termsType = $stateParams.termsType;
                        var termsPropertyId = $stateParams.termsPropertyId;
                        var termsVoucherId = $stateParams.termsVoucherId;

                        var termsId = $stateParams.termsId;//specific hardcoded terms id

                        var termsPromise = TermsService.get({
                                termsId: termsId,
                                propertyId : termsPropertyId,
                                voucherId : termsVoucherId,
                                lang : lang,
                                termsType : termsType,
                                groupCode: typeof currentPropertyGroup !== 'undefined' ?  currentPropertyGroup.code : undefined
                            }).$promise.then(
                            function (response) {
                                return cb(response);
                            },
                            function (err) {
                                return errCb(err);
                            }.bind(this));

                        termsPromises.push(termsPromise);

                        var terms = $q.all(termsPromises);
                        return terms;

                    }]
                }
            })
        ;
    }
})();
