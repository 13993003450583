(function() {
    'use strict';
    angular
        .module('vobeApp')
        .factory('PublicSitePartKeys', PublicSitePartKeys);

    PublicSitePartKeys.$inject = ['$resource'];

    function PublicSitePartKeys ($resource) {
        var resourceUrl =  'api/public-site-part-keys?partName=:partName';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    }
})();
