(function() {
    'use strict';

    angular.module('vobeApp').controller('PropertyVoucherPackageDetailDialogController', PropertyVoucherPackageDetailDialogController);

    function PropertyVoucherPackageDetailDialogController(
        $scope,
        $rootScope,
        $uiRouterGlobals,
        $state,
        $uibModalInstance,
        currentProperty,
        currentPropertyGroup,
        allVouchers,
        ShoppingCartAdd,
        $q,
        DetermineItemLanguage,
        Analytics,
        voucherConfig,
        shoppingCart,
        $sce,
        ModalService,
        voucherPool,
        $window,
        $timeout,
        ENV,
        PromotionCodeService,
        ShoppingCartItemDetailsService,
        CurrencyExchange,
        $filter,
        currentLanguage,
        voucherId,
        propertyCategories,
        groupCategories,
        contextType
    ) {
        'ngInject';
        var vm = this;


        vm.$onInit = function(){
            console.log('=> $onInit \t PropertyVoucherPackageDetailDialogController('+voucherId+')')
            /**
             * resolved values from parent state
             */
            vm.addToCartProgress = false;
            vm.shoppingCart = shoppingCart;
            vm.currentProperty = currentProperty;
            vm.currentPropertyGroup = currentPropertyGroup;
            var isGroup = typeof vm.currentPropertyGroup !== 'undefined';
            vm.allVouchers = allVouchers;//unused

            /**
             * resolved values from this state
             */
            vm.voucherPool = voucherPool;

            /**
             * params from URL
             */
            vm.voucherId = voucherId;

            /**
             * variables used in modal
             */
            //populate dropdown default selection
            vm.$stateParams = $uiRouterGlobals.params;
            vm.currentVoucher;
            vm.voucherClassActive = $uiRouterGlobals.params.cat || ($uiRouterGlobals.current.data ? $uiRouterGlobals.current.data.voucherClassActive : 'all');
            vm.selectedAmount = 0;
            vm.selectedAmountAfterDiscount = 0;
            getDefaultVoucher(); //this will set vm.currentVoucher, also this will set vm.voucherPool if not already set
            vm.selectedTypedAmount='';//this is in current currency
            vm.selectedTypedAmountAfterDiscount = '';//this is in current currency
            //
            vm.voucherProperty = (typeof vm.currentPropertyGroup === 'undefined') ? getVoucherPropertyForIndividualSite() : getVoucherPropertyForGroupSite();
            vm.isBulk = ($uiRouterGlobals.params.bulk || $uiRouterGlobals.params.contextType == 'refunds' || voucherConfig[currentProperty.hotelId].configMap.isBulk === 'true');

            vm.addItemToCartAndRefresh = addItemToCartAndRefresh;
            vm.addItemToCartAndCheckout = addItemToCartAndCheckout;
            vm.buyInBulk = buyInBulk;
            vm.clear = clear;
            vm.updateSelectedAmountAfterDiscount = updateSelectedAmountAfterDiscount;
            vm.updateSelectedTypedAmountAfterDiscount = updateSelectedTypedAmountAfterDiscount;
            vm.getSelectedAmountInContext = getSelectedAmountInContext;
            vm.getSelectedAmountAfterDiscountInContext = getSelectedAmountAfterDiscountInContext;
            vm.isRefunds = contextType == 'refunds';

            //currency
            vm.baseCurrency = {
                code : vm.currentVoucher.currency
            };

            vm.currentCurrency = {
                code : vm.baseCurrency.code
            }
            var currentCurrency = CurrencyExchange.currencies.current;//current currency might be NULL if this is group engine
            if(currentCurrency && currentCurrency!=null){
                vm.currentCurrency.code = currentCurrency.code;
            }

            $rootScope.$on('CurrencyExchangeProvider.changeCurrency', function(event, args) {
                vm.currentCurrency.code = args.code;
            });

            vm.appliedPromotionCode = PromotionCodeService.findApplicablePromo(vm.shoppingCart, vm.currentProperty, isGroup, vm.currentVoucher);

            vm.getImageFileName = getImageFileName;

            vm.range = [];

            if (vm.currentVoucher.totalRate.increment > 0) {
                vm.range = range(vm.currentVoucher.totalRate.priceMin, vm.currentVoucher.totalRate.priceMax, vm.currentVoucher.totalRate.increment);
            }

            vm.disableGeneratedAmounts = (vm.currentVoucher && vm.currentVoucher.meta && vm.currentVoucher.meta.disableGeneratedAmounts);
            if(!vm.voucherPool.enableTypedAmounts && vm.disableGeneratedAmounts){
                vm.disableGeneratedAmounts = false;
            }
            if(vm.disableGeneratedAmounts){
                vm.selectedTypedAmount = $filter('exchangenumber')(vm.currentVoucher.totalRate.priceMin,vm.currentVoucher.currency,vm.currentCurrency.code);
                vm.updateSelectedTypedAmountAfterDiscount();
            }

            vm.displayActionButtons = {
                display : true,
                errorCode : undefined,
                errorValue : undefined
            };
            vm.maxTransactionLimit = parseInt(voucherConfig[currentProperty.hotelId].configMap.maxTransactionLimit) || 0;
            vm.limitQuantity = parseInt(voucherConfig[currentProperty.hotelId].configMap.limitQuantity) || 0;


            if (vm.maxTransactionLimit > 0 || vm.limitQuantity > 0) {
                //console.log('test', vm.shoppingCart.shoppingCartItems.totalPerProperty[currentProperty.hotelId]);
                displayActionButtons(
                    vm.maxTransactionLimit,
                    vm.limitQuantity,
                    vm.selectedAmountAfterDiscount,
                    vm.shoppingCart.shoppingCartItems.totalPerProperty[currentProperty.hotelId],
                    vm.shoppingCart.shoppingCartItems.items.length
                );
            }


            /**
             * variables needed for pools
             */
            vm.voucherPoolObjectVouchersArray = [];
            angular.forEach(vm.voucherPool.vouchers,function(voucher,vkey){
                vm.voucherPoolObjectVouchersArray.push(voucher);
            });
            vm.refresh = refresh;
            vm.homogeneous = isVoucherPoolHomogeneous();
            vm.propertyCategories = propertyCategories;
            vm.groupCategories = groupCategories;
            vm.poolCategory = getPoolCategoryFromVoucherPool(vm.voucherPool);

            /**
             * analytics
             */
            try {
                trackProductDetail();
            } catch (e) {
                console.error(e);
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function getActiveVoucherCategory() {
            console.log('=> getActiveVoucherCategory ...', vm.voucherClassActive);

            var category = {
                id: 1,
                friendlyName: 'all',
                name: 'All'
            }

            //some vouchers don't have categories, so we need to check if there are any categories at all
            if (vm.currentVoucher && vm.currentVoucher.categories && Object.keys(vm.currentVoucher.categories).length > 0) {
                Object.keys(vm.currentVoucher.categories).forEach(function (key) {
                    var currentCategory = vm.currentVoucher.categories[key].category;
                    console.log('currentCategory', currentCategory);
                    if (currentCategory && currentCategory.friendlyName === vm.voucherClassActive) {
                        console.log('=> getActiveVoucherCategory ... found: ', currentCategory);
                        category = currentCategory;
                        return;
                    }
                });
            }
            console.log('=> getActiveVoucherCategory ... found: ', category);
            return category;
        }

        function getPoolCategoryFromVoucherPool(voucherPool) {
            var categories = typeof vm.currentPropertyGroup !== 'undefined' ? vm.groupCategories : vm.propertyCategories;
            var poolCategory;
            angular.forEach(categories, function (c, cKey) {
                if (c.id === voucherPool.poolId) {
                    poolCategory = c;
                }
            });
            return poolCategory;
        }

        function getPoolCategory(voucher) {
            var categories = typeof vm.currentPropertyGroup !== 'undefined' ? vm.groupCategories : vm.propertyCategories;
            var poolCategory;
            angular.forEach(categories, function (c, cKey) {
                if (c.id === voucher.pool) {
                    poolCategory = c;
                }
            });
            return poolCategory;
        }


        function getDefaultVoucher() {

            var useFirst = !vm.voucherId || vm.voucherId.length === 0;
            var defaultVoucher;
            angular.forEach(vm.allVouchers.wrapper.voucherPools, function (pool, key) {
                angular.forEach(pool.vouchers, function (voucher, voucherKey) {
                    if (useFirst && !defaultVoucher) {
                        defaultVoucher = voucher;
                        vm.voucherPool = pool;
                    } else if (voucher.id === parseInt(vm.voucherId, 10)) {
                        defaultVoucher = voucher;
                        vm.voucherPool = pool;
                    }
                });
            });

            if(!defaultVoucher && vm.voucherPool) {
                //it could be distant page of a group
                angular.forEach(vm.voucherPool.vouchers, function (voucher, voucherKey) {
                    if (useFirst && !defaultVoucher) {
                        defaultVoucher = voucher;
                    } else if (voucher.id === parseInt(vm.voucherId, 10)) {
                        defaultVoucher = voucher;
                    }
                });
            }

            if (defaultVoucher) {
                vm.currentVoucher = defaultVoucher;
                vm.currentVoucher.voucherDescriptionTrustedHtml = $sce.trustAsHtml(defaultVoucher.voucherDescription);
                vm.currentVoucher.voucherDescription2TrustedHtml = !defaultVoucher.voucherDescription2 || defaultVoucher.voucherDescription2 == null ? $sce.trustAsHtml('<span></span>') : $sce.trustAsHtml(defaultVoucher.voucherDescription2);
                //TODO: selected amounts
                vm.selectedAmount = defaultVoucher.totalRate.priceMin;
                vm.selectedAmountAfterDiscount = defaultVoucher.totalRateAfterDiscount.priceMin;
            } else {
                $state.transitionTo('list', $uiRouterGlobals.params, {inherit: true, notify: true, reload: true});
            }
        }

        function updateSelectedAmountAfterDiscount() {
            //reset typed amounts
            vm.selectedTypedAmount='';
            vm.selectedTypedAmountAfterDiscount='';
            //calculate after discount
            var beforeDiscountAmount = vm.selectedAmount;
            var newSelectedAmountAfterDiscount = ShoppingCartItemDetailsService.calculateSelectedAmountAfterDiscount(beforeDiscountAmount,vm.appliedPromotionCode);

            if (vm.maxTransactionLimit > 0 || vm.limitQuantity > 0) {
                //console.log('test', vm.shoppingCart.shoppingCartItems.totalPerProperty[currentProperty.hotelId]);
                displayActionButtons(
                    vm.maxTransactionLimit,
                    vm.limitQuantity,
                    newSelectedAmountAfterDiscount,
                    vm.shoppingCart.shoppingCartItems.totalPerProperty[currentProperty.hotelId],
                    vm.shoppingCart.shoppingCartItems.items.length
                );
            }
            vm.selectedAmountAfterDiscount = newSelectedAmountAfterDiscount;
        }

        function updateSelectedTypedAmountAfterDiscount() {

            //calculate after discount
            var beforeDiscountAmount = $filter('exchangenumber')(vm.selectedTypedAmount,vm.currentCurrency.code,vm.currentVoucher.currency);
            var newSelectedAmountAfterDiscount = ShoppingCartItemDetailsService.calculateSelectedAmountAfterDiscount(beforeDiscountAmount,vm.appliedPromotionCode);

            if (vm.maxTransactionLimit > 0 || vm.limitQuantity > 0) {
                //console.log('test', vm.shoppingCart.shoppingCartItems.totalPerProperty[currentProperty.hotelId]);
                displayActionButtons(
                    vm.maxTransactionLimit,
                    vm.limitQuantity,
                    newSelectedAmountAfterDiscount,
                    vm.shoppingCart.shoppingCartItems.totalPerProperty[currentProperty.hotelId],
                    vm.shoppingCart.shoppingCartItems.items.length
                );
            }
            vm.selectedTypedAmountAfterDiscount = $filter('exchangenumber')(newSelectedAmountAfterDiscount,vm.currentVoucher.currency,vm.currentCurrency.code);
        }

        function getSelectedAmountInContext(){
            if(vm.selectedTypedAmount && vm.selectedTypedAmount>0){
                return $filter('exchangenumber')(vm.selectedTypedAmount,vm.currentCurrency.code,vm.currentVoucher.currency);
            }
            return vm.selectedAmount;
        }

        function getSelectedAmountAfterDiscountInContext(){
            if(vm.selectedTypedAmountAfterDiscount && vm.selectedTypedAmountAfterDiscount>0){
                return $filter('exchangenumber')(vm.selectedTypedAmountAfterDiscount,vm.currentCurrency.code,vm.currentVoucher.currency);
            }
            return vm.selectedAmountAfterDiscount;
        }

        function getVoucherPropertyForIndividualSite(){
            var voucher = vm.currentVoucher;
            var property = vm.currentProperty;
            console.log('getVoucherPropertyForIndividualSite', voucher, property)
            if(voucher.hotelId != property.hotelId){
                //try among master hotels
                if(vm.currentProperty.masterProperties && vm.currentProperty.masterProperties!=null){
                    angular.forEach(vm.currentProperty.masterProperties,function(hotel,hkey){
                        if(hotel.hotelId==voucher.hotelId){
                            property = hotel;
                        }
                    });
                }
            }

            return property;
        }

        function getVoucherPropertyForGroupSite(){
            if(!vm.currentVoucher){
                return undefined;
            }
            if(!vm.currentPropertyGroup || !vm.currentPropertyGroup.hotels){
                return undefined;
            }
            var property = undefined;
            angular.forEach(vm.currentPropertyGroup.hotels,function(hotel,hkey){
                if(hotel.hotelId==vm.currentVoucher.hotelId){
                    property = hotel;
                }
            })
            return property;
        }

        function isVoucherPoolHomogeneous(){

            var voucherIds = [];
            angular.forEach( vm.voucherPool.vouchers,function(voucher,vkey){
                if(!voucherIds.includes(voucher.id)){
                    voucherIds.push(voucher.id);
                }
            });
            return voucherIds.length<=1;
        }

        function trackProductDetail() {
            if(!vm.currentVoucher){
                return;
            }
            Analytics.addProduct(
                vm.currentVoucher.id, //productId
                vm.currentVoucher.voucherName, //name
                'cash', //category
                vm.voucherProperty.hotelCode, //brand
                '', //variant
                getSelectedAmountAfterDiscountInContext(), //price
                '1', //quantity
                '', //coupon
                '' //position
            );
            Analytics.trackDetail();

            //tag manager
            var dataLayer = $window.dataLayer = $window.dataLayer || [];
            var voucherCategory = {
                id: 1,
                name: 'All'
            }
            //get the voucher category
            if (vm.voucherClassActive.toLowerCase() !== 'all') {
                voucherCategory = vm.currentVoucher.categories[vm.voucherClassActive] ? vm.currentVoucher.categories[vm.voucherClassActive].category : undefined;
            }

            console.log('voucherCategory', voucherCategory);

            //ga4
            dataLayer.push({
                event: "view_item",
                ecommerce: {
                    currency: vm.currentVoucher.currency,
                    value: getSelectedAmountAfterDiscountInContext(),
                    items: [
                        {
                            item_id: vm.currentVoucher.id,
                            item_name: vm.currentVoucher.voucherName,
                            affiliation: vm.currentProperty.hotelName,
                            item_list_id: voucherCategory.id,
                            item_list_name: voucherCategory.name,
                            discount: getSelectedAmountInContext() - getSelectedAmountAfterDiscountInContext(),
                            index: 0,
                            item_category: "Gift Voucher",
                            price: getSelectedAmountAfterDiscountInContext(),
                            quantity: 1
                        }
                    ]
                }
            });

            console.log('last dataLayer', dataLayer[dataLayer.length - 1]);

            //send voucher detail view.
            if (ENV !== 'dev' && typeof posthog !== 'undefined') {
                posthog.capture('voucherDetail', {
                    voucherName: vm.currentVoucher.voucherName,
                    voucherId: vm.currentVoucher.id,
                    voucherPrice: getSelectedAmountInContext(),
                    voucherCategory: vm.currentVoucher.voucherType
                });
            }
        }

        function trackAddToCart(itemVm) {
            console.log('=> trackAddToCart ...', itemVm)

            Analytics.addProduct(
                vm.currentVoucher.id, //productId
                vm.currentVoucher.voucherName, //name
                'package', //category
                vm.voucherProperty.hotelCode, //brand
                '', //variant
                getSelectedAmountAfterDiscountInContext(), //price
                '1', //quantity
                '', //coupon
                '' //position
            );
            Analytics.trackCart('add');

            //tag manager
            var dataLayer = $window.dataLayer = $window.dataLayer || [];

            //ga4_event
            dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                    currency: itemVm.itemCurrency,
                    value: itemVm.itemTotalPriceWithDiscount,
                    affiliation: "In1Solutions Gift Voucher App",
                    items: [
                        {
                            item_id: itemVm.itemId.toString(),
                            item_name: itemVm.itemName,
                            affiliation: currentProperty.hotelName,
                            item_category: 'Gift Voucher',
                            discount: (itemVm.itemTotalPrice - itemVm.itemTotalPriceWithDiscount),
                            item_brand: itemVm.propertyName,
                            price: itemVm.itemTotalPriceWithDiscount,
                            quantity: 1,
                            item_list_id: itemVm.itemCategoryId,
                            item_list_name: itemVm.itemCategoryName
                        }
                    ]
                }
            });

            //posthog
            if (ENV !== 'dev' && typeof posthog !== 'undefined') {
                posthog.capture('voucherAddToCart', {
                    voucherName: itemVm.itemName,
                    voucherId: itemVm.itemId,
                    voucherPrice: itemVm.itemTotalPrice,
                    voucherCurrencyCode: itemVm.itemCurrency,
                    voucherCategory: itemVm.itemType
                });
            }
        }




        function getImageFileName(){

            var giftCard = vm.voucherPool.giftCard;
            if(giftCard && giftCard.imageUrl && giftCard.imageUrl.trim().length>0){
                return useImgix( giftCard.imageUrl);
            }

            var firstVoucher = undefined;
            angular.forEach(vm.voucherPool.vouchers,function(voucher,vkey){
                firstVoucher = firstVoucher || voucher;
            });
            if (firstVoucher && firstVoucher.images && firstVoucher!=null && firstVoucher.images[7]) {
                return useImgix(firstVoucher.images[7]);
            } else {
                return "//in1-image-library.imgix.net/no-image.jpg";
            }
        }

        function useImgix(imgUrl){
            var old_host = 'dddo4e1h8fdy4.cloudfront.net';
            var new_host = 'in1-image-library.imgix.net';
            return imgUrl.replace(old_host, new_host);
        }

        function displayActionButtons(maxTransactionLimit, maxItemLimit, selectedAmount, total, totalItems) {
            //console.log('displayActionButtons', maxTransactionLimit, selectedAmount, total);
            //1.
            var maxTransactionLimitPass = false;
            if (angular.isUndefined(selectedAmount) || angular.isUndefined(total) || maxTransactionLimit == 0) {
                maxTransactionLimitPass = true;
            }
            else if (total + selectedAmount <= maxTransactionLimit) {
                maxTransactionLimitPass = true;
            }
            //2.
            var maxItemsLimitPass = false;
            if (angular.isUndefined(totalItems) || maxItemLimit == 0) {
                maxItemsLimitPass = true;
            }
            else if (totalItems + 1 <= maxItemLimit) {
                maxItemsLimitPass = true;
            }

            if(!maxTransactionLimitPass){
                vm.displayActionButtons.display = false;
                vm.displayActionButtons.errorCode = "vouchers.maxtransactionlimit";
                vm.displayActionButtons.errorValue = maxTransactionLimit;
            }
            else if (!maxItemsLimitPass){
                vm.displayActionButtons.display = false;
                vm.displayActionButtons.errorCode = "vouchers.maxitemslimit";
                vm.displayActionButtons.errorValue = maxItemLimit;
            }

            return maxTransactionLimitPass && maxItemsLimitPass;
        }

        function range(min, max, step) {
            //console.log(min);
            //console.log(max);
            //console.log(step);

            step = step || 1;
            if (step == 0) {
                step = 1;
            }
            if (max == 0) {
                max = min;
            }

            //console.log("doing range again");

            var defaultAmount = voucherConfig[currentProperty.hotelId].configMap.cashDefault;
            var closestRank = Math.abs(vm.selectedAmount - defaultAmount);

            var input = [];
            for (var i = min; i <= max; i += step) {
                input.push(i);
                var valueRank = Math.abs(i - defaultAmount);
                if (valueRank <= closestRank) {
                    //if(vm.selectedAmount==0){
                    vm.selectedAmount = i;
                    updateSelectedAmountAfterDiscount(i);
                    //}
                    closestRank = valueRank;
                }
            }

            return input;
        }

        function addItemToCartAndCheckout(event) {
            console.log('=> addItemToCartAndCheckout...')
            vm.addToCartProgress = true;
            addItemToCart(event, goToShoppingCart);
        }

        function addItemToCartAndRefresh(event) {
            vm.addToCartProgress = true;
            addItemToCart(event, backToList);
        }

        function addItemToCart(event, callback) {
            console.log('=> addItemToCart...')
            var category = getActiveVoucherCategory();
            event.preventDefault();
            var itemVM = {
                propertyId: vm.voucherProperty.hotelId,
                propertyCode: vm.voucherProperty.hotelCode,
                propertyName: vm.voucherProperty.hotelName,
                propertyCountry: vm.voucherProperty.hotelCountry,
                itemId: vm.currentVoucher.id,
                itemName: vm.currentVoucher.voucherName,
                itemDescription: vm.currentVoucher.voucherDescription,
                collectionDescription: vm.currentVoucher.collectDescription,
                itemTotalPrice: getSelectedAmountInContext(),//discounts later ?
                itemTotalPriceWithDiscount: getSelectedAmountAfterDiscountInContext(),
                itemCurrency: vm.currentVoucher.currency,
                itemType: vm.currentVoucher.voucherType,
                itemEmailCategoryId: !vm.currentVoucher.meta ? null : vm.currentVoucher.meta.selectedEmailCategoryId,
                itemFixedDeliveryType: !vm.currentVoucher.meta ? null : vm.currentVoucher.meta.fixedDeliveryOption,
                itemMeta: !vm.currentVoucher.meta ? null : vm.currentVoucher.meta,
                itemImageFileName: getImageFileName(),
                passkey: $uiRouterGlobals.params.passkey || '',
                channelId: (typeof currentPropertyGroup !== 'undefined' ? currentPropertyGroup.masterProperty.hotelId : $uiRouterGlobals.params.channel) || 0,
                selectedCurrency: CurrencyExchange.currencies.current ? CurrencyExchange.currencies.current.code : vm.currentVoucher.currency,
                exchange : CurrencyExchange.currencies.current ? CurrencyExchange.currencies.current.rate : 1,
                itemCategoryName: category.name,
                itemCategoryId: category.id,
            };

            //build additional fields
            var additionalFields = {};
            if(vm.currentVoucher.meta && vm.currentVoucher.meta.redemptionEndDate && vm.currentVoucher.meta.redemptionEndDate!=null ){
                additionalFields['not_valid_after_date'] = new Date(vm.currentVoucher.meta.redemptionEndDate).getTime()/1000;
            }

            itemVM.additionalFields = additionalFields;

            itemVM.itemLanguage = DetermineItemLanguage.getLanguage(itemVM);

            var cb = callback || angular.noop;
            addItem(itemVM, cb);
            //we always dismiss the dialog =
            //vm.clear();
        }

        function buyInBulk(event) {
            vm.clear();
            $timeout(function () {
                var params = {};//angular.extend({},$uiRouterGlobals.params);
                params.voucherId = vm.currentVoucher.id;

                params.voucherPool = vm.voucherPool;
                params.focusedAmount = getSelectedAmountInContext();
                params.appliedPromotionCode = vm.appliedPromotionCode;
                $state.transitionTo('bulk-add', params, { inherit: true, notify: true, reload: false });
            },10);
        }

        function backToList(shoppingCart) {
            $uibModalInstance.close('addToCart');
            //.then(function() {
            //});
        }


        function refresh() {
            console.log('=> refresh ...', $uiRouterGlobals.params)
            var params = $uiRouterGlobals.params;
            params.voucherPool = vm.voucherPool;
            params.voucherId = vm.currentVoucher.id;//should be updated id after select pool
            params.doNotZoomIn = true;
            $uibModalInstance.close(false);
            $state.transitionTo($state.current.name, params, {
            	  reload: $state.current.name, inherit: true, notify: true
            });
        }

        function goToShoppingCart() {
            console.log('=> goToShoppingCart...', $uibModalInstance)
            $uibModalInstance.close('shoppingCart');
        }

        function addItem(itemVM, callback) {
            console.log('=> addItem...')
            var cb = callback || angular.noop;

            //params needed to create shopping cart from scratch
            //build shopping cart locale
            var variant = '';
            if ($uiRouterGlobals.params.contextType) {
                variant = '_' + $uiRouterGlobals.params.contextType;
            }
            variant += (variant.length === 0 ? '_' : '') + currentProperty.hotelId;

            var src = undefined;
            if (typeof currentPropertyGroup !== 'undefined') {
                src = currentPropertyGroup.code;
            }
            if ($uiRouterGlobals.params.src) {
                src = $uiRouterGlobals.params.src;
            }
            itemVM.requestParams = {};
            itemVM.requestParams.ref = $uiRouterGlobals.params.ref;
            itemVM.requestParams.src = src;
            itemVM.requestParams.lang = currentLanguage + variant;
            itemVM.requestParams.contextType = $uiRouterGlobals.params.contextType;
            itemVM.requestParams.propertyType = $uiRouterGlobals.params.propertyType;
            itemVM.requestParams.propertyCode = $uiRouterGlobals.params.propertyId;
            itemVM.requestParams.customerRef = $uiRouterGlobals.params.customerRef;

            return ShoppingCartAdd.add(
                itemVM,
                function(shoppingCart) {
                    trackAddToCart(itemVM);
                    vm.addToCartProgress = false;
                    $rootScope.$broadcast('itemAddedToCart', { shoppingCart: shoppingCart, openShoppingCartDropdown: true });
                    return cb(shoppingCart);
                },
                function(err) {
                    vm.addToCartProgress = false;
                    $rootScope.$broadcast('itemAddedToCartError', err);
                    return cb();
                }.bind(this)
            ).$promise;
        }

    }
})();
