(function() {
    'use strict';

    angular.module('vobeApp').controller('LanguageDropdownController', LanguageDropdownController);

    LanguageDropdownController.$inject = [
        '$scope',
        '$rootScope',
        '$state',
        '$stateParams',
        'currentProperty',
        'shoppingCartConfigs',
        '$translate',
        'JhiLanguageService',
        'tmhDynamicLocale',
        '$q'
    ];

    function LanguageDropdownController(
        $scope,
        $rootScope,
        $state,
        $stateParams,
        currentProperty,
        shoppingCartConfigs,
        $translate,
        JhiLanguageService,
        tmhDynamicLocale,
        $q
    ) {
        var vm = this;

        vm.changeLanguage = changeLanguage;
        vm.languages = ['en'];

        var propertyId = currentProperty.hotelId;
        if (shoppingCartConfigs[propertyId] && shoppingCartConfigs[propertyId].configMap && shoppingCartConfigs[propertyId].configMap.languages) {
            var languagesString = shoppingCartConfigs[propertyId].configMap.languages;
            vm.languages = languagesString.replace(/\s/g, '').split(',');
            if(propertyId == 15311){
                vm.languages.push('ar');
            }
        }
        //JhiLanguageService.getAll().then(function (languages) {
        //    vm.languages = languages;
        //});

        function changeLanguage(languageKey) {
            var languageChangePromises = [];
            languageChangePromises.push($translate.use(languageKey));
            try {
                languageChangePromises.push(tmhDynamicLocale.set(languageKey));
            } catch (e) {
                // TODO: handle exception
            }
            var promise = $q.all(languageChangePromises);
            promise.then(function() {
                var params = $stateParams;
                //params.lang= null;
                $state.transitionTo($state.current, params, { reload: true, inherit: false, notify: true });
                //$state.reload();
            });
        }
    }
})();
