(function() {
    'use strict';

    angular
        .module('vobeApp')
        .factory('VouchersProcessService', VouchersProcessService);

    VouchersProcessService.$inject = ['$q', '$http', 'PromotionCodeService', 'ShoppingCartItemDetailsService'];

    function VouchersProcessService($q, $http, PromotionCodeService, ShoppingCartItemDetailsService) {

        var dataPromise;

        var service = {
            processGroupVouchers : processGroupVouchers,
            setPoolMinMaxValues : setPoolMinMaxValues,
            extractPageHolder: extractPageHolder
        };

        return service;

        function processGroupVouchers(groupVouchers,currentProperty,shoppingCart,voucherConfig,propertyGiftCards) {
            //1. if there are discounts, filter them out here, it's not nice to do it out in the controller or html via filters... trust me.
            if (!shoppingCart.shoppingCartDiscounts.empty) {
                var voucherPoolsGenerated = [];
                angular.forEach(groupVouchers.wrapper.voucherPools, function(voucherPool, key) {
                    var voucherFirstKey = Object.keys(voucherPool.vouchers)[0];
                    var voucherId = voucherPool.vouchers[voucherFirstKey].id;
                    var voucherHotelId = voucherPool.vouchers[voucherFirstKey].hotelId;
                    var isVoucherApplicable = false;
                    //loop all promos
                    angular.forEach(shoppingCart.shoppingCartDiscounts.discounts,function(d,dIndex){
                        if(!d.hideInapplicable){
                            isVoucherApplicable = true;//if discount is not hiding voucher, then it's applicable
                        }
                        if (d.propertyId && d.propertyId!=null && d.propertyId>0 && d.propertyId!=voucherHotelId){
                            //voucher is applicable if belongs to different property
                            isVoucherApplicable = true;
                        }
                        else if (d.filteredItemIds.includes(voucherId)) {
                            //voucher is applicable if is assigned to this discount
                            isVoucherApplicable = true;
                        }
                    });
                    if(isVoucherApplicable){
                        voucherPoolsGenerated.push(voucherPool);
                    }
                });
                groupVouchers.wrapper.voucherPools = voucherPoolsGenerated;
                groupVouchers.voucherPoolsCount = voucherPoolsGenerated.length;
                //TODO: voucherClass ?
            }

            var generatedCashPoolsWithGiftCard = [];
            var generatedCashPoolsFeatured = [];
            var featuredVouchers = []; //these are amounts
            var propertyConfig = voucherConfig[currentProperty.hotelId] || {};

            if (propertyConfig && propertyConfig.configMap && propertyConfig.configMap.featuredCashVouchers) {
                var value = propertyConfig.configMap.featuredCashVouchers.replace(/ /g, '');
                featuredVouchers = value.split(',');
            }
            //iterate each pool
            angular.forEach(groupVouchers.wrapper.voucherPools, function(voucherPool, pkey) {

                //GENERATE MIN/MAX VALUES
                voucherPool.minValue = 0;
                voucherPool.maxValue = 0;
                voucherPool.minValueAfterDiscount = 0;
                voucherPool.maxValueAfterDiscount = 0;
                voucherPool.currency = currentProperty.hotelCurrency;

                if(voucherPool.type=='cash'){
                    var count = 0;
                    var firstVoucher;
                    angular.forEach(voucherPool.vouchers, function(voucher, vkey) {
                        count++;
                        firstVoucher = firstVoucher || voucher;
                    });
                    if(count==1){
                        var generatedCashVouchers = {};
                        //generate cash vouchers, this can be removed if cash will be using package card and dialog components
                        var min = firstVoucher.totalRate.priceMin;
                        var max = (firstVoucher.totalRate.priceMax == 0 ? firstVoucher.totalRate.priceMin : firstVoucher.totalRate.priceMax);
                        var increment = firstVoucher.totalRate.increment ? firstVoucher.totalRate.increment : 25;

                        var base = 0;
                        for(var nextAmount = 0; nextAmount<=min; nextAmount = nextAmount+increment){
                            base = nextAmount;
                        }
                        var generatedCashVouchersIndex = 0;
                        for(var amount = min, iteration=0; amount <= max; amount=(iteration==0 ? base : amount )+increment, iteration++){
                            var generatedVoucher = angular.copy(firstVoucher);
                            //change price
                            generatedVoucher.voucherPrice = amount;
                            var appliedPromotionCode = PromotionCodeService.findApplicablePromo(shoppingCart, currentProperty, true, generatedVoucher);
                            var amountDiscount = ShoppingCartItemDetailsService.calculateSelectedAmountAfterDiscount(amount,appliedPromotionCode);

                            //change total rate
                            generatedVoucher.totalRate.priceMin=amount;
                            generatedVoucher.totalRate.priceMax=amount;
                            generatedVoucher.totalRateAfterDiscount.priceMin=amountDiscount;
                            generatedVoucher.totalRateAfterDiscount.priceMax=amountDiscount;
                            generatedVoucher.totalRate.increment=firstVoucher.totalRate.increment ? firstVoucher.totalRate.increment : 0;
                            //change total rate after discount
                            generatedVoucher.totalRateChannel.priceMin=amount;
                            generatedVoucher.totalRateChannel.priceMax=amount;
                            generatedVoucher.totalRateChannelAfterDiscount.priceMin=amountDiscount;
                            generatedVoucher.totalRateChannelAfterDiscount.priceMax=amountDiscount;
                            generatedVoucher.totalRateChannel.increment=firstVoucher.totalRate.increment ? firstVoucher.totalRate.increment : 0;//TODO fix after adding inct to channel rates in DB
                            //because map key must be unique, with cash-wildcard it's just index (as voucher id is the same)
                            generatedCashVouchers[generatedCashVouchersIndex]=generatedVoucher;
                            generatedCashVouchersIndex++;
                            if(generatedCashVouchersIndex>=500){
                                break;
                            }
                        }
                        voucherPool.vouchers = generatedCashVouchers;
                    }
                }


                //todo: names here?
                //voucherPool.cashVoucherName = "Monetary";
                angular.forEach(voucherPool.vouchers, function(voucher, vkey) {

                    setPoolMinMaxValues(voucherPool, voucher);
                    //fix currency
                    voucherPool.currency = voucher.currency;
                    //fix name
                    //voucher.currency + ' ' + $filter('number')(voucher.totalRate.minvoucher, 2);

                    //generate pool with featured cash voucher
                    if(~featuredVouchers.indexOf('' + voucher.totalRate.priceMin)){
                        var generatedFeaturedPool={};
                        generatedFeaturedPool.minValue = voucher.totalRate.priceMin;
                        generatedFeaturedPool.maxValue = (voucher.totalRate.priceMax == 0 ? voucher.totalRate.priceMin : voucher.totalRate.priceMax);
                        generatedFeaturedPool.minValueAfterDiscount = voucher.totalRateAfterDiscount.priceMin;
                        generatedFeaturedPool.maxValueAfterDiscount = (voucher.totalRateAfterDiscount.priceMax == 0 ? voucher.totalRateAfterDiscount.priceMin : voucher.totalRateAfterDiscount.priceMax);
                        generatedFeaturedPool.currency = voucher.currency;
                        generatedFeaturedPool.vouchers = [];
                        generatedFeaturedPool.vouchers.push(voucher);
                        generatedFeaturedPool.sequence = voucher.totalRate.priceMin;
                        generatedFeaturedPool.cashVoucherName = voucherPool.cashVoucherName;
                        generatedFeaturedPool.type = voucherPool.type;
                        generatedFeaturedPool.featured = true;
                        generatedCashPoolsFeatured.push(generatedFeaturedPool);
                    }
                });

                //fix sequence
                angular.forEach(voucherPool.vouchers, function(voucher, vkey) {
                    if (voucher.sequence !== 999) {
                        voucherPool.sequence = voucher.sequence;
                    } else {
                        voucherPool.sequence = voucherPool.minValue;
                    }
                });
                //voucherPool.sequence = voucherPool.minValue;
                if(propertyGiftCards){
                    angular.forEach(propertyGiftCards, function(giftCard, gkey) {
                        if(voucherPool.type=='cash' || voucherPool.type=='cash-wildcard'){
                            var generatedGiftCardPool={};
                            generatedGiftCardPool.minValue = voucherPool.minValue;
                            generatedGiftCardPool.maxValue = voucherPool.maxValue;
                            generatedGiftCardPool.minValueAfterDiscount = voucherPool.minValueAfterDiscount;
                            generatedGiftCardPool.maxValueAfterDiscount = voucherPool.maxValueAfterDiscount;
                            generatedGiftCardPool.currency = voucherPool.currency;
                            generatedGiftCardPool.vouchers = voucherPool.vouchers;
                            generatedGiftCardPool.sequence = voucherPool.minValue;
                            generatedGiftCardPool.cashVoucherName = voucherPool.cashVoucherName;
                            generatedGiftCardPool.type = voucherPool.type;
                            generatedGiftCardPool.giftCard = giftCard;
                            generatedCashPoolsWithGiftCard.push(generatedGiftCardPool);
                        }
                    });
                }
            });

            //add generated gift cards
            if(generatedCashPoolsWithGiftCard.length>0){
                //add at the begining
                groupVouchers.wrapper.voucherPools = generatedCashPoolsWithGiftCard.concat(groupVouchers.wrapper.voucherPools);
            }
            if(generatedCashPoolsFeatured.length>0){
                //add at the begining
                groupVouchers.wrapper.voucherPools = generatedCashPoolsFeatured.concat(groupVouchers.wrapper.voucherPools);
            }

            groupVouchers.voucherPoolsCount = (groupVouchers && groupVouchers.wrapper && groupVouchers.wrapper.voucherPools) ? groupVouchers.wrapper.voucherPools.length : 0;

        }

        function extractPageHolder(groupVouchers){

            return {
                nrOfElements: groupVouchers.nrOfElements || groupVouchers.voucherPoolsCount,
                firstElementOnPage: groupVouchers.firstElementOnPage || 0,
                lastElementOnPage: groupVouchers.lastElementOnPage || 0,
                firstLinkedPage: groupVouchers.firstLinkedPage || 0,
                lastLinkedPage: groupVouchers.lastLinkedPage || 0,
                maxLinkedPages: groupVouchers.maxLinkedPages || 0,
                page: groupVouchers.page || 0,
                pageCount: groupVouchers.pageCount || 0,
                pageSize: groupVouchers.pageSize || 0
            }
        }


        function setPoolMinMaxValues(voucherPool, voucher){
            voucherPool.minValue = voucherPool.minValue || 0;
            voucherPool.maxValue = voucherPool.maxValue || 0;
            voucherPool.minValueAfterDiscount = voucherPool.minValueAfterDiscount || 0;
            voucherPool.maxValueAfterDiscount = voucherPool.maxValueAfterDiscount || 0;
            //get min and max
            if (voucherPool.minValue == 0 || voucherPool.minValue > voucher.totalRate.priceMin) {
                voucherPool.minValue = voucher.totalRate.priceMin;
            }
            if (voucherPool.maxValue == 0 || voucherPool.maxValue <(voucher.totalRate.priceMax == 0 ? voucher.totalRate.priceMin : voucher.totalRate.priceMax)) {
                voucherPool.maxValue = (voucher.totalRate.priceMax == 0 ? voucher.totalRate.priceMin : voucher.totalRate.priceMax);
            }
            //get min and max after discount
            if (
                voucherPool.minValueAfterDiscount == 0 ||
                voucherPool.minValueAfterDiscount > voucher.totalRateAfterDiscount.priceMin
            ) {
                voucherPool.minValueAfterDiscount = voucher.totalRateAfterDiscount.priceMin;
            }
            if (
                voucherPool.maxValueAfterDiscount == 0 ||
                voucherPool.maxValueAfterDiscount < (voucher.totalRateAfterDiscount.priceMax == 0 ? voucher.totalRateAfterDiscount.priceMin : voucher.totalRateAfterDiscount.priceMax)
            ) {
                voucherPool.maxValueAfterDiscount = (voucher.totalRateAfterDiscount.priceMax == 0 ? voucher.totalRateAfterDiscount.priceMin : voucher.totalRateAfterDiscount.priceMax);
            }
        }
    }
})();
