(function() {
    'use strict';

    angular.module('vobeApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {

        function isHotelIdInGroup(currentPropertyGroup, hotelId){
            if(typeof currentPropertyGroup==='undefined'){
                return false;
            }
            var found = false;
            angular.forEach(currentPropertyGroup.hotels, function(hotel, hotelIndex) {
                if (hotel.hotelId == hotelId) {
                    found = true;
                }
            });
            return found;
        }

        function isHotelIdInMasters(currentProperty, hotelId){
            if(!currentProperty.masterProperties || currentProperty.masterProperties==null){
                return false;
            }
            var found = false;
            angular.forEach(currentProperty.masterProperties, function(hotel, hotelIndex) {
                if (hotel.hotelId == hotelId) {
                    found = true;
                }
            });
            return found;
        }

        var sendDataLayerProductImpression = function(voucherPool) {
            console.log('sendDataLayerProductImpression', voucherPool);
            var dataLayer = window.dataLayer = window.dataLayer || [];
        };

        $stateProvider
            .state('bulk-add', {
                parent: 'list',
                url: '/bulk-add?voucherId&comment&dedication',
                params: {
                    //params hidden from URL
                    appliedPromotionCode: undefined,
                    voucherPool: undefined,
                    focusedAmount: 0
                },
                data: {
                    authorities: [],
                    hideClear: true
                },
                //reloadOnSearch: false, //THIS WILL PREVENT RELOADING WHEN CAT in URL CHANGES!
                views: {
                    'content@': {
                        templateUrl: 'app/site/property/vouchers/bulk-add.html',
                        controller: 'PropertyVouchersBulkAddController',
                        controllerAs: 'vm'
                    },
                    'header@': {
                        templateUrl: 'app/layouts/header/header.html',
                        controller: 'BrandedHeaderController',
                        controllerAs: 'vm'
                    },
                    'footer@': {
                        templateUrl: 'app/layouts/footer/footer.html',
                        controller: 'BrandedFooterController',
                        controllerAs: 'vm'
                    },
                    'cookies@': {
                        templateUrl: 'app/components/cookies-consent/cookies-banner.html',
                        controller: 'CookiesBannerController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    currentProperty: [
                        'currentProperty',
                        function(currentProperty) {
                            return currentProperty;
                        }
                    ],
                    currentPropertyGroup : [
                        'currentPropertyGroup',
                        function(currentPropertyGroup) {
                            return currentPropertyGroup;
                        }
                    ],
                    currentLanguage : [
                        'currentLanguage',
                        function(currentLanguage) {
                            return currentLanguage;
                        }
                    ],
                    allVouchers: [
                        'allVouchers',
                        function(allVouchers) {
                            return allVouchers;
                        }
                    ],
                    shoppingCart: [
                        'shoppingCart',
                        function(shoppingCart) {
                            return shoppingCart;
                        }
                    ],
                    shoppingCartConfigs: [
                        'shoppingCartConfigs',
                        function(shoppingCartConfigs) {
                            return shoppingCartConfigs;
                        }
                    ],
                    emailConfig: ['VobeConfig', 'shoppingCart', function(vobeConfig, shoppingCart) {
                        var propertyIds = [];
                        angular.forEach(shoppingCart.shoppingCartItems.items, function(value, key) {
                            if(jQuery.inArray(value.owningProperty.propertyId, this) < 0 ){
                                this.push(value.owningProperty.propertyId);
                            }

                        }, propertyIds);
                        return vobeConfig.emailConfig.get({propertyIds : propertyIds}).$promise;
                    }],
                    voucherPool: [
                        '$transition$',
                        'allVouchers',
                        '$q',
                        function($transition$, allVouchers, $q) {
                            if($transition$.params().voucherPool){
                                return $transition$.params().voucherPool;
                            }
                            //we are looking for particular pool that holds this cash voucher
                            var voucherId;
                            if($transition$.params().voucherId && $transition$.params().voucherId>0){
                                voucherId = $transition$.params().voucherId;
                            }
                            //get the first pool that contains this voucher id
                            var firstPool;
                            var fallbackPool;
                            var selectedPool;
                            if (allVouchers && allVouchers.wrapper && allVouchers.wrapper.voucherPools) {
                                angular.forEach(allVouchers.wrapper.voucherPools, function(voucherPool, pkey) {
                                    var poolHasVoucher = false;
                                    angular.forEach(voucherPool.vouchers, function(voucher, vkey) {
                                        if (!voucherId || (voucher.id == voucherId)) {
                                            poolHasVoucher = true;
                                        }
                                    });
                                    //this is the top most fallback
                                    if(!firstPool){
                                        firstPool = voucherPool;
                                    }
                                    //next fallback is a combined pool with this cashVoucherId
                                    if(!fallbackPool
                                        && poolHasVoucher
                                    ){
                                        fallbackPool = voucherPool;
                                    }
                                    //then we can choose selected pool
                                    if (
                                        (!voucherId || poolHasVoucher)
                                    ){
                                        selectedPool = voucherPool;
                                    }

                                });
                            }
                            var returnPool = selectedPool || fallbackPool || firstPool;
                            var deferred = $q.defer();
                            if(returnPool){
                                deferred.resolve(returnPool);
                            } else {
                                deferred.reject(returnPool);
                            }
                            return deferred.promise;
                        }
                    ],
                    shopMoreState: function($state, $transition$, DetermineShopMoreState) {
                            'ngInject';
                            var shopMoreStateData = DetermineShopMoreState.getShopMoreState();
                            if($transition$.params().contextType=='refunds') {
                                //if we refund bulk voucher, come back to this bulk page
                                var params = {
                                    cat: $transition$.params().cat,
                                    contextType: $transition$.params().contextType,
                                    propertyId: $transition$.params().propertyId,
                                    propertyType: $transition$.params().propertyType
                                };
                                params.bulk = true;
                                shopMoreStateData = {
                                    name: 'bulk-add',
                                    params: params,
                                    url: $state.href('bulk-add', params),
                                };
                                DetermineShopMoreState.setShopMoreState(shopMoreStateData);
                            }
                            return shopMoreStateData;
                        },
                }
            })
            .state('bulk-add.preview', {
                url: '/preview?uuid&emailCategoryId',
                params: {
                    templateItem : undefined
                },
                data: {
                    authorities: [],
                    autoscroll : true
                },
                onEnter: function($transition$, $state, $uibModal, currentProperty, currentPropertyGroup, shoppingCart, currentLanguage, shoppingCartConfigs) {
                    var params = $transition$.params();
                    var modal = $uibModal.open({
                        templateUrl: 'app/site/shoppingcart/shopping-cart-preview.html',
                        controller: 'ShoppingCartPreviewController',
                        controllerAs: 'vm',
                        backdrop: 'true',
                        animation: false,
                        windowClass : '',
                        size: 'lg',
                        resolve : {
                            currentProperty: function(){
                                return currentProperty;
                            },
                            currentPropertyGroup: function(){
                                return currentPropertyGroup;
                            },
                            shoppingCart: function(){
                                return shoppingCart;
                            },
                            currentLanguage: function(){
                                return currentLanguage;
                            },
                            shoppingCartConfigs: function(){
                                return shoppingCartConfigs;
                            },
                            templateItem: function(){
                                return $transition$.params().templateItem;
                            },
                            params: function () {
                                return {
                                    uuid: params.uuid,
                                    src: params.src,
                                    ref: params.ref,
                                    emailCategoryId: params.emailCategoryId,
                                    channel: params.channel
                                };
                            }
                        }
                    });

                    modal.result.then(function() {
                        var stillInShoppingCartPreview = $state.is('bulk-add.preview');
                        if(stillInShoppingCartPreview){
                            $state.go('^', {}, { reload: false,location: 'replace' });
                        }
                    }, function() {
                        var stillInShoppingCartPreview = $state.is('bulk-add.preview');
                        if(stillInShoppingCartPreview){
                            $state.go('^', {}, { reload: false,location: 'replace' });
                        }
                    });

                    $state.currentModal = modal;

                },
                onExit: ['$state',function($state){
                    $state.currentModal.close();
                }]
            })
            .state('terms-bulk-add', {
            parent: 'bulk-add',
            url: '/terms?uuid',
            params: {
                templateItem: undefined
            },
            data: {
                authorities: [],
                autoscroll : true
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'shoppingCart','currentLanguage', function($stateParams, $state, $uibModal, shoppingCart, currentLanguage) {
                var modal = $uibModal.open({
                    templateUrl: 'app/site/shoppingcart/shopping-cart-terms-dialog.html',
                    controller: 'ShoppingCartTermsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'false',
                    animation: false,
                    windowClass : '',
                    size: 'lg',
                    resolve : {
                        shoppingCart: shoppingCart,
                        terms : ['TermsService',function(TermsService){

                            var cartItem = $stateParams.templateItem;


                            var language = currentLanguage;
                            var delivery = cartItem.recipient.deliveryMethodType;
                            var cb = angular.noop;

                            return TermsService.get(
                                {propertyId : cartItem.owningProperty.propertyId,
                                    voucherId : cartItem.itemId,
                                    lang : (language || 'en' ).split('_')[0].split('-')[0],
                                    termsType : delivery
                                },
                                function (response) {
                                    return cb(response);
                                },
                                function (err) {
                                    return cb(err);
                                }.bind(this)).$promise;
                        }]
                    }
                });

                modal.result.then(function() {
                    var stillIn = $state.is('bulk-add.terms');
                    if(stillIn){
                        $state.go('^', {}, { reload: false });
                    }
                }, function() {
                    var stillIn = $state.is('bulk-add.terms');
                    if(stillIn){
                        $state.go('^', {}, { reload: false });
                    }
                });

                $state.currentModal = modal;

            }],
            onExit: ['$state',function($state){
                $state.currentModal.close();
            }]
        });
    }
})();
