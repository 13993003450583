(function () {
    'use strict';

    angular
        .module('vobeApp')
        .factory('ShoppingCartRemove', ShoppingCartRemove);

    ShoppingCartRemove.$inject = ['$resource'];

    function ShoppingCartRemove ($resource) {
        var service = $resource('api/shoppingcart/remove/:itemUUID', {}, {
            'remove': {
                method: 'DELETE',
                transformResponse: function (data) {
                    if(!data || (typeof data === 'string' && data.length==0)){
                        return data;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
})();
